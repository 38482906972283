import { generateRestApiQuery } from '../../helpers/query';
import { ArticleCategoryRequestConfig } from './ArticleCategory';

export const generateArticleCategoryQuery = (config: ArticleCategoryRequestConfig): string => {
    const filters = {
        ...config.categories ? {
            $or: Object.fromEntries(config.categories.map((category, index) => ([
                index,
                {
                    article_category: {
                        category: {
                            $eq: category,
                        },
                    },
                },
            ]))),
        } : {},
        ...config.year ? {
            date: {
                $between: [`${config.year}-01-01`, `${config.year}-12-31`],
            },
        } : {},
        ...config.tag ? {
            tags: {
                tag: {
                    $eq: config.tag,
                },
            },
        } : {},
        ...config.excludeArticleId ? {
            $not: {
                id: {
                    $eq: config.excludeArticleId,
                },
            },
        } : {},
    };

    const { sort, pagination } = config;

    return generateRestApiQuery({ filters, sort, pagination });
};
