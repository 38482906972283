import { convertNumberToCurrency, stringIsNumber } from '../../helpers/number';
import {
    EurolandTickerChange,
    EurolandTickerChangePer,
    EurolandTickerDate,
    EurolandTickerLast,
    EurolandTickerResponse,
    EurolandValueName,
} from '../Euroland/Euroland';
import { getEurolandTickerFormat, getEurolandTickerValueByName } from '../Euroland/EurolandTransformers';
import { SharePriceChangeDirection, SharePriceData } from './SharePrice';

const transformToChangeDirection = (resource?: number): SharePriceChangeDirection => {
    if (resource === 1) return SharePriceChangeDirection.up;
    if (resource === -1) return SharePriceChangeDirection.down;

    return SharePriceChangeDirection.equal;
};

export const transformToSharePrice = (resource: EurolandTickerResponse): SharePriceData => {
    const lastValue = getEurolandTickerValueByName<EurolandTickerLast>(resource, EurolandValueName.last);
    const changeValue = getEurolandTickerValueByName<EurolandTickerChange>(resource, EurolandValueName.change);
    const changePerValue = getEurolandTickerValueByName<EurolandTickerChangePer>(resource, EurolandValueName.changePer);
    const dateValue = getEurolandTickerValueByName<EurolandTickerDate>(resource, EurolandValueName.date);

    const lastFormat = getEurolandTickerFormat<EurolandTickerLast>(lastValue);
    const changeFormat = getEurolandTickerFormat<EurolandTickerChange>(changeValue);
    const changePerFormat = getEurolandTickerFormat<EurolandTickerChangePer>(changePerValue);
    const dateFormat = getEurolandTickerFormat<EurolandTickerDate>(dateValue);

    const lastNumber = lastFormat && stringIsNumber(lastFormat.value)
        ? Number(lastFormat.value)
        : 0;

    const value = lastNumber
        ? convertNumberToCurrency(lastNumber)
        : '-';

    const change = changeFormat
        ? changeFormat.value
        : '';

    const changePercentage = changePerFormat
        ? changePerFormat.value
        : '';

    const changeDirection = transformToChangeDirection(changeValue?.changeIndicator);

    const date = dateFormat
        ? dateFormat.value
        : '';

    return {
        value,
        change,
        changePercentage,
        changeDirection,
        date,
    };
};
