import { transformToArticle } from '../../entities/Article/ArticleTransformers';
import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { Navigation } from '../../entities/Navigation/Navigation';
import { getRestApiCall } from '../../entities/Rest/RestService';
import { ArticleSearchResponse, PageSearchResponse, SolutionSearchResponse } from '../../entities/Search/Search';
import {
    generateArticleSearchQuery,
    generatePageSearchQuery,
    generateRecommendedPageDescriptionsSearchQuery,
    generateSolutionSearchQuery,
} from '../../entities/Search/SearchRequests';
import { transformToPageSearchResult } from '../../entities/Search/SearchTransformers';
import { transformToSolution } from '../../entities/Solution/SolutionTransformers';
import { TypedDispatch } from '../store';
import {
    setArticleResults,
    setError,
    setHasFetchedRecommendedPageResults,
    setIsLoading,
    setIsSuccessful,
    setPageResults,
    setRecommendedPageResults,
    setSolutionResults,
} from './searchReducer';

export const fetchSearchResults = (query: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const pageSearchQuery = generatePageSearchQuery(query);
        const articleSearchQuery = generateArticleSearchQuery(query);
        const solutionSearchQuery = generateSolutionSearchQuery(query);

        const pageSearchResponse = await getRestApiCall<PageSearchResponse>(`/pages?${pageSearchQuery}`);
        const articleSearchResponse = await getRestApiCall<ArticleSearchResponse>(`/articles?${articleSearchQuery}`);
        const solutionSearchResponse = await getRestApiCall<SolutionSearchResponse>(`/solutions?${solutionSearchQuery}`);

        if (!isFetchResultSuccessful(pageSearchResponse)) {
            dispatch(setError(pageSearchResponse.error));
            return;
        }

        if (!isFetchResultSuccessful(articleSearchResponse)) {
            dispatch(setError(articleSearchResponse.error));
            return;
        }

        if (!isFetchResultSuccessful(solutionSearchResponse)) {
            dispatch(setError(solutionSearchResponse.error));
            return;
        }

        const { data: pageResultsData } = pageSearchResponse.data;
        const { data: articleResultsData } = articleSearchResponse.data;
        const { data: solutionResultsData } = solutionSearchResponse.data;

        const pageResults = pageResultsData
            ? pageResultsData.map(transformToPageSearchResult)
            : [];

        const articleResults = articleResultsData
            ? articleResultsData.map(transformToArticle)
            : [];

        const solutionResults = solutionResultsData
            ? solutionResultsData.map(transformToSolution)
            : [];

        dispatch(setPageResults(pageResults));
        dispatch(setArticleResults(articleResults));
        dispatch(setSolutionResults(solutionResults));

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[fetchSearchResults]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const generateRecommendedPages = (mainNavigation: Navigation) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setHasFetchedRecommendedPageResults(false));
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const recommendedPageBases = mainNavigation.list.map(page => ({
            title: page.label,
            slug: page.href.slice(1),
        }));

        const slugs = recommendedPageBases.map(page => page.slug);

        const recommendedPagesQuery = generateRecommendedPageDescriptionsSearchQuery(slugs);

        const recommendedPagesResponse = await getRestApiCall<PageSearchResponse>(`/pages?${recommendedPagesQuery}&populate=*`);

        if (!isFetchResultSuccessful(recommendedPagesResponse)) {
            dispatch(setError(recommendedPagesResponse.error));
            return;
        }

        const { data: pageDescriptionsData } = recommendedPagesResponse.data;

        const recommendedPages = pageDescriptionsData
            ? pageDescriptionsData.map(transformToPageSearchResult)
            : [];

        dispatch(setRecommendedPageResults(recommendedPages));
    } catch (error) {
        console.error('[generateRecommendedPages]', error);
    } finally {
        dispatch(setHasFetchedRecommendedPageResults(true));
        dispatch(setIsLoading(false));
    }
};
