import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
    RefAttributes,
    useEffect,
    useRef,
} from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { Button, RootPortal } from '../../components';
import useTrans from '../../hooks/useTrans';

import './CookieSettings.scss';

interface CookieSettingsProps extends RefAttributes<HTMLDivElement> {
    isRevealed: boolean;
    cookiePagePath: string;
    onAccept: () => void;
    className?: string;
}

const CookieSettings: ForwardRefExoticComponent<CookieSettingsProps> = forwardRef(({
    isRevealed,
    cookiePagePath,
    onAccept,
    className = '',
}, ref: Ref<HTMLDivElement>): ReactElement => {
    const trans = useTrans();
    const acceptButtonRef = useRef<HTMLButtonElement>(null);

    useEffect((): void => {
        if (acceptButtonRef.current) {
            acceptButtonRef.current.focus();
        }
    }, [acceptButtonRef]);

    const cookieSettingsClassNames = classNames('cookie-settings', {
        'cookie-settings--is-revealed': isRevealed,
    }, className);

    return (
        <RootPortal>
            <div ref={ref} className={cookieSettingsClassNames}>
                <div className="cookie-settings__wrapper">
                    <div className="cookie-settings__card">
                        <p className="cookie-settings__description">
                            {trans('containers.cookieSettings.description.partOne')}

                            <Link to={cookiePagePath} className="cookie-settings__link">
                                {trans('containers.cookieSettings.description.readMore')}
                            </Link>

                            {trans('containers.cookieSettings.description.partTwo')}
                        </p>

                        <div className="cookie-settings__button-wrapper">
                            <Button
                                ref={acceptButtonRef}
                                text={trans('containers.cookieSettings.accept')}
                                onClick={onAccept}
                                className="cookie-settings__accept-button"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </RootPortal>
    );
});

export default CookieSettings;
