export const getStringPrefixOrSuffix = (value = ''): { value: string, prefix?: string; suffix?: string } => {
    const firstChar = value.charAt(0);
    const lastChar = value.charAt(value.length - 1);

    const firstCharIsNotNumber = Number.isNaN(parseInt(firstChar, 10));
    const lastCharIsNotNumber = Number.isNaN(parseInt(lastChar, 10));

    if (firstCharIsNotNumber) {
        return { value: value.substring(1), prefix: firstChar };
    }

    if (lastCharIsNotNumber) {
        return { value: value.substring(0, value.length - 1), suffix: lastChar };
    }

    return { value };
};
