import { FC, ReactElement, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { PageSectionRenderer } from '../../containers';
import { NotFoundPage } from '../../pages';
import { setHasDataFromServer } from '../../redux/app/appReducer';
import { fetchPage } from '../../redux/page/pageActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

interface ConnectedPageSectionRendererProps {
    className?: string;
}

const ConnectedPageSectionRenderer: FC<ConnectedPageSectionRendererProps> = ({
    className = '',
}): ReactElement => {
    const { pathname } = useLocation();

    const dispatch = useTypedDispatch();

    const hasDataFromServer = useTypedSelector(state => state.appReducer.hasDataFromServer);

    const mainNavigation = useTypedSelector(state => state.navigationReducer.mainNavigation);

    const hasFetched = useTypedSelector(state => state.pageReducer.hasFetched);
    const isLoading = useTypedSelector(state => state.pageReducer.isLoading);
    const pageData = useTypedSelector(state => state.pageReducer.pageData);
    const breadcrumbs = useTypedSelector(state => state.pageReducer.breadcrumbs);

    useEffect((): void => {
        if (!mainNavigation) return;

        if (hasDataFromServer) {
            dispatch(setHasDataFromServer(false));
            return;
        }

        dispatch(fetchPage(pathname));
    }, [pathname, mainNavigation]);

    if (hasFetched && !pageData) {
        return (
            <NotFoundPage />
        );
    }

    return (
        <PageSectionRenderer
            isLoading={isLoading}
            header={pageData?.header}
            breadcrumbs={breadcrumbs}
            sections={pageData?.sections || []}
            className={className}
        />
    );
};

export default ConnectedPageSectionRenderer;
