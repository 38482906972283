import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { PageSection } from '../../PageSection/PageSection';
import {
    AnalystCoverageTableInterface,
    AnalystCoverageTableResource,
    AnalystCoverageTableRow,
    AnalystCoverageTableRowResource,
} from './AnalystCoverageTable';

const transformToAnalystCoverageTableRow = (resource: AnalystCoverageTableRowResource): AnalystCoverageTableRow => ({
    broker: resource.broker,
    analyst: resource.analyst,
    targetPrice: resource.targetPrice,
    recommendation: resource.recommendation,
});

export const transformToAnalystCoverageTable = (resource: AnalystCoverageTableResource): AnalystCoverageTableInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    const rows = resource.analystRows
        ? resource.analystRows.map(transformToAnalystCoverageTableRow)
        : [];

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.analystCoverageTable,
        title: resource.title,
        paragraph: resource.paragraph || '',
        rows,
    };
};
