import {
    forwardRef,
    ForwardRefExoticComponent,
    HTMLAttributes,
    PropsWithChildren,
    ReactElement,
    Ref,
    RefAttributes,
    useEffect,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';

import { DefaultComponentAppearance } from '../../entities/Component/Component';
import useCombinedRefs from '../../hooks/useCombinedRef';

import './Section.scss';

interface SectionProps extends Pick<DefaultComponentAppearance, 'isDark'>, HTMLAttributes<HTMLDivElement>, RefAttributes<HTMLDivElement> {
    className?: string;
}

const Section: ForwardRefExoticComponent<PropsWithChildren<SectionProps>> = forwardRef(({
    isDark,
    className = '',
    children,
    ...sectionProps
}, ref: Ref<HTMLDivElement>): ReactElement => {
    const sectionRef = useRef<HTMLDivElement>(null);
    const combinedRef = useCombinedRefs<HTMLDivElement>(sectionRef, ref);

    const [differentPrevNeighbour, setDifferentPrevNeighbour] = useState<boolean>(false);
    const [differentNextNeighbour, setDifferentNextNeighbour] = useState<boolean>(false);

    useEffect((): void => {
        if (!sectionRef.current) return;

        const sectionIsDark = sectionRef.current.getAttribute('data-dark');

        if (sectionRef.current.previousSibling) {
            const prevNeighbour = sectionRef.current.previousSibling as HTMLElement;
            const prevNeighbourIsDark = prevNeighbour.getAttribute('data-dark');

            setDifferentPrevNeighbour(sectionIsDark !== prevNeighbourIsDark);
        }

        if (sectionRef.current.nextSibling) {
            const nextNeighbour = sectionRef.current.nextSibling as HTMLElement;
            const nextNeighbourIsDark = nextNeighbour.getAttribute('data-dark');

            setDifferentNextNeighbour(sectionIsDark !== nextNeighbourIsDark);
        }
    }, [sectionRef.current?.previousSibling, sectionRef.current?.nextSibling]);

    const sectionClassNames = classNames('section', {
        'section--different-prev-neighbour': differentPrevNeighbour,
        'section--different-next-neighbour': differentNextNeighbour,
    }, className);

    return (
        <section
            {...sectionProps}
            ref={combinedRef}
            data-dark={isDark}
            className={sectionClassNames}
        >
            {children}
        </section>
    );
});

export default Section;
