import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { SafeHtml, Section, ThemeTitle } from '../../../components';
import { ContentColumn, SectionWrapper } from '../../../compositions';
import { ContentColumnsInterface } from '../../../entities/@sections/ContentColumns/ContentColumns';

import './ContentColumns.scss';

interface ContentColumnsProps extends ContentColumnsInterface {
    className?: string;
    columnClassName?: string;
    columnTitleClassName?: string;
}

const ContentColumns: FC<ContentColumnsProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    titleHtml,
    paragraphHtml,
    columnWidth,
    columns,
    className = '',
    columnClassName = '',
    columnTitleClassName = '',
}): ReactElement => {
    const columnsClassName = classNames('content-columns__columns', {
        [`content-columns__columns--col-${columnWidth}`]: columnWidth,
    });

    return (
        <Section isDark={isDark} className={`content-columns ${className}`}>
            <SectionWrapper
                noSpacingTop={noSpacingTop}
                noSpacingBottom={noSpacingBottom}
                className="content-columns__wrapper"
            >
                {(titleHtml || paragraphHtml) && (
                    <div className={`content-columns__title-paragraph-wrapper ${columnTitleClassName}`}>
                        {titleHtml && (
                            <ThemeTitle
                                titleHtml={titleHtml}
                                className="content-columns__title"
                            />
                        )}

                        {paragraphHtml && (
                            <SafeHtml
                                html={paragraphHtml}
                                className="content-columns__paragraph"
                            />
                        )}
                    </div>
                )}

                <div className={columnsClassName}>
                    {columns.map(column => (
                        <ContentColumn
                            {...column}
                            key={column.id}
                            className={`content-columns__column ${columnClassName}`}
                        />
                    ))}
                </div>
            </SectionWrapper>
        </Section>
    );
};

export default ContentColumns;
