import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { AppRoute, appRoutes } from '../../../../constants/routing';
import { Solution } from '../../../../entities/Solution/Solution';
import { replaceUrlParamKeysWithValues } from '../../../../helpers/url';
import useTrans from '../../../../hooks/useTrans';

import './SolutionSearchResultItem.scss';

interface SolutionSearchResultItemProps extends Solution {
    className?: string;
}

const SolutionSearchResultItem: FC<SolutionSearchResultItemProps> = ({
    title,
    slug,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const paramPath = trans(appRoutes[AppRoute.solution].path);
    const solutionPath = replaceUrlParamKeysWithValues(paramPath, { slug });

    return (
        <div className={`solution-search-result-item ${className}`}>
            <h2 className="solution-search-result-item__title">
                <Link to={solutionPath} className="solution-search-result-item__link">
                    {title}
                </Link>
            </h2>

            <p className="solution-search-result-item__read-more-label">
                {trans('common.readMore')}
            </p>
        </div>
    );
};

export default SolutionSearchResultItem;
