import { FC, ReactElement, useEffect } from 'react';

import { Calendar } from '../../containers';
import { DefaultComponentAppearance } from '../../entities/Component/Component';
import { fetchEvents } from '../../redux/events/eventsActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

interface ConnectedCalendarProps extends DefaultComponentAppearance {
    className?: string;
}

const ConnectedCalendar: FC<ConnectedCalendarProps> = ({
    className = '',
    ...defaultComponent
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const isFetched = useTypedSelector(state => state.eventsReducer.isSuccessful);
    const isLoading = useTypedSelector(state => state.eventsReducer.isLoading);
    const events = useTypedSelector(state => state.eventsReducer.events);
    const pastEvents = useTypedSelector(state => state.eventsReducer.pastEvents);

    useEffect((): void => {
        if (!isFetched) {
            dispatch(fetchEvents());
        }
    }, []);

    return (
        <Calendar
            {...defaultComponent}
            isLoading={isLoading}
            events={events}
            pastEvents={pastEvents}
            className={className}
        />
    );
};

export default ConnectedCalendar;
