import { FC, ReactElement } from 'react';

import { ShareholderTableInterface } from '../../../entities/@sections/ShareholderTable/ShareholderTable';
import useFormatDate from '../../../hooks/useFormatDate';
import useTrans from '../../../hooks/useTrans';
import { GenericTableSection } from '../..';

import './Shareholders.scss';

interface ShareholdersProps extends ShareholderTableInterface {
    className?: string;
}

const Shareholders: FC<ShareholdersProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    title,
    paragraph,
    rows,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const formatDate = useFormatDate();

    const tableHeaders = [
        trans('containers.shareholders.mandatoryDisclosingParty'),
        trans('containers.shareholders.ownership'),
        trans('containers.shareholders.dateOfLastDisclosure'),
    ];

    const tableRows = rows.map(row => [
        row.mandatoryDisclosingParty,
        row.ownership,
        formatDate(row.dateOfLastDisclosure),
    ]);

    return (
        <GenericTableSection
            isDark={isDark}
            noSpacingTop={noSpacingTop}
            noSpacingBottom={noSpacingBottom}
            title={title}
            paragraph={paragraph}
            columns={[2, 1, 1]}
            tableHeaders={tableHeaders}
            rows={tableRows}
            className={`shareholders ${className}`}
            tableClassName="shareholders__table"
            wrapperClassName="shareholders__wrapper"
        />
    );
};

export default Shareholders;
