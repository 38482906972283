import { QueryPaginationObject, QuerySortObject } from '../../helpers/query';
import { Entity, Meta } from '../../types';
import { Article, ArticleEntityResource } from '../Article/Article';
import { DateResource } from '../Date/Date';
import { PaginationInterface, PaginationMetaResource } from '../Pagination/Pagination';

export enum ArticleCategoryKeyResource {
    newsRelease = 'News Release',
    pressRelease = 'Press Release',
    shareBuyback = 'Share Buyback',
}

export interface ArticleCategoryResource {
    category?: string;
    articles?: Entity<ArticleEntityResource[]>;
    createdAt?: DateResource;
    updatedAt?: DateResource;
}

export interface ArticleCategoryEntityResource {
    id?: string;
    attributes?: ArticleCategoryResource;
}

export interface ArticleCategoryRequestConfig {
    key: string;
    categories: string[];
    year?: number;
    tag?: string;
    excludeArticleId?: string;
    sort?: QuerySortObject;
    pagination?: QueryPaginationObject;
}

export enum ArticleCategoryKey {
    default = 'default',
    newsRelease = 'news-release',
    pressRelease = 'press-release',
    shareBuyback = 'share-buyback',
}

export type ArticleCategoryResponse = Entity<ArticleEntityResource[]> & Meta<PaginationMetaResource>;

export interface ArticleCategoryInterface {
    articles: Article[];
    pagination?: PaginationInterface;
}
