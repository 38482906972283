import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { getRestApiCall } from '../../entities/Rest/RestService';
import { SolutionResponse } from '../../entities/Solution/Solution';
import { generateSolutionDetailQuery } from '../../entities/Solution/SolutionRequests';
import { transformToSolution } from '../../entities/Solution/SolutionTransformers';
import { TypedDispatch } from '../store';
import {
    setError,
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
    setSolutionDetail,
} from './solutionDetailReducer';

export const fetchSolutionDetail = (slug: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setSolutionDetail(undefined));
    dispatch(setHasFetched(false));
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const solutionDetailQuery = generateSolutionDetailQuery(slug);

        const solutionResponse = await getRestApiCall<SolutionResponse>(`/solutions?${solutionDetailQuery}`);

        if (!isFetchResultSuccessful(solutionResponse)) {
            dispatch(setError(solutionResponse.error));
            return;
        }

        const { data: solutionData } = solutionResponse.data;

        const solutionDataResource = solutionData
            ? solutionData[0]
            : undefined;

        const solutionDetail = solutionDataResource?.attributes
            ? transformToSolution(solutionDataResource)
            : undefined;

        dispatch(setSolutionDetail(solutionDetail));

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[fetchSolutionDetail]', error);
    } finally {
        dispatch(setHasFetched(true));
        dispatch(setIsLoading(false));
    }
};
