import { FC, ReactElement } from 'react';

import { Section } from '../../../components';
import { ContentColumn, SectionWrapper } from '../../../compositions';
import { ConnectedNewsletterForm } from '../../../connectors';
import { NewsletterFormInterface } from '../../../entities/@sections/NewsletterForm/NewsletterForm';

import './NewsletterFormSection.scss';

interface NewsLetterFormSectionProps extends NewsletterFormInterface {
    formTitle?: string;
    formButtonLabel?: string;
    className?: string;
}

const NewsletterFormSection: FC<NewsLetterFormSectionProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    titleHtml,
    paragraphHtml,
    formTitle,
    formButtonLabel,
    className = '',
}): ReactElement => (
    <Section isDark={isDark} className={`newsletter-form-section ${className}`}>
        <SectionWrapper noSpacingTop={noSpacingTop} noSpacingBottom={noSpacingBottom}>
            <div className="newsletter-form-section__wrapper">
                <div className="newsletter-form-section__content-wrapper">
                    <ContentColumn
                        titleHtml={titleHtml}
                        paragraphHtml={paragraphHtml}
                        className="newsletter-form-section__content"
                    />
                </div>

                <ConnectedNewsletterForm
                    formTitle={formTitle}
                    formButtonLabel={formButtonLabel}
                    className="newsletter-form-section__form"
                />
            </div>
        </SectionWrapper>
    </Section>
);

export default NewsletterFormSection;
