/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconVisibilityOff: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={100} viewBoxHeight={86.3} className={className}>
        <path className="st0" d="M50,18.2c12.5,0,22.7,10.2,22.7,22.7c0,2.9-0.6,5.7-1.6,8.3l13.3,13.3C91.2,56.9,96.7,49.4,100,41C92.1,21,72.7,6.9,50,6.9C43.6,6.9,37.6,8,31.8,10l9.9,9.8C44.3,18.8,47,18.2,50,18.2z" />
        <path className="st0" d="M49.2,27.3l14.4,14.3c0-0.2,0-0.5,0-0.7c0-7.5-6.1-13.6-13.6-13.6C49.7,27.3,49.5,27.3,49.2,27.3" />
        <path className="st0" d="M10.3,0L4.5,5.8l10.4,10.4l2,2C9.4,24.1,3.6,31.8,0,40.9C7.9,60.8,27.3,75,50,75c7,0,13.8-1.4,19.9-3.8l1.9,1.9l13.3,13.3l5.8-5.8L10.3,0z M50,63.6c-12.5,0-22.7-10.2-22.7-22.7c0-3.6,0.9-6.9,2.4-10l7,7c-0.2,1-0.4,1.9-0.4,3c0,7.5,6.1,13.6,13.6,13.6c1,0,2-0.1,3-0.4l7,7C57,62.7,53.6,63.6,50,63.6z" />
    </Svg>
);

export default IconVisibilityOff;
/* eslint-enable max-len */
