import { FC, useEffect, useState } from 'react';

import {
    GoogleMap,
    Marker,
    MarkerClusterer,
    useJsApiLoader,
} from '@react-google-maps/api';

import { containerStyle, markerClustererStyles, options } from '../../constants';
import worldWideLocations from '../../constants/locations-tkh.json';
import { getMarkerName, getPathToClusterIcon, getPathToMarkerIcon } from '../../helpers';
import { Tooltip } from '..';

export enum WorldMapTag {
    keyLocation = 'key location',
    salesOffices = 'sales offices',
    manuAssemble = 'manu / assemble',
    researchAndDevelopment = 'R&D / engineering',
}

export interface CustomMarker {
    name: string;
    type: string;
    tags: WorldMapTag[];
    street: string;
    zipCode?: string;
    city?: string;
    country: string;
    geoCode: {
        lat: number;
        lng: number;
    };
    tooltipOpen: boolean;
}

export enum MarkerType {
    tkhGroup = 'tkh group',
    smartVision = 'smart vision',
    smartConnectivity = 'smart connectivity',
    smartManufacturing = 'smart manufacturing',
}

export const MarkerColorsType = {
    [MarkerType.tkhGroup]: 'yellow',
    [MarkerType.smartVision]: 'turquoise',
    [MarkerType.smartConnectivity]: 'light-blue',
    [MarkerType.smartManufacturing]: 'dark-blue',
};

interface WorldMapProps {
    activeFilters: Set<MarkerType>;
}

const WorldMap: FC<WorldMapProps> = ({ activeFilters }) => {
    const [map, setMap] = useState<google.maps.Map | null>(null);
    const [markers, setMarkers] = useState<CustomMarker[]>([]);
    const [filteredMarkers, setFilteredMarkers] = useState<CustomMarker[]>([]);

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_MAP_KEY || '',
    });

    useEffect(() => {
        const locations = Object.values(worldWideLocations).map(location => ({
            ...location,
            tooltipOpen: false,
        })) as CustomMarker[];

        setMarkers(locations);
        setFilteredMarkers(locations);
    }, []);

    const onLoad = (mapInstance: google.maps.Map) => setMap(mapInstance);
    const onUnmount = () => setMap(null);

    const handleMarkerClick = (marker: CustomMarker) => {
        if (map === null) {
            return;
        }

        const zoomPositionForTooltip = 3;

        if (map.getZoom()! < zoomPositionForTooltip) {
            map.setZoom(zoomPositionForTooltip);
        }

        map.setCenter(marker.geoCode);

        const updatedMarkers = filteredMarkers.map((m) => {
            if (m !== marker) {
                return {
                    ...m,
                    tooltipOpen: false,
                };
            }

            return {
                ...m,
                tooltipOpen: !m.tooltipOpen,
            };
        });

        setFilteredMarkers(updatedMarkers);
    };

    useEffect(() => {
        if (activeFilters.size === 0) {
            setFilteredMarkers(markers);
        } else {
            setFilteredMarkers(markers.filter(marker => activeFilters.has(marker.type as MarkerType)));
        }
    }, [activeFilters, markers]);

    const countMarkerTypes = (markerIcons: string[]) => {
        const typeCounts: { [key: string]: number } = {};

        markerIcons.forEach((marker) => {
            if (typeCounts[marker]) {
                typeCounts[marker] += 1;
            } else {
                typeCounts[marker] = 1;
            }
        });

        return typeCounts;
    };

    const getMostFrequentType = (markerIcons: string[]) => {
        const typeCounts = countMarkerTypes(markerIcons);

        let mostFrequentType: string | null = null;
        let maxCount = 0;

        Object.entries(typeCounts).forEach(([type, count]) => {
            if (count > maxCount) {
                maxCount = count;
                mostFrequentType = type;
            }
        });

        return mostFrequentType;
    };

    const getClusterIcon = (freshMarkers: google.maps.Marker[]) => {
        const markerIcons = freshMarkers.map((marker) => getMarkerName(marker.getIcon() as string));
        const mostFrequentType = getMostFrequentType(markerIcons as string[])!;

        return getPathToClusterIcon(mostFrequentType);
    };

    const calculateClusterMarkers = (clusterMarkers: google.maps.Marker[], numStyles: number) => {
        const index = Math.min(clusterMarkers.length, numStyles);
        const iconUrl = getClusterIcon(clusterMarkers);

        for (let i = 0; i < markerClustererStyles.length; i += 1) {
            markerClustererStyles[i].url = iconUrl;
        }

        return {
            text: clusterMarkers.length.toString(),
            index,
            url: iconUrl,
        };
    };

    if (!isLoaded) {
        return null;
    }

    return (
        <GoogleMap
            mapContainerStyle={containerStyle}
            options={options}
            onLoad={onLoad}
            onUnmount={onUnmount}
        >
            <MarkerClusterer
                styles={markerClustererStyles}
                calculator={calculateClusterMarkers}
                gridSize={50}
            >
                {(clusterer) => (
                    <>
                        {filteredMarkers.map((marker) => (
                            <div key={`${marker.geoCode.lat}-${marker.geoCode.lng}`}>
                                <Marker
                                    key={`${marker.geoCode.lat}-${marker.geoCode.lng}`}
                                    position={marker.geoCode}
                                    options={{ icon: getPathToMarkerIcon(marker.type) }}
                                    onClick={() => handleMarkerClick(marker)}
                                    clusterer={clusterer}
                                />

                                {marker.tooltipOpen && marker.tags.includes(WorldMapTag.keyLocation) && (
                                    <Tooltip
                                        key={`info-${marker.geoCode.lat}-${marker.geoCode.lng}`}
                                        marker={marker}
                                        onClick={handleMarkerClick}
                                    />
                                )}
                            </div>
                        ))}
                    </>
                )}
            </MarkerClusterer>
        </GoogleMap>
    );
};

export default WorldMap;
