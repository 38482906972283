import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { defaultGlobalSettings, GlobalSettings } from '../../entities/GlobalSettings/GlobalSettings';
import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type GlobalSettingsState = AsyncReduxState<{
    settings: GlobalSettings;
}>;

const initialState: GlobalSettingsState = {
    ...initialAsyncReduxState,
    settings: defaultGlobalSettings,
};

const globalSettingsSlice = createSlice({
    name: 'globalSettingsReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): GlobalSettingsState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): GlobalSettingsState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): GlobalSettingsState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setSettings(state, action: PayloadAction<GlobalSettings>): GlobalSettingsState {
            return {
                ...state,
                settings: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsSuccessful,
    setError,
    setSettings,
} = globalSettingsSlice.actions;

export default globalSettingsSlice;
