import { FC, ReactElement, useState } from 'react';

import { Section } from '../../../components';
import { FinancialResultsTable, SectionWrapper, YearSelector } from '../../../compositions';
import { FinancialResultsTableListInterface } from '../../../entities/@sections/FinancialResultsTableList/FinancialResultsTableList';

import './FinancialResults.scss';

interface FinancialResultsProps extends FinancialResultsTableListInterface {
    className?: string;
}

const FinancialResults: FC<FinancialResultsProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    showYearSelector,
    title,
    years,
    className = '',
}): ReactElement => {
    const [activeYear, setActiveYear] = useState<number | undefined>(years[0]?.year);

    const activeTable = years.find(year => year.year === activeYear);

    return (
        <Section isDark={isDark} className={`financial-results ${className}`}>
            <SectionWrapper noSpacingTop={noSpacingTop} noSpacingBottom={noSpacingBottom}>
                <h2 className="financial-results__title">
                    {title}
                </h2>

                {showYearSelector && years.length > 1 && (
                    <YearSelector
                        activeYear={activeYear}
                        years={years.map(year => year.year)}
                        onSelect={setActiveYear}
                        className="financial-results__year-selector"
                    />
                )}

                {showYearSelector && activeTable && (
                    <FinancialResultsTable
                        {...activeTable}
                        className="financial-results__table"
                    />
                )}

                {!showYearSelector && (
                    <ul>
                        {years.map(year => (
                            <li key={year.year}>
                                <h3 className="financial-results__year-title">
                                    {year.year}
                                </h3>

                                <FinancialResultsTable
                                    {...year}
                                    className="financial-results__table"
                                />
                            </li>
                        ))}
                    </ul>
                )}
            </SectionWrapper>
        </Section>
    );
};

export default FinancialResults;
