import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { PageSection } from '../../PageSection/PageSection';
import { transformToPerson } from '../../Person/PersonTransformers';
import { ContactPersonFormInterface, ContactPersonFormResource } from './ContactPersonForm';

export const transformToContactPersonForm = (resource: ContactPersonFormResource): ContactPersonFormInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    const person = resource.person?.data
        ? transformToPerson(resource.person.data)
        : undefined;

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.contactPersonForm,
        title: resource.title,
        person,
    };
};
