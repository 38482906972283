import { FC, ReactElement } from 'react';

import { Picture } from '../../../../../components';
import { Video } from '../../../../../compositions';
import { Testimonial } from '../../../../../entities/Testimonial/Testimonial';

import './TestimonialMedia.scss';

export interface TestimonialMediaProps extends Testimonial {
    isDisabled?: boolean;
    className?: string;
}

const TestimonialMedia: FC<TestimonialMediaProps> = ({
    isDisabled,
    image,
    video,
    className = '',
}): ReactElement => {
    if (video) {
        return (
            <Video
                {...video}
                controls
                isKeyboardNavigationDisabled={isDisabled}
                isPlaybackDisabled={isDisabled}
                className={`testimonial-media ${className}`}
            />
        );
    }

    return (
        <Picture
            {...image}
            hasScaling
            className={`testimonial-media ${className}`}
            imageClassName="testimonial-media__image"
        />
    );
};

export default TestimonialMedia;
