export interface CookieSettingsData {
    cookieSettingsVisible: boolean;
    cookieSettingsAccepted: boolean;
    cookieSettingsBannerHeight: number;
}

export const defaultCookieSettings: CookieSettingsData = {
    cookieSettingsVisible: true,
    cookieSettingsAccepted: false,
    cookieSettingsBannerHeight: 0,
};
