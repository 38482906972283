import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { Picture } from '../../../../../components';
import { AppRoute, appRoutes } from '../../../../../constants/routing';
import { ImageFormatSize } from '../../../../../entities/Media/Media';
import { Solution } from '../../../../../entities/Solution/Solution';
import { replaceUrlParamKeysWithValues } from '../../../../../helpers/url';
import useTrans from '../../../../../hooks/useTrans';

import './SolutionCard.scss';

export interface SolutionCardProps extends Solution {
    className?: string;
}

const SolutionCard: FC<SolutionCardProps> = ({
    slug,
    title,
    image,
    buttonLabel,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const paramPath = trans(appRoutes[AppRoute.solution].path);
    const solutionPath = replaceUrlParamKeysWithValues(paramPath, { slug });

    return (
        <div className={`solution-card ${className}`}>
            <div className="solution-card__image-wrapper">
                {image ? (
                    <Picture
                        hasScaling
                        src={image.src}
                        alt={image.alt}
                        formats={image.formats}
                        formatOverride={ImageFormatSize.small}
                        className="solution-card__picture"
                        imageClassName="solution-card__image"
                    />
                ) : (
                    <div className="solution-card__picture-placeholder" />
                )}
            </div>

            <div className="solution-card__text-wrapper">
                <h2 className="solution-card__title">
                    <Link to={solutionPath} className="solution-card__link">
                        {title}
                    </Link>
                </h2>

                <p className="solution-card__read-more-label">
                    {buttonLabel || trans('containers.solutionsCarousel.readMore')}
                </p>
            </div>
        </div>
    );
};

export default SolutionCard;
