import { transformToHorizontalAlignment } from '../../Alignment/AlignmentTransformers';
import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { transformToLink } from '../../Link/LinkTransformers';
import { ImageObjectFitResource, ObjectFitResource } from '../../Media/Media';
import { transformToImage } from '../../Media/MediaTransformers';
import { PageSection } from '../../PageSection/PageSection';
import {
    ImageObjectFit,
    ImageViewResource,
    TextImageCardInterface,
    TextImageCardResource,
    TextImageInterface,
    TextImageResource,
    TextImageType,
} from './TextImage';

const transformToTextImageType = (resource?: ImageViewResource): TextImageType => {
    if (resource === ImageViewResource.wideImage) return TextImageType.wideImage;
    if (resource === ImageViewResource.narrowImage) return TextImageType.narrowImage;

    return TextImageType.default;
};

const transformToImageObjectFit = (resource?: ImageObjectFitResource): ImageObjectFit => {
    if (resource?.imageFit === ObjectFitResource.fill) return ImageObjectFit.fill;
    if (resource?.imageFit === ObjectFitResource.cover) return ImageObjectFit.cover;

    return ImageObjectFit.contain;
};

const transformToTextImageCard = (resource: TextImageCardResource): TextImageCardInterface => ({
    title: resource.title,
    text: resource.text || '',
});

export const transformToTextImage = (resource: TextImageResource): TextImageInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    const imageType = transformToTextImageType(resource.imageView);
    const imageAlignment = transformToHorizontalAlignment(resource.imageAlignment);

    const links = resource.links
        ? resource.links.map(transformToLink)
        : [];

    const image = resource.image?.data
        ? transformToImage(resource.image.data)
        : undefined;

    const imageObjectFit = transformToImageObjectFit(resource.imageObjectFit);

    const card = resource.card
        ? transformToTextImageCard(resource.card)
        : undefined;

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.textImage,
        imageType,
        imageAlignment,
        isCompact: resource.isCompact,
        hasBigTitle: resource.hasBigTitle,
        titleHtml: resource.title,
        paragraphHtml: resource.paragraphHtml || '',
        caption: resource.caption || '',
        links,
        image,
        imageObjectFit,
        card,
    };
};
