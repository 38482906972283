import { FC, memo, ReactElement } from 'react';

import { Skeleton } from '../../../../components';
import { generateIdArray } from '../../../../helpers/array';
import { randomInBetweenValue } from '../../../../helpers/number';

import './FooterSkeletons.scss';

interface FooterSkeletonsProps {
    className?: string;
    navClassName?: string;
}

const FooterSkeletons: FC<FooterSkeletonsProps> = ({
    className = '',
    navClassName = '',
}): ReactElement => {
    const amountOfGroups = randomInBetweenValue(4, 6);
    const groupSkeletons = generateIdArray(amountOfGroups);

    return (
        <div className={`footer-skeletons ${className}`}>
            {groupSkeletons.map(group => {
                const amountOfItems = randomInBetweenValue(3, 5);
                const items = generateIdArray(amountOfItems);

                return (
                    <div key={group} className={`footer-skeletons__nav ${navClassName}`}>
                        <Skeleton className="footer-skeletons__title" />

                        <ul className="footer-skeletons__list">
                            {items.map(item => (
                                <li key={item} className="footer-skeletons__item">
                                    <Skeleton className="footer-skeletons__item-line" />
                                </li>
                            ))}
                        </ul>
                    </div>
                );
            })}
        </div>
    );
};

export default memo(FooterSkeletons);
