import { generateRestApiQuery } from '../../helpers/query';
import { SolutionTagRequestConfig } from './SolutionTag';

export const generateSolutionTagQuery = (config: SolutionTagRequestConfig): string => {
    const filters = config.tag
        ? {
            tags: {
                tag: {
                    $eq: config.tag,
                },
            },
        }
        : undefined;

    const populate = {
        tags: { populate: ['*'] },
        image: { populate: ['*'] },
        relatedContent: {
            populate: {
                appearance: { populate: ['*'] },
                links: { populate: ['link'] },
            },
        },
        sidebar: { populate: ['title', 'description'] },
    };

    const { sort, pagination } = config;

    return generateRestApiQuery({
        filters,
        populate,
        sort,
        pagination,
    });
};
