import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Section, ThemeTitle } from '../../../components';
import { QuickLink, SectionWrapper } from '../../../compositions';
import { QuickLinksInterface } from '../../../entities/@sections/QuickLinks/QuickLinks';

import './QuickLinksSection.scss';

interface QuickLinksSectionProps extends QuickLinksInterface {
    className?: string;
}

const QuickLinksSection: FC<QuickLinksSectionProps> = ({
    showParagraphs,
    isDark,
    noSpacingTop,
    noSpacingBottom,
    titleHtml,
    links,
    className = '',
}): ReactElement => {
    const quickLinksWrapperClassName = classNames('quick-links-section__wrapper', {
        'quick-links-section__wrapper--has-small-layout': showParagraphs,
        'quick-links-section__wrapper--has-large-layout': !showParagraphs,
    });

    return (
        <Section isDark={isDark} className={`quick-links-section ${className}`}>
            <SectionWrapper
                noSpacingTop={noSpacingTop}
                noSpacingBottom={noSpacingBottom}
                className={quickLinksWrapperClassName}
            >
                {titleHtml && (
                    <ThemeTitle
                        titleHtml={titleHtml}
                        className="quick-links-section__title"
                    />
                )}

                <ul className="quick-links-section__list">
                    {links.map(link => (
                        <li key={link.id} className="quick-links-section__list-item">
                            <QuickLink
                                {...link}
                                isLarge={!showParagraphs}
                                className="quick-links-section__quick-link"
                                linkClassName="quick-links-section__quick-link-link"
                            />
                        </li>
                    ))}
                </ul>
            </SectionWrapper>
        </Section>
    );
};

export default QuickLinksSection;
