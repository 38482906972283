import {
    FC,
    MouseEvent,
    ReactElement,
    useMemo,
} from 'react';

import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import { Button, LinkButton } from '../../../../../components';

import './YearSelectorButton.scss';

interface YearSelectorButtonProps {
    isActive: boolean;
    updateParams?: boolean;
    label: string | number;
    year?: number;
    onClick: (year?: number) => void;
    className?: string;
}

const YearSelectorButton: FC<YearSelectorButtonProps> = ({
    isActive,
    updateParams,
    label,
    year,
    onClick,
    className = '',
}): ReactElement => {
    const location = useLocation();

    const yearLink = useMemo((): string => {
        const { search } = location;
        const params = new URLSearchParams(search);

        params.set('year', year?.toString() || '');

        if (params.has('page')) {
            params.set('page', '1');
        }

        return `?${params.toString()}`;
    }, [location]);

    const handleClick = (): void => {
        onClick(year);
    };

    const handleLinkClick = (event: MouseEvent<HTMLAnchorElement>): void => {
        event.preventDefault();
        onClick(year);
    };

    const buttonClassName = classNames('year-selector-button', {
        'year-selector-button--is-active': isActive,
    }, className);

    return updateParams ? (
        <LinkButton
            to={yearLink}
            text={label.toString()}
            onClick={handleLinkClick}
            className={buttonClassName}
        />
    ) : (
        <Button
            text={label.toString()}
            onClick={handleClick}
            className={buttonClassName}
        />
    );
};

export default YearSelectorButton;
