import { FC, ReactElement } from 'react';

import { Section, ThemeTitle } from '../../../components';
import {
    DownloadList,
    PeopleList,
    SectionWrapper,
    VideoModal,
} from '../../../compositions';
import { PeopleListType } from '../../../entities/@sections/PeopleList/PeopleList';
import { WebcastInterface } from '../../../entities/@sections/Webcast/Webcast';
import useTrans from '../../../hooks/useTrans';

import './Webcast.scss';

interface WebcastProps extends WebcastInterface {
    className?: string;
}

const Webcast: FC<WebcastProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    titleHtml,
    video,
    iframeUrl,
    speakers,
    downloads,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <Section isDark={isDark} className={`webcast ${className}`}>
            <SectionWrapper noSpacingTop={noSpacingTop} noSpacingBottom={noSpacingBottom}>
                <ThemeTitle
                    titleHtml={titleHtml}
                    className="webcast__title"
                />

                {video && (
                    <VideoModal
                        showPoster
                        video={video}
                        className="webcast__video"
                        posterPlaceholderClassName="webcast__poster-placeholder"
                    />
                )}

                {!video && iframeUrl && (
                    <iframe
                        title={trans('containers.webcast.iframeTitle')}
                        src={iframeUrl}
                        className="webcast__iframe"
                    />
                )}

                <div className="webcast__bottom-wrapper">
                    {speakers.length > 0 && (
                        <div className="webcast__speakers-wrapper">
                            <h3 className="webcast__speakers-title">
                                {trans('containers.webcast.speakers')}
                            </h3>

                            <PeopleList
                                layoutType={PeopleListType.hideDescription}
                                people={speakers}
                                className="webcast__speakers-list"
                            />
                        </div>
                    )}

                    {downloads.length > 0 && (
                        <div className="webcast__download-wrapper">
                            <h3 className="webcast__downloads-title">
                                {trans('containers.webcast.downloads')}
                            </h3>

                            <DownloadList
                                files={downloads}
                                className="webcast__downloads"
                            />
                        </div>
                    )}
                </div>
            </SectionWrapper>
        </Section>
    );
};

export default Webcast;
