export enum AppearanceResource {
    light = 'Light',
    dark = 'Dark',
}

export interface DefaultComponentResource {
    __component: string;
}

export interface DefaultComponentAppearanceResource {
    appearance: {
        appearance?: AppearanceResource;
        noSpacingTop: boolean;
        noSpacingBottom: boolean;
    };
}

export interface DefaultComponent {
    reactKey?: string;
    type?: string;
}

export interface DefaultComponentAppearance {
    isDark?: boolean;
    noSpacingTop?: boolean;
    noSpacingBottom?: boolean;
}
