import {
    CSSProperties,
    FC,
    ReactElement,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';
import { useWindowSize } from 'react-use';

import {
    LinkButton,
    Picture,
    ThemeTitle,
    Wrapper,
} from '../../../components';
import { DateIsoString } from '../../../entities/Date/Date';
import { Link } from '../../../entities/Link/Link';
import { Image } from '../../../entities/Media/Media';
import useElementSize from '../../../hooks/useElementSize';
import useFormatDate from '../../../hooks/useFormatDate';
import { Breadcrumbs, HeaderQuickLinksGroup } from '../..';

import './TypeHeader.scss';

interface TypeHeaderProps {
    hideImage?: boolean;
    hasMarginBottom?: boolean;
    hasFullTitleWidth?: boolean;
    breadcrumbs?: Link[];
    titleHtml: string;
    caption?: string;
    link?: Link;
    quickLinks?: Link[];
    location?: string;
    date?: DateIsoString;
    images: Image[];
    className?: string;
    titleClassName?: string;
}

const TypeHeader: FC<TypeHeaderProps> = ({
    hideImage,
    hasMarginBottom,
    hasFullTitleWidth,
    breadcrumbs,
    titleHtml,
    caption,
    link,
    quickLinks,
    location,
    date,
    images,
    className = '',
    titleClassName = '',
}): ReactElement => {
    const headerRef = useRef<HTMLElement>(null);
    const pictureWrapperRef = useRef<HTMLDivElement>(null);
    const pictureRef = useRef<HTMLPictureElement>(null);
    const { width: windowWidth } = useWindowSize();
    const { height: headerHeight } = useElementSize(headerRef);
    const formatDate = useFormatDate();

    const protrusionHeight = 112;
    const [pictureHeight, setPictureHeight] = useState(0);

    useEffect((): void => {
        if (!pictureRef.current || !pictureWrapperRef.current) {
            return;
        }

        const pictureTop = pictureRef.current.offsetTop;
        const pictureWrapperTop = pictureWrapperRef.current.offsetTop;

        const calculatedPictureHeight = headerHeight - pictureTop - pictureWrapperTop + protrusionHeight;

        setPictureHeight(calculatedPictureHeight);
    }, [pictureRef, headerHeight, windowWidth]);

    const hasDarkNeighbour = useMemo((): boolean => {
        if (!headerRef.current?.nextSibling) return false;

        const neighbour = headerRef.current.nextSibling as HTMLElement;

        return neighbour.getAttribute('data-dark') === 'true';
    }, [headerRef.current?.nextSibling]);

    const image = !hideImage && images?.length ? images[0] : undefined;

    const headerClassName = classNames('type-header', {
        'type-header--has-dark-neighbour': hasDarkNeighbour,
        'type-header--has-margin-bottom': hasMarginBottom,
        'type-header--has-full-title-width': hasFullTitleWidth,
        'type-header--has-meta': location || date,
        'type-header--has-image': !!image,
    }, className);

    const cssVariables = {
        '--header-height': `${headerHeight}px`,
        '--header-picture-height': `${pictureHeight}px`,
        '--header-protrusion-height': `${protrusionHeight}px`,
    } as CSSProperties;

    return (
        <header
            data-dark="true"
            ref={headerRef}
            style={cssVariables}
            className={headerClassName}
        >
            <Wrapper className="type-header__wrapper">
                <div className="type-header__content">
                    {breadcrumbs && (
                        <Breadcrumbs
                            links={breadcrumbs}
                            className="type-header__breadcrumbs"
                        />
                    )}

                    <ThemeTitle
                        titleHtml={titleHtml}
                        className={`type-header__title ${titleClassName}`}
                    />

                    {caption && (
                        <h2 className="type-header__caption">
                            {caption}
                        </h2>
                    )}

                    {location && (
                        <p className="type-header__location">
                            {location}
                        </p>
                    )}

                    {date && (
                        <p className="type-header__date">
                            {formatDate(date)}
                        </p>
                    )}

                    {link && (
                        <LinkButton
                            to={link.path}
                            text={link.label}
                            className="type-header__link-button"
                        />
                    )}

                    {quickLinks && (
                        <HeaderQuickLinksGroup
                            links={quickLinks}
                            className="type-header__quick-links"
                        />
                    )}
                </div>

                {image && (
                    <div
                        ref={pictureWrapperRef}
                        className="type-header__picture-wrapper"
                    >
                        <Picture
                            ref={pictureRef}
                            src={image.src}
                            alt={image.alt}
                            formats={image.formats}
                            className="type-header__picture"
                            imageClassName="type-header__image"
                        />
                    </div>
                )}
            </Wrapper>
        </header>
    );
};

export default TypeHeader;
