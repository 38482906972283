/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconMail: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={24} viewBoxHeight={18} className={className}>
        <path d="M2.25 4.07812L12 10.7812L21.75 4.07812V2.25H2.25V4.07812ZM21.75 6.79688L12 13.5L2.25 6.79688V15.75H21.75V6.79688ZM0 15.75V5.25V2.25V0H2.25H21.75H24V2.25V5.25V15.75V18H21.75H2.25H0V15.75Z" />
    </Svg>
);

export default IconMail;
/* eslint-enable max-len */
