import { FC, ReactElement } from 'react';

import { TableCell, TableRow } from '../../../../../components';
import { ShareholderDocumentTableRowInterface } from '../../../../../entities/@sections/ShareholderDocuments/ShareholderDocuments';
import { LabeledDownloadButton } from '../../../..';

import './ShareholderDocumentsTableRow.scss';

interface ShareholderDocumentsTableRowProps extends ShareholderDocumentTableRowInterface {
    cellClassName?: string;
    cellContentClassName?: string;
    className?: string;
}

const ShareholderDocumentsTableRow: FC<ShareholderDocumentsTableRowProps> = ({
    category,
    files,
    className = '',
    cellClassName,
    cellContentClassName,
}): ReactElement => (
    <TableRow className={`shareholder-documents-table-row ${className}`}>
        <TableCell
            className={`shareholder-documents-table-row__cell ${cellClassName}`}
            contentClassName={`shareholder-documents-table-row__cell-content ${cellContentClassName}`}
        >
            {category}
        </TableCell>

        <TableCell
            className={`shareholder-documents-table-row__cell ${cellClassName}`}
            contentClassName={`shareholder-documents-table-row__cell-content ${cellContentClassName}`}
        >
            {files.map(file => (
                <LabeledDownloadButton
                    key={file.title}
                    file={file}
                    className="shareholder-documents-table-row__download-button"
                />
            ))}
        </TableCell>
    </TableRow>
);

export default ShareholderDocumentsTableRow;
