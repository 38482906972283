import { FC, ReactElement } from 'react';

import { Picture, SafeHtml } from '../../../../../components';
import { sdgIcons } from '../../../../../constants/sdgs';
import { SdgCarouselSlideInterface } from '../../../../../entities/@sections/SdgCarousel/SdgCarousel';

import './Sdg.scss';

export interface SdgProps extends SdgCarouselSlideInterface {
    className?: string;
}

const Sdg: FC<SdgProps> = ({
    type,
    title,
    bodyHtml,
    className = '',
}): ReactElement => {
    const icon = sdgIcons[type];

    return (
        <div
            style={{ backgroundColor: icon.color }}
            className={`sdg ${className}`}
        >
            <Picture
                src={icon.image}
                alt={icon.name}
                className="sdg__logo"
            />

            <h2 className="sdg__title">
                {title}
            </h2>

            <SafeHtml
                html={bodyHtml}
                className="sdg__intro"
            />
        </div>
    );
};

export default Sdg;
