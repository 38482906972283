import { FC, ReactElement } from 'react';

import { Section } from '../../../components';
import { SectionWrapper } from '../../../compositions';
import { ConnectedContactForm } from '../../../connectors';
import { ContactCompanyFormInterface } from '../../../entities/@sections/ContactCompanyForm/ContactCompanyForm';
import { ContactCompany } from './subcomponents';

import './ContactCompanyForm.scss';

interface ContactCompanyFormProps extends ContactCompanyFormInterface {
    className?: string;
}

const ContactCompanyForm: FC<ContactCompanyFormProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    title,
    subtitle,
    addresses,
    className = '',
}): ReactElement => (
    <Section isDark={isDark} className={`contact-company-form ${className}`}>
        <SectionWrapper noSpacingTop={noSpacingTop} noSpacingBottom={noSpacingBottom}>
            <h2 className="contact-company-form__title">
                {title}
            </h2>

            <div className="contact-company-form__content">
                <ContactCompany
                    title={subtitle}
                    addresses={addresses}
                    className="contact-company-form__contact-company"
                />

                <ConnectedContactForm />
            </div>
        </SectionWrapper>
    </Section>
);

export default ContactCompanyForm;
