export enum StatusCode {
    unauthorized = 401,
    forbidden = 403,
    notFound = 404,
    gone = 410,
    server = 500,
}

interface AsyncReduxProperties {
    isLoading: boolean;
    isSuccessful: boolean;
    error: string;
    statusCode?: StatusCode;
}

export type AsyncReduxState<T = unknown> = Readonly<{
    [P in keyof T]: T[P];
} & AsyncReduxProperties>;

export const initialAsyncReduxState: AsyncReduxState = {
    isLoading: false,
    isSuccessful: false,
    error: '',
};
