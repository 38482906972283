import { EurolandTickerFormat, EurolandTickerResponse, EurolandTickerValueBase } from './Euroland';

export const getEurolandTickerValueByName = <T extends EurolandTickerValueBase>(
    resource?: EurolandTickerResponse,
    name?: T['name'],
): T | undefined => {
    if (!resource || !name) return undefined;

    return resource.Values.find(obj => obj.name === name) as T | undefined;
};

export const getEurolandTickerFormat = <T extends EurolandTickerValueBase>(resource?: T): EurolandTickerFormat | undefined => {
    if (!resource) return undefined;

    return resource.Formats.length > 0
        ? resource.Formats[0]
        : undefined;
};
