import { FC, ReactElement } from 'react';

import { ShareBuybackHistoryTableInterface } from '../../../entities/@sections/ShareBuybackHistoryTable/ShareBuybackHistoryTable';
import { convertNumberToCurrency } from '../../../helpers/number';
import useTrans from '../../../hooks/useTrans';
import { GenericTableSection } from '../..';

import './ShareBuybackHistory.scss';

interface ShareBuybackHistoryProps extends ShareBuybackHistoryTableInterface {
    className?: string;
}

const ShareBuybackHistory: FC<ShareBuybackHistoryProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    title,
    paragraph,
    rows,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const tableHeaders = [
        trans('containers.shareBuybackHistory.yearAnnounced'),
        trans('containers.shareBuybackHistory.totalAmountPaid'),
        trans('containers.shareBuybackHistory.totalSharesPurchased'),
        trans('containers.shareBuybackHistory.averagePricePaid'),
        trans('containers.shareBuybackHistory.downloadProgram'),
    ];

    const tableRows = rows.map(row => [
        row.yearAnnounced.toString(),
        convertNumberToCurrency(row.totalAmountPaid),
        row.totalSharesPurchased.toLocaleString('en-GB'),
        convertNumberToCurrency(row.averagePricePaid),
        row.file,
    ]);

    return (
        <GenericTableSection
            isDark={isDark}
            noSpacingTop={noSpacingTop}
            noSpacingBottom={noSpacingBottom}
            title={title}
            paragraph={paragraph}
            tableHeaders={tableHeaders}
            rows={tableRows}
            className={`share-buyback-history ${className}`}
            tableClassName="share-buyback-history__table"
        />
    );
};

export default ShareBuybackHistory;
