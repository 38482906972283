import { FC, ReactElement } from 'react';

import { AnalystCoverageTableInterface } from '../../../entities/@sections/AnalystCoverageTable/AnalystCoverageTable';
import { convertNumberToCurrency } from '../../../helpers/number';
import useTrans from '../../../hooks/useTrans';
import { GenericTableSection } from '../..';

interface AnalystCoverageProps extends AnalystCoverageTableInterface {
    className?: string;
}

const AnalystCoverage: FC<AnalystCoverageProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    title,
    paragraph,
    rows,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const tableHeaders = [
        trans('containers.analystCoverage.broker'),
        trans('containers.analystCoverage.analyst'),
        trans('containers.analystCoverage.targetPrice'),
        trans('containers.analystCoverage.publicationDate'),
    ];

    const tableRows = rows.map(row => [
        row.broker,
        row.analyst,
        convertNumberToCurrency(row.targetPrice),
        row.recommendation,
    ]);

    return (
        <GenericTableSection
            isDark={isDark}
            noSpacingTop={noSpacingTop}
            noSpacingBottom={noSpacingBottom}
            title={title}
            paragraph={paragraph}
            columns={[1, 2, 1, 2]}
            tableHeaders={tableHeaders}
            rows={tableRows}
            className={`analyst-coverage ${className}`}
            tableClassName="analyst-coverage__table"
            wrapperClassName="analyst-coverage__wrapper"
        />
    );
};

export default AnalystCoverage;
