import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import {
    LinkButton,
    Picture,
    SafeHtml,
    Section,
    ThemeTitle,
} from '../../../components';
import { ImpactCard, SectionWrapper } from '../../../compositions';
import { ImageObjectFit, TextImageInterface } from '../../../entities/@sections/TextImage/TextImage';

import './TextImage.scss';

interface TextImageProps extends TextImageInterface {
    className?: string;
    contentClassName?: string;
    imageClassName?: string;
    pictureClassName?: string;
}

const TextImage: FC<TextImageProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    hasBigTitle,
    isCompact,
    imageType,
    titleHtml,
    paragraphHtml,
    caption,
    links = [],
    image,
    imageAlignment,
    imageObjectFit,
    card,
    className = '',
    contentClassName = '',
    imageClassName = '',
    pictureClassName = '',
}): ReactElement => {
    const sectionClassName = classNames('text-image', {
        'text-image--has-link': !!links.length,
        'text-image--has-big-title': hasBigTitle,
        'text-image--has-image-object-fit-contain': imageObjectFit === ImageObjectFit.contain,
        'text-image--is-compact': isCompact,
        'text-image--has-card': !!card,
        [`text-image--is-${imageType}-type`]: imageType,
        [`text-image--image-align-${imageAlignment}`]: imageAlignment,
    }, className);

    return (
        <Section isDark={isDark} className={sectionClassName}>
            <SectionWrapper
                noSpacingTop={noSpacingTop}
                noSpacingBottom={noSpacingBottom}
                className="text-image__wrapper"
            >
                <div className="text-image__inner-wrapper">
                    <div className={`text-image__content ${contentClassName}`}>
                        {caption && (
                            <div className="text-image__caption">
                                {caption}
                            </div>
                        )}

                        <ThemeTitle
                            titleHtml={titleHtml}
                            className="text-image__title"
                        />

                        <SafeHtml
                            html={paragraphHtml}
                            className="text-image__paragraph"
                        />

                        {!!links.length && (
                            <ul className="text-image__link-button-list">
                                {links.map(link => (
                                    <li key={link.path} className="text-image__link-button-item">
                                        <LinkButton
                                            to={link.path}
                                            text={link.label}
                                            className="text-image__link-button"
                                        />
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>

                    <div className="text-image__picture-wrapper">
                        {image && (
                            <Picture
                                hasScaling
                                src={image.src}
                                alt={image.alt}
                                formats={image.formats}
                                className={`text-image__picture ${pictureClassName}`}
                                imageClassName={`text-image__image ${imageClassName}`}
                            />
                        )}

                        {card && (
                            <div className="text-image__card-wrapper">
                                <ImpactCard
                                    title={card.title}
                                    text={card.text}
                                    className="text-image__impact-card"
                                />
                            </div>
                        )}
                    </div>
                </div>
            </SectionWrapper>
        </Section>
    );
};

export default TextImage;
