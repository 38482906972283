import { PressReleaseFormData } from '../../../../entities/@forms/PressReleaseForm/PressReleaseForm';
import {
    FormErrors,
    FormValidation,
    validateEmailAddress,
    validateForm,
    validateName,
} from '../../../../entities/FormValidation/FormValidationService';

export type PressReleaseFormErrors = FormErrors<PressReleaseFormData>;

export const validatePressReleaseFormData = (formData: PressReleaseFormData): FormValidation<PressReleaseFormErrors> => {
    const errors: PressReleaseFormErrors = {
        name: validateName('name', formData.name),
        email: validateEmailAddress(formData.email),
    };

    return validateForm<PressReleaseFormErrors>(errors);
};
