/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconX: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={16} viewBoxHeight={14} className={className}>
        <path d="M12.1562 0.5H14.3438L9.53125 6.03125L15.2188 13.5H10.7812L7.28125 8.96875L3.3125 13.5H1.09375L6.25 7.625L0.8125 0.5H5.375L8.5 4.65625L12.1562 0.5ZM11.375 12.1875H12.5938L4.71875 1.75H3.40625L11.375 12.1875Z" />
    </Svg>
);

export default IconX;
/* eslint-enable max-len */
