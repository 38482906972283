import { generateRestApiQuery } from '../../helpers/query';

export const generatePageSearchQuery = (query: string): string => {
    const filters = {
        $or: {
            0: {
                title: {
                    $contains: query,
                },
            },
            1: {
                slug: {
                    $contains: query,
                },
            },
            2: {
                seo: {
                    metaTitle: {
                        $contains: query,
                    },
                },
            },
            3: {
                seo: {
                    metaDescription: {
                        $contains: query,
                    },
                },
            },
            4: {
                seo: {
                    keywords: {
                        $contains: query,
                    },
                },
            },
        },
    };

    const populate = {
        seo: { populate: ['*'] },
    };

    const pagination = {
        pageSize: 10,
    };

    return generateRestApiQuery({ filters, populate, pagination });
};

export const generateArticleSearchQuery = (query: string): string => {
    const filters = {
        $or: {
            0: {
                title: {
                    $contains: query,
                },
            },
            1: {
                slug: {
                    $contains: query,
                },
            },
            2: {
                description: {
                    $contains: query,
                },
            },
        },
    };

    const populate = {
        article_category: { populate: ['*'] },
    };

    const pagination = {
        pageSize: 10,
    };

    return generateRestApiQuery({ filters, populate, pagination });
};

export const generateSolutionSearchQuery = (query: string): string => {
    const filters = {
        $or: {
            0: {
                title: {
                    $contains: query,
                },
            },
            1: {
                slug: {
                    $contains: query,
                },
            },
            2: {
                description: {
                    $contains: query,
                },
            },
        },
    };

    const pagination = {
        pageSize: 10,
    };

    return generateRestApiQuery({ filters, pagination });
};

export const generateRecommendedPageDescriptionsSearchQuery = (slugs: string[]): string => {
    const filters = {
        $or: Object.fromEntries(slugs.map((slug, index) => ([
            index,
            {
                slug: {
                    $contains: slug,
                },
            },
        ]))),
    };

    const pagination = {
        pageSize: 10,
    };

    return generateRestApiQuery({ filters, pagination });
};
