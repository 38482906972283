export enum EurolandValueName {
    last = 'last',
    changePer = 'changePer',
    change = 'change',
    date = 'date',
}

export interface EurolandTickerFormat {
    format: string;
    value: string;
}

export interface EurolandTickerValueBase {
    name: EurolandValueName;
    Formats: EurolandTickerFormat[];
}

export interface EurolandTickerLast extends EurolandTickerValueBase {
    name: EurolandValueName.last;
}

export interface EurolandTickerChangePer extends EurolandTickerValueBase {
    changeIndicator: number;
    name: EurolandValueName.changePer;
}

export interface EurolandTickerChange extends EurolandTickerValueBase {
    changeIndicator: number;
    name: EurolandValueName.change;
}

export interface EurolandTickerDate extends EurolandTickerValueBase {
    name: EurolandValueName.date;
}

export type EurolandTickerValue = EurolandTickerLast | EurolandTickerChangePer | EurolandTickerChange | EurolandTickerDate;

export interface EurolandTickerResponse {
    e: number;
    Values: EurolandTickerValue[];
}
