import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { PageSection } from '../../PageSection/PageSection';
import { NewsletterCallToActionInterface, NewsletterCallToActionResource } from './NewsletterCallToAction';

export const transformToNewsletterCallToAction = (resource: NewsletterCallToActionResource): NewsletterCallToActionInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.newsletterCallToAction,
        title: resource.title,
        buttonLabel: resource.buttonLabel,
    };
};
