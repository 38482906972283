import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { PageSection } from '../../PageSection/PageSection';
import {
    FixedComponentInterface,
    FixedComponentResource,
    FixedComponentType,
    FixedComponentTypeResource,
} from './FixedComponent';

const transformToFixedComponentType = (resource: FixedComponentTypeResource): FixedComponentType => {
    if (resource === FixedComponentTypeResource.map) return FixedComponentType.map;
    if (resource === FixedComponentTypeResource.share) return FixedComponentType.share;
    if (resource === FixedComponentTypeResource.sharePerformance) return FixedComponentType.sharePerformance;
    if (resource === FixedComponentTypeResource.calendar) return FixedComponentType.calendar;

    return FixedComponentType.vacancies;
};

export const transformToFixedComponent = (resource: FixedComponentResource): FixedComponentInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    const component = transformToFixedComponentType(resource.component);

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.fixedComponent,
        component,
    };
};
