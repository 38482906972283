import { RefObject } from 'react';

export const scrollToTop = (behavior: ScrollBehavior = 'smooth'): void => {
    if (typeof window !== 'undefined') {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior,
        });
    }
};

export const scrollIntoView = <T extends HTMLElement>(ref?: RefObject<T>): void => {
    if (!ref?.current) return;

    ref.current.scrollIntoView({
        behavior: 'smooth',
    });
};
