import { EventsResponse } from '../../entities/Event/Event';
import { generateEventsQuery, generatePastEventsQuery } from '../../entities/Event/EventRequests';
import { transformToEvent } from '../../entities/Event/EventTransformers';
import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { transformToPagination } from '../../entities/Pagination/PaginationTransformers';
import { getRestApiCall } from '../../entities/Rest/RestService';
import { TypedDispatch } from '../store';
import {
    setError,
    setEvents,
    setEventsPagination,
    setIsLoading,
    setIsSuccessful,
    setPastEvents,
    setPastEventsPagination,
} from './eventsReducer';

export const fetchEvents = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const today = new Intl.DateTimeFormat('en-CA').format(new Date());

        const eventsQuery = generateEventsQuery(today);
        const pastEventsQuery = generatePastEventsQuery(today);

        const eventsResponse = await getRestApiCall<EventsResponse>(`/events?${eventsQuery}&populate=*`);
        const pastEventsResponse = await getRestApiCall<EventsResponse>(`/events?${pastEventsQuery}&populate=*`);

        if (!isFetchResultSuccessful(eventsResponse)) {
            dispatch(setError(eventsResponse.error));
            return;
        }

        if (!isFetchResultSuccessful(pastEventsResponse)) {
            dispatch(setError(pastEventsResponse.error));
            return;
        }

        const { data: eventsData, meta: eventsMeta } = eventsResponse.data;
        const { data: pastEventsData, meta: pastEventsMeta } = pastEventsResponse.data;

        const events = eventsData
            ? eventsData.map(transformToEvent)
            : [];

        const pastEvents = pastEventsData
            ? pastEventsData.map(transformToEvent)
            : [];

        const eventsPagination = eventsMeta
            ? transformToPagination(eventsMeta.pagination)
            : undefined;

        const pastEventsPagination = pastEventsMeta
            ? transformToPagination(pastEventsMeta.pagination)
            : undefined;

        dispatch(setEvents(events));
        dispatch(setEventsPagination(eventsPagination));

        dispatch(setPastEvents(pastEvents));
        dispatch(setPastEventsPagination(pastEventsPagination));

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[fetchEvents]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
