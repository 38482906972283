import { FC, ReactElement } from 'react';

import { Section } from '../../../components';
import { SectionWrapper } from '../../../compositions';
import { DisclaimerInterface } from '../../../entities/@sections/Disclaimer/Disclaimer';

import './Disclaimer.scss';

interface DisclaimerProps extends DisclaimerInterface {
    className?: string;
}

const Disclaimer: FC<DisclaimerProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    paragraph,
    className = '',
}): ReactElement => (
    <Section isDark={isDark} className={`disclaimer ${className}`}>
        <SectionWrapper noSpacingTop={noSpacingTop} noSpacingBottom={noSpacingBottom}>
            <p className="disclaimer__paragraph">
                {paragraph}
            </p>
        </SectionWrapper>
    </Section>
);

export default Disclaimer;
