import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { transformToKeyFigure } from '../../KeyFigure/KeyFigureTransformers';
import { PageSection } from '../../PageSection/PageSection';
import { HighlightedKeyFigureInterface, HighlightedKeyFigureResource } from './HighlightedKeyFigure';

export const transformToHighlightedKeyFigure = (resource: HighlightedKeyFigureResource): HighlightedKeyFigureInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    const keyFigure = transformToKeyFigure(resource.keyFigure);

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.highlightedKeyFigure,
        title: resource.title,
        paragraph: resource.paragraph || '',
        keyFigure,
    };
};
