import {
    Alignment,
    AlignmentResource,
    HorizontalAlignment,
    HorizontalAlignmentResource,
} from './Alignment';

export const transformToAlignment = (resource?: AlignmentResource): Alignment => {
    if (resource === AlignmentResource.vertical) return Alignment.vertical;

    return Alignment.horizontal;
};

export const transformToHorizontalAlignment = (resource?: HorizontalAlignmentResource): HorizontalAlignment => {
    if (resource === HorizontalAlignmentResource.right) return HorizontalAlignment.right;

    return HorizontalAlignment.left;
};
