import { FC, ReactElement, useState } from 'react';

import { Section } from '../../../components';
import { FlipCard, SectionWrapper } from '../../../compositions';
import { FlipCardListInterface } from '../../../entities/@sections/FlipCardList/FlipCardList';

import './FlipCardList.scss';

interface FlipCardListProps extends FlipCardListInterface {
    className?: string;
}

const FlipCardList: FC<FlipCardListProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    title,
    cards,
    className = '',
}): ReactElement => {
    const [maxFaceHeight, setMaxFaceHeight] = useState<number>(0);

    const handleLoadFlipCard = (faceHeight: number): void => {
        if (faceHeight > maxFaceHeight) {
            setMaxFaceHeight(faceHeight);
        }
    };

    return (
        <Section isDark={isDark} className={`flip-card-list ${className}`}>
            <SectionWrapper noSpacingTop={noSpacingTop} noSpacingBottom={noSpacingBottom}>
                {title && (
                    <h2 className="flip-card-list__title">
                        {title}
                    </h2>
                )}

                <ul className="flip-card-list__list">
                    {cards.map(card => (
                        <li key={card.id}>
                            <FlipCard
                                {...card}
                                key={maxFaceHeight}
                                heightOverride={maxFaceHeight}
                                onLoad={handleLoadFlipCard}
                            />
                        </li>
                    ))}
                </ul>
            </SectionWrapper>
        </Section>
    );
};

export default FlipCardList;
