import {
    DefaultComponent,
    DefaultComponentAppearance,
    DefaultComponentAppearanceResource,
    DefaultComponentResource,
} from '../../Component/Component';
import { Link, LinkResource } from '../../Link/Link';

export enum TextColumnsTypeResource {
    singleColumn = 'Single column [2]',
    singleWideColumn = 'Single wide column. [4]',
    twoColumns = 'Two equal-width columns [2, 2]  ',
    twoWideColumns = 'Two columns, each with three sections [3, 3]',
    threeColumns = 'Three equal-width columns [2, 2, 2]',
}

export interface TextColumnsResource extends DefaultComponentResource, DefaultComponentAppearanceResource {
    id: string;
    title?: string;
    intro?: string;
    paragraph?: string;
    link?: LinkResource;
    outerLink?: LinkResource;
    formatting?: TextColumnsTypeResource;
}

export enum TextColumnsType {
    singleColumn = '2',
    singleWideColumn = '4',
    twoColumns = '2_2',
    twoWideColumns = '3_3',
    threeColumns = '2_2_2',
}

export type ColumnsType = [2] | [4] | [2, 2] | [3, 3] | [2, 2, 2];

export const textColumnsTypes: Record<TextColumnsType, ColumnsType> = {
    [TextColumnsType.singleColumn]: [2],
    [TextColumnsType.singleWideColumn]: [4],
    [TextColumnsType.twoColumns]: [2, 2],
    [TextColumnsType.twoWideColumns]: [3, 3],
    [TextColumnsType.threeColumns]: [2, 2, 2],
};

export interface TextColumnsInterface extends DefaultComponent, DefaultComponentAppearance {
    columnsType: ColumnsType;
    titleHtml: string;
    intro?: string;
    paragraphHtml: string;
    link?: Link;
    outerLink?: Link;
}
