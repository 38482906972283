import { generateRestApiQuery, QuerySortDirection } from '../../helpers/query';

export const generateEventsQuery = (date: string): string => {
    const filters = date
        ? {
            date: {
                $gte: date,
            },
        }
        : undefined;

    const sort = {
        date: QuerySortDirection.asc,
    };

    return generateRestApiQuery({ filters, sort });
};

export const generatePastEventsQuery = (date: string): string => {
    const filters = date
        ? {
            date: {
                $lt: date,
            },
        }
        : undefined;

    const sort = {
        date: QuerySortDirection.desc,
    };

    return generateRestApiQuery({ filters, sort });
};
