import { FC, PropsWithChildren, ReactElement } from 'react';

import classNames from 'classnames';

import { TableCell, TableRow } from '../../../../../components';

import './FinancialResultsRowMobile.scss';

interface MobileFinancialResultsTableRowProps {
    isSubordinate?: boolean;
    label: string;
    className?: string;
}

const FinancialResultsRowMobile: FC<PropsWithChildren<MobileFinancialResultsTableRowProps>> = ({
    isSubordinate,
    label,
    className = '',
    children,
}): ReactElement => {
    const tableRowClassName = classNames('financial-results-row-mobile', {
        'financial-results-row-mobile--is-subordinate': isSubordinate,
        'financial-results-row-mobile--has-element': children && typeof children !== 'string',
    }, className);

    return (
        <TableRow className={tableRowClassName}>
            <TableCell className="financial-results-row-mobile__cell">
                {label}
            </TableCell>
            <TableCell className="financial-results-row-mobile__cell">
                {children}
            </TableCell>
        </TableRow>
    );
};

export default FinancialResultsRowMobile;
