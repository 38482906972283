/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconAt: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={24} viewBoxHeight={25} className={className}>
        <g clipPath="url(#clip0_2411_24655)">
            <path
                d="M17.5295 14.7742C17.5295 15.7822 17.8163 16.1842 18.5663 16.1842C20.2379 16.1842 21.3023 14.0542 21.3023 10.5118C21.3023 5.09741 17.3567 2.50541 12.4307 2.50541C7.36314 2.50541 2.75394 5.90381 2.75394 12.3262C2.75394 18.4606 6.78594 21.8014 12.9779 21.8014C15.0803 21.8014 16.4915 21.571 18.6503 20.851L19.1135 22.7794C16.9823 23.4718 14.7047 23.6722 12.9491 23.6722C4.82754 23.6722 0.477539 19.2082 0.477539 12.325C0.477539 5.38421 5.51874 0.661011 12.4595 0.661011C19.6883 0.661011 23.5175 4.98101 23.5175 10.2802C23.5175 14.773 22.1075 18.2002 17.6723 18.2002C15.6551 18.2002 14.3315 17.3938 14.1587 15.607C13.6403 17.5942 12.2579 18.2002 10.3847 18.2002C7.87914 18.2002 5.77674 16.2694 5.77674 12.3826C5.77674 8.46581 7.62114 6.04661 10.9331 6.04661C12.6899 6.04661 13.7843 6.73781 14.2715 7.83221L15.1079 6.30581H17.5271V14.7742H17.5295ZM13.9883 10.9726C13.9883 9.38981 12.8063 8.72621 11.8271 8.72621C10.7615 8.72621 9.58194 9.58901 9.58194 12.1246C9.58194 14.1406 10.4747 15.2638 11.8271 15.2638C12.7775 15.2638 13.9883 14.659 13.9883 12.9886V10.9726Z"
                fill="currentColor"
            />
        </g>
        <defs>
            <clipPath id="clip0_2411_24655">
                <rect width="24" height="24" fill="white" transform="translate(0 0.167847)" />
            </clipPath>
        </defs>
    </Svg>
);

export default IconAt;
/* eslint-enable max-len */
