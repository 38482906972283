import {
    CSSProperties,
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import classNames from 'classnames';

import { Picture } from '../../../../../components';
import { Image } from '../../../../../entities/Media/Media';
import usePreferReducedMotion from '../../../../../hooks/usePreferReducedMotion';
import useTimeout from '../../../../../hooks/useTimeout';

import './VisualHeaderCarousel.scss';

interface VisualHeaderCarouselProps {
    isAnimated?: boolean;
    isInView?: boolean;
    images: Image[];
    className?: string;
}

const VisualHeaderCarousel: FC<VisualHeaderCarouselProps> = ({
    isAnimated,
    isInView,
    images,
    className = '',
}): ReactElement => {
    const preferReducedMotion = usePreferReducedMotion();
    const shouldAnimate = isAnimated && !preferReducedMotion;

    const [activeIndex, setActiveIndex] = useState<number>(-1);

    useEffect((): void => {
        setActiveIndex(0);
    }, []);

    useTimeout((): void => {
        if (!isInView || images.length === 0) return;

        const newIndex = activeIndex + 1;

        setActiveIndex(newIndex % images.length);
    }, 5000, [isInView, activeIndex]);

    const handleTransitionEnd = (): void => {
        if (activeIndex === images.length) {
            setActiveIndex(-1);
        }
    };

    return (
        <div className={`visual-header-carousel ${className}`}>
            <div className="visual-header-carousel__overlay" />

            {images.map((image, index) => {
                const pictureClassNames = classNames('visual-header-carousel__picture', {
                    'visual-header-carousel__picture--should-show': activeIndex >= 0 && index <= activeIndex,
                    'visual-header-carousel__picture--no-animation': !shouldAnimate,
                });

                const cssVariables = {
                    '--visual-header-image-index': index,
                } as CSSProperties;

                return (
                    <Picture
                        key={image.src}
                        style={cssVariables}
                        src={image.src}
                        alt={image.alt}
                        formats={image.formats}
                        onTransitionEnd={handleTransitionEnd}
                        className={pictureClassNames}
                        imageClassName="visual-header-carousel__image"
                    />
                );
            })}
        </div>
    );
};

export default VisualHeaderCarousel;
