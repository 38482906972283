import {
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import { Picture } from '../../components';
import { DownloadableFile, Image, Video as VideoType } from '../../entities/Media/Media';
import trans from '../../helpers/trans';
import {
    Dialog,
    IconButton,
    ModalContent,
    Video,
    VideoEmbed,
} from '..';

import './VideoModal.scss';

interface VideoModalProps {
    showPoster?: boolean;
    isKeyboardNavigationDisabled?: boolean;
    video?: VideoType;
    embedUrl?: string;
    poster?: Image;
    closedCaptions?: DownloadableFile;
    className?: string;
    posterPlaceholderClassName?: string;
}

const VideoModal: FC<VideoModalProps> = ({
    showPoster,
    isKeyboardNavigationDisabled,
    video,
    embedUrl,
    poster,
    closedCaptions,
    className = '',
    posterPlaceholderClassName = '',
}): ReactElement => {
    const dialogRef = useRef<HTMLDialogElement>(null);

    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect((): void => {
        const toggleVideoPlayState = async (): Promise<void> => {
            if (!dialogRef.current) return;

            const videoElement = dialogRef.current.querySelector('video');

            if (!videoElement) return;

            if (isOpen) {
                videoElement.currentTime = 0;

                await videoElement.play();
            } else {
                await videoElement.pause();
            }
        };

        toggleVideoPlayState();
    }, [isOpen]);

    const handleOpenDialog = async (): Promise<void> => {
        if (!dialogRef.current) return;

        dialogRef.current.showModal();
        setIsOpen(true);
    };

    const handleCloseDialog = async (): Promise<void> => {
        if (!dialogRef.current) return;

        dialogRef.current.close();
        setIsOpen(false);
    };

    return (
        <div className={`video-modal ${className}`}>
            {showPoster && (
                <div className="video-modal__poster-wrapper">
                    {poster ? (
                        <Picture
                            {...poster}
                            className="video-modal__poster"
                            imageClassName="video-modal__poster-image"
                        />
                    ) : (
                        <div className={`video-modal__poster-placeholder ${posterPlaceholderClassName}`} />
                    )}

                    <div className="video-modal__play-button-wrapper">
                        <IconButton
                            icon="play"
                            tabIndex={isKeyboardNavigationDisabled ? -1 : undefined}
                            text={trans('compositions.video.button.play')}
                            hideLabel
                            onClick={handleOpenDialog}
                            className="video-modal__play-button"
                            iconClassName="video-modal__play-button-icon"
                        />
                    </div>
                </div>
            )}

            {!showPoster && (
                <IconButton
                    icon="play"
                    text={trans('compositions.video.button.play')}
                    onClick={handleOpenDialog}
                    className="video-modal__dialog-button"
                    iconClassName="video-modal__dialog-button-icon"
                />
            )}

            <Dialog
                ref={dialogRef}
                enableBackdropClose
                onClose={handleCloseDialog}
                className="video-modal__dialog"
            >
                <ModalContent className="video-modal__dialog-content">
                    {video && (
                        <Video
                            {...video}
                            controls
                            closedCaptionsSrc={closedCaptions?.src}
                            className="video-modal__video"
                            controlsWrapperClassName="video-modal__video-controls-wrapper"
                            videoClassName="video-modal__video-element"
                        />
                    )}

                    {embedUrl && isOpen && (
                        <VideoEmbed
                            embedUrl={embedUrl}
                            className="video-modal__video-embed"
                        />
                    )}
                </ModalContent>
            </Dialog>
        </div>
    );
};

export default VideoModal;
