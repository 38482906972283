import { Params } from 'react-router-dom';

import { urls } from '../constants';

interface ApiGeneratorOptions {
    apiUrl?: string;
    endpoint: string;
    queryParams?: Record<string, string>;
}

export const generateApiUrl = (options: ApiGeneratorOptions): string => {
    const apiUrl = options.apiUrl || urls.api;

    const url = new URL(apiUrl + options.endpoint);

    if (options.queryParams) {
        const params = Object.entries(options.queryParams);

        for (let i = 0; i < params.length; i += 1) {
            const [key, value] = params[i];
            url.searchParams.set(key, value);
        }
    }

    return String(url);
};

export const replaceUrlValuesWithParamKeys = (url: string, params: Params): string => {
    const paramEntries = Object.entries(params);

    return paramEntries.reduce((accumulatedUrl, [key, value]) => {
        if (!value) return accumulatedUrl;

        return accumulatedUrl.replace(value, `:${key}`);
    }, url);
};

export const replaceUrlParamKeysWithValues = (url: string, params: Params): string => {
    const paramEntries = Object.entries(params);

    return paramEntries.reduce((accumulatedUrl, [key, value]) => {
        if (!value) return accumulatedUrl;

        return accumulatedUrl.replace(`:${key}`, value);
    }, url);
};

export const getFileExtensionFromUrl = (url: string): string | undefined => {
    if (!url.includes('.')) {
        return undefined;
    }

    const extension = url.split('.').pop();

    if (extension?.includes('?')) {
        return extension.split('?')[0];
    }

    return extension;
};
