import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { GlobalSettingsResponse } from '../../entities/GlobalSettings/GlobalSettings';
import { transformToGlobalSettings } from '../../entities/GlobalSettings/GlobalSettingsTransformers';
import { getRestApiCall } from '../../entities/Rest/RestService';
import { TypedDispatch } from '../store';
import {
    setError,
    setIsLoading,
    setIsSuccessful,
    setSettings,
} from './globalSettingsReducer';

export const fetchGlobalSettings = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const response = await getRestApiCall<GlobalSettingsResponse>('/setting?populate=*');

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));
            return;
        }

        const globalSettings = transformToGlobalSettings(response.data);

        dispatch(setSettings(globalSettings));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[fetchGlobalSettings]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
