import { FC, ReactElement, useRef } from 'react';

import { CheckIntersection } from '../../../components';
import { TypeHeader, VisualHeader } from '../../../compositions';
import { HeaderInterface, HeaderLayout } from '../../../entities/Header/HeaderInterface';
import { Link } from '../../../entities/Link/Link';

interface HeaderSectionProps extends HeaderInterface {
    breadcrumbs: Link[];
    className?: string;
    headerEndClassName?: string;
}

const HeaderSection: FC<HeaderSectionProps> = ({
    isAnimated,
    isFullScreen,
    breadcrumbs,
    titleHtml,
    caption,
    date,
    images,
    layout,
    link,
    links = [],
    location,
    className = '',
    headerEndClassName = '',
}): ReactElement => {
    const scrollTargetRef = useRef<HTMLDivElement>(null);

    if (layout === HeaderLayout.fullScreen && images.length > 0) {
        return (
            <>
                <CheckIntersection threshold={0.5}>
                    {isInView => (
                        <VisualHeader
                            isAnimated={isAnimated}
                            isFullScreen={isFullScreen}
                            isInView={isInView}
                            breadcrumbs={breadcrumbs}
                            titleHtml={titleHtml}
                            caption={caption}
                            link={link}
                            quickLinks={links}
                            location={location}
                            date={date}
                            images={images}
                            scrollDownIndicatorTargetRef={scrollTargetRef}
                            className={className}
                        />
                    )}
                </CheckIntersection>

                <div ref={scrollTargetRef} className={headerEndClassName} />
            </>
        );
    }

    return (
        <TypeHeader
            hasMarginBottom
            breadcrumbs={breadcrumbs}
            titleHtml={titleHtml}
            caption={caption}
            link={link}
            quickLinks={links}
            location={location}
            date={date}
            images={images}
            className={`${className} ${headerEndClassName}`}
        />
    );
};

export default HeaderSection;
