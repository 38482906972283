import { sanitizeAllHtml } from './sanitizeHtml';

export const getNodesFromHtmlString = (htmlString: string): ChildNode[] => {
    const doc = new DOMParser().parseFromString(htmlString, 'text/html');
    const { childNodes } = doc.body;

    return Array.from(childNodes).filter(node => node.nodeType === Node.ELEMENT_NODE);
};

export const convertNodesToHtmlString = (nodes: ChildNode[]): string => {
    const tempContainer = document.createElement('div');

    nodes.forEach(node => {
        tempContainer.append(node.cloneNode(true));
    });

    return tempContainer.innerHTML;
};

export const getFirstParagraphContentFromHtmlString = (htmlString: string): string => {
    const match = htmlString.match(/<(\w+)>(.*?)<\/\1>/);

    if (match && match.length >= 3) {
        return sanitizeAllHtml(match[2]);
    }

    return '';
};
