import { FC, ReactElement } from 'react';

import { Section } from '../../../components';
import { DownloadList, SectionWrapper } from '../../../compositions';
import { DownloadListInterface } from '../../../entities/@sections/DownloadList/DownloadList';

import './DownloadListSection.scss';

interface DownloadListSectionProps extends DownloadListInterface {
    className?: string;
}

const DownloadListSection: FC<DownloadListSectionProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    alignment,
    title,
    files,
    className = '',
}): ReactElement => (
    <Section isDark={isDark} className={`download-list-section ${className}`}>
        <SectionWrapper noSpacingTop={noSpacingTop} noSpacingBottom={noSpacingBottom}>
            {title && (
                <h2 className="download-list-section__title">
                    {title}
                </h2>
            )}

            <DownloadList
                alignment={alignment}
                files={files}
                className="download-list-section__list"
            />
        </SectionWrapper>
    </Section>
);

export default DownloadListSection;
