/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconLink: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={20} viewBoxHeight={16} className={className}>
        <path d="M18.0938 8.375L14.5938 11.875C12.8438 13.6562 9.96875 13.6562 8.21875 11.875C6.65625 10.3125 6.4375 7.875 7.71875 6.0625L7.75 6.03125C8.09375 5.5625 8.71875 5.46875 9.15625 5.78125C9.59375 6.09375 9.71875 6.71875 9.375 7.1875L9.34375 7.21875C8.625 8.21875 8.75 9.59375 9.625 10.4688C10.5938 11.4688 12.1875 11.4688 13.1875 10.4688L16.6875 6.96875C17.6875 5.96875 17.6875 4.375 16.6875 3.40625C15.8125 2.53125 14.4375 2.40625 13.4375 3.125L13.4062 3.15625C12.9375 3.5 12.3125 3.375 12 2.9375C11.6875 2.5 11.7812 1.875 12.2188 1.53125L12.2812 1.5C14.0938 0.21875 16.5312 0.4375 18.0938 2C19.875 3.75 19.875 6.625 18.0938 8.375ZM1.875 7.65625L5.375 4.125C7.15625 2.375 10 2.375 11.7812 4.125C13.3438 5.6875 13.5312 8.15625 12.25 9.96875L12.2188 10C11.9062 10.4688 11.25 10.5625 10.8125 10.25C10.375 9.9375 10.25 9.3125 10.5938 8.84375L10.625 8.8125C11.3438 7.78125 11.2188 6.4375 10.3438 5.5625C9.375 4.5625 7.78125 4.5625 6.78125 5.5625L3.28125 9.0625C2.3125 10.0312 2.3125 11.625 3.28125 12.625C4.15625 13.5 5.53125 13.625 6.53125 12.9062L6.5625 12.875C7.03125 12.5312 7.65625 12.6562 7.96875 13.0938C8.28125 13.5312 8.1875 14.1562 7.75 14.5L7.6875 14.5312C5.90625 15.8125 3.4375 15.5938 1.875 14.0312C0.09375 12.2812 0.09375 9.40625 1.875 7.65625Z" />
    </Svg>
);

export default IconLink;
/* eslint-enable max-len */
