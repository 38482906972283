import {
    FC,
    ReactElement,
    useCallback,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';

import { Section } from '../../../components';
import { Carousel, ChevronNavigation, SectionWrapper } from '../../../compositions';
import { VisualCarouselInterface } from '../../../entities/@sections/VisualCarousel/VisualCarousel';
import { clamp } from '../../../helpers/number';
import useDeviceWidth from '../../../hooks/useDeviceWidth';
import useElementSize from '../../../hooks/useElementSize';
import { VisualCarouselSlide } from './subcomponents';

import './VisualCarousel.scss';

interface VisualCarouselProps extends VisualCarouselInterface {
    className?: string;
}

const VisualCarousel: FC<VisualCarouselProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    slides,
    className = '',
}): ReactElement => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const carouselRef = useRef<HTMLDivElement>(null);
    const { isMobile } = useDeviceWidth();
    const { width: slideWidth } = useElementSize(carouselRef);

    const [activeIndex, setActiveIndex] = useState(0);

    const handleChevronClick = useCallback((value: -1 | 1): void => {
        const newIndex = activeIndex + value;
        const clampedValue = clamp(newIndex, 0, slides.length - 1);

        if (!isMobile) {
            setActiveIndex(clampedValue);

            return;
        }

        if (carouselRef.current) {
            carouselRef.current.scroll({
                left: newIndex * slideWidth,
                behavior: 'smooth',
            });
        }
    }, [isMobile, activeIndex, slideWidth]);

    const navigationClassNames = classNames('visual-carousel__navigation', {
        'visual-carousel__navigation--no-spacing-bottom': noSpacingBottom,
    });

    return (
        <Section
            ref={wrapperRef}
            isDark={isDark}
            className={`visual-carousel ${className}`}
        >
            <SectionWrapper
                hideGrid
                noSpacingTop={noSpacingTop}
                noSpacingBottom={noSpacingBottom}
                className="visual-carousel__wrapper"
            >
                <Carousel
                    ref={carouselRef}
                    activeIndex={activeIndex}
                    items={(
                        slides.map(slide => (
                            <VisualCarouselSlide
                                {...slide}
                                key={slide.id}
                                className="visual-carousel__slide"
                            />
                        ))
                    )}
                    slidesLength={slides.length}
                    onMobileActiveIndexChange={setActiveIndex}
                />

                {slides.length > 1 && (
                    <div data-dark="true" className={navigationClassNames}>
                        <ChevronNavigation
                            isPrevButtonDisabled={activeIndex === 0}
                            isNextButtonDisabled={activeIndex === slides.length - 1}
                            onClick={handleChevronClick}
                        />
                    </div>
                )}
            </SectionWrapper>
        </Section>
    );
};

export default VisualCarousel;
