import { FC, ReactElement } from 'react';

import { Details } from '../../../../../components';
import { ContentColumn, ShareholderDocumentsTable } from '../../../../../compositions';
import { ShareholderMeetingsArchiveYearInterface } from '../../../../../entities/@sections/ShareholderMeetingsArchive/ShareholderMeetingsArchive';
import useTrans from '../../../../../hooks/useTrans';

import './ShareholderMeetingsArchiveYear.scss';

interface ShareholderMeetingsArchiveYearProps extends ShareholderMeetingsArchiveYearInterface {
    className?: string;
    summaryClassName?: string;
}

const ShareholderMeetingsArchiveYear: FC<ShareholderMeetingsArchiveYearProps> = ({
    year,
    intro,
    paragraphHtml,
    tableRows,
    className = '',
    summaryClassName = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <Details
            summary={`${year}`}
            className={`shareholder-meetings-archive-year ${className}`}
            summaryClassName={`shareholder-meetings-archive-year__summary ${summaryClassName}`}
        >
            <div className="shareholder-meetings-archive-year__wrapper">
                <ContentColumn
                    introHtml={`<p>${intro}</p>`}
                    paragraphHtml={paragraphHtml}
                    className="shareholder-meetings-archive-year__content-column"
                />

                {tableRows.length > 0 && (
                    <Details
                        summary={trans('containers.shareholderDocuments.title')}
                        className="shareholder-meetings-archive-year__details"
                        summaryClassName="shareholder-meetings-archive-year__details-summary"
                    >
                        <ShareholderDocumentsTable
                            tableRows={tableRows}
                            className="shareholder-meetings-archive-year__table"
                        />
                    </Details>
                )}
            </div>
        </Details>
    );
};

export default ShareholderMeetingsArchiveYear;
