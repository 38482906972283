import { FC, ReactElement } from 'react';

import { Person } from '../../entities/Person/Person';

import './BlockQuote.scss';

export interface BlockQuoteProps {
    text: string;
    author?: Person;
    className?: string;
    textWrapperClassName?: string;
}

const BlockQuote: FC<BlockQuoteProps> = ({
    text,
    author,
    className = '',
    textWrapperClassName = '',
}): ReactElement => (
    <blockquote className={`block-quote ${className}`}>
        <div className={`block-quote__text-wrapper ${textWrapperClassName}`}>
            <p className="block-quote__text">
                {text}
            </p>
        </div>

        <footer className="block-quote__footer">
            {author && (
                <>
                    <p className="block-quote__author">
                        {author.name}
                    </p>

                    <p className="block-quote__jobtitle">
                        {author.jobTitle}
                    </p>
                </>
            )}
        </footer>
    </blockquote>
);

export default BlockQuote;
