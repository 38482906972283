import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { PageSection } from '../../PageSection/PageSection';
import { TellerSharePriceInterface, TellerSharePriceResource } from './TellerSharePrice';

export const transformToTellerSharePrice = (resource: TellerSharePriceResource): TellerSharePriceInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.tellerSharePrice,
        title: resource.title,
        paragraph: resource.paragraph || '',
    };
};
