import { ArticleCategoryEntityResource, ArticleCategoryKey, ArticleCategoryKeyResource } from './ArticleCategory';

export const transformToArticleCategory = (resource?: ArticleCategoryEntityResource): string => {
    if (!resource?.attributes?.category) return '';

    return resource.attributes.category;
};

export const transformToArticleCategoryKey = (resource?: ArticleCategoryEntityResource): ArticleCategoryKey => {
    if (resource?.attributes?.category === ArticleCategoryKeyResource.newsRelease) return ArticleCategoryKey.newsRelease;
    if (resource?.attributes?.category === ArticleCategoryKeyResource.pressRelease) return ArticleCategoryKey.pressRelease;
    if (resource?.attributes?.category === ArticleCategoryKeyResource.shareBuyback) return ArticleCategoryKey.shareBuyback;

    return ArticleCategoryKey.default;
};
