import { NavigationItem } from '../entities/Navigation/Navigation';

export const findNavigationItemByPathname = (pathname: string, list: NavigationItem[]): NavigationItem | undefined => {
    for (let i = 0; i < list.length; i += 1) {
        const item = list[i];
        const itemSplitHref = item.href.split('/');

        if (itemSplitHref[itemSplitHref.length - 1] === pathname) {
            return item;
        }

        const foundItem = findNavigationItemByPathname(pathname, item.subNavigation);

        if (foundItem) {
            return foundItem;
        }
    }

    return undefined;
};

export const findPageCategory = (pathname: string, list: NavigationItem[]): string => {
    for (let i = 0; i < list.length; i += 1) {
        const mainItem = list[i];
        const mainItemSplitHref = mainItem.href.split('/');
        const { subNavigation: mainSubNavigation } = mainItem;

        if (mainItemSplitHref[mainItemSplitHref.length - 1] === pathname) {
            return mainItem.label;
        }

        for (let j = 0; j < mainSubNavigation.length; j += 1) {
            const subItem = mainSubNavigation[j];
            const subItemSplitHref = subItem.href.split('/');
            const { subNavigation: subSubNavigation } = subItem;

            if (subItemSplitHref[subItemSplitHref.length - 1] === pathname) {
                return mainItem.label;
            }

            for (let k = 0; k < subSubNavigation.length; k += 1) {
                const subSubItem = subSubNavigation[k];
                const subSubItemSplitHref = subSubItem.href.split('/');

                if (subSubItemSplitHref[subSubItemSplitHref.length - 1] === pathname) {
                    return mainItem.label;
                }
            }
        }
    }

    return '';
};
