import { FC, ReactElement } from 'react';

import { ThemeTitle } from '../../../../../components';
import { FilterButton } from '..';
import { MarkerType } from '../WorldMap/WorldMap';

import './InteractiveMapHeader.scss';

interface InteractiveMapHeaderProps {
    titleHtml: string;
    onFilterClick: (type: MarkerType) => void;
    className?: string;
}

const InteractiveMapHeader: FC<InteractiveMapHeaderProps> = ({
    titleHtml,
    onFilterClick,
    className = '',
}): ReactElement => (
    <section className={`interactive-map-header ${className}`}>
        <ThemeTitle
            titleHtml={titleHtml}
            className="interactive-map-header__title"
        />

        <div className="interactive-map-header__filters">
            {Object.values(MarkerType).map((type) => (
                <FilterButton
                    key={type}
                    type={type}
                    onClick={onFilterClick}
                />
            ))}
        </div>
    </section>
);

export default InteractiveMapHeader;
