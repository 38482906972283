import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Event } from '../../entities/Event/Event';
import { PaginationInterface } from '../../entities/Pagination/Pagination';
import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type EventsState = AsyncReduxState<{
    events: Event[];
    eventsPagination?: PaginationInterface;
    pastEvents: Event[];
    pastEventsPagination?: PaginationInterface;
}>;

const initialState: EventsState = {
    ...initialAsyncReduxState,
    events: [],
    pastEvents: [],
};

const eventsSlice = createSlice({
    name: 'eventsReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): EventsState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): EventsState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): EventsState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setEvents(state, action: PayloadAction<Event[]>): EventsState {
            return {
                ...state,
                events: action.payload,
            };
        },
        setEventsPagination(state, action: PayloadAction<PaginationInterface | undefined>): EventsState {
            return {
                ...state,
                eventsPagination: action.payload,
            };
        },
        setPastEvents(state, action: PayloadAction<Event[]>): EventsState {
            return {
                ...state,
                pastEvents: action.payload,
            };
        },
        setPastEventsPagination(state, action: PayloadAction<PaginationInterface | undefined>): EventsState {
            return {
                ...state,
                pastEventsPagination: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsSuccessful,
    setError,
    setEvents,
    setEventsPagination,
    setPastEvents,
    setPastEventsPagination,
} = eventsSlice.actions;

export default eventsSlice;
