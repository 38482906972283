import { FC, ReactElement, useRef } from 'react';

import { NavLink, NavLinkProps } from 'react-router-dom';

import useElementSize from '../../../hooks/useElementSize';
import { HomeQuickLinkContent } from './subcomponents';

import './HomeQuickLink.scss';

export interface HomeQuickLinkProps extends NavLinkProps {
    text?: string;
    className?: string;
}

const HomeQuickLink: FC<HomeQuickLinkProps> = ({ text, className = '', ...navLinkProps }): ReactElement => {
    const bottomRef = useRef<HTMLDivElement>(null);

    const { width } = useElementSize(bottomRef);

    return (
        <NavLink
            {...navLinkProps}
            className={`home-quick-link ${className}`}
        >
            <HomeQuickLinkContent
                label={text}
                ref={bottomRef}
                className="home-quick-link__bottom"
            />

            <div className="home-quick-link__top-wrapper">
                <HomeQuickLinkContent
                    label={text}
                    style={{ width }}
                    className="home-quick-link__top"
                />
            </div>
        </NavLink>
    );
};

export default HomeQuickLink;
