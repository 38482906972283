import { FC, ReactElement } from 'react';

import { FinancialResultsTableListYearInterface } from '../../../entities/@sections/FinancialResultsTableList/FinancialResultsTableList';
import { generateIdArray } from '../../../helpers/array';
import useTrans from '../../../hooks/useTrans';
import { NonSortTable } from '../..';
import { FinancialResultsTableRow } from './subcomponents';

import './FinancialResultsTable.scss';

interface FinancialResultsTableProps extends FinancialResultsTableListYearInterface {
    className?: string;
}

const FinancialResultsTable: FC<FinancialResultsTableProps> = ({
    rows,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const tableKeys = generateIdArray(rows.length);

    const tableHeaders = [
        trans('compositions.financialResultsTable.headers.period'),
        trans('compositions.financialResultsTable.headers.releaseDate'),
        trans('compositions.financialResultsTable.headers.pressRelease'),
        trans('compositions.financialResultsTable.headers.webcast'),
        trans('compositions.financialResultsTable.headers.presentation'),
    ];

    return (
        <div className={`financial-results-table ${className}`}>
            <NonSortTable
                tableHeaders={tableHeaders}
                cellClassName="financial-results-table__cell"
                cellContentClassName="financial-results-table__cell-content"
                className="financial-results-table__table"
                headerRowClassName="financial-results-table__header-row"
                tableHeadClassName="financial-results-table__table-head"
            >
                {rows.map((row, index) => (
                    <FinancialResultsTableRow
                        key={tableKeys[index]}
                        {...row}
                        tableHeaders={tableHeaders}
                        cellClassName="financial-results-table__cell"
                    />
                ))}
            </NonSortTable>
        </div>
    );
};

export default FinancialResultsTable;
