import { FC, ReactElement } from 'react';

import { Section, VacancyCard } from '../../components';
import { SectionWrapper } from '../../compositions';
import { DefaultComponentAppearance } from '../../entities/Component/Component';
import { Vacancy } from '../../entities/Vacancy/Vacancy';
import useTrans from '../../hooks/useTrans';
import { VacancyCardListSkeletons } from './skeletons';

import './VacancyCardList.scss';

interface VacancyCardListProps extends DefaultComponentAppearance {
    isLoading: boolean;
    vacancies: Vacancy[];
    className?: string;
}

const VacancyCardList: FC<VacancyCardListProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    isLoading,
    vacancies,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <Section isDark={isDark} className={`vacancy-card-list ${className}`}>
            <SectionWrapper noSpacingTop={noSpacingTop} noSpacingBottom={noSpacingBottom}>
                {isLoading && (
                    <VacancyCardListSkeletons />
                )}

                {!isLoading && vacancies.length === 0 && (
                    <div className="vacancy-card-list__no-results-wrapper">
                        <p className="vacancy-card-list__no-results">
                            {trans('containers.vacancyCardList.noResults')}
                        </p>
                    </div>
                )}

                {!isLoading && vacancies.length > 0 && (
                    <ul className="vacancy-card-list__list">
                        {vacancies.map(vacancy => (
                            <li key={vacancy.id} className="vacancy-card-list__list-item">
                                <VacancyCard vacancy={vacancy} />
                            </li>
                        ))}
                    </ul>
                )}
            </SectionWrapper>
        </Section>
    );
};

export default VacancyCardList;
