import { FC, ReactElement } from 'react';

import { Vacancy } from '../../../entities/Vacancy/Vacancy';
import useTrans from '../../../hooks/useTrans';
import { LinkButton, SafeHtml } from '../..';

import './VacancyCard.scss';

export interface VacancyCardProps {
    vacancy: Vacancy;
    className?: string;
}

const VacancyCard: FC<VacancyCardProps> = ({
    vacancy,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <div className={`vacancy-card ${className}`}>
            <h2 className="vacancy-card__title">
                {vacancy.title}
            </h2>

            {vacancy.subtitle && (
                <h3 className="vacancy-card__subtitle">
                    {vacancy.subtitle}
                </h3>
            )}

            {vacancy.descriptionHtml && (
                <SafeHtml
                    html={vacancy.descriptionHtml}
                    className="vacancy-card__description"
                />
            )}

            {vacancy.file && (
                <LinkButton
                    to={vacancy.file.src}
                    text={trans('components.vacancyCard.download')}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="vacancy-card__download-button"
                />
            )}
        </div>
    );
};

export default VacancyCard;
