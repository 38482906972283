import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { AppRoute, appRoutes } from '../../../../constants/routing';
import { Article } from '../../../../entities/Article/Article';
import { replaceUrlParamKeysWithValues } from '../../../../helpers/url';
import useFormatDate from '../../../../hooks/useFormatDate';
import useTrans from '../../../../hooks/useTrans';

import './ArticleSearchResultItem.scss';

interface ArticleSearchResultItemProps extends Article {
    className?: string;
}

const ArticleSearchResultItem: FC<ArticleSearchResultItemProps> = ({
    title,
    category,
    date,
    slug,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const formatDate = useFormatDate();

    const paramPath = trans(appRoutes[AppRoute.article].path);
    const articlePath = replaceUrlParamKeysWithValues(paramPath, { slug });

    return (
        <div className={`article-search-result-item ${className}`}>
            <h2 className="article-search-result-item__title">
                <Link to={articlePath} className="article-search-result-item__link">
                    {title}
                </Link>
            </h2>

            <div className="article-search-result-item__meta-wrapper">
                <h3 className="article-search-result-item__category">
                    {category}
                </h3>

                <h3 className="article-search-result-item__date">
                    {formatDate(date)}
                </h3>
            </div>

            <p className="article-search-result-item__read-more-label">
                {trans('common.readMore')}
            </p>
        </div>
    );
};

export default ArticleSearchResultItem;
