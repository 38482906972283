import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { transformToDateIsoString } from '../../Date/DateTransformers';
import { transformToLink } from '../../Link/LinkTransformers';
import { transformToDownloadableFile } from '../../Media/MediaTransformers';
import { PageSection } from '../../PageSection/PageSection';
import {
    LatestShareBuybackInterface,
    LatestShareBuybackResource,
    ShareBuybackInterface,
    ShareBuybackLinkInterface,
    ShareBuyBackLinkResource,
    ShareBuybackResource,
} from './ShareBuyback';

const transformToLatestShareBuyback = (resource: LatestShareBuybackResource): LatestShareBuybackInterface => {
    const date = transformToDateIsoString(resource.date);

    const link = transformToLink(resource.link);

    return {
        date,
        title: resource.title,
        link,
    };
};

const transformToShareBuybackLink = (resource: ShareBuyBackLinkResource): ShareBuybackLinkInterface => {
    const file = resource.file?.data
        ? transformToDownloadableFile(resource.file.data)
        : undefined;

    const link = resource.link
        ? transformToLink(resource.link)
        : undefined;

    return {
        id: resource.id,
        title: resource.title,
        file,
        link,
    };
};

export const transformToShareBuyback = (resource: ShareBuybackResource): ShareBuybackInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    const latest = resource.latest
        ? transformToLatestShareBuyback(resource.latest)
        : undefined;

    const links = resource.links
        ? resource.links.map(transformToShareBuybackLink)
        : [];

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.shareBuyback,
        title: resource.title,
        intro: resource.intro,
        paragraph: resource.paragraph || '',
        latest,
        links,
    };
};
