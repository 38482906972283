import { FC, ReactElement } from 'react';

import { Menu } from '../../containers';
import { useTypedSelector } from '../../redux/store';

interface ConnectedMenuProps {
    className?: string;
}

const ConnectedMenu: FC<ConnectedMenuProps> = ({
    className = '',
}): ReactElement => {
    const { mainNavigation, legalNavigation } = useTypedSelector(state => state.navigationReducer);

    return (
        <Menu
            mainNavigation={mainNavigation}
            legalNavigation={legalNavigation}
            className={className}
        />
    );
};

export default ConnectedMenu;
