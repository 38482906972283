import { FC, ReactElement } from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { Picture, Section } from '../../../components';
import { SectionWrapper } from '../../../compositions';
import { AppRoute, appRoutes } from '../../../constants/routing';
import { HighlightedArticleInterface } from '../../../entities/@sections/HighlightedArticle/HighlightedArticle';
import { replaceUrlParamKeysWithValues } from '../../../helpers/url';
import useTrans from '../../../hooks/useTrans';

import './HighlightedArticle.scss';

interface HighlightedArticleProps extends HighlightedArticleInterface {
    className?: string;
}

const HighlightedArticle: FC<HighlightedArticleProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    showImage,
    title,
    article,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const paramPath = trans(appRoutes[AppRoute.article].path);
    const articlePath = replaceUrlParamKeysWithValues(paramPath, {
        slug: article?.slug,
    });

    const sectionClassName = classNames('highlighted-article', {
        'highlighted-article--show-image': showImage,
    }, className);

    return (
        <Section isDark={isDark} className={sectionClassName}>
            <SectionWrapper
                noSpacingTop={noSpacingTop}
                noSpacingBottom={noSpacingBottom}
                className="highlighted-article__wrapper"
            >
                {article && (
                    <Picture
                        {...article.image}
                        hasScaling
                        className="highlighted-article__picture"
                        imageClassName="highlighted-article__image"
                    />
                )}

                <div className="highlighted-article__content">
                    <h2 className="highlighted-article__title">
                        {title}
                    </h2>

                    {article && (
                        <div className="highlighted-article__article-wrapper">
                            <h3 className="highlighted-article__article-title">
                                <Link to={articlePath} className="highlighted-article__link">
                                    {article.title}
                                </Link>
                            </h3>

                            <h4 className="highlighted-article__article-category">
                                {article.category}
                            </h4>

                            <p className="highlighted-article__read-more-label">
                                {trans('common.readMore')}
                            </p>
                        </div>
                    )}
                </div>
            </SectionWrapper>
        </Section>
    );
};

export default HighlightedArticle;
