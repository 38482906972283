import { FC, ReactElement } from 'react';

import { InfoWindow } from '@react-google-maps/api';

import { IconPin } from '../../../../../components/Icon/set';
import { CustomMarker } from '../WorldMap/WorldMap';

import './Tooltip.scss';

interface TooltipProps {
    marker: CustomMarker;
    onClick: (marker: CustomMarker) => void;
}

const Tooltip: FC<TooltipProps> = ({
    marker,
    onClick,
}): ReactElement => {
    const firstTag = marker.tags[0];

    return (
        <InfoWindow
            position={marker.geoCode}
            onCloseClick={() => onClick(marker)}
        >
            <div>
                <strong className="tooltip__tag">
                    {firstTag}
                </strong>

                <h2>{marker.name}</h2>

                <div className="tooltip__address">
                    <IconPin className="tooltip__address--icon" />

                    <div>
                        <p>
                            <small>
                                {marker.street}
                            </small>
                        </p>

                        <p>
                            <small>
                                <span>{marker.zipCode}</span>
                                <span>, </span>
                                <span>{marker.city}</span>
                            </small>
                        </p>

                        <p>
                            <small>
                                {marker.country}
                            </small>
                        </p>
                    </div>
                </div>
            </div>
        </InfoWindow>
    );
};

export default Tooltip;
