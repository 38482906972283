import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { PageSection } from '../../PageSection/PageSection';
import { transformToPerson } from '../../Person/PersonTransformers';
import { PeopleListInterface, PeopleListResource, PeopleListType, PeopleListTypeResource } from './PeopleList';

const transformToPeopleListType = (resource?: PeopleListTypeResource): PeopleListType => {
    if (resource === PeopleListTypeResource.hideDescription) return PeopleListType.hideDescription;
    if (resource === PeopleListTypeResource.hidePortraits) return PeopleListType.hidePortraits;

    return PeopleListType.default;
};

export const transformToPeopleList = (resource: PeopleListResource): PeopleListInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    const layoutType = transformToPeopleListType(resource.type);

    const people = resource.people?.data
        ? resource.people.data.map(transformToPerson)
        : [];

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.peopleList,
        layoutType,
        people,
    };
};
