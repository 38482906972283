/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconCheck: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={100} viewBoxHeight={77} className={className}>
        <path d="M31.8,60.5L8.1,36.8l-8.1,8l31.8,31.8L100,8.4l-8-8L31.8,60.5z" />
    </Svg>
);

export default IconCheck;
/* eslint-enable max-len */
