import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import classNames from 'classnames';

import { Wrapper } from '../../components';
import { ContentColumn, ImpactCard, TypeHeader } from '../../compositions';
import { Solution } from '../../entities/Solution/Solution';
import { isSSR } from '../../helpers';
import { convertNodesToHtmlString, getNodesFromHtmlString } from '../../helpers/html';
import { SanitizeOptions } from '../../helpers/sanitizeHtml';
import { QuickLinksSection } from '..';
import { SolutionDetailSkeletons } from './skeletons';

import './SolutionDetailRenderer.scss';

interface SolutionDetailRendererProps {
    isLoading: boolean;
    solution?: Solution;
    className?: string;
}

const SolutionDetailRenderer: FC<SolutionDetailRendererProps> = ({
    isLoading,
    solution,
    className = '',
}): ReactElement => {
    const [introHtml, setIntroHtml] = useState<string>('');
    const [restHtml, setRestHtml] = useState<string>('');

    useEffect((): void => {
        if (!solution) return;

        const bodyHtmlNodes = getNodesFromHtmlString(solution.body);

        const introNode = bodyHtmlNodes.slice(0, 1);
        const restNodes = bodyHtmlNodes.slice(1);

        const introHtmlString = convertNodesToHtmlString(introNode);
        const restHtmlString = convertNodesToHtmlString(restNodes);

        setIntroHtml(introHtmlString);
        setRestHtml(restHtmlString);
    }, [solution]);

    if (isLoading || !solution) {
        return (
            <SolutionDetailSkeletons />
        );
    }

    const sanitizeOptions: SanitizeOptions = {
        allowedTags: [
            ...['figure', 'figcaption', 'colgroup', 'col'],
            ...['table', 'thead', 'tbody', 'th', 'tr', 'td'],
            ...['hr'],
            ...['iframe'],
        ],
        allowedAttributes: {
            iframe: ['src'],
        },
    };

    const headerImages = solution.image ? [solution.image] : [];
    const { sidebar, relatedContent } = solution;

    const solutionDetailRendererClassNames = classNames('solution-detail-renderer', {
        'solution-detail-renderer--no-image': solution.hideHeaderImage || headerImages.length === 0,
    }, className);

    return (
        <article className={solutionDetailRendererClassNames}>
            <TypeHeader
                hideImage={solution.hideHeaderImage}
                caption={solution.tag}
                titleHtml={solution.headerTitleHtml}
                images={headerImages}
            />

            <Wrapper className="solution-detail-renderer__wrapper">
                <div className="solution-detail-renderer__body-wrapper">
                    {isSSR ? (
                        <ContentColumn
                            paragraphHtml={solution.body}
                            sanitizeOptions={sanitizeOptions}
                            className="solution-detail-renderer__body"
                        />
                    ) : (
                        <ContentColumn
                            introHtml={introHtml}
                            paragraphHtml={restHtml}
                            sanitizeOptions={sanitizeOptions}
                            className="solution-detail-renderer__body"
                        />
                    )}

                    {sidebar && (
                        <aside className="solution-detail-renderer__sidebar">
                            <ImpactCard
                                title={sidebar.title}
                                text={sidebar.description}
                                className="solution-detail-renderer__impact-card"
                                paragraphWrapperClassName="solution-detail-renderer__impact-card-paragraph"
                            />
                        </aside>
                    )}
                </div>
            </Wrapper>

            {relatedContent && (
                <QuickLinksSection
                    {...relatedContent}
                    className="solution-detail-renderer__related-content"
                />
            )}
        </article>
    );
};

export default SolutionDetailRenderer;
