import { FC, ReactElement } from 'react';

import { ErrorLabel, InputLabel, Select } from '../../../components';
import { FormOption } from '../../../types';

import './SelectInput.scss';

interface SelectInputProps {
    isLoading?: boolean;
    isLoadingMessage?: string;
    isSearchable?: boolean;
    label: string;
    additionalLabel?: string;
    hideLabel?: boolean;
    name: string;
    value?: FormOption;
    options: FormOption[];
    placeholder?: string;
    required?: boolean;
    disabled?: boolean;
    error?: string;
    onChange: (value: string) => void;
    className?: string;
}

const SelectInput: FC<SelectInputProps> = ({
    isLoading = false,
    isLoadingMessage,
    isSearchable = false,
    label,
    additionalLabel,
    hideLabel = false,
    name,
    value,
    options,
    placeholder,
    required = false,
    disabled = false,
    error = '',
    onChange,
    className = '',
}): ReactElement => {
    const handleChange = (selected: FormOption): void => {
        onChange(selected.value);
    };

    return (
        <label
            aria-label={hideLabel ? label : undefined}
            className={`select-input ${className}`}
        >
            {!hideLabel && (
                <InputLabel
                    label={label}
                    additionalLabel={additionalLabel}
                    className="select-input__label"
                />
            )}

            <Select
                isLoading={isLoading}
                isLoadingMessage={isLoadingMessage}
                isSearchable={isSearchable}
                name={name}
                required={required}
                value={value}
                options={options}
                placeholder={placeholder}
                disabled={disabled}
                error={error}
                onChange={handleChange}
            />

            {error && (
                <ErrorLabel
                    text={error}
                    className="select-input__error-label"
                />
            )}
        </label>
    );
};

export default SelectInput;
