import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { transformToDateIsoString } from '../../Date/DateTransformers';
import { PageSection } from '../../PageSection/PageSection';
import {
    ShareholderTableInterface,
    ShareholderTableResource,
    ShareholderTableRowInterface,
    ShareholderTableRowResource,
} from './ShareholderTable';

const transformToShareholderTableRow = (resource: ShareholderTableRowResource): ShareholderTableRowInterface => {
    const dateOfLastDisclosure = transformToDateIsoString(resource.dateOfLastDisclosure);

    return {
        mandatoryDisclosingParty: resource.mandatoryDisclosingParty,
        ownership: resource.ownership,
        dateOfLastDisclosure,
    };
};

export const transformToShareholderTable = (resource: ShareholderTableResource): ShareholderTableInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    const rows = resource.rows.map(transformToShareholderTableRow);

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.shareholderTable,
        title: resource.title,
        paragraph: resource.paragraph || '',
        rows,
    };
};
