import {
    Link,
    LinkResource,
    LinkTarget,
    LinkTargetResource,
} from './Link';

const transformToLinkTarget = (resource?: LinkTargetResource): LinkTarget | undefined => {
    if (resource === LinkTargetResource.blank) return LinkTarget.blank;

    return undefined;
};

export const transformToLink = (resource: LinkResource): Link => {
    const target = transformToLinkTarget(resource.target);

    return {
        label: resource.label,
        path: resource.link,
        target,
    };
};
