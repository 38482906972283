import { transformToImage, transformToVideo } from '../Media/MediaTransformers';
import { transformToPerson } from '../Person/PersonTransformers';
import { Testimonial, TestimonialResource } from './Testimonial';

export const transformToTestimonial = (resource: TestimonialResource): Testimonial => {
    const image = resource.media && resource.media?.data?.attributes?.mime.startsWith('image')
        ? transformToImage(resource.media?.data)
        : undefined;

    const video = resource.media && resource.media?.data?.attributes?.mime.startsWith('video')
        ? transformToVideo(resource.media?.data)
        : undefined;

    const author = resource.person?.data
        ? transformToPerson(resource.person.data)
        : undefined;

    return {
        id: resource.id,
        image,
        video,
        quote: resource.quote,
        author,
    };
};
