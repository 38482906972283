export enum AlignmentResource {
    horizontal = 'Horizontal',
    vertical = 'Vertical',
}

export enum Alignment {
    horizontal = 'horizontal',
    vertical = 'vertical',
}

export enum HorizontalAlignmentResource {
    left = 'left',
    right = 'right'
}

export enum HorizontalAlignment {
    left = 'left',
    right = 'right',
}
