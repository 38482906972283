import { FC, ReactElement } from 'react';

import { DownloadableFile } from '../../../entities/Media/Media';
import { DownloadButton } from '../..';

import './LabeledDownloadButton.scss';

interface LabeledDownloadButtonProps {
    file: DownloadableFile;
    className?: string;
    buttonClassName?: string;
}

const LabeledDownloadButton: FC<LabeledDownloadButtonProps> = ({
    file,
    className = '',
    buttonClassName = '',
}): ReactElement => (
    <div className={`labeled-download-button ${className}`}>
        <DownloadButton
            href={file.src}
            extension={file.extension}
            className={`labeled-download-button__button ${buttonClassName}`}
        />

        <p className="labeled-download-button__label">
            {file.title}
        </p>
    </div>
);

export default LabeledDownloadButton;
