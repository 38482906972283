import { FC, ReactElement, useMemo } from 'react';

import useTrans from '../../../hooks/useTrans';
import { YearSelectorButton } from './subcomponents';

import './YearSelector.scss';

interface YearSelectorProps {
    updateParams?: boolean;
    hideLabel?: boolean;
    showAllButton?: boolean;
    activeYear?: number;
    years: number[];
    onSelect: (year?: number) => void;
    className?: string;
}

const YearSelector: FC<YearSelectorProps> = ({
    updateParams,
    hideLabel,
    showAllButton,
    activeYear,
    years,
    onSelect,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return useMemo(() => (
        <div className={`year-selector ${className}`}>
            {!hideLabel && (
                <div className="year-selector__label">
                    {trans('compositions.yearSelector.label')}
                </div>
            )}

            <div className="year-selector__buttons">
                {showAllButton && (
                    <YearSelectorButton
                        updateParams={updateParams}
                        isActive={!activeYear}
                        label={trans('compositions.yearSelector.all')}
                        onClick={onSelect}
                    />
                )}

                {years.map(year => (
                    <YearSelectorButton
                        key={year}
                        updateParams={updateParams}
                        isActive={activeYear === year}
                        label={year}
                        year={year}
                        onClick={onSelect}
                        className="year-selector__button"
                    />
                ))}
            </div>
        </div>
    ), [activeYear, years]);
};

export default YearSelector;
