/* eslint-disable max-len */
import React, { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconMute: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={100} viewBoxHeight={100} className={className}>
        <path d="M49.7,10.9L23.8,31.5h-11C5.7,31.5,0,37.2,0,44.2v11.5c0,7.1,5.7,12.8,12.8,12.8h11l25.8,20.5c2.6,2.1,6.4,0.2,6.4-3.1V14C56.1,10.7,52.3,8.9,49.7,10.9z" />
        <path d="M90.5,50l8.1-8.1c1.8-1.8,1.8-4.7,0-6.5c-1.8-1.8-4.7-1.8-6.5,0L84,43.5l-8.1-8.1c-1.8-1.8-4.7-1.8-6.5,0c-1.8,1.8-1.8,4.7,0,6.5l8.1,8.1l-8.1,8.1c-1.8,1.8-1.8,4.7,0,6.5c0.9,0.9,2.1,1.3,3.2,1.3c1.2,0,2.4-0.4,3.2-1.3l8.1-8.1l8.1,8.1c0.9,0.9,2.1,1.3,3.2,1.3c1.2,0,2.4-0.4,3.2-1.3c1.8-1.8,1.8-4.7,0-6.5L90.5,50z" />
    </Svg>
);

export default IconMute;
/* eslint-enable max-len */
