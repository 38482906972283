import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { transformToPagination } from '../../entities/Pagination/PaginationTransformers';
import { getRestApiCall } from '../../entities/Rest/RestService';
import { VacanciesResponse } from '../../entities/Vacancy/Vacancy';
import { transformToVacancy } from '../../entities/Vacancy/VacancyTransformers';
import { TypedDispatch } from '../store';
import {
    setError,
    setIsLoading,
    setIsSuccessful,
    setPagination,
    setVacancies,
} from './vacanciesReducer';

export const fetchVacancies = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const response = await getRestApiCall<VacanciesResponse>('/vacancies?populate=*');

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));
            return;
        }

        const { data: vacanciesData, meta: vacanciesMeta } = response.data;

        const vacancies = vacanciesData
            ? vacanciesData.map(transformToVacancy)
            : [];

        const pagination = vacanciesMeta
            ? transformToPagination(vacanciesMeta.pagination)
            : undefined;

        dispatch(setVacancies(vacancies));
        dispatch(setPagination(pagination));

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[fetchVacancies]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
