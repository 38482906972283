import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { PageSection } from '../../PageSection/PageSection';
import { SolutionDetailBodyInterface, SolutionDetailBodyResource } from './SolutionDetailBody';

export const transformToSolutionDetailBody = (resource: SolutionDetailBodyResource): SolutionDetailBodyInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.solutionDetailBody,
        intro: resource.intro || '',
        bodyHtml: resource.body || '',
        linkedInLabel: resource.linkedInLabel || '',
    };
};
