import { FC, ReactElement } from 'react';

import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import { Page } from '../../components';
import { TypeHeader } from '../../compositions';
import { ConnectedSearchResults } from '../../connectors';
import useTrans from '../../hooks/useTrans';

import './SearchResultsPage.scss';

const SearchResultsPage: FC = (): ReactElement => {
    const trans = useTrans();
    const { query = '' } = useParams();

    return (
        <Page className="search-results-page">
            <Helmet>
                <title>{trans('pages.searchResults.meta.title')}</title>
                <meta property="og:title" content={trans('pages.searchResults.meta.title')} />
                <meta name="description" content={trans('pages.searchResults.meta.description')} />
                <meta property="og:description" content={trans('pages.searchResults.meta.description')} />
            </Helmet>

            <TypeHeader
                hasFullTitleWidth
                images={[]}
                titleHtml={trans('pages.searchResults.title', { query })}
                titleClassName="search-results-page__title"
            />

            <ConnectedSearchResults query={query} />
        </Page>
    );
};

export default SearchResultsPage;
