import {
    FC,
    FormEvent,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { ErrorLabel } from '../../../components';
import { RecaptchaDisclaimer, SubmitButton, TextInput } from '../../../compositions';
import { PressReleaseFormData } from '../../../entities/@forms/PressReleaseForm/PressReleaseForm';
import { scrollIntoView } from '../../../helpers/scroll';
import useTrans from '../../../hooks/useTrans';
import { FormProps } from '../../../types';
import { PressReleaseFormErrors, validatePressReleaseFormData } from './validations';

import './PressReleaseForm.scss';

interface PressReleaseFormProps extends FormProps<PressReleaseFormData> {
    className?: string;
}

const PressReleaseForm: FC<PressReleaseFormProps> = ({
    isLoading,
    isSuccessful,
    onSubmit,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const formRef = useRef<HTMLFormElement>(null);
    const [formErrors, setFormErrors] = useState<PressReleaseFormErrors>({});
    const [botDetected, setBotDetected] = useState<boolean>(false);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    useEffect((): void => {
        if (isSuccessful) {
            setName('');
            setEmail('');
        }
    }, [isSuccessful]);

    const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();

        if (!executeRecaptcha) {
            setBotDetected(true);
            return;
        }

        const formData: PressReleaseFormData = {
            name,
            email,
        };

        const [errors, hasErrors] = validatePressReleaseFormData(formData);
        const token = await executeRecaptcha();

        setFormErrors(errors);
        setBotDetected(!token);

        if (!hasErrors && token) {
            onSubmit(formData);
        } else {
            scrollIntoView<HTMLFormElement>(formRef);
        }
    };

    return (
        <form
            ref={formRef}
            onSubmit={handleSubmit}
            className={`press-release-form ${className}`}
        >
            <TextInput
                label={trans('containers.pressReleaseForm.name')}
                value={name}
                error={formErrors.name}
                disabled={isLoading}
                onChange={setName}
            />

            <TextInput
                type="email"
                label={trans('containers.pressReleaseForm.email')}
                value={email}
                error={formErrors.email}
                disabled={isLoading}
                onChange={setEmail}
            />

            <SubmitButton
                isLoading={isLoading}
                text={trans('containers.pressReleaseForm.submitButton')}
                className="press-release-form__submit-button"
            />

            <RecaptchaDisclaimer className="press-release-form__recaptcha-disclaimer" />

            {botDetected && (
                <ErrorLabel
                    text={trans('errors.botDetected')}
                    className="press-release-form__recaptcha-error"
                />
            )}
        </form>
    );
};

export default PressReleaseForm;
