import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { PageSection } from '../../PageSection/PageSection';
import { transformToTestimonial as transformToTestimonialType } from '../../Testimonial/TestimonialTransformers';
import { TestimonialInterface, TestimonialResource } from './Testimonial';

export const transformToTestimonial = (resource: TestimonialResource): TestimonialInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    const testimonial = transformToTestimonialType(resource.testimonial);

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.testimonial,
        testimonial,
    };
};
