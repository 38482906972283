import { FC, PropsWithChildren, ReactElement } from 'react';

import { Icon } from '../../../components';
import { IconName } from '../../../components/Icon/Icon';

import './FormCard.scss';

interface FormCardProps {
    icon: IconName;
    title: string;
    className?: string;
}

const FormCard: FC<PropsWithChildren<FormCardProps>> = ({
    icon,
    title,
    className = '',
    children,
}): ReactElement => (
    <div data-dark="true" className={`form-card ${className}`}>
        <div className="form-card__title-wrapper">
            <Icon name={icon} className="form-card__icon" />

            <h3 className="form-card__title">
                {title}
            </h3>
        </div>

        <div className="form-card__children">
            {children}
        </div>
    </div>
);

export default FormCard;
