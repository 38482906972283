import { FC, memo, ReactElement } from 'react';

import { Skeleton } from '../../../../../components';
import { generateIdArray } from '../../../../../helpers/array';
import { randomInBetweenValue } from '../../../../../helpers/number';

import './CalendarSkeletons.scss';

interface CalendarSkeletonsProps {
    className?: string;
}

const CalendarSkeletons: FC<CalendarSkeletonsProps> = ({
    className = '',
}): ReactElement => {
    const amountOfEvents = randomInBetweenValue(3, 6);
    const eventSkeletons = generateIdArray(amountOfEvents);

    const amountOfPastEvents = randomInBetweenValue(3, 6);
    const pastEventSkeletons = generateIdArray(amountOfPastEvents);

    return (
        <div className={`calendar-skeletons ${className}`}>
            <div className="calendar-skeletons__list-wrapper">
                <div className="calendar-skeletons__title-wrapper">
                    <Skeleton className="calendar-skeletons__title" />
                </div>

                <ul className="calendar-skeletons__list">
                    {eventSkeletons.map(calendarEvent => (
                        <li key={calendarEvent} className="calendar-skeletons__list-item">
                            <div className="calendar-skeletons__event">
                                <div className="calendar-skeletons__event-text-wrapper">
                                    <Skeleton className="calendar-skeletons__event-date" />
                                    <Skeleton className="calendar-skeletons__event-title" />
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>

            <div className="calendar-skeletons__list-wrapper">
                <div className="calendar-skeletons__title-wrapper">
                    <Skeleton className="calendar-skeletons__title" />
                </div>

                <ul className="calendar-skeletons__list">
                    {pastEventSkeletons.map(calendarEvent => (
                        <li key={calendarEvent} className="calendar-skeletons__list-item">
                            <div className="calendar-skeletons__event">
                                <div className="calendar-skeletons__event-text-wrapper">
                                    <Skeleton className="calendar-skeletons__event-date" />
                                    <Skeleton className="calendar-skeletons__event-title" />
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default memo(CalendarSkeletons);
