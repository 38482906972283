import {
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
    VideoHTMLAttributes,
} from 'react';

import classNames from 'classnames';
import { useToggle } from 'react-use';

import trans from '../../helpers/trans';
import { IconButton } from '..';

import './Video.scss';

export interface VideoProps extends VideoHTMLAttributes<HTMLVideoElement> {
    isKeyboardNavigationDisabled?: boolean;
    isPlaybackDisabled?: boolean;
    type: string;
    closedCaptionsSrc?: string;
    className?: string;
    controlsWrapperClassName?: string;
    videoClassName?: string;
}

const Video: FC<VideoProps> = ({
    isKeyboardNavigationDisabled,
    isPlaybackDisabled,
    autoPlay,
    muted,
    controls,
    src,
    type,
    closedCaptionsSrc,
    className = '',
    controlsWrapperClassName = '',
    videoClassName = '',
    ...videoProps
}): ReactElement => {
    const [isPlaying, setIsPlaying] = useState<boolean>(!!(autoPlay && muted));
    const [isMuted, toggleIsMuted] = useToggle(!!muted);
    const [hasClosedCaptions, toggleHasClosedCaptions] = useToggle(!!closedCaptionsSrc);

    const videoRef = useRef<HTMLVideoElement>(null);

    const handlePlayStateClick = async (): Promise<void> => {
        const videoElement = videoRef.current;

        if (!videoElement) return;

        if (isPlaying || isPlaybackDisabled) {
            await videoElement.pause();
        } else {
            await videoElement.play();
        }
    };

    const handlePlay = (): void => setIsPlaying(true);
    const handlePause = (): void => setIsPlaying(false);

    useEffect((): void => {
        const pauseVideo = async (): Promise<void> => {
            if (videoRef.current) {
                await videoRef.current.pause();
            }
        };

        pauseVideo();
    }, [isPlaybackDisabled]);

    const videoClassNames = classNames('video', {
        'video--is-paused': !isPlaying,
        'video--hide-captions': !hasClosedCaptions,
    }, className);

    return (
        <div className={videoClassNames}>
            {controls && (
                <div className="video__play-button-wrapper">
                    <IconButton
                        icon={isPlaying ? 'pause' : 'play'}
                        tabIndex={isKeyboardNavigationDisabled ? -1 : undefined}
                        text={trans(`compositions.video.button.${isPlaying ? 'pause' : 'play'}`)}
                        hideLabel
                        onClick={handlePlayStateClick}
                        className="video__play-button"
                        iconClassName="video__icon"
                    />
                </div>
            )}

            <div className={`video__controls-wrapper ${controlsWrapperClassName}`}>
                {closedCaptionsSrc && (
                    <IconButton
                        icon="closed-captions"
                        tabIndex={isKeyboardNavigationDisabled ? -1 : undefined}
                        text={trans(`compositions.video.button.closedCaptions.${hasClosedCaptions ? 'hide' : 'show'}`)}
                        hideLabel
                        onClick={toggleHasClosedCaptions}
                        className="video__control-button"
                        iconClassName="video__control-icon"
                    />
                )}

                <IconButton
                    icon={isMuted ? 'mute' : 'unmute'}
                    tabIndex={isKeyboardNavigationDisabled ? -1 : undefined}
                    text={trans(`compositions.video.button.volume.${isMuted ? 'unmute' : 'mute'}`)}
                    hideLabel
                    onClick={toggleIsMuted}
                    className="video__control-button"
                    iconClassName="video__control-icon"
                />
            </div>

            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video
                {...videoProps}
                ref={videoRef}
                autoPlay={autoPlay}
                muted={isMuted}
                tabIndex={isKeyboardNavigationDisabled ? -1 : undefined}
                onPlay={handlePlay}
                onPause={handlePause}
                crossOrigin="anonymous"
                className={`video__element ${videoClassName}`}
            >
                <source src={src} type={type} />

                {closedCaptionsSrc && (
                    <track
                        default
                        kind="captions"
                        label={trans('compositions.video.closedCaptions')}
                        src={closedCaptionsSrc}
                        srcLang="en"
                    />
                )}

                {trans('compositions.video.notSupported')}
            </video>
        </div>
    );
};

export default Video;
