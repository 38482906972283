import { FC, PropsWithChildren, ReactElement } from 'react';

import './ModalContent.scss';

interface ModalContentProps {
    title?: string;
    className?: string;
}

const ModalContent: FC<PropsWithChildren<ModalContentProps>> = ({
    title,
    className = '',
    children,
}): ReactElement => (
    <div className={`modal-content ${className}`}>
        {title && (
            <header className="modal-content__header">
                <h1 className="modal-content__title">{title}</h1>
            </header>
        )}

        <div className="modal-content__content-wrapper">
            {children}
        </div>
    </div>
);

export default ModalContent;
