import { FC, ReactElement } from 'react';

import { SafeHtml, Section } from '../../../components';
import { SectionWrapper } from '../../../compositions';
import { PageIntroInterface } from '../../../entities/@sections/PageIntro/PageIntro';

import './PageIntro.scss';

interface PageIntroProps extends PageIntroInterface {
    className?: string;
}

const PageIntro: FC<PageIntroProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    titleHtml,
    paragraphHtml,
    className = '',
}): ReactElement => (
    <Section isDark={isDark} className={`page-intro ${className}`}>
        <SectionWrapper noSpacingTop={noSpacingTop} noSpacingBottom={noSpacingBottom}>
            <div className="page-intro__wrapper">
                <SafeHtml
                    html={titleHtml}
                    className="page-intro__title"
                />

                <SafeHtml
                    html={paragraphHtml}
                    className="page-intro__paragraph"
                />
            </div>
        </SectionWrapper>
    </Section>
);

export default PageIntro;
