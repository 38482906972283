import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ArticleCategoryInterface } from '../../entities/ArticleCategory/ArticleCategory';
import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type ArticleCategoriesState = AsyncReduxState<{
    categories: Record<string, ArticleCategoryInterface>;
}>;

const initialState: ArticleCategoriesState = {
    ...initialAsyncReduxState,
    categories: {},
};

const articleCategoriesSlice = createSlice({
    name: 'articleCategoriesReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): ArticleCategoriesState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): ArticleCategoriesState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): ArticleCategoriesState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setCategories(state, action: PayloadAction<Record<string, ArticleCategoryInterface>>): ArticleCategoriesState {
            return {
                ...state,
                categories: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsSuccessful,
    setError,
    setCategories,
} = articleCategoriesSlice.actions;

export default articleCategoriesSlice;
