import { FC, ReactElement } from 'react';

import { LinkButton, Section } from '../../../components';
import { FormCard, LinkedInCard, SectionWrapper } from '../../../compositions';
import { ConnectedPressReleaseForm } from '../../../connectors';
import { ContactInvestorRelationsInterface } from '../../../entities/@sections/ContactInvestorRelations/ContactInvestorRelations';
import useTrans from '../../../hooks/useTrans';

import './ContactInvestorRelations.scss';

interface ContactInvestorRelationsProps extends ContactInvestorRelationsInterface {
    linkedInPath: string;
    className?: string;
}

const ContactInvestorRelations: FC<ContactInvestorRelationsProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    title,
    paragraph,
    contactLink,
    linkedInPath,
    linkedInLabel,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <Section isDark={isDark} className={`contact-investor-relations ${className}`}>
            <SectionWrapper
                noSpacingTop={noSpacingTop}
                noSpacingBottom={noSpacingBottom}
                className="contact-investor-relations__wrapper"
            >
                <div className="contact-investor-relations__contact-wrapper">
                    <h2 className="contact-investor-relations__title">
                        {title}
                    </h2>

                    <p className="contact-investor-relations__intro">
                        {paragraph}
                    </p>

                    <div className="contact-investor-relations__cards-wrapper">
                        <div className="contact-investor-relations__card-wrapper">
                            <FormCard
                                icon="at"
                                title={trans('containers.contactInvestorRelations.contact')}
                                className="contact-investor-relations__card"
                            >
                                <LinkButton
                                    to={contactLink.path}
                                    text={contactLink.label}
                                />
                            </FormCard>
                        </div>

                        <div className="contact-investor-relations__card-wrapper">
                            <LinkedInCard
                                link={{
                                    path: linkedInPath,
                                    label: linkedInLabel,
                                }}
                                className="contact-investor-relations__card"
                            />
                        </div>
                    </div>
                </div>

                <div className="contact-investor-relations__card-wrapper">
                    <FormCard
                        icon="mail"
                        title={trans('containers.pressReleaseForm.title')}
                        className="contact-investor-relations__card"
                    >
                        <ConnectedPressReleaseForm className="contact-investor-relations__press-release-form" />
                    </FormCard>
                </div>
            </SectionWrapper>
        </Section>
    );
};

export default ContactInvestorRelations;
