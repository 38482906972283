import { useEffect, useRef } from 'react';

import { useWindowSize } from 'react-use';

import useResizeObserver from './useResizeObserver';

// This is a variation of useWindowSize where we use the lvh and lvw units to get the viewport size. This way you will
// not get any resizes on mobile due to the menu bars showing/hiding while scrolling. For more information see:
// https://dev.to/frehner/css-vh-dvh-lvh-svh-and-vw-units-27k4

const useLargeViewportSize = (): { width: number, height: number } => {
    const windowSize = useWindowSize();
    const divRef = useRef<HTMLDivElement | null>(null);

    const domRect = useResizeObserver(divRef, [divRef.current]);
    const width = domRect?.width || windowSize.width || 0;
    const height = domRect?.height || windowSize.height || 0;

    useEffect((): () => void => {
        const div = document.createElement('div');
        div.style.position = 'absolute';
        div.style.top = '-99999px';
        div.style.left = '-99999px';
        div.style.height = '100lvh';
        div.style.width = '100lvw';
        div.style.visibility = 'hidden';
        div.style.pointerEvents = 'none';

        document.body.appendChild(div);
        divRef.current = div;

        return () => {
            if (divRef.current) {
                document.body.removeChild(divRef.current);
            }
        };
    }, []);

    return { width, height };
};

export default useLargeViewportSize;
