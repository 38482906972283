import { FC, ReactElement } from 'react';

import { Card } from '../../../components';

import './ImpactCard.scss';

export interface ImpactCardProps {
    text: string;
    title: string;
    className?: string;
    paragraphWrapperClassName?: string;
}

const ImpactCard: FC<ImpactCardProps> = ({
    text,
    title,
    className = '',
    paragraphWrapperClassName = '',
}): ReactElement => (
    <Card className={`impact-card ${className}`}>
        <h2 className="impact-card__title">
            {title}
        </h2>

        <div className={`impact-card__paragraph-wrapper ${paragraphWrapperClassName}`}>
            <p className="impact-card__paragraph">
                {text}
            </p>
        </div>
    </Card>
);

export default ImpactCard;
