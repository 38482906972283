import { generateRestApiQuery } from '../../helpers/query';

export const generateSolutionDetailQuery = (slug: string): string => {
    const filters = {
        slug: {
            $eq: slug,
        },
    };

    const populate = {
        tags: { populate: ['*'] },
        image: { populate: ['*'] },
        relatedContent: {
            populate: {
                appearance: { populate: ['*'] },
                links: { populate: ['link'] },
            },
        },
        sidebar: { populate: ['title', 'description'] },
        seo: { populate: ['*'] },
    };

    return generateRestApiQuery({ filters, populate });
};
