import { FC, ReactElement } from 'react';

import { ContactInvestorRelations } from '../../containers';
import { ContactInvestorRelationsInterface } from '../../entities/@sections/ContactInvestorRelations/ContactInvestorRelations';
import { useTypedSelector } from '../../redux/store';

interface ConnectedContactInvestorRelationsProps extends ContactInvestorRelationsInterface {
    className?: string;
}

const ConnectedContactInvestorRelations: FC<ConnectedContactInvestorRelationsProps> = ({
    className = '',
    ...contactInvestorRelationsProps
}): ReactElement => {
    const globalSettings = useTypedSelector(state => state.globalSettingsReducer.settings);

    return (
        <ContactInvestorRelations
            {...contactInvestorRelationsProps}
            linkedInPath={globalSettings.linkedInUrl || ''}
            className={className}
        />
    );
};

export default ConnectedContactInvestorRelations;
