import { generateUuid } from '../../helpers/string';
import { LinkTarget } from '../Link/Link';
import {
    Navigation,
    NavigationEntityResource,
    NavigationItem,
    NavigationItemEntityResource,
    NavigationType,
} from './Navigation';

const transformToNavigationItem = (resource: NavigationItemEntityResource): NavigationItem => {
    const target = resource.attributes?.target as LinkTarget || LinkTarget.self;

    const subNavigation = resource.attributes?.children.data?.map(transformToNavigationItem) || [];
    const sortedSubNavigation = subNavigation.sort((listItem, comparisonListItem) => listItem.order - comparisonListItem.order);

    return {
        id: resource.id?.toString() || resource.attributes?.url || generateUuid(),
        order: resource.attributes?.order || 1,
        label: resource.attributes?.title || '',
        href: resource.attributes?.url || '',
        target,
        subNavigation: sortedSubNavigation,
    };
};

export const transformToNavigation = (response: NavigationEntityResource): Navigation => {
    const navigationItems = response.attributes?.items.data?.length
        ? response.attributes.items.data.map(transformToNavigationItem)
        : [];

    const sortedNavigationItems = navigationItems.sort((item, comparisonItem) => item.order - comparisonItem.order);

    return {
        id: response.id?.toString() || response.attributes?.slug || generateUuid(),
        title: response.attributes?.title || '',
        slug: (response.attributes?.slug || '') as NavigationType,
        list: sortedNavigationItems,
    };
};
