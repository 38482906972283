import { PaginationResource } from '../entities/Pagination/Pagination';

type UriComponent = string | number | boolean;
type QueryFilterValue = string[] | UriComponent;
type QueryFilterObject = {
    [key: string]: QueryFilterValue | QueryFilterObject;
};

export type QueryPopulateObject = {
    [key: string]: string[] | QueryPopulateObject;
};

export enum QuerySortDirection {
    asc = 'asc',
    desc = 'desc',
}

export type QuerySortObject = Record<string, QuerySortDirection>;

export type QueryPaginationObject = Partial<Pick<PaginationResource, 'page' | 'pageSize'>>;

export interface QueryConfig {
    filters?: QueryFilterObject;
    populate?: QueryPopulateObject;
    sort?: QuerySortObject;
    pagination?: QueryPaginationObject;
}

const generateQueryFilterString = (filters: QueryFilterObject, parentKey?: string): string => {
    const filterEntries = Object.entries(filters);

    const queryChunks = filterEntries.map(([key, value]) => {
        const fullKey = parentKey ? `${parentKey}[${key}]` : key;

        if (typeof value === 'object' && !Array.isArray(value)) {
            return generateQueryFilterString(value as QueryFilterObject, fullKey);
        }

        if (key === '$between' && Array.isArray(value)) {
            return value.map(val => `${fullKey}=${val}`).join('&');
        }

        return `${fullKey}=${encodeURIComponent(value as UriComponent)}`;
    });

    return queryChunks.join('&');
};

const generateQueryPopulateString = (populate: QueryPopulateObject, parentKey?: string): string => {
    const filterEntries = Object.entries(populate);

    const queryChunks = filterEntries.map(([key, value]) => {
        const fullKey = parentKey ? `${parentKey}[${key}]` : key;

        if (typeof value === 'object' && !Array.isArray(value)) {
            return generateQueryPopulateString(value as QueryPopulateObject, fullKey);
        }

        return `${fullKey}=${encodeURIComponent(value.join(','))}`;
    });

    return queryChunks.join('&');
};

export const generateRestApiQuery = (config: QueryConfig): string => {
    const sortEntries = config.sort
        ? Object.entries(config.sort)
        : [];

    const paginationEntries = config.pagination
        ? Object.entries(config.pagination)
        : [];

    const filters = config.filters
        ? generateQueryFilterString({ filters: config.filters })
        : undefined;

    const populate = config.populate
        ? generateQueryPopulateString({ populate: config.populate })
        : undefined;

    const sort = sortEntries.map(([key, value]) => (
        `sort=${key}:${value}`
    )).join('&');

    const pagination = paginationEntries.map(([key, value]) => (
        `pagination[${key}]=${value}`
    )).join('&');

    return [
        filters,
        populate,
        sort,
        pagination,
    ].filter(Boolean).join('&');
};
