import { transformToAlignment } from '../../Alignment/AlignmentTransformers';
import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { transformToDownloadableFile } from '../../Media/MediaTransformers';
import { PageSection } from '../../PageSection/PageSection';
import { DownloadListInterface, DownloadListResource } from './DownloadList';

export const transformToDownloadList = (resource: DownloadListResource): DownloadListInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    const alignment = transformToAlignment(resource.downloadListType);

    const files = resource.files?.data
        ? resource.files.data.map(transformToDownloadableFile)
        : [];

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.downloadList,
        alignment,
        title: resource.title,
        files,
    };
};
