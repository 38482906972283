import { homePageSlug } from '../../constants';
import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { PageResponse } from '../../entities/Page/Page';
import { generatePageQuery } from '../../entities/Page/PageRequests';
import { transformToPage } from '../../entities/Page/PageTransformers';
import { getRestApiCall } from '../../entities/Rest/RestService';
import { getBreadcrumbs } from '../../helpers/breadcrumbs';
import { ReducerGetter, TypedDispatch } from '../store';
import {
    setBreadcrumbs,
    setError,
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
    setPageData,
} from './pageReducer';

export const fetchPage = (slug: string) => async (dispatch: TypedDispatch, getState: ReducerGetter): Promise<void> => {
    dispatch(setPageData(undefined));
    dispatch(setHasFetched(false));
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const { mainNavigation } = getState().navigationReducer;

        const pageSlug = slug === '/' ? homePageSlug : slug;
        const pageQuery = generatePageQuery(pageSlug.slice(1));

        const pageResponse = await getRestApiCall<PageResponse>(`/pages?${pageQuery}`);

        if (!isFetchResultSuccessful(pageResponse)) {
            dispatch(setError(pageResponse.error));
            return;
        }

        const { data: pageDataArray = [] } = pageResponse.data;

        const pageDataResource = pageDataArray.length > 0
            ? pageDataArray[0]
            : undefined;

        const pageData = pageDataResource?.attributes
            ? transformToPage(pageDataResource.attributes)
            : undefined;

        if (mainNavigation) {
            const breadcrumbs = getBreadcrumbs(slug, mainNavigation.list);

            dispatch(setBreadcrumbs(breadcrumbs));
        }

        dispatch(setPageData(pageData));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[fetchPage]', error);
    } finally {
        dispatch(setHasFetched(true));
        dispatch(setIsLoading(false));
    }
};
