export const getVimeoEmbedUrl = (url: string): string => {
    if (!url) return '';

    const regExp = /^(http:\/\/|https:\/\/)?(www\.)?(vimeo\.com\/)([0-9]+)$/;
    const match = url.match(regExp);

    const videoId = match?.[4];

    if (videoId?.length === 9) {
        return `https://player.vimeo.com/video/${videoId}?autoplay=0&title=0&byline=0&portrait=0`;
    }

    return '';
};

export const getYouTubeEmbedUrl = (url: string): string => {
    if (!url) return '';

    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
    const match = url.match(regExp);

    const videoId = match?.[2];

    if (videoId?.length === 11) {
        return `https://www.youtube.com/embed/${videoId}?autoplay=0&rel=0`;
    }

    return '';
};
