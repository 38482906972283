import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { transformToLink } from '../../Link/LinkTransformers';
import { PageSection } from '../../PageSection/PageSection';
import { MapCallToActionInterface, MapCallToActionResource } from './MapCallToAction';

export const transformToMapCallToAction = (resource: MapCallToActionResource): MapCallToActionInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    const link = resource.link
        ? transformToLink(resource.link)
        : undefined;

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.mapCallToAction,
        titleHtml: resource.title || '',
        paragraphHtml: resource.paragraph || '',
        link,
    };
};
