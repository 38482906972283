import { transformToAddress } from '../../Address/AddressTransformers';
import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { PageSection } from '../../PageSection/PageSection';
import { ContactCompanyFormInterface, ContactCompanyFormResource } from './ContactCompanyForm';

export const transformToContactCompanyForm = (resource: ContactCompanyFormResource): ContactCompanyFormInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    const addresses = resource.addresses?.data
        ? resource.addresses.data.map(transformToAddress)
        : [];

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.contactCompanyForm,
        title: resource.title,
        subtitle: resource.subtitle || '',
        addresses,
    };
};
