import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { transformToLink } from '../../Link/LinkTransformers';
import { PageSection } from '../../PageSection/PageSection';
import { ContactInvestorRelationsInterface, ContactInvestorRelationsResource } from './ContactInvestorRelations';

export const transformToContactInvestorRelations = (resource: ContactInvestorRelationsResource): ContactInvestorRelationsInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    const contactLink = transformToLink(resource.contactLink);

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.contactInvestorRelations,
        title: resource.title,
        paragraph: resource.paragraph || '',
        contactLink,
        linkedInLabel: resource.linkedInLabel || '',
    };
};
