import { Entity } from '../../types';
import { DateResource } from '../Date/Date';
import { Link } from '../Link/Link';
import { PageRelationEntityResource } from '../Page/Page';

interface GlobalSettingsResource {
    linkedinUrl: string;
    contact?: Entity<PageRelationEntityResource>;
    cookie?: Entity<PageRelationEntityResource>;
    newsLetter?: Entity<PageRelationEntityResource>;
    createdAt?: DateResource;
    updatedAt?: DateResource;
}

interface GlobalSettingEntityResource {
    id?: string;
    attributes?: GlobalSettingsResource;
}

export type GlobalSettingsResponse = Entity<GlobalSettingEntityResource>;

export interface GlobalSettings {
    linkedInUrl?: string;
    contactLink?: Link;
    cookieLink?: Link;
    newsletterLink?: Link;
}

export const defaultGlobalSettings = {
    linkedInUrl: '',
    contactLink: undefined,
    cookieLink: undefined,
    newsletterLink: undefined,
};
