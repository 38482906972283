import { FC, ReactElement } from 'react';

import { Testimonial } from '../../entities/Testimonial/Testimonial';
import { BlockQuote } from '..';

import './TestimonialQuote.scss';

export interface TestimonialQuoteProps extends Testimonial {
    className?: string;
    blockQuoteTextWrapperClassName?: string;
}

const TestimonialQuote: FC<TestimonialQuoteProps> = ({
    quote,
    author,
    className = '',
    blockQuoteTextWrapperClassName = '',
}): ReactElement => (
    <div className={`testimonial-quote ${className}`}>
        <BlockQuote
            text={quote}
            author={author}
            textWrapperClassName={blockQuoteTextWrapperClassName}
        />

        {author && (
            <p className="testimonial-quote__author-bio">
                {author.paragraph}
            </p>
        )}
    </div>
);

export default TestimonialQuote;
