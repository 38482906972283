import {
    FC,
    ReactElement,
    useEffect,
    useRef,
} from 'react';

import { CookieSettings } from '../../containers';
import { useCookieSettings, useCookieSettingsDispatch } from '../../context/CookieContext';
import useElementSize from '../../hooks/useElementSize';
import { useTypedSelector } from '../../redux/store';

const ConnectedCookieSettings: FC = (): ReactElement => {
    const cookieSettings = useCookieSettings();
    const dispatchCookieSettings = useCookieSettingsDispatch();

    const bannerRef = useRef<HTMLDivElement>(null);
    const { height: bannerHeight } = useElementSize(bannerRef);

    const globalSettings = useTypedSelector(state => state.globalSettingsReducer.settings);

    useEffect((): void => {
        dispatchCookieSettings({
            ...cookieSettings,
            cookieSettingsBannerHeight: bannerHeight,
        });
    }, [bannerHeight]);

    const handleAcceptCookies = (): void => {
        dispatchCookieSettings({
            ...cookieSettings,
            cookieSettingsVisible: false,
            cookieSettingsAccepted: true,
        });
    };

    return (
        <CookieSettings
            ref={bannerRef}
            isRevealed={cookieSettings.cookieSettingsVisible}
            cookiePagePath={globalSettings.cookieLink?.path || ''}
            onAccept={handleAcceptCookies}
        />
    );
};

export default ConnectedCookieSettings;
