import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { transformToDownloadableFile } from '../../Media/MediaTransformers';
import { PageSection } from '../../PageSection/PageSection';
import { PresentationInterface, PresentationResource } from './Presentation';

export const transformToPresentation = (resource: PresentationResource): PresentationInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    const file = resource.file?.data
        ? transformToDownloadableFile(resource.file.data)
        : undefined;

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.presentation,
        caption: resource.caption,
        titleHtml: resource.title,
        file,
    };
};
