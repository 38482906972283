import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Article } from '../../entities/Article/Article';
import { PageSearchResult } from '../../entities/Search/Search';
import { Solution } from '../../entities/Solution/Solution';
import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type SearchState = AsyncReduxState<{
    pageResults: PageSearchResult[];
    articleResults: Article[];
    solutionResults: Solution[];
    hasFetchedRecommendedPageResults: boolean;
    recommendedPageResults: PageSearchResult[];
}>;

const initialState: SearchState = {
    ...initialAsyncReduxState,
    pageResults: [],
    articleResults: [],
    solutionResults: [],
    hasFetchedRecommendedPageResults: false,
    recommendedPageResults: [],
};

const searchSlice = createSlice({
    name: 'searchReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): SearchState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): SearchState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): SearchState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setPageResults(state, action: PayloadAction<PageSearchResult[]>): SearchState {
            return {
                ...state,
                pageResults: action.payload,
            };
        },
        setArticleResults(state, action: PayloadAction<Article[]>): SearchState {
            return {
                ...state,
                articleResults: action.payload,
            };
        },
        setSolutionResults(state, action: PayloadAction<Solution[]>): SearchState {
            return {
                ...state,
                solutionResults: action.payload,
            };
        },
        setHasFetchedRecommendedPageResults(state, action: PayloadAction<boolean>): SearchState {
            return {
                ...state,
                hasFetchedRecommendedPageResults: action.payload,
            };
        },
        setRecommendedPageResults(state, action: PayloadAction<PageSearchResult[]>): SearchState {
            return {
                ...state,
                recommendedPageResults: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsSuccessful,
    setError,
    setPageResults,
    setArticleResults,
    setSolutionResults,
    setHasFetchedRecommendedPageResults,
    setRecommendedPageResults,
} = searchSlice.actions;

export default searchSlice;
