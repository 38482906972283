import { FC, ReactElement, useEffect, useState } from 'react';

import { Details } from '../../../../../components';
import trans from '../../../../../helpers/trans';
import worldWideLocations from '../../constants/locations-tkh.json';
import { GroupItem } from '..';
import { MarkerType } from '../WorldMap/WorldMap';

import './DepartmentDetails.scss';

interface DepartmentsInfo {
    type: string;
    name: string;
}

interface Departments {
    tkhGroup: DepartmentsInfo[];
    smartVision: DepartmentsInfo[];
    smartConnectivity: DepartmentsInfo[];
    smartManufacturing: DepartmentsInfo[];
}

interface DepartmentDetailsProps {
    className?: string;
}

const DepartmentDetails: FC<DepartmentDetailsProps> = ({ className = '' }): ReactElement => {
    const [departments, setDepartments] = useState<Departments>({
        tkhGroup: [],
        smartVision: [],
        smartConnectivity: [],
        smartManufacturing: [],
    });

    useEffect(() => {
        const groupedDepartments: Departments = {
            tkhGroup: worldWideLocations.filter((item: DepartmentsInfo) => item.type === MarkerType.tkhGroup),
            smartVision: worldWideLocations.filter((item: DepartmentsInfo) => item.type === MarkerType.smartVision),
            smartConnectivity: worldWideLocations.filter((item: DepartmentsInfo) => item.type === MarkerType.smartConnectivity),
            smartManufacturing: worldWideLocations.filter((item: DepartmentsInfo) => item.type === MarkerType.smartManufacturing),
        };

        setDepartments(groupedDepartments);
    }, []);

    return (
        <Details
            summary={trans('containers.interactiveMap.title')}
            summaryClassName="department-details__summary"
            className={`department-details ${className}`}
        >
            <div className="department-details__items-wrapper">
                <GroupItem
                    title={trans('containers.interactiveMap.departments.tkhGroup')}
                    items={departments.tkhGroup}
                    titleClassName="department-details__group-title"
                />
                <GroupItem
                    title={trans('containers.interactiveMap.departments.smartVision')}
                    items={departments.smartVision}
                    titleClassName="department-details__vision-title"
                />
                <GroupItem
                    title={trans('containers.interactiveMap.departments.smartConnectivity')}
                    items={departments.smartConnectivity}
                    titleClassName="department-details__connectivity-title"
                />
                <GroupItem
                    title={trans('containers.interactiveMap.departments.smartManufacturing')}
                    items={departments.smartManufacturing}
                    titleClassName="department-details__manufacturing-title"
                />
            </div>
        </Details>
    );
};

export default DepartmentDetails;
