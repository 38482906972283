import { FC, ReactElement } from 'react';

import { Helmet } from 'react-helmet-async';

import { LinkButton, Wrapper } from '../../components';
import { AppRoute, appRoutes } from '../../constants/routing';
import useTrans from '../../hooks/useTrans';

import './NotFoundPage.scss';

const NotFoundPage: FC = (): ReactElement => {
    const trans = useTrans();

    return (
        <div className="not-found-page">
            <Helmet>
                <title>{trans('pages.notFound.meta.title')}</title>
                <meta property="og:title" content={trans('pages.notFound.meta.title')} />
                <meta name="description" content={trans('pages.notFound.meta.description')} />
                <meta property="og:description" content={trans('pages.notFound.meta.description')} />
            </Helmet>

            <Wrapper className="not-found-page__wrapper">
                <div className="not-found-page__content-wrapper">
                    <h1 className="not-found-page__title">
                        {trans('pages.notFound.heading')}
                    </h1>

                    <p className="not-found-page__description">
                        {trans('pages.notFound.description')}
                    </p>

                    <LinkButton
                        to={trans(appRoutes[AppRoute.home].path)}
                        text={trans('pages.notFound.button.backToHome')}
                        className="not-found-page__link-button"
                    />
                </div>
            </Wrapper>
        </div>
    );
};

export default NotFoundPage;
