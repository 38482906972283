import { useMemo, useState } from 'react';

import { useDebounce, useWindowSize } from 'react-use';

import { isSSR } from '../helpers';

const useWindowResizing = (debounceMs: number) => {
    const { width } = useWindowSize();

    const [debouncedWidth, setDebouncedWidth] = useState(!isSSR ? window.innerWidth : 0);

    useDebounce(() => {
        setDebouncedWidth(width);
    }, debounceMs, [width]);

    return useMemo(() => {
        if (isSSR) {
            return false;
        }

        return width !== debouncedWidth;
    }, [width, debouncedWidth]);
};

export default useWindowResizing;
