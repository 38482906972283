import { transformToDefaultComponent } from '../../Component/ComponentTransformers';
import { transformToImage } from '../../Media/MediaTransformers';
import { PageSection } from '../../PageSection/PageSection';
import { BackgroundIllustrationInterface, BackgroundIllustrationResource } from './BackgroundIllustration';

export const transformToBackgroundIllustration = (resource: BackgroundIllustrationResource): BackgroundIllustrationInterface => {
    const defaultComponent = transformToDefaultComponent(resource);

    const image = resource.image?.data
        ? transformToImage(resource.image.data)
        : undefined;

    return {
        ...defaultComponent,
        type: PageSection.backgroundIllustration,
        image,
    };
};
