import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Navigation } from '../../entities/Navigation/Navigation';
import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type NavigationState = AsyncReduxState<{
    mainNavigation?: Navigation;
    footerNavigation?: Navigation;
    legalNavigation?: Navigation;
}>;

const initialState: NavigationState = {
    ...initialAsyncReduxState,
};

const navigationSlice = createSlice({
    name: 'navigationReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): NavigationState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): NavigationState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): NavigationState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setMainNavigation(state, action: PayloadAction<Navigation | undefined>): NavigationState {
            return {
                ...state,
                mainNavigation: action.payload,
            };
        },
        setFooterNavigation(state, action: PayloadAction<Navigation | undefined>): NavigationState {
            return {
                ...state,
                footerNavigation: action.payload,
            };
        },
        setLegalNavigation(state, action: PayloadAction<Navigation | undefined>): NavigationState {
            return {
                ...state,
                legalNavigation: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsSuccessful,
    setError,
    setMainNavigation,
    setFooterNavigation,
    setLegalNavigation,
} = navigationSlice.actions;

export default navigationSlice;
