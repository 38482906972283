import { AsyncReduxState, FormOption } from '../types';

export const homePageSlug = '/home';
export const companyTwitterHandle = '';

export const hyphenString = '--';

export const urls = {
    api: process.env.REACT_APP_REST_URL,
};

export const defaultFormOption = (): FormOption => ({
    label: '',
    value: '',
});

export const initialAsyncReduxState: AsyncReduxState = {
    isLoading: false,
    isSuccessful: false,
    error: '',
    statusCode: undefined,
};
