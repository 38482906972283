import { FC, ReactElement } from 'react';

import { Page } from '../../components';
import { ConnectedSolutionDetailHelmet, ConnectedSolutionDetailRenderer } from '../../connectors';

const SolutionDetailPage: FC = (): ReactElement => (
    <Page className="solution-detail-page">
        <ConnectedSolutionDetailHelmet />

        <ConnectedSolutionDetailRenderer />
    </Page>
);

export default SolutionDetailPage;
