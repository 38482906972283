import { FC, ReactElement, useEffect } from 'react';

import { SolutionsCarousel } from '../../containers';
import { SolutionsCarouselInterface } from '../../entities/@sections/SolutionsCarousel/SolutionsCarousel';
import { QuerySortDirection } from '../../helpers/query';
import { generateStoreKey } from '../../helpers/store';
import { setHasDataFromServer } from '../../redux/app/appReducer';
import { fetchSolutionTag } from '../../redux/solutionTags/solutionTagsActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

interface ConnectedSolutionsCarouselProps extends SolutionsCarouselInterface {
    className?: string;
}

const ConnectedSolutionsCarousel: FC<ConnectedSolutionsCarouselProps> = ({
    title,
    tag,
    className = '',
    ...defaultComponent
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const hasDataFromServer = useTypedSelector(state => state.appReducer.hasDataFromServer);

    const isLoading = useTypedSelector(state => state.solutionTagsReducer.isLoading);
    const solutionTags = useTypedSelector(state => state.solutionTagsReducer.tags);

    const key = generateStoreKey([tag]);

    useEffect((): void => {
        if (hasDataFromServer) {
            dispatch(setHasDataFromServer(false));
            return;
        }

        dispatch(fetchSolutionTag({
            key,
            tag,
            sort: {
                publishedAt: QuerySortDirection.desc,
            },
            pagination: {
                pageSize: 6,
                page: 1,
            },
        }));
    }, []);

    return (
        <SolutionsCarousel
            {...defaultComponent}
            isLoading={isLoading}
            title={title}
            solutions={solutionTags[key]?.solutions || []}
            className={className}
        />
    );
};

export default ConnectedSolutionsCarousel;
