import { Link } from '../../../../entities/Link/Link';

export const generateBreadcrumbStructuredData = (breadcrumbs: Link[], siteUrl: string): string => {
    const structuredData = {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: breadcrumbs.map((breadcrumb, index) => ({
            '@type': 'ListItem',
            position: index + 1,
            name: breadcrumb.label,
            item: index < breadcrumbs.length - 1
                ? siteUrl + breadcrumb.path
                : undefined,
        })),
    };

    return JSON.stringify(structuredData);
};
