import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { PageSection } from '../../PageSection/PageSection';
import {
    FinancialOverviewTableInterface,
    FinancialOverviewTableResource,
    FinancialOverviewTableRowInterface,
    FinancialOverviewTableRowResource,
} from './FinancialOverviewTable';

const transformToFinancialOverviewTableRow = (resource: FinancialOverviewTableRowResource): FinancialOverviewTableRowInterface => ({
    isBold: resource.isBold,
    title: resource.title,
    numbers: resource.numbers.map(num => num.number || ''),
});

export const transformToFinancialOverviewTable = (resource: FinancialOverviewTableResource): FinancialOverviewTableInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    const years = resource.years
        ? resource.years.map(yearResource => yearResource.year)
        : [];

    const rows = resource.rows.map(transformToFinancialOverviewTableRow);

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.financialOverviewTable,
        headerTitle: resource.title,
        years,
        rows,
    };
};
