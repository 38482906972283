import { DateIsoString, DateResource, defaultDateIsoString } from './Date';

export const transformToDateIsoString = (resource: DateResource): DateIsoString => {
    // @ts-ignore
    const isValid = new Date(resource) !== 'Invalid Date' && !Number.isNaN(new Date(resource));

    return isValid
        ? new Date(resource).toISOString()
        : defaultDateIsoString;
};

export const transformToTimeString = (resource: string): string => {
    const [hours, minutes] = resource.split(':');

    if (!hours || !minutes) return '';

    return [hours, minutes].join(':');
};
