import { FC, ReactElement } from 'react';

import { Section } from '../../../components';
import { PeopleList, SectionWrapper } from '../../../compositions';
import { PeopleListInterface } from '../../../entities/@sections/PeopleList/PeopleList';

import './PeopleListSection.scss';

interface PeopleListSectionProps extends PeopleListInterface {
    className?: string;
}

const PeopleListSection: FC<PeopleListSectionProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    layoutType,
    people,
    className = '',
}): ReactElement => (
    <Section isDark={isDark} className={`people-list-section ${className}`}>
        <SectionWrapper noSpacingTop={noSpacingTop} noSpacingBottom={noSpacingBottom}>
            <PeopleList
                layoutType={layoutType}
                people={people}
                className="people-list-section__list"
                listItemClassName="people-list-section__list-item"
            />
        </SectionWrapper>
    </Section>
);

export default PeopleListSection;
