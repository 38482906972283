import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { transformToLink } from '../../Link/LinkTransformers';
import { PageSection } from '../../PageSection/PageSection';
import {
    CallToActionCardInterface,
    CallToActionCardListInterface,
    CallToActionCardListResource,
    CallToActionCardResource,
} from './CallToActionCardList';

const transformToCallToActionCard = (resource: CallToActionCardResource): CallToActionCardInterface => {
    const link = transformToLink(resource.link);

    return {
        id: resource.id,
        title: resource.title,
        paragraph: resource.text || '',
        link,
    };
};

export const transformToCallToActionCardList = (resource: CallToActionCardListResource): CallToActionCardListInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    const cards = resource.cards.map(transformToCallToActionCard);

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.callToActionCardList,
        title: resource.title,
        cards,
    };
};
