import { generateUuid } from '../../helpers/string';
import { transformToArticleCategory, transformToArticleCategoryKey } from '../ArticleCategory/ArticleCategoryTransformers';
import { defaultDateIsoString } from '../Date/Date';
import { transformToDateIsoString } from '../Date/DateTransformers';
import { transformToDownloadableFile, transformToImage } from '../Media/MediaTransformers';
import { transformToSeo } from '../Seo/SeoTransformers';
import { transformToTag } from '../Tag/TagTransformers';
import { Article, ArticleEntityResource } from './Article';

export const transformToArticle = (resource: ArticleEntityResource): Article => {
    const category = transformToArticleCategory(resource.attributes?.article_category?.data);
    const categoryKey = transformToArticleCategoryKey(resource.attributes?.article_category?.data);
    const tag = transformToTag(resource.attributes?.tags?.data);

    const createdAt = resource.attributes?.createdAt
        ? transformToDateIsoString(resource.attributes.createdAt)
        : defaultDateIsoString;

    const updatedAt = resource.attributes?.updatedAt
        ? transformToDateIsoString(resource.attributes.updatedAt)
        : defaultDateIsoString;

    const date = resource.attributes?.date
        ? transformToDateIsoString(resource.attributes.date)
        : createdAt;

    const image = resource.attributes?.image?.data
        ? transformToImage(resource.attributes.image.data)
        : undefined;

    const download = resource.attributes?.download?.data
        ? transformToDownloadableFile(resource.attributes.download.data)
        : undefined;

    const seo = resource.attributes?.seo
        ? transformToSeo(resource.attributes.seo)
        : undefined;

    return {
        id: resource.id?.toString() || resource.attributes?.slug || generateUuid(),
        category,
        categoryKey,
        tag,
        title: resource.attributes?.title || '',
        slug: resource.attributes?.slug || '',
        date,
        location: resource.attributes?.location,
        image,
        bodyHtml: resource.attributes?.description || '',
        download,
        seo,
        createdAt,
        updatedAt,
    };
};
