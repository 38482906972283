export const getHighestChildrenHeight = (element: HTMLUListElement): number => {
    const children = element.querySelectorAll('li > div');

    if (!children) {
        return 0;
    }

    return Array.from(children)
        .reduce((total, child) => {
            const { height } = child.getBoundingClientRect();

            return height > total ? height : total;
        }, 0);
};

export const getActiveChildHeight = (element: HTMLUListElement, index: number): number => {
    const children = element.querySelectorAll('li > div');

    if (!children) {
        return 0;
    }

    return Array.from(children)[index].getBoundingClientRect().height;
};
