import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { TableCell, TableRow } from '../../../../../components';
import { FinancialResultsTableListRowInterface } from '../../../../../entities/@sections/FinancialResultsTableList/FinancialResultsTableList';
import useDeviceWidth from '../../../../../hooks/useDeviceWidth';
import useFormatDate from '../../../../../hooks/useFormatDate';
import useTrans from '../../../../../hooks/useTrans';
import { DownloadButton, VideoModal } from '../../../..';
import { FinancialResultsRowMobile } from '..';

import './FinancialResultsTableRow.scss';

interface FinancialResultsTableRowProps extends FinancialResultsTableListRowInterface {
    tableHeaders: string[];
    cellClassName?: string;
    cellContentClassName?: string;
    className?: string;
}

const FinancialResultsTableRow: FC<FinancialResultsTableRowProps> = ({
    tableHeaders,
    period,
    releaseDate,
    pressRelease,
    webcast,
    presentation,
    iframeUrl,
    cellClassName = '',
    cellContentClassName = '',
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const { isMobile } = useDeviceWidth();
    const formatDate = useFormatDate();

    const noMedia = !pressRelease && !webcast && !presentation;
    const formattedDate = releaseDate ? formatDate(releaseDate) : trans('compositions.financialResultsTable.comingSoon');

    const rowClassName = classNames('financial-results-table-row', {
        'financial-results-table-row--no-media': noMedia,
        'financial-results-table-row--no-release-date': !releaseDate,
    }, className);

    if (isMobile) {
        return (
            <>
                <FinancialResultsRowMobile label={tableHeaders[0]}>
                    {period}
                </FinancialResultsRowMobile>

                <FinancialResultsRowMobile
                    isSubordinate={!releaseDate}
                    label={tableHeaders[1]}
                >
                    {formattedDate}
                </FinancialResultsRowMobile>

                <FinancialResultsRowMobile
                    isSubordinate={!pressRelease}
                    label={tableHeaders[2]}
                >
                    {pressRelease ? (
                        <DownloadButton
                            href={pressRelease.src}
                            extension={pressRelease.extension}
                        />
                    ) : '-'}
                </FinancialResultsRowMobile>

                <FinancialResultsRowMobile
                    isSubordinate={!webcast}
                    label={tableHeaders[3]}
                >
                    {(webcast || iframeUrl) ? (
                        <VideoModal
                            video={webcast}
                            embedUrl={iframeUrl}
                        />
                    ) : '-'}
                </FinancialResultsRowMobile>

                <FinancialResultsRowMobile
                    isSubordinate={!presentation}
                    label={tableHeaders[4]}
                >
                    {presentation ? (
                        <DownloadButton
                            href={presentation.src}
                            extension={presentation.extension}
                        />
                    ) : '-'}
                </FinancialResultsRowMobile>
            </>
        );
    }

    return (
        <TableRow className={rowClassName}>
            <TableCell
                className={`financial-results-table-row__cell ${cellClassName}`}
                contentClassName={`financial-results-table-row__cell-content ${cellContentClassName}`}
            >
                {period}
            </TableCell>

            <TableCell
                className={`financial-results-table-row__cell ${cellClassName}`}
                contentClassName={`financial-results-table-row__cell-content ${cellContentClassName}`}
            >
                {formattedDate}
            </TableCell>

            {!noMedia ? (
                <>
                    <TableCell
                        className={`financial-results-table-row__cell ${cellClassName}`}
                        contentClassName={`financial-results-table-row__cell-content ${cellContentClassName}`}
                    >
                        {pressRelease && (
                            <DownloadButton
                                href={pressRelease.src}
                                extension={pressRelease.extension}
                            />
                        )}
                    </TableCell>

                    <TableCell
                        className={`financial-results-table-row__cell ${cellClassName}`}
                        contentClassName={`financial-results-table-row__cell-content ${cellContentClassName}`}
                    >
                        {(webcast || iframeUrl) && (
                            <VideoModal
                                video={webcast}
                                embedUrl={iframeUrl}
                            />
                        )}
                    </TableCell>

                    <TableCell
                        className={`financial-results-table-row__cell ${cellClassName}`}
                        contentClassName={`financial-results-table-row__cell-content ${cellContentClassName}`}
                    >
                        {presentation && (
                            <DownloadButton
                                href={presentation.src}
                                extension={presentation.extension}
                            />
                        )}
                    </TableCell>
                </>
            ) : (
                <TableCell
                    className={`financial-results-table-row__cell ${cellClassName}`}
                    contentClassName={`financial-results-table-row__cell-content ${cellContentClassName}`}
                >
                    {trans('compositions.financialResultsTable.filesUnavailable')}
                </TableCell>
            )}
        </TableRow>
    );
};

export default FinancialResultsTableRow;
