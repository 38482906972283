import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { IconButton } from '../../../../compositions';
import { NavigationItem as NavigationItemType } from '../../../../entities/Navigation/Navigation';
import useTrans from '../../../../hooks/useTrans';
import { NavigationGroupItem, NavigationItem } from '..';

import './NavigationItemList.scss';

interface NavigationItemListProps {
    isSubNavigation?: boolean;
    activeGroup?: string;
    navigationItems: NavigationItemType[];
    tabIndex: number;
    onBackButtonClick?: () => void;
    onGroupClick?: (id: string) => void;
    onItemClick?: () => void;
    className?: string;
}

const NavigationItemList: FC<NavigationItemListProps> = ({
    isSubNavigation,
    activeGroup,
    navigationItems,
    tabIndex,
    onBackButtonClick,
    onGroupClick,
    onItemClick,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const navigationItemListClassName = classNames('navigation-item-list', {
        'navigation-item-list--is-sub-navigation': isSubNavigation,
    }, className);

    return (
        <div className={navigationItemListClassName}>
            {isSubNavigation && (
                <IconButton
                    icon="arrow-left"
                    tabIndex={tabIndex}
                    text={trans('common.back')}
                    onClick={onBackButtonClick}
                    className="navigation-item-list__back-button"
                    iconClassName="navigation-item-list__back-button-icon"
                />
            )}

            <ul className="navigation-item-list__list">
                {navigationItems.map(navItem => {
                    if (navItem.subNavigation.length > 0) {
                        return (
                            <NavigationGroupItem
                                key={navItem.id}
                                isSubItem={isSubNavigation}
                                isActive={activeGroup === navItem.id}
                                groupItem={navItem}
                                tabIndex={tabIndex}
                                onClick={onGroupClick}
                                className="navigation-item-list__item"
                            />
                        );
                    }

                    return (
                        <NavigationItem
                            key={navItem.id}
                            to={navItem.href}
                            label={navItem.label}
                            tabIndex={tabIndex}
                            onClick={onItemClick}
                            className="navigation-item-list__item"
                        />
                    );
                })}
            </ul>
        </div>
    );
};

export default NavigationItemList;
