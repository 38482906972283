import { MarkerColorsType, MarkerType } from '../subcomponents/WorldMap/WorldMap';

export const camelCaseMarkerType = (markerType: string): string => {
    const [firstWord, secondWord] = markerType.split(' ');
    return `${firstWord}${secondWord.charAt(0).toUpperCase()}${secondWord.slice(1)}`;
};

export const getMarkerName = (fileName: string): string | null => {
    const match = fileName.match(/markers\/(marker-[^/]+)\.svg$/);
    return match ? match[1] : null;
};

export const getPathToMarkerIcon = (type: string): string => {
    const markerColor = MarkerColorsType[type as MarkerType];
    return `images/markers/marker-${markerColor}.svg`;
};

export const getPathToClusterIcon = (type: string): string => `images/markers/clusters/${type}.svg`;
