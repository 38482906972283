import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { createRoot } from 'react-dom/client';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import CookieProvider from './context/CookieContext';
import LocaleProvider from './context/LocaleContext';
import { isProduction } from './helpers';
import { registerServiceWorker } from './helpers/pwa';
import { store } from './redux/store';

import './styles/global.scss';

const container = document.getElementById('app-root') as Element;
const root = createRoot(container);

const helmetContext = {};

root.render(
    <StoreProvider store={store}>
        <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY || ''}
            scriptProps={{ defer: true }}
        >
            <BrowserRouter>
                <CookieProvider>
                    <LocaleProvider>
                        <HelmetProvider context={helmetContext}>
                            <App />
                        </HelmetProvider>
                    </LocaleProvider>
                </CookieProvider>
            </BrowserRouter>
        </GoogleReCaptchaProvider>
    </StoreProvider>,
);

// Service worker registration
if (isProduction) {
    registerServiceWorker().catch(error => {
        console.log('ServiceWorker registration failed:', error);
    });
}
