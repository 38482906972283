import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Solution } from '../../entities/Solution/Solution';
import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type SolutionDetailState = AsyncReduxState<{
    hasFetched: boolean;
    solutionDetail?: Solution;
}>;

const initialState: SolutionDetailState = {
    ...initialAsyncReduxState,
    hasFetched: false,
};

const solutionDetailSlice = createSlice({
    name: 'solutionDetailReducer',
    initialState,
    reducers: {
        setHasFetched(state, action: PayloadAction<boolean>): SolutionDetailState {
            return {
                ...state,
                hasFetched: action.payload,
            };
        },
        setIsLoading(state, action: PayloadAction<boolean>): SolutionDetailState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): SolutionDetailState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): SolutionDetailState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setSolutionDetail(state, action: PayloadAction<Solution | undefined>): SolutionDetailState {
            return {
                ...state,
                solutionDetail: action.payload,
            };
        },
    },
});

export const {
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
    setError,
    setSolutionDetail,
} = solutionDetailSlice.actions;

export default solutionDetailSlice;
