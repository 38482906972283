import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Article } from '../../entities/Article/Article';
import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type ArticleDetailState = AsyncReduxState<{
    hasFetched: boolean;
    articleDetail?: Article;
}>;

const initialState: ArticleDetailState = {
    ...initialAsyncReduxState,
    hasFetched: false,
};

const articleDetailSlice = createSlice({
    name: 'articleDetailReducer',
    initialState,
    reducers: {
        setHasFetched(state, action: PayloadAction<boolean>): ArticleDetailState {
            return {
                ...state,
                hasFetched: action.payload,
            };
        },
        setIsLoading(state, action: PayloadAction<boolean>): ArticleDetailState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): ArticleDetailState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): ArticleDetailState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setArticleDetail(state, action: PayloadAction<Article | undefined>): ArticleDetailState {
            return {
                ...state,
                articleDetail: action.payload,
            };
        },
    },
});

export const {
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
    setError,
    setArticleDetail,
} = articleDetailSlice.actions;

export default articleDetailSlice;
