/* eslint-disable max-len */
import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { PageSection } from '../../PageSection/PageSection';
import {
    IllustratedContentBridgeIllustration,
    IllustratedContentBridgeIllustrationResource,
    IllustratedContentBridgeInterface,
    IllustratedContentBridgeResource,
} from './IllustratedContentBridge';

const transformToIllustratedContentBridgeIllustration = (
    resource?: IllustratedContentBridgeIllustrationResource,
): IllustratedContentBridgeIllustration => {
    if (resource === IllustratedContentBridgeIllustrationResource.manufacturing) return IllustratedContentBridgeIllustration.manufacturing;
    if (resource === IllustratedContentBridgeIllustrationResource.manufacturingAlternative) return IllustratedContentBridgeIllustration.manufacturingAlternative;
    if (resource === IllustratedContentBridgeIllustrationResource.vision) return IllustratedContentBridgeIllustration.vision;
    if (resource === IllustratedContentBridgeIllustrationResource.visionAlternative) return IllustratedContentBridgeIllustration.visionAlternative;
    if (resource === IllustratedContentBridgeIllustrationResource.sustainability) return IllustratedContentBridgeIllustration.sustainability;
    if (resource === IllustratedContentBridgeIllustrationResource.people) return IllustratedContentBridgeIllustration.people;
    if (resource === IllustratedContentBridgeIllustrationResource.innovation) return IllustratedContentBridgeIllustration.innovation;

    return IllustratedContentBridgeIllustration.connectivity;
};

export const transformToIllustratedContentBridge = (resource: IllustratedContentBridgeResource): IllustratedContentBridgeInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    const illustration = transformToIllustratedContentBridgeIllustration(resource.illustration);

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.illustratedContentBridge,
        titleHtml: resource.title,
        paragraph: resource.paragraph || '',
        illustration,
    };
};
/* eslint-enable max-len */
