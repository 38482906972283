import {
    CSSProperties,
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import { useDebounce } from 'react-use';
import { noop } from 'react-use/lib/misc/util';

import { Section } from '../../../components';
import { ParallaxLineSection, ParallaxLineSectionHeader } from '../../../compositions';
import { ParallaxLineSectionInterface } from '../../../entities/@sections/ParallaxLineSection/ParallaxLineSection';
import { gsap, ScrollTrigger } from '../../../entities/Gsap/GsapService';
import useDocumentScrollSize from '../../../hooks/useDocumentScrollSize';
import useElementSize from '../../../hooks/useElementSize';
import useLargeViewportSize from '../../../hooks/useLargeViewportSize';
import { getAnimationDuration } from './helpers';

import './ParallaxLineSectionScene.scss';

interface ParallaxLineSectionSceneProps extends ParallaxLineSectionInterface {
    index: number;
    className?: string;
}

const ParallaxLineSectionScene: FC<ParallaxLineSectionSceneProps> = ({
    isDark,
    index,
    titleHtml,
    caption,
    imageType,
    className = '',
}): ReactElement => {
    const parallaxLineSectionRef = useRef<HTMLDivElement>(null);
    const headerRef = useRef<HTMLDivElement>(null);

    const { height: windowHeight } = useLargeViewportSize();
    const { height: headerHeight } = useElementSize(headerRef);
    const { height: documentScrollHeight } = useDocumentScrollSize();

    const introTimeline = useRef<gsap.core.Timeline | null>(null);
    const parallaxTimeline = useRef<gsap.core.Timeline | null>(null);

    const [isVisualAbsolute, setIsVisualAbsolute] = useState<boolean>(false);

    useEffect((): () => void => {
        if (!parallaxLineSectionRef.current) {
            return noop;
        }

        const introScene = gsap.timeline({
            scrollTrigger: {
                invalidateOnRefresh: true,
                trigger: parallaxLineSectionRef.current,
                end: self => `+=${getAnimationDuration(self.trigger, window)}`,
                onUpdate: event => {
                    const tl = introTimeline.current;

                    if (tl) {
                        gsap.killTweensOf(tl);
                        gsap.to(tl, { time: event.progress * tl.totalDuration(), duration: 0 });
                    }
                },
            },
        });

        const parallaxScene = gsap.timeline({
            scrollTrigger: {
                invalidateOnRefresh: true,
                pin: true,
                trigger: parallaxLineSectionRef.current,
                end: self => `+=${getAnimationDuration(self.trigger, window)}`,
                onUpdate: event => {
                    const tl = parallaxTimeline.current;

                    if (tl) {
                        gsap.killTweensOf(tl);
                        gsap.to(tl, { time: event.progress * tl.totalDuration(), duration: 0 });
                    }

                    setIsVisualAbsolute(event.progress > 0.5);
                },
            },
        });

        return () => {
            introScene.kill();
            parallaxScene.kill();
        };
    }, [introTimeline, parallaxTimeline]);

    useEffect((): () => void => {
        introTimeline.current = gsap.timeline({ paused: true });
        parallaxTimeline.current = gsap.timeline({ paused: true });

        return () => {
            introTimeline.current?.kill();
            parallaxTimeline.current?.kill();
        };
    }, []);

    useDebounce((): void => {
        if (documentScrollHeight) {
            // ScrollTrigger only updates on a window resize. So we have to manually trigger refresh
            // when the document height changes.
            ScrollTrigger.refresh();
        }
    }, 100, [documentScrollHeight]);

    const cssVariables = {
        '--parallax-line-section-index': index,
        '--line-section-grid-lines-height': `${windowHeight - headerHeight}px`,
        '--line-section-min-height': `${headerHeight}px`,
    } as CSSProperties;

    return (
        <Section
            isDark={isDark}
            style={cssVariables}
            className={`parallax-line-section-scene ${className}`}
        >
            <ParallaxLineSection
                ref={parallaxLineSectionRef}
                isAbsolute={isVisualAbsolute}
                imageType={imageType}
                introTimeline={introTimeline.current || undefined}
                parallaxTimeline={parallaxTimeline.current || undefined}
                className="parallax-line-section-scene__line-section"
            />

            <ParallaxLineSectionHeader
                ref={headerRef}
                titleHtml={titleHtml}
                caption={caption}
            />
        </Section>
    );
};

export default ParallaxLineSectionScene;
