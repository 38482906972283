import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type ContactFormState = AsyncReduxState;

const initialState: ContactFormState = {
    ...initialAsyncReduxState,
};

const contactFormSlice = createSlice({
    name: 'contactFormReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): ContactFormState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): ContactFormState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): ContactFormState {
            return {
                ...state,
                error: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsSuccessful,
    setError,
} = contactFormSlice.actions;

export default contactFormSlice;
