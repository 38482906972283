import { FC, memo, ReactElement } from 'react';

import { Skeleton } from '../../../../components';
import { generateIdArray } from '../../../../helpers/array';
import { randomInBetweenValue } from '../../../../helpers/number';

import './VacancyCardListSkeletons.scss';

interface VacancyCardListSkeletonsProps {
    className?: string;
}

const VacancyCardListSkeletons: FC<VacancyCardListSkeletonsProps> = ({
    className = '',
}): ReactElement => {
    const amountOfVacancies = randomInBetweenValue(3, 4);
    const vacancySkeletons = generateIdArray(amountOfVacancies);

    return (
        <ul className={`vacancy-card-list-skeletons ${className}`}>
            {vacancySkeletons.map(vacancy => (
                <li key={vacancy} className="vacancy-card-list-skeletons__list-item">
                    <div className="vacancy-card-list-skeletons__card">
                        <Skeleton className="vacancy-card-list-skeletons__card-title" />
                        <Skeleton className="vacancy-card-list-skeletons__card-subtitle" />

                        <div className="vacancy-card-list-skeletons__card-description">
                            <Skeleton className="vacancy-card-list-skeletons__card-description-line" />
                            <Skeleton className="vacancy-card-list-skeletons__card-description-line" />
                        </div>

                        <div className="vacancy-card-list-skeletons__card-download-wrapper">
                            <Skeleton className="vacancy-card-list-skeletons__card-download-button" />
                        </div>
                    </div>
                </li>
            ))}
        </ul>
    );
};

export default memo(VacancyCardListSkeletons);
