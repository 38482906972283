import { FC, ReactElement } from 'react';

import { Card, Icon, LinkButton } from '../../../components';
import { Link } from '../../../entities/Link/Link';
import useTrans from '../../../hooks/useTrans';

import './LinkedInCard.scss';

interface LinkedInFormProps {
    link: Link;
    className?: string;
}

const LinkedInCard: FC<LinkedInFormProps> = ({ link, className = '' }): ReactElement => {
    const trans = useTrans();

    return (
        <Card className={`linked-in-card ${className}`}>
            <div className="linked-in-card__title-wrapper">
                <Icon name="linked-in" className="linked-in-card__icon" />

                <h3 className="linked-in-card__title">
                    {trans('compositions.linkedInCard.title')}
                </h3>
            </div>

            <LinkButton
                to={link.path}
                text={link.label}
                className="linked-in-card__link-button"
            />
        </Card>
    );
};

export default LinkedInCard;
