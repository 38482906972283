import { FC, ReactElement } from 'react';

import { NavigationItem as NavigationItemType } from '../../../../entities/Navigation/Navigation';
import { NavigationItem } from '..';

import './SubNavigationItemList.scss';

interface SubNavigationItemListProps {
    navigationItems: NavigationItemType[];
    tabIndex: number;
    className?: string;
}

const SubNavigationItemList: FC<SubNavigationItemListProps> = ({
    tabIndex,
    navigationItems,
    className = '',
}): ReactElement => (
    <ul className={`sub-navigation-item-list ${className}`}>
        {navigationItems.map(navItem => (
            <NavigationItem
                key={navItem.id}
                tabIndex={tabIndex}
                to={navItem.href}
                label={navItem.label}
                className="sub-navigation-item-list__item"
            />
        ))}
    </ul>
);

export default SubNavigationItemList;
