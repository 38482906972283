/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconHamburger: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={21} viewBoxHeight={18} className={className}>
        <path d="M0 1.625C0 1.01562 0.46875 0.5 1.125 0.5H19.875C20.4844 0.5 21 1.01562 21 1.625C21 2.28125 20.4844 2.75 19.875 2.75H1.125C0.46875 2.75 0 2.28125 0 1.625ZM0 9.125C0 8.51562 0.46875 8 1.125 8H19.875C20.4844 8 21 8.51562 21 9.125C21 9.78125 20.4844 10.25 19.875 10.25H1.125C0.46875 10.25 0 9.78125 0 9.125ZM21 16.625C21 17.2812 20.4844 17.75 19.875 17.75H1.125C0.46875 17.75 0 17.2812 0 16.625C0 16.0156 0.46875 15.5 1.125 15.5H19.875C20.4844 15.5 21 16.0156 21 16.625Z" />
    </Svg>
);

export default IconHamburger;
/* eslint-enable max-len */
