import { ContactFormData } from '../../../../entities/@forms/ContactForm/ContactForm';
import {
    FormErrors,
    FormValidation,
    validateEmailAddress,
    validateForm,
    validateName,
    validateRequiredString,
} from '../../../../entities/FormValidation/FormValidationService';

export type ContactFormErrors = FormErrors<ContactFormData>;

export const validateContactFormData = (formData: ContactFormData): FormValidation<ContactFormErrors> => {
    const errors: ContactFormErrors = {
        name: validateName('name', formData.name),
        email: validateEmailAddress(formData.email),
        message: validateRequiredString('message', formData.message),
    };

    return validateForm<ContactFormErrors>(errors);
};
