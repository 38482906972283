import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { NavigationResponse, NavigationType } from '../../entities/Navigation/Navigation';
import { generateNavigationQuery } from '../../entities/Navigation/NavigationRequests';
import { transformToNavigation } from '../../entities/Navigation/NavigationTransformers';
import { getRestApiCall } from '../../entities/Rest/RestService';
import { TypedDispatch } from '../store';
import {
    setError,
    setFooterNavigation,
    setIsLoading,
    setIsSuccessful,
    setLegalNavigation,
    setMainNavigation,
} from './navigationReducer';

export const fetchNavigations = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const mainNavigationQuery = generateNavigationQuery(NavigationType.main);
        const footerNavigationQuery = generateNavigationQuery(NavigationType.footer);
        const legalNavigationQuery = generateNavigationQuery(NavigationType.legal);

        const mainNavigationResponse = await getRestApiCall<NavigationResponse>(`/menus?${mainNavigationQuery}&populate=*&nested=1`);
        const footerNavigationResponse = await getRestApiCall<NavigationResponse>(`/menus?${footerNavigationQuery}&populate=*&nested=1`);
        const legalNavigationResponse = await getRestApiCall<NavigationResponse>(`/menus?${legalNavigationQuery}&populate=*&nested=1`);

        if (!isFetchResultSuccessful(mainNavigationResponse)) {
            dispatch(setError(mainNavigationResponse.error));
            return;
        }

        if (!isFetchResultSuccessful(footerNavigationResponse)) {
            dispatch(setError(footerNavigationResponse.error));
            return;
        }

        if (!isFetchResultSuccessful(legalNavigationResponse)) {
            dispatch(setError(legalNavigationResponse.error));
            return;
        }

        const { data: mainNavigationData = [] } = mainNavigationResponse.data;
        const { data: footerNavigationData = [] } = footerNavigationResponse.data;
        const { data: legalNavigationData = [] } = legalNavigationResponse.data;

        const mainNavigation = mainNavigationData.length > 0
            ? transformToNavigation(mainNavigationData[0])
            : undefined;

        const footerNavigation = footerNavigationData.length > 0
            ? transformToNavigation(footerNavigationData[0])
            : undefined;

        const legalNavigation = legalNavigationData.length > 0
            ? transformToNavigation(legalNavigationData[0])
            : undefined;

        dispatch(setMainNavigation(mainNavigation));
        dispatch(setFooterNavigation(footerNavigation));
        dispatch(setLegalNavigation(legalNavigation));

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[fetchNavigations]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
