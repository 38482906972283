import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Alignment } from '../../../entities/Alignment/Alignment';
import { DownloadableFile } from '../../../entities/Media/Media';
import { LabeledDownloadButton } from '../..';

import './DownloadList.scss';

interface DownloadListProps {
    alignment?: Alignment;
    files: DownloadableFile[];
    className?: string;
}

const DownloadList: FC<DownloadListProps> = ({
    alignment,
    files,
    className = '',
}): ReactElement => {
    const downloadListClassNames = classNames('download-list', {
        [`download-list--layout-${alignment}`]: alignment,
    }, className);

    return (
        <ul className={downloadListClassNames}>
            {files.map(file => (
                <li key={file.title} className="download-list__item">
                    <LabeledDownloadButton file={file} />
                </li>
            ))}
        </ul>
    );
};

export default DownloadList;
