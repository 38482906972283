import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Icon } from '../../../../components';
import { NavigationItem } from '../../../../entities/Navigation/Navigation';

import './NavigationGroupItem.scss';

interface NavigationGroupItemProps {
    isActive: boolean;
    isSubItem?: boolean;
    tabIndex?: number;
    groupItem: NavigationItem;
    onClick?: (id: string) => void;
    className?: string;
}

const NavigationGroupItem: FC<NavigationGroupItemProps> = ({
    isActive,
    isSubItem = false,
    tabIndex,
    groupItem,
    onClick,
    className = '',
}): ReactElement => {
    const buttonClassNames = classNames('navigation-group-item__button', {
        'navigation-group-item__button--is-active': isActive,
        'navigation-group-item__button--is-sub-item': isSubItem,
    });

    const handleButtonClick = (): void => {
        if (onClick) {
            onClick(groupItem.id);
        }
    };

    return (
        <li className={`navigation-group-item ${className}`}>
            <button
                type="button"
                tabIndex={tabIndex}
                onClick={handleButtonClick}
                className={buttonClassNames}
            >
                <span className="navigation-group-item__label">
                    {groupItem.label}

                    {isSubItem && (
                        <div className="navigation-group-item__chevron-icons-wrapper">
                            <Icon name="chevron-right" className="navigation-group-item__chevron-icon" />
                            <Icon name="chevron-right" className="navigation-group-item__chevron-icon" />
                        </div>
                    )}
                </span>
            </button>
        </li>
    );
};

export default NavigationGroupItem;
