import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { PageSection } from '../../PageSection/PageSection';
import { DisclaimerInterface, DisclaimerResource } from './Disclaimer';

export const transformToDisclaimer = (resource: DisclaimerResource): DisclaimerInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.disclaimer,
        paragraph: resource.paragraph || '',
    };
};
