import { FC, ReactElement } from 'react';

import { Footer } from '../../containers';
import { useTypedSelector } from '../../redux/store';

interface ConnectedFooterProps {
    className?: string;
}

const ConnectedFooter: FC<ConnectedFooterProps> = ({
    className = '',
}): ReactElement => {
    const { isLoading, footerNavigation, legalNavigation } = useTypedSelector(state => state.navigationReducer);

    return (
        <Footer
            isLoading={isLoading}
            footerNavigation={footerNavigation}
            legalNavigation={legalNavigation}
            className={className}
        />
    );
};

export default ConnectedFooter;
