/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconChevronBoldRight: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={6} viewBoxHeight={12} className={className}>
        <path
            d="M 0.5 11.75 L 5.75 6.125 L 0.5 0.5"
            className="stroke"
        />
    </Svg>
);

export default IconChevronBoldRight;
/* eslint-enable max-len */
