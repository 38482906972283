import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { PageSection } from '../../PageSection/PageSection';
import { transformToTestimonial } from '../../Testimonial/TestimonialTransformers';
import { TestimonialCarouselInterface, TestimonialCarouselResource } from './TestimonialCarousel';

export const transformToTestimonialCarousel = (resource: TestimonialCarouselResource): TestimonialCarouselInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    const testimonials = resource.testimonials.map(transformToTestimonial);

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.testimonialCarousel,
        title: resource.title,
        subtitle: resource.subtitle || '',
        testimonials,
    };
};
