import {
    FC,
    PropsWithChildren,
    ReactElement,
    useEffect,
} from 'react';

import { fetchGlobalSettings } from '../../redux/globalSettings/globalSettingsActions';
import { fetchNavigations } from '../../redux/navigation/navigationActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

const ConnectedAppWrapper: FC<PropsWithChildren> = ({
    children,
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const navigationIsFetched = useTypedSelector(state => state.navigationReducer.isSuccessful);
    const globalSettingsAreFetched = useTypedSelector(state => state.globalSettingsReducer.isSuccessful);

    useEffect((): void => {
        if (!navigationIsFetched) {
            dispatch(fetchNavigations());
        }

        if (!globalSettingsAreFetched) {
            dispatch(fetchGlobalSettings());
        }
    }, []);

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
};

export default ConnectedAppWrapper;
