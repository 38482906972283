import {
    FC,
    FormEvent,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { Card, ErrorLabel, Icon } from '../../../components';
import { RecaptchaDisclaimer, SubmitButton, TextInput } from '../../../compositions';
import { ContactFormData } from '../../../entities/@forms/ContactForm/ContactForm';
import { scrollIntoView } from '../../../helpers/scroll';
import useTrans from '../../../hooks/useTrans';
import { FormProps } from '../../../types';
import { ContactFormErrors, validateContactFormData } from './validations';

import './ContactForm.scss';

interface ContactFormProps extends FormProps<ContactFormData> {
    className?: string;
}

const ContactForm: FC<ContactFormProps> = ({
    isLoading,
    isSuccessful,
    onSubmit,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const formRef = useRef<HTMLFormElement>(null);
    const [formErrors, setFormErrors] = useState<ContactFormErrors>({});
    const [botDetected, setBotDetected] = useState<boolean>(false);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    useEffect((): void => {
        if (isSuccessful) {
            setName('');
            setEmail('');
            setMessage('');
        }
    }, [isSuccessful]);

    const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();

        if (!executeRecaptcha) {
            setBotDetected(true);
            return;
        }

        const formData: ContactFormData = {
            name,
            email,
            message,
        };

        const [errors, hasErrors] = validateContactFormData(formData);
        const token = await executeRecaptcha();

        setFormErrors(errors);
        setBotDetected(!token);

        if (!hasErrors && token) {
            onSubmit(formData);
        } else {
            scrollIntoView<HTMLFormElement>(formRef);
        }
    };

    return (
        <Card className={`contact-form ${className}`}>
            <form
                ref={formRef}
                onSubmit={handleSubmit}
                className="contact-form__form"
            >
                <div className="contact-form__title-wrapper">
                    <Icon name="mail" className="contact-form__title-icon" />

                    <h2 className="contact-form__title">
                        {trans('containers.contactForm.title')}
                    </h2>
                </div>

                <TextInput
                    label={trans('containers.contactForm.name')}
                    value={name}
                    error={formErrors.name}
                    disabled={isLoading}
                    onChange={setName}
                    className="contact-form__input"
                />

                <TextInput
                    label={trans('containers.contactForm.email')}
                    type="email"
                    value={email}
                    error={formErrors.email}
                    disabled={isLoading}
                    onChange={setEmail}
                    className="contact-form__input"
                />

                <TextInput
                    label={trans('containers.contactForm.message')}
                    value={message}
                    error={formErrors.message}
                    disabled={isLoading}
                    onChange={setMessage}
                    className="contact-form__input"
                />

                <SubmitButton
                    isLoading={isLoading}
                    text={trans('containers.contactForm.submitButton')}
                    className="contact-form__submit-button"
                />

                <RecaptchaDisclaimer className="contact-form__recaptcha-disclaimer" />

                {botDetected && (
                    <ErrorLabel
                        text={trans('errors.botDetected')}
                        className="contact-form__recaptcha-error"
                    />
                )}
            </form>
        </Card>
    );
};

export default ContactForm;
