import { FC, memo, ReactElement } from 'react';

import { Skeleton } from '../../../../../components';
import { generateIdArray } from '../../../../../helpers/array';

import './SolutionsCarouselSkeletons.scss';

interface SolutionsCarouselSkeletonsProps {
    amountOfCards?: number;
    className?: string;
    itemClassName?: string;
}

const SolutionsCarouselSkeletons: FC<SolutionsCarouselSkeletonsProps> = ({
    amountOfCards = 3,
    className = '',
    itemClassName = '',
}): ReactElement => {
    const skeletonCards = generateIdArray(amountOfCards);

    return (
        <ul className={`solutions-carousel-skeletons ${className}`}>
            {skeletonCards.map(card => (
                <li key={card} className={`solutions-carousel-skeletons__item ${itemClassName}`}>
                    <div className="solutions-carousel-skeletons__image" />

                    <div className="solutions-carousel-skeletons__text-wrapper">
                        <Skeleton className="solutions-carousel-skeletons__title" />
                        <Skeleton className="solutions-carousel-skeletons__link-button" />
                    </div>
                </li>
            ))}
        </ul>
    );
};

export default memo(SolutionsCarouselSkeletons);
