import { generateRestApiQuery } from '../../helpers/query';

export const generatePageQuery = (slug: string): string => {
    const pageSlugEnd = slug.split('/').at(-1) || '';

    const filters = {
        slug: {
            $eq: pageSlugEnd,
        },
    };

    const populate = {
        header: { populate: ['*'] },
        elements: {
            populate: {
                appearance: { populate: ['*'] },
                addresses: { populate: ['*'] },
                card: { populate: ['*'] },
                cards: { populate: ['*'] },
                flipCards: { populate: ['*'] },
                image: { populate: ['*'] },
                imageObjectFit: { populate: ['*'] },
                poster: { populate: ['*'] },
                video: { populate: ['*'] },
                downloads: { populate: ['*'] },
                speakers: { populate: ['*'] },
                file: { populate: ['*'] },
                files: { populate: ['*'] },
                figures: { populate: ['*'] },
                contactLink: { populate: ['*'] },
                link: { populate: ['*'] },
                links: { populate: ['*'] },
                article: { populate: ['*'] },
                article_categories: { populate: ['*'] },
                tag: { populate: ['*'] },
                person: { populate: ['*'] },
                people: { populate: ['*'] },
                columns: { populate: ['*'] },
                latest: { populate: ['*'] },
                shareBuybackHistory: { populate: ['*'] },
                slides: { populate: ['*'] },
                sdgSlides: { populate: ['*'] },
                testimonial: { populate: ['*'] },
                testimonials: { populate: ['*'] },
                tableRows: { populate: ['*'] },
                analystRows: { populate: ['*'] },
                rows: { populate: ['*'] },
                years: {
                    populate: {
                        tableRows: { populate: ['*'] },
                        files: { populate: ['*'] },
                        files10To20: { populate: ['*'] },
                    },
                },
            },
        },
        seo: { populate: ['*'] },
    };

    return generateRestApiQuery({ filters, populate });
};
