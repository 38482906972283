import { DateResource } from '../Date/Date';

export interface MediaFormatResource {
    hash: string;
    name: string;
    mime: string;
    ext?: string;
    size: number;
    width?: number;
    height?: number;
    url: string;
}

export enum ImageFormatSize {
    original = 'original',
    large = 'large',
    medium = 'medium',
    small = 'small',
    thumbnail = 'thumbnail',
}

export interface MediaResource {
    name: string;
    alternativeText?: string;
    caption?: string;
    width?: number;
    height?: number;
    formats?: Partial<Record<ImageFormatSize, MediaFormatResource>>;
    hash: string;
    ext?: string;
    mime: string;
    size: number;
    url: string;
    previewUrl?: string;
    provider?: string;
    provider_metadata?: unknown;
    related?: unknown;
    createdAt?: DateResource;
    updatedAt?: DateResource;
}

export interface MediaEntityResource {
    id?: string;
    attributes?: MediaResource;
}

export interface ImageFormat {
    srcSet: string;
    type: string;
    media: string;
}

export interface Image {
    src: string;
    formats: Partial<Record<ImageFormatSize, ImageFormat>>;
    mime: string;
    alt: string;
}

export enum ObjectFitResource {
    fill = 'fill',
    cover = 'cover',
    contain = 'contain',
}

export interface ImageObjectFitResource {
    id: string;
    imageFit: ObjectFitResource;
}

export interface Video {
    src: string;
    type: string;
}

export interface DownloadableFile {
    src: string;
    title: string;
    extension: string;
}
