import { transformToSeo } from '../Seo/SeoTransformers';
import { PageSearchResponseEntityResource, PageSearchResult } from './Search';

export const transformToPageSearchResult = (resource: PageSearchResponseEntityResource): PageSearchResult => {
    const seoData = resource.attributes?.seo
        ? transformToSeo(resource.attributes.seo)
        : undefined;

    return {
        title: seoData?.title || resource.attributes?.title || '',
        description: seoData?.description || '',
        slug: `/${resource.attributes?.slug}` || '',
    };
};
