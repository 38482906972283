import { FC, ReactElement } from 'react';

import { Article } from '../../../entities/Article/Article';
import { generateIdArray } from '../../../helpers/array';
import { ArticleCard } from '../..';

import './ArticleCardList.scss';

interface ArticleCardListProps {
    hasImagePlaceholder?: boolean;
    hideMeta?: boolean;
    articles?: Article[];
    articleCardClassName?: string;
    className?: string;
}

const ArticleCardList: FC<ArticleCardListProps> = ({
    hasImagePlaceholder,
    hideMeta,
    articles = [],
    articleCardClassName = '',
    className = '',
}): ReactElement => {
    const articleKeys = generateIdArray(articles.length);

    return (
        <ul className={`article-card-list ${className}`}>
            {articles.map((article, index) => (
                <li
                    key={articleKeys[index]}
                    className="article-card-list__list-item"
                >
                    <ArticleCard
                        hasImagePlaceholder={hasImagePlaceholder}
                        hideMeta={hideMeta}
                        {...article}
                        className={`article-card-list__article-card ${articleCardClassName}`}
                    />
                </li>
            ))}
        </ul>
    );
};

export default ArticleCardList;
