import { FC, ReactElement } from 'react';

import { LinkButton } from '../../../../../components';
import { LatestShareBuybackInterface } from '../../../../../entities/@sections/ShareBuyback/ShareBuyback';
import useFormatDate from '../../../../../hooks/useFormatDate';

import './BuybackCard.scss';

export interface BuybackCardProps extends LatestShareBuybackInterface {
    className?: string;
}

const BuybackCard: FC<BuybackCardProps> = ({
    date,
    title,
    link,
    className = '',
}): ReactElement => {
    const formatDate = useFormatDate();

    return (
        <div className={`buyback-card ${className}`}>
            <div className="buyback-card__date">
                {formatDate(date)}
            </div>

            <h3 className="buyback-card__title">
                {title}
            </h3>

            <LinkButton
                to={link.path}
                text={link.label}
                className="buyback-card__link-button"
            />
        </div>
    );
};

export default BuybackCard;
