import { FC, ReactElement } from 'react';

import { Section } from '../../../components';
import { GenericTable, SectionWrapper } from '../../../compositions';
import { GenericTableProps } from '../../../compositions/@tables/GenericTable/GenericTable';
import { DefaultComponentAppearance } from '../../../entities/Component/Component';

import './GenericTableSection.scss';

interface GenericTableSectionProps extends GenericTableProps, DefaultComponentAppearance {
    title: string;
    paragraph?: string;
    className?: string;
    tableClassName?: string;
    wrapperClassName?: string;
}

const GenericTableSection: FC<GenericTableSectionProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    title,
    paragraph,
    className = '',
    tableClassName = '',
    wrapperClassName = '',
    ...genericTableProps
}): ReactElement => (
    <Section isDark={isDark} className={`generic-table-section ${className}`}>
        <SectionWrapper noSpacingTop={noSpacingTop} noSpacingBottom={noSpacingBottom}>
            <div className={`generic-table-section__wrapper ${wrapperClassName}`}>
                <h2 className="generic-table-section__title">{title}</h2>

                {paragraph && (
                    <p className="generic-table-section__paragraph">
                        {paragraph}
                    </p>
                )}

                <GenericTable
                    {...genericTableProps}
                    className={`generic-table-section__table ${tableClassName}`}
                />
            </div>
        </SectionWrapper>
    </Section>
);

export default GenericTableSection;
