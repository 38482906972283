import { generateUuid } from '../../helpers/string';
import { transformToImage } from '../Media/MediaTransformers';
import { Person, PersonEntityResource } from './Person';

export const transformToPerson = (resource: PersonEntityResource): Person => {
    const image = resource.attributes?.photo?.data
        ? transformToImage(resource.attributes.photo?.data)
        : undefined;

    return {
        id: resource.id || resource.attributes?.name || generateUuid(),
        name: resource.attributes?.name || '',
        jobTitle: resource.attributes?.function || '',
        paragraph: resource.attributes?.description || '',
        email: resource.attributes?.publicEmail || '',
        image,
    };
};
