import { useCallback } from 'react';

import { DateFormatOptions, defaultDateFormatOptions } from '../constants/date';
import { Language } from '../constants/locale';
import { useLocale } from '../context/LocaleContext';
import { DateIsoString } from '../entities/Date/Date';

type DateFormatFunction = (date?: DateIsoString, options?: DateFormatOptions) => string;

const dateLocales: Record<Language, string> = {
    [Language.en]: 'en-GB',
};

const useFormatDate = (): DateFormatFunction => {
    const { language } = useLocale();

    return useCallback<DateFormatFunction>((date, options = defaultDateFormatOptions) => {
        if (!date) return '';

        const dateLocale = dateLocales[language];
        const dateObject = new Date(date);

        return new Intl.DateTimeFormat(dateLocale, options).format(dateObject);
    }, [language]);
};

export default useFormatDate;
