import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { LinkButton } from '../../../components';
import { QuickLinkInterface } from '../../../entities/@sections/QuickLinks/QuickLinks';

import './QuickLink.scss';

export interface QuickLinkProps extends QuickLinkInterface {
    isLarge?: boolean;
    className?: string;
    linkClassName?: string;
}

const QuickLink: FC<QuickLinkProps> = ({
    isLarge,
    link,
    paragraph,
    className = '',
    linkClassName = '',
}): ReactElement => {
    const quickLinkClassName = classNames('quick-link', {
        'quick-link--is-large': isLarge,
    }, className);

    return (
        <div className={quickLinkClassName}>
            <LinkButton
                to={link.path}
                target={link.target}
                className={`quick-link__link ${linkClassName}`}
            >
                {link.label}
            </LinkButton>

            {!isLarge && (
                <p className="quick-link__description">
                    {paragraph}
                </p>
            )}
        </div>
    );
};

export default QuickLink;
