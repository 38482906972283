import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { PageSection } from '../../PageSection/PageSection';
import { transformToSdgType } from '../../Sdg/Sdg';
import {
    SdgCarouselInterface,
    SdgCarouselResource,
    SdgCarouselSlideInterface,
    SdgCarouselSlideResource,
} from './SdgCarousel';

const transformToSdgCarouselSlide = (resource: SdgCarouselSlideResource): SdgCarouselSlideInterface => {
    const type = transformToSdgType(resource.type);

    return {
        type,
        title: resource.title,
        bodyHtml: resource.body || '',
    };
};

export const transformToSdgCarousel = (resource: SdgCarouselResource): SdgCarouselInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    const slides = resource.sdgSlides
        ? resource.sdgSlides.map(transformToSdgCarouselSlide)
        : [];

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.sdgCarousel,
        slides,
    };
};
