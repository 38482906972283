import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { transformToKeyFigure } from '../../KeyFigure/KeyFigureTransformers';
import { PageSection } from '../../PageSection/PageSection';
import { KeyFiguresInterface, KeyFiguresResource } from './KeyFigures';

export const transformToKeyFigures = (resource: KeyFiguresResource): KeyFiguresInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    const figures = resource.figures
        ? resource.figures.map(transformToKeyFigure)
        : [];

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.keyFigures,
        isAnimated: resource.isAnimated,
        figures,
    };
};
