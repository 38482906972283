import { FC, ReactElement } from 'react';

import { LinkButton } from '../../../../../components';
import { Event } from '../../../../../entities/Event/Event';
import useFormatDate from '../../../../../hooks/useFormatDate';
import useTrans from '../../../../../hooks/useTrans';

import './CalenderEvent.scss';

export interface CalendarEventProps extends Event {
    className?: string;
}

const CalendarEvent: FC<CalendarEventProps> = ({
    date,
    time,
    location,
    title,
    link,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const formatDate = useFormatDate();

    return (
        <div className={`calendar-event ${className}`}>
            <div className="calendar-event__text-wrapper">
                <div className="calendar-event__date-wrapper">
                    <p className="calendar-event__date">
                        {formatDate(date, {
                            day: 'numeric',
                            month: 'long',
                            year: 'numeric',
                        })}
                    </p>

                    {time && (
                        <p className="calendar-event__time">
                            {time}
                        </p>
                    )}
                </div>

                {location && (
                    <p className="calendar-event__location">
                        {location}
                    </p>
                )}

                <h3 className="calendar-event__title">
                    {title}
                </h3>
            </div>

            {link && (
                <LinkButton
                    text={link.label || trans('common.viewMore')}
                    to={link.path}
                    className="calendar-event__link-button"
                />
            )}
        </div>
    );
};

export default CalendarEvent;
