import { Entity } from '../../types';
import {
    DefaultComponent,
    DefaultComponentAppearance,
    DefaultComponentAppearanceResource,
    DefaultComponentResource,
} from '../Component/Component';
import { DateIsoString, DateResource } from '../Date/Date';
import { Link, LinkResource } from '../Link/Link';
import { Image, MediaEntityResource } from '../Media/Media';

export enum HeaderLayoutResource {
    default = 'Default',
    fullScreen = 'Full_screen',
    floatingImage = 'Floating image',
}

export enum HeaderLayout {
    fullScreen = 'fullScreen',
    floatingImage = 'floatingImage',
}

export interface HeaderResource extends DefaultComponentResource, DefaultComponentAppearanceResource {
    id: string;
    title: string;
    date?: DateResource;
    image?: Entity<MediaEntityResource[]>;
    layout?: HeaderLayoutResource;
    location?: string;
    link?: LinkResource;
    isAnimated: boolean;
    isFullScreen: boolean;
    links?: LinkResource[];
    caption?: string;
}

export interface HeaderInterface extends DefaultComponent, DefaultComponentAppearance {
    isAnimated: boolean;
    isFullScreen: boolean;
    titleHtml: string;
    caption?: string;
    date?: DateIsoString;
    images: Image[];
    layout: HeaderLayout;
    link?: Link;
    links?: Link[];
    location?: string;
}
