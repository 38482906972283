import {
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';

import { BackgroundIllustration } from '../../../compositions';
import { BackgroundIllustrationInterface } from '../../../entities/@sections/BackgroundIllustration/BackgroundIllustration';

import './BackgroundIllustrationSection.scss';

interface BackgroundIllustrationSectionProps extends BackgroundIllustrationInterface {
    className?: string;
}

const BackgroundIllustrationSection: FC<BackgroundIllustrationSectionProps> = ({
    image,
    className = '',
}): ReactElement => {
    const backgroundIllustrationSectionRef = useRef<HTMLDivElement>(null);

    const [neighborIsDark, setNeighborIsDark] = useState<boolean>(false);

    useEffect((): void => {
        if (!backgroundIllustrationSectionRef.current) return;

        const neighbor = backgroundIllustrationSectionRef.current.nextSibling as HTMLElement;

        if (neighbor) {
            setNeighborIsDark(neighbor.getAttribute('data-dark') === 'true');
        }
    }, [backgroundIllustrationSectionRef]);

    const backgroundIllustrationSectionClassNames = classNames('background-illustration-section', {
        'background-illustration-section--is-dark': neighborIsDark,
    }, className);

    return (
        <section ref={backgroundIllustrationSectionRef} className={backgroundIllustrationSectionClassNames}>
            {image && (
                <BackgroundIllustration image={image} />
            )}
        </section>
    );
};

export default BackgroundIllustrationSection;
