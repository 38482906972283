import {
    CSSProperties,
    FC,
    ReactElement,
    useRef,
} from 'react';

import { ThemeTitle } from '../../../components';
import { ValueCardInterface } from '../../../entities/@sections/ValueCards/ValueCards';
import useElementSize from '../../../hooks/useElementSize';

import './ValueCard.scss';

export interface ValueCardProps extends ValueCardInterface {
    className?: string;
}

const ValueCard: FC<ValueCardProps> = ({
    titleHtml,
    description,
    image,
    className = '',
}): ReactElement => {
    const cardRef = useRef<HTMLDivElement>(null);
    const titleRef = useRef<HTMLHeadingElement>(null);

    const { height: cardHeight } = useElementSize(cardRef);
    const { height: titleHeight } = useElementSize(titleRef);

    const cssVariables = {
        '--value-card-height': `${cardHeight}px`,
        '--value-card-title-height': `${titleHeight}px`,
    } as CSSProperties;

    return (
        <div
            ref={cardRef}
            style={cssVariables}
            className={`value-card ${className}`}
        >
            {image && (
                <img
                    src={image.src}
                    alt={image.alt}
                    className="value-card__image"
                />
            )}

            <div className="value-card__content">
                <ThemeTitle
                    titleHtml={titleHtml}
                    className="value-card__content-title"
                />

                <p className="value-card__description">
                    {description}
                </p>
            </div>

            <div className="value-card__title-wrapper">
                <ThemeTitle
                    ref={titleRef}
                    titleHtml={titleHtml}
                    className="value-card__title"
                />
            </div>
        </div>
    );
};

export default ValueCard;
