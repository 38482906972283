import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { Navigation } from '../../../../entities/Navigation/Navigation';

import './SsrNavigation.scss';

interface SsrNavigationProps {
    mainNavigation: Navigation;
    legalNavigation: Navigation;
}

const SsrNavigation: FC<SsrNavigationProps> = ({
    mainNavigation,
    legalNavigation,
}): ReactElement => (
    <div className="ssr-navigation">
        <nav aria-label={mainNavigation.title}>
            <ul>
                {mainNavigation.list.map(item => {
                    if (item.subNavigation.length === 0) {
                        return (
                            <li key={item.id}>
                                <Link to={item.href}>
                                    {item.label}
                                </Link>
                            </li>
                        );
                    }

                    return (
                        <li key={item.id}>
                            <ul>
                                {item.subNavigation.map(subItem => {
                                    if (subItem.subNavigation.length === 0) {
                                        return (
                                            <li key={subItem.id}>
                                                <Link to={subItem.href}>
                                                    {subItem.label}
                                                </Link>
                                            </li>
                                        );
                                    }

                                    return (
                                        <ul key={subItem.id}>
                                            <ul>
                                                {subItem.subNavigation.map(subSubItem => (
                                                    <li key={subSubItem.id}>
                                                        <Link to={subSubItem.href}>
                                                            {subSubItem.label}
                                                        </Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </ul>
                                    );
                                })}
                            </ul>
                        </li>
                    );
                })}
            </ul>
        </nav>

        <nav aria-label={legalNavigation.title}>
            <ul>
                {legalNavigation.list.map(item => (
                    <li key={item.id}>
                        <Link to={item.href}>
                            {item.label}
                        </Link>
                    </li>
                ))}
            </ul>
        </nav>
    </div>
);

export default SsrNavigation;
