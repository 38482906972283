/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconDownload: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={14} viewBoxHeight={15} className={className}>
        <path d="M7 11.0575L3.462 7.51847L4.169 6.79947L6.5 9.13047V0.480469H7.5V9.13047L9.83 6.80047L10.538 7.51847L7 11.0575ZM0 14.4805V10.4425H1V13.4805H13V10.4425H14V14.4805H0Z" />
    </Svg>
);

export default IconDownload;
/* eslint-enable max-len */
