import { FC, ReactElement } from 'react';

import { ArticleDetailHelmet } from '../../containers';
import { useTypedSelector } from '../../redux/store';

const ConnectedArticleDetailHelmet: FC = (): ReactElement | null => {
    const articleDetail = useTypedSelector(state => state.articleDetailReducer.articleDetail);

    if (!articleDetail) {
        return null;
    }

    return (
        <ArticleDetailHelmet
            article={articleDetail}
        />
    );
};

export default ConnectedArticleDetailHelmet;
