import {
    CSSProperties,
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';

import { Section } from '../../../components';
import {
    ContentColumn,
    LabeledDownloadButton,
    PeopleList,
    SectionWrapper,
} from '../../../compositions';
import { CommitteeInterface } from '../../../entities/@sections/Committee/Committee';
import useElementResize from '../../../hooks/useElementSize';

import './Committee.scss';

interface CommitteeProps extends CommitteeInterface {
    className?: string;
}

const Committee: FC<CommitteeProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    hidePortraits,
    title,
    paragraphHtml,
    file,
    people,
    className = '',
}): ReactElement => {
    const contentColumnRef = useRef<HTMLDivElement>(null);
    const titleRef = useRef<HTMLHeadingElement>(null);

    const [titleRefLoaded, setTitleRefLoaded] = useState<boolean>(false);

    const { height: titleHeight } = useElementResize(titleRef, [titleRefLoaded]);

    const sectionClassName = classNames('committee', {
        'committee--has-1-member': people.length === 1,
        'committee--has-2-members': people.length === 2,
        'committee--has-3-members': people.length === 3,
        'committee--has-4-or-more-members': people.length > 3,
        'committee--hide-portraits': hidePortraits,
    }, className);

    useEffect((): void => {
        if (!contentColumnRef.current) return;

        // @ts-ignore
        titleRef.current = contentColumnRef.current.querySelector('h2');
    }, [contentColumnRef, title]);

    useEffect((): void => {
        setTitleRefLoaded(true);
    }, [titleRef]);

    const cssVariables = {
        '--committee-title-height': `${titleHeight}px`,
    } as CSSProperties;

    return (
        <Section style={cssVariables} isDark={isDark} className={sectionClassName}>
            <SectionWrapper
                noSpacingTop={noSpacingTop}
                noSpacingBottom={noSpacingBottom}
                className="committee__wrapper"
            >
                <div className="committee__content-wrapper">
                    <ContentColumn
                        ref={contentColumnRef}
                        titleHtml={`<h2>${title}</h2>`}
                        paragraphHtml={paragraphHtml}
                        className="committee__content"
                    />

                    {file && (
                        <LabeledDownloadButton file={file} />
                    )}
                </div>

                <PeopleList
                    hidePortraits={hidePortraits}
                    hideDescriptions
                    people={people}
                    className="committee__members"
                    listItemClassName="committee__person-wrapper"
                    personClassName="committee__person"
                />
            </SectionWrapper>
        </Section>
    );
};

export default Committee;
