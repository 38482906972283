import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Section } from '../../../components';
import { SectionWrapper, ValueCard } from '../../../compositions';
import { ValueCardsInterface } from '../../../entities/@sections/ValueCards/ValueCards';

import './ValueCardList.scss';

interface ValueCardListProps extends ValueCardsInterface {
    className?: string;
}

const ValueCardList: FC<ValueCardListProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    title,
    cards,
    className = '',
}): ReactElement => {
    const listClassName = classNames('value-card-list__list', {
        [`value-card-list__list--has-${cards.length}-cards`]: cards.length,
    });

    return (
        <Section isDark={isDark} className={`value-card-list ${className}`}>
            <SectionWrapper noSpacingTop={noSpacingTop} noSpacingBottom={noSpacingBottom}>
                {title && (
                    <h2 className="value-card-list__title">
                        {title}
                    </h2>
                )}

                <ul className={listClassName}>
                    {cards.map(card => (
                        <li key={card.id} className="value-card-list__list-item">
                            <ValueCard {...card} />
                        </li>
                    ))}
                </ul>
            </SectionWrapper>
        </Section>
    );
};

export default ValueCardList;
