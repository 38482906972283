import { isSSR } from '../helpers';
import { FormOption } from '../types';

export enum Locale {
    english = 'English',
}

export enum Direction {
    ltr = 'ltr',
    rtl = 'rtl',
}

export enum Language {
    en = 'en',
}

export interface LocaleData {
    name: Locale;
    direction: Direction;
    language: Language;
}

export const locales: Record<Locale, LocaleData> = {
    [Locale.english]: {
        name: Locale.english,
        language: Language.en,
        direction: Direction.ltr,
    },
};

export const defaultLocale = locales[Locale.english];

export const languageOptions: FormOption[] = Object.values(locales).map(locale => ({
    label: locale.name,
    value: locale.language,
}));

export const getLocaleFromBrowser = (): LocaleData | undefined => {
    if (isSSR) return undefined;

    const { language } = navigator;

    if (language.startsWith('en')) return locales[Locale.english];

    return defaultLocale;
};
