/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconChevronRight: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={17} viewBoxHeight={17} className={className}>
        <path
            d="M 5 16 L 12 8.5 L 5 1"
            className="stroke"
        />
    </Svg>
);

export default IconChevronRight;
/* eslint-enable max-len */
