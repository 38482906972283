import { FC, ReactElement } from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { Picture } from '../../../components';
import { AppRoute, appRoutes } from '../../../constants/routing';
import { Article } from '../../../entities/Article/Article';
import { ImageFormatSize } from '../../../entities/Media/Media';
import { getDateTime } from '../../../helpers/date';
import { replaceUrlParamKeysWithValues } from '../../../helpers/url';
import useFormatDate from '../../../hooks/useFormatDate';
import useTrans from '../../../hooks/useTrans';

import './ArticleCard.scss';

export interface ArticleCardProps extends Article {
    hasImagePlaceholder?: boolean;
    hideMeta?: boolean;
    className?: string;
}

const ArticleCard: FC<ArticleCardProps> = ({
    hasImagePlaceholder,
    hideMeta,
    category,
    categoryKey,
    date,
    title,
    slug,
    image,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const formatDate = useFormatDate();

    const paramPath = trans(appRoutes[AppRoute.article].path);
    const articlePath = replaceUrlParamKeysWithValues(paramPath, { slug });

    const articleDateTime = getDateTime(date);

    const articleCardClassNames = classNames('article-card', {
        [`article-card--${categoryKey}`]: categoryKey,
        'article-card--has-image-placeholder': hasImagePlaceholder,
        'article-card--hide-meta': hideMeta,
    }, className);

    return (
        <div className={articleCardClassNames}>
            <div className="article-card__header">
                {image && (
                    <Picture
                        hasScaling
                        src={image.src}
                        alt={image.alt}
                        formats={image.formats}
                        formatOverride={ImageFormatSize.small}
                        className="article-card__picture"
                        imageClassName="article-card__image"
                    />
                )}

                {hasImagePlaceholder && <div className="article-card__image-placeholder" />}
            </div>

            <div className="article-card__body">
                <div className="article-card__meta-wrapper">
                    <p className="article-card__category">
                        {category}
                    </p>

                    <time dateTime={articleDateTime} className="article-card__date">
                        {formatDate(date)}
                    </time>
                </div>

                <div className="article-card__text-wrapper">
                    <h2 className="article-card__title">
                        <Link to={articlePath} className="article-card__link">
                            {title}
                        </Link>
                    </h2>

                    <p className="article-card__read-more-label">
                        {trans('common.readMore')}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ArticleCard;
