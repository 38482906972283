import {
    FC,
    PropsWithChildren,
    ReactElement,
    TableHTMLAttributes,
} from 'react';

import classNames from 'classnames';

import { HorizontalAlignment } from '../../../entities/Alignment/Alignment';

import './TableCell.scss';

interface TableCellProps extends TableHTMLAttributes<HTMLTableCellElement> {
    isHead?: boolean;
    isBold?: boolean;
    align?: HorizontalAlignment;
    colSpan?: number;
    contentClassName?: string;
    className?: string;
}

const TableCell: FC<PropsWithChildren<TableCellProps>> = ({
    isHead,
    isBold,
    align,
    colSpan,
    contentClassName = '',
    className = '',
    children,
    ...tableCellProps
}): ReactElement => {
    const CellComponent = isHead ? 'th' : 'td';

    const tableCellClassNames = classNames('table-cell', {
        'table-cell--is-bold': isBold,
        [`table-cell--align-${align}`]: align,
    }, className);

    return (
        <CellComponent
            {...tableCellProps}
            colSpan={colSpan}
            className={tableCellClassNames}
        >
            <span className={`table-cell__content-wrapper ${contentClassName}`}>
                {children}
            </span>
        </CellComponent>
    );
};

export default TableCell;
