import { DefaultComponent, DefaultComponentResource } from '../Component/Component';

export type PageSectionResource = DefaultComponentResource & unknown;
export type PageSectionInterface = DefaultComponent & unknown;

export enum PageSection {
    textImage = 'textImage',
    pageIntro = 'pageIntro',
    keyFigures = 'keyFigures',
    articlesCarousel = 'articlesCarousel',
    parallaxLineSection = 'parallaxLineSection',
    textColumns = 'textColumns',
    contentColumns = 'contentColumns',
    highlightedImpactCard = 'highlightedImpactCard',
    goalCardList = 'goalCardList',
    mapCallToAction = 'mapCallToAction',
    videoSection = 'videoSection',
    pageCallToAction = 'pageCallToAction',
    visualCarousel = 'visualCarousel',
    quickLinks = 'quickLinks',
    backgroundIllustration = 'backgroundIllustration',
    shareholderDocuments = 'shareholderDocuments',
    shareholderMeetingsArchive = 'shareholderMeetingsArchive',
    peopleList = 'peopleList',
    downloadList = 'downloadList',
    committee = 'committee',
    imageSection = 'imageSection',
    valueCards = 'valueCards',
    testimonialCarousel = 'testimonialCarousel',
    fixedComponent = 'fixedComponent',
    testimonial = 'testimonial',
    sdgCarousel = 'sdgCarousel',
    flipCardList = 'flipCardList',
    presentation = 'presentation',
    contactInvestorRelations = 'contactInvestorRelations',
    financialResultsTableList = 'financialResultsTableList',
    annualDownloads = 'annualDownloads',
    webcast = 'webcast',
    financialOverviewTable = 'financialOverviewTable',
    highlightedDownloadCard = 'highlightedDownloadCard',
    highlightedKeyFigure = 'highlightedKeyFigure',
    tellerSharePrice = 'tellerSharePrice',
    shareholderTable = 'shareholderTable',
    analystCoverageTable = 'analystCoverageTable',
    shareBuyback = 'shareBuyback',
    shareBuybackHistoryTable = 'shareBuybackHistoryTable',
    callToActionCardList = 'callToActionCardList',
    newsletterForm = 'newsletterForm',
    contactPersonForm = 'contactPersonForm',
    illustratedContentBridge = 'illustratedContentBridge',
    solutionDetailBody = 'solutionDetailBody',
    solutionsCarousel = 'solutionsCarousel',
    highlightedArticle = 'highlightedArticle',
    articlesOverview = 'articlesOverview',
    newsletterCallToAction = 'newsletterCallToAction',
    latestArticles = 'latestArticles',
    contactCompanyForm = 'contactCompanyForm',
    disclaimer = 'disclaimer',
}
