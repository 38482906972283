import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { transformToLink } from '../../Link/LinkTransformers';
import { transformToImage } from '../../Media/MediaTransformers';
import { PageSection } from '../../PageSection/PageSection';
import { PageCallToActionInterface, PageCallToActionResource } from './PageCallToAction';

export const transformToPageCallToAction = (resource: PageCallToActionResource): PageCallToActionInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    const image = resource.image?.data
        ? transformToImage(resource.image.data)
        : undefined;

    const link = resource.link
        ? transformToLink(resource.link)
        : undefined;

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.pageCallToAction,
        titleHtml: resource.title,
        paragraph: resource.paragraph || '',
        image,
        link,
    };
};
