import { RouteData } from '../LocalizedRoutes';

export enum AppRoute {
    article = 'routes.article',
    custom = 'routes.custom',
    home = 'routes.home',
    searchResults = 'routes.searchResults',
    solution = 'routes.solution',
}

export const appRoutes = Object.fromEntries<RouteData<AppRoute>>(
    Object.entries<string>(AppRoute).map(([, value]) => ([value, {
        key: value as AppRoute,
        label: `${value}.label`,
        path: `${value}.path`,
    }])),
);
