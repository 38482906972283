import { FC, ReactElement, useState } from 'react';

import { Section } from '../../../components';
import { SectionWrapper } from '../../../compositions';
import { DefaultComponentAppearance } from '../../../entities/Component/Component';
import { DepartmentDetails, InteractiveMapHeader, WorldMap } from './subcomponents';
import { MarkerType } from './subcomponents/WorldMap/WorldMap';

import './InteractiveMap.scss';

interface InteractiveMapProps extends DefaultComponentAppearance {
    className?: string;
}

const InteractiveMap: FC<InteractiveMapProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    className = '',
}): ReactElement => {
    const [activeFilters, setActiveFilters] = useState<Set<MarkerType>>(new Set());

    const handleFilterClick = (type: MarkerType) => {
        const newFilters = new Set(activeFilters);

        if (newFilters.has(type)) {
            newFilters.delete(type);
        } else {
            newFilters.add(type);
        }

        setActiveFilters(newFilters);
    };

    return (
        <Section isDark={isDark} className={`interactive-map ${className}`}>
            <SectionWrapper noSpacingTop={noSpacingTop} noSpacingBottom={noSpacingBottom}>
                <InteractiveMapHeader
                    titleHtml="<h1>Our <strong>Global</strong> Presence</h1>"
                    onFilterClick={handleFilterClick}
                />

                <WorldMap activeFilters={activeFilters} />

                <DepartmentDetails className="interactive-map__details" />
            </SectionWrapper>
        </Section>
    );
};

export default InteractiveMap;
