import { FC, ReactElement } from 'react';

import { Page } from '../../components';
import { ConnectedArticleDetailHelmet, ConnectedArticleDetailRenderer } from '../../connectors';

const ArticleDetailPage: FC = (): ReactElement => (
    <Page className="article-detail-page">
        <ConnectedArticleDetailHelmet />

        <ConnectedArticleDetailRenderer />
    </Page>
);

export default ArticleDetailPage;
