import { FC, ReactElement } from 'react';

import { Picture, Section } from '../../../components';
import { SectionWrapper } from '../../../compositions';
import { ImageSectionInterface } from '../../../entities/@sections/ImageSection/ImageSection';

import './ImageSection.scss';

interface ImageSectionProps extends ImageSectionInterface {
    className?: string;
}

const ImageSection: FC<ImageSectionProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    image,
    className = '',
}): ReactElement => (
    <Section isDark={isDark} className={`image-section ${className}`}>
        <SectionWrapper noSpacingTop={noSpacingTop} noSpacingBottom={noSpacingBottom}>
            <div className="image-section__inner-wrapper">
                <Picture
                    {...image}
                    className="image-section__picture"
                />
            </div>
        </SectionWrapper>
    </Section>
);

export default ImageSection;
