import { FC, ReactElement } from 'react';

import useTrans from '../../../../hooks/useTrans';
import { PageSearchResultItem } from '..';
import { PageSearchResultItemProps } from '../PageSearchResultItem/PageSearchResultItem';

import './PageSearchResults.scss';

interface PageSearchResultsProps {
    isRecommended?: boolean;
    pageResults: PageSearchResultItemProps[];
    className?: string;
}

const PageSearchResults: FC<PageSearchResultsProps> = ({
    isRecommended,
    pageResults,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <div className={`page-search-results ${className}`}>
            {!isRecommended && (
                <h2 className="page-search-results__amount-title">
                    {trans('containers.searchResults.amountOfResults', {
                        amount: pageResults.length,
                    })}
                </h2>
            )}

            <h3 className="page-search-results__title">
                {trans(`containers.searchResults.${isRecommended ? 'recommendedPages' : 'pages'}`)}
            </h3>

            <ul className="page-search-results__list">
                {pageResults.map(result => (
                    <li key={result.slug} className="page-search-results__list-item">
                        <PageSearchResultItem {...result} />
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default PageSearchResults;
