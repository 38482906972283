import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { transformToDateIsoString } from '../../Date/DateTransformers';
import { transformToEmbedUrl } from '../../Embed/EmbedTransformers';
import { transformToDownloadableFile, transformToVideo } from '../../Media/MediaTransformers';
import { PageSection } from '../../PageSection/PageSection';
import {
    FinancialResultsTableListInterface,
    FinancialResultsTableListResource,
    FinancialResultsTableListRowInterface,
    FinancialResultsTableListRowResource,
    FinancialResultsTableListYearInterface,
    FinancialResultsTableListYearResource,
} from './FinancialResultsTableList';

const transformToFinancialResultsTableListRow = (resource: FinancialResultsTableListRowResource): FinancialResultsTableListRowInterface => {
    const releaseDate = transformToDateIsoString(resource.releaseDate);

    const pressRelease = resource.pressRelease?.data
        ? transformToDownloadableFile(resource.pressRelease.data)
        : undefined;

    const webcast = resource.webcast?.data
        ? transformToVideo(resource.webcast.data)
        : undefined;

    const presentation = resource.presentation?.data
        ? transformToDownloadableFile(resource.presentation.data)
        : undefined;

    const iframeUrl = !webcast && resource?.iframeUrl
        ? transformToEmbedUrl(resource.iframeUrl)
        : '';

    return {
        period: resource.period,
        releaseDate,
        pressRelease,
        webcast,
        presentation,
        iframeUrl,
    };
};

const transformToFinancialResultsTableListYear = (resource: FinancialResultsTableListYearResource): FinancialResultsTableListYearInterface => {
    const rows = resource.tableRows
        ? resource.tableRows.map(transformToFinancialResultsTableListRow)
        : [];

    return {
        year: resource.year,
        rows,
    };
};

export const transformToFinancialResultsTableList = (resource: FinancialResultsTableListResource): FinancialResultsTableListInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    const years = resource.years
        ? resource.years.map(transformToFinancialResultsTableListYear)
        : [];

    const next10Years = resource.yearsNext10
        ? resource.yearsNext10.map(transformToFinancialResultsTableListYear)
        : [];

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.financialResultsTableList,
        showYearSelector: resource.showYearSelector,
        title: resource.title,
        years: [...years, ...next10Years],
    };
};
