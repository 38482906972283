import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { transformToImage } from '../../Media/MediaTransformers';
import { PageSection } from '../../PageSection/PageSection';
import { ImageSectionInterface, ImageSectionResource } from './ImageSection';

export const transformToImageSection = (resource: ImageSectionResource): ImageSectionInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    const image = resource.image?.data
        ? transformToImage(resource.image.data)
        : undefined;

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.imageSection,
        image,
    };
};
