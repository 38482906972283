export const initMatomo = (): void => {
    // @ts-ignore
    const _paq = window._paq || [];
    // @ts-ignore
    window._paq = _paq;

    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);

    const url = 'https://matomo.mytkhgroup.com';

    _paq.push(['setTrackerUrl', `${url}/matomo.php`]);
    _paq.push(['setSiteId', '2']);

    const matomoScript = document.createElement('script');
    const firstScriptElement = document.getElementsByTagName('script')[0];

    matomoScript.async = true;
    matomoScript.src = `${url}/matomo.js`;
    firstScriptElement.parentNode?.insertBefore(matomoScript, firstScriptElement);
};
