import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { Section } from '../../../components';
import {
    BackgroundIllustration,
    DownloadList,
    SectionWrapper,
    YearSelector,
} from '../../../compositions';
import { AnnualDownloadsInterface, AnnualDownloadsYear } from '../../../entities/@sections/AnnualDownloads/AnnualDownloads';
import { Alignment } from '../../../entities/Alignment/Alignment';
import { DownloadableFile } from '../../../entities/Media/Media';

import './AnnualDownloads.scss';

interface AnnualDownloadsProps extends AnnualDownloadsInterface {
    hasBackgroundIllustration?: boolean;
    className?: string;
}

const AnnualDownloads: FC<AnnualDownloadsProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    hasBackgroundIllustration,
    title,
    years,
    className = '',
}): ReactElement => {
    const [activeYear, setActiveYear] = useState<AnnualDownloadsYear | undefined>();
    const [activeFiles, setActiveFiles] = useState<DownloadableFile[]>([]);

    useEffect((): void => {
        if (years.length > 0) {
            setActiveYear(years[0]);
            setActiveFiles(years[0].files);
        }
    }, []);

    const handleSelectYear = (year?: number): void => {
        const selectedYear = years.find(yearObj => yearObj.year === year);

        setActiveYear(selectedYear);

        if (selectedYear) {
            const { files } = selectedYear;

            setActiveFiles(files);
        } else {
            setActiveFiles([]);
        }
    };

    return (
        <Section isDark={isDark} className={`annual-downloads ${className}`}>
            <SectionWrapper noSpacingTop={noSpacingTop} noSpacingBottom={noSpacingBottom}>
                <h2 className="annual-downloads__title">
                    {title}
                </h2>

                <YearSelector
                    years={years.map(year => year.year)}
                    activeYear={activeYear?.year}
                    onSelect={handleSelectYear}
                    className="annual-downloads__year-selector"
                />

                <DownloadList
                    alignment={Alignment.vertical}
                    files={activeFiles}
                    className="annual-downloads__list"
                />

                {hasBackgroundIllustration && (
                    <BackgroundIllustration
                        image={{
                            src: 'images/manufacturing-lines.svg',
                            formats: {},
                            mime: 'image/svg',
                            alt: 'Illustration',
                        }}
                        className="annual-downloads__background-illustration"
                        pictureClassName="annual-downloads__background-illustration-picture"
                    />
                )}
            </SectionWrapper>
        </Section>
    );
};

export default AnnualDownloads;
