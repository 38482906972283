import { FC, ReactElement } from 'react';

import { Solution } from '../../../../entities/Solution/Solution';
import useTrans from '../../../../hooks/useTrans';
import { SolutionSearchResultItem } from '..';

import './SolutionSearchResults.scss';

interface SolutionSearchResultsProps {
    solutionResults: Solution[];
    className?: string;
}

const SolutionSearchResults: FC<SolutionSearchResultsProps> = ({
    solutionResults,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <div className={`solution-search-results ${className}`}>
            <h2 className="solution-search-results__amount-title">
                {trans('containers.searchResults.amountOfResults', {
                    amount: solutionResults.length,
                })}
            </h2>

            <h3 className="solution-search-results__title">
                {trans('containers.searchResults.solutions')}
            </h3>

            <ul className="solution-search-results__list">
                {solutionResults.map(result => (
                    <li key={result.id} className="solution-search-results__list-item">
                        <SolutionSearchResultItem {...result} />
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default SolutionSearchResults;
