import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { transformToEmbedUrl } from '../../Embed/EmbedTransformers';
import { transformToDownloadableFile, transformToVideo } from '../../Media/MediaTransformers';
import { PageSection } from '../../PageSection/PageSection';
import { transformToPerson } from '../../Person/PersonTransformers';
import { WebcastInterface, WebcastResource } from './Webcast';

export const transformToWebcast = (resource: WebcastResource): WebcastInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    const video = resource.video?.data
        ? transformToVideo(resource.video.data)
        : undefined;

    const iframeUrl = resource.iframeUrl
        ? transformToEmbedUrl(resource.iframeUrl)
        : '';

    const speakers = resource.speakers?.data
        ? resource.speakers.data.map(transformToPerson)
        : [];

    const downloads = resource.downloads?.data
        ? resource.downloads.data.map(transformToDownloadableFile)
        : [];

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.webcast,
        titleHtml: resource.title || '',
        video,
        iframeUrl,
        speakers,
        downloads,
    };
};
