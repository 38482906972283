import { FC, ReactElement } from 'react';

import { SolutionDetailHelmet } from '../../containers';
import { useTypedSelector } from '../../redux/store';

const ConnectedSolutionDetailHelmet: FC = (): ReactElement | null => {
    const solutionDetail = useTypedSelector(state => state.solutionDetailReducer.solutionDetail);

    if (!solutionDetail) {
        return null;
    }

    return (
        <SolutionDetailHelmet
            solution={solutionDetail}
        />
    );
};

export default ConnectedSolutionDetailHelmet;
