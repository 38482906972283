import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { transformToDownloadableFile } from '../../Media/MediaTransformers';
import { PageSection } from '../../PageSection/PageSection';
import { HighlightedDownloadCardInterface, HighlightedDownloadCardResource } from './HighlightedDownloadCard';

export const transformToHighlightedDownloadCard = (resource: HighlightedDownloadCardResource): HighlightedDownloadCardInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    const file = resource.file?.data
        ? transformToDownloadableFile(resource.file.data)
        : undefined;

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.highlightedDownloadCard,
        title: resource.title,
        paragraphHtml: resource.paragraph || '',
        file,
    };
};
