/* eslint-disable max-len */
import React, { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconClosedCaptions: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={100} viewBoxHeight={100} className={className}>
        <path d="M92.4,44H7.6c-3.3,0-6-2.7-6-6s2.7-6,6-6h84.7c3.3,0,6,2.7,6,6S95.7,44,92.4,44z" />
        <path d="M78,68H22c-3.3,0-6-2.7-6-6s2.7-6,6-6h56c3.3,0,6,2.7,6,6S81.3,68,78,68z" />
    </Svg>
);

export default IconClosedCaptions;
/* eslint-enable max-len */
