import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    RefAttributes,
    useEffect,
    useRef,
} from 'react';

import classNames from 'classnames';
import { Linear } from 'gsap';

import { GridLines, Picture, Wrapper } from '../../components';
import { ParallaxLineSectionType } from '../../entities/@sections/ParallaxLineSection/ParallaxLineSection';
import useTrans from '../../hooks/useTrans';

import './ParallaxLineSection.scss';

interface ParallaxSectionProps extends RefAttributes<HTMLDivElement> {
    isAbsolute: boolean;
    imageType: ParallaxLineSectionType;
    introTimeline?: gsap.core.Timeline;
    parallaxTimeline?: gsap.core.Timeline;
    className?: string;
}

const ParallaxLineSection: ForwardRefExoticComponent<ParallaxSectionProps> = forwardRef(({
    isAbsolute,
    imageType,
    introTimeline,
    parallaxTimeline,
    className = '',
}, ref): ReactElement => {
    const trans = useTrans();

    const svgPictureWrapperRef = useRef<HTMLDivElement>(null);
    const svgPictureRef = useRef<HTMLPictureElement>(null);
    const svgRef = useRef<HTMLImageElement>(null);
    const darkenRef = useRef<HTMLDivElement>(null);
    const gradientTopRef = useRef<HTMLDivElement>(null);

    useEffect((): void => {
        if (
            !svgPictureWrapperRef.current
            || !svgPictureRef.current
            || !svgRef.current
            || !darkenRef.current
            || !gradientTopRef.current
            || !introTimeline
            || !parallaxTimeline
        ) {
            return;
        }

        introTimeline.fromTo(darkenRef.current, { opacity: 1 }, { opacity: 0, duration: 1, ease: Linear.easeNone }, 0);
        introTimeline.fromTo(gradientTopRef.current, { y: '-100%' }, { y: '-150%', duration: 1, ease: Linear.easeNone }, 0);

        parallaxTimeline.fromTo(svgPictureWrapperRef.current, { y: '100%' }, { y: '0%', duration: 1, ease: Linear.easeNone }, 0);
        parallaxTimeline.fromTo(svgPictureRef.current, { y: '-100%' }, { y: '0%', duration: 1, ease: Linear.easeNone }, 0);
        parallaxTimeline.fromTo(svgRef.current, { opacity: 0 }, { opacity: 1, duration: 0.5, ease: Linear.easeNone }, 0);
    }, [introTimeline, parallaxTimeline]);

    const image = {
        src: `/images/parallax-line-section/${imageType}.jpg`,
        alt: trans(`compositions.parallaxLineSection.imageAlts.${imageType}.image`),
    };

    const lineSvg = {
        src: `/images/parallax-line-section/${imageType}.svg`,
        alt: trans(`compositions.parallaxLineSection.imageAlts.${imageType}.lineSvg`),
    };

    const sectionClassName = classNames('parallax-line-section', {
        'parallax-line-section--is-absolute': isAbsolute,
    }, className);

    return (
        <div ref={ref} className={sectionClassName}>
            <div className="parallax-line-section__image-and-svg-wrapper">
                <Picture
                    src={image.src}
                    alt={image.alt}
                    className="parallax-line-section__image-picture"
                    imageClassName="parallax-line-section__image"
                />

                <div
                    ref={svgPictureWrapperRef}
                    className="parallax-line-section__svg-picture-wrapper"
                >
                    <Wrapper hideGrid className="parallax-line-section__lines-wrapper">
                        <GridLines className="parallax-line-section__lines" />
                    </Wrapper>

                    <picture
                        ref={svgPictureRef}
                        className="parallax-line-section__svg-picture"
                    >
                        <img
                            ref={svgRef}
                            src={lineSvg.src}
                            alt={lineSvg.alt}
                            className="parallax-line-section__svg"
                        />
                    </picture>
                </div>

                <div
                    ref={darkenRef}
                    className="parallax-line-section__darken"
                />

                <div
                    ref={gradientTopRef}
                    className="parallax-line-section__gradient-top"
                />

                <div className="parallax-line-section__gradient-bottom" />
            </div>
        </div>
    );
});

export default ParallaxLineSection;
