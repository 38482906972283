import { FC, PropsWithChildren, ReactElement } from 'react';

import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import { companyTwitterHandle } from '../../../constants';
import { Solution } from '../../../entities/Solution/Solution';
import { getFirstParagraphContentFromHtmlString } from '../../../helpers/html';
import { trimToMaxLength } from '../../../helpers/string';
import useTrans from '../../../hooks/useTrans';

interface SolutionDetailHelmetProps {
    solution: Solution;
}

const SolutionDetailHelmet: FC<PropsWithChildren<SolutionDetailHelmetProps>> = ({
    solution,
    children,
}): ReactElement => {
    const { pathname } = useLocation();
    const trans = useTrans();

    const firstParagraphContent = getFirstParagraphContentFromHtmlString(solution.body);
    const solutionDescription = trimToMaxLength(firstParagraphContent, 160);

    const siteUrl = trans('company.url');
    const logoUrl = `${siteUrl}/images/logo.jpg`;
    const fallbackImageUrl = `${siteUrl}/images/metadata-preview.jpg`;

    const metaTitle = solution.seo
        ? solution.seo.title
        : solution.title;

    const metaDescription = solution.seo
        ? solution.seo.description
        : solutionDescription;

    const metaKeywords = solution.seo
        ? solution.seo.keywords
        : undefined;

    const metaImage = solution.seo
        ? solution.seo.image
        : solution.image;

    return (
        <Helmet>
            <title>{solution.title}</title>
            <meta name="description" content={metaDescription} />
            <meta name="keywords" content={metaKeywords} />

            <meta property="og:title" content={metaTitle} />
            <meta property="og:description" content={metaDescription} />

            <meta property="og:image" content={metaImage?.src || fallbackImageUrl} />
            <meta property="og:image:alt" content={metaImage?.alt || trans('company.name')} />

            <meta property="og:url" content={siteUrl + pathname} />

            {companyTwitterHandle && (
                <>
                    <meta property="twitter:card" content="summary" />
                    <meta property="twitter:site" content={companyTwitterHandle} />

                    <meta property="twitter:title" content={metaTitle} />
                    <meta property="twitter:description" content={metaDescription} />

                    <meta property="twitter:image" content={metaImage?.src || fallbackImageUrl} />
                    <meta property="twitter:image:alt" content={metaImage?.alt || trans('company.name')} />
                </>
            )}

            <link rel="canonical" href={siteUrl + pathname} />

            <script type="application/ld+json">
                {JSON.stringify({
                    '@context': 'https://schema.org',
                    '@type': 'BlogPosting',
                    mainEntityOfPage: {
                        '@type': 'WebPage',
                    },
                    author: {
                        '@type': 'Organization',
                        name: 'TKH Group',
                    },
                    publisher: {
                        '@type': 'Organization',
                        name: 'TKH Group',
                        logo: {
                            '@type': 'ImageObject',
                            url: logoUrl,
                        },
                    },
                    headline: metaTitle,
                    image: metaImage?.src ? [metaImage.src] : undefined,
                    datePublished: solution.createdAt,
                    dateModified: solution.updatedAt,
                })}
            </script>

            {children}
        </Helmet>
    );
};

export default SolutionDetailHelmet;
