import {
    createContext,
    Dispatch,
    FC,
    PropsWithChildren,
    Reducer,
    useContext,
    useEffect,
    useReducer,
} from 'react';

import { useLocalStorage } from 'react-use';

import { defaultLocale, LocaleData } from '../constants/locale';

export const LocaleContext = createContext<LocaleData>(defaultLocale);
export const LocaleDispatchContext = createContext((localeData => localeData) as Dispatch<LocaleData>);

export const useLocale = () => useContext(LocaleContext);
export const useLocaleDispatch = () => useContext(LocaleDispatchContext);

const localeReducer: Reducer<LocaleData, LocaleData> = (prevState, localeData) => localeData;

const LocaleProvider: FC<PropsWithChildren> = ({ children }) => {
    const [storedLocale, storeLocale] = useLocalStorage<LocaleData>('locale', undefined);

    const [locale, dispatch] = useReducer(localeReducer, storedLocale || defaultLocale);

    useEffect((): void => {
        const { language, direction } = locale;

        document.documentElement.dir = direction;
        document.documentElement.lang = language;

        storeLocale(locale);
    }, [locale]);

    return (
        <LocaleContext.Provider value={locale}>
            <LocaleDispatchContext.Provider value={dispatch}>
                {children}
            </LocaleDispatchContext.Provider>
        </LocaleContext.Provider>
    );
};

export default LocaleProvider;
