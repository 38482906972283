import { Entity, Meta } from '../../types';
import { DateResource } from '../Date/Date';
import { LinkTarget } from '../Link/Link';
import { PaginationMetaResource } from '../Pagination/Pagination';

export enum NavigationType {
    main = 'main',
    footer = 'footer',
    legal = 'legal',
}

export enum NavigationLevel {
    hidden = 0,
    base = 1,
    group = 2,
    subGroup = 3,
}

export interface NavigationItemResource {
    order: number;
    title: string;
    url: string;
    target: string;
    children: Entity<NavigationItemEntityResource[]>;
    createdAt: DateResource;
    updatedAt: DateResource;
}

export interface NavigationItemEntityResource {
    id?: number;
    attributes?: NavigationItemResource;
}

export interface NavigationResource {
    slug: string;
    title: string;
    items: Entity<NavigationItemEntityResource[]>;
    createdAt: DateResource;
    updatedAt: DateResource;
}

export interface NavigationEntityResource {
    id?: number;
    attributes?: NavigationResource;
}

export type NavigationResponse = Entity<NavigationEntityResource[]> & Meta<PaginationMetaResource>;

export interface NavigationItem {
    id: string;
    order: number;
    label: string;
    href: string;
    target: LinkTarget;
    subNavigation: NavigationItem[];
}

export interface Navigation {
    id: string;
    title: string;
    slug: NavigationType;
    list: NavigationItem[];
}
