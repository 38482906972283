import {
    FC,
    FormEvent,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { Card, ErrorLabel, Icon } from '../../../components';
import { RecaptchaDisclaimer, SubmitButton, TextInput } from '../../../compositions';
import { NewsletterFormData } from '../../../entities/@forms/NewsletterForm/NewsletterForm';
import { scrollIntoView } from '../../../helpers/scroll';
import useTrans from '../../../hooks/useTrans';
import { FormProps } from '../../../types';
import { NewsletterFormErrors, validateNewsletterFormData } from './validations';

import './NewsLetterForm.scss';

export interface NewsletterFormProps extends FormProps<NewsletterFormData> {
    title?: string;
    submitButtonLabel?: string;
    className?: string;
}

const NewsletterForm: FC<NewsletterFormProps> = ({
    isLoading,
    isSuccessful,
    title,
    submitButtonLabel,
    onSubmit,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const formRef = useRef<HTMLFormElement>(null);
    const [formErrors, setFormErrors] = useState<NewsletterFormErrors>({});
    const [botDetected, setBotDetected] = useState<boolean>(false);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    useEffect((): void => {
        if (isSuccessful) {
            setName('');
            setEmail('');
        }
    }, [isSuccessful]);

    const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();

        if (!executeRecaptcha) {
            setBotDetected(true);
            return;
        }

        const formData: NewsletterFormData = {
            name,
            email,
        };

        const [errors, hasErrors] = validateNewsletterFormData(formData);
        const token = await executeRecaptcha();

        setFormErrors(errors);
        setBotDetected(!token);

        if (!hasErrors && token) {
            onSubmit(formData);
        } else {
            scrollIntoView<HTMLFormElement>(formRef);
        }
    };

    return (
        <Card className={`newsletter-form ${className}`}>
            <form
                ref={formRef}
                onSubmit={handleSubmit}
                className="newsletter-form__form"
            >
                <div className="newsletter-form__title-wrapper">
                    <Icon name="mail" className="newsletter-form__title-icon" />

                    <h2 className="newsletter-form__title">
                        {title || trans('containers.newsletterForm.title')}
                    </h2>
                </div>

                <TextInput
                    label={trans('containers.newsletterForm.name')}
                    value={name}
                    error={formErrors.name}
                    disabled={isLoading}
                    onChange={setName}
                />

                <TextInput
                    label={trans('containers.newsletterForm.email')}
                    type="email"
                    value={email}
                    error={formErrors.email}
                    disabled={isLoading}
                    onChange={setEmail}
                />

                <SubmitButton
                    isLoading={isLoading}
                    text={submitButtonLabel || trans('containers.newsletterForm.submitButton')}
                    className="newsletter-form__submit-button"
                />

                <RecaptchaDisclaimer className="newsletter-form__recaptcha-disclaimer" />

                {botDetected && (
                    <ErrorLabel
                        text={trans('errors.botDetected')}
                        className="newsletter-form__recaptcha-error"
                    />
                )}
            </form>
        </Card>
    );
};

export default NewsletterForm;
