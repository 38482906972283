import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    RefAttributes,
} from 'react';

import { ThemeTitle, Wrapper } from '../../../components';

import './ParallaxLineSectionHeader.scss';

interface ParallaxLineSectionHeaderProps extends RefAttributes<HTMLDivElement> {
    titleHtml: string;
    caption: string;
    className?: string;
}

const ParallaxLineSectionHeader: ForwardRefExoticComponent<ParallaxLineSectionHeaderProps> = forwardRef(({
    titleHtml,
    caption,
    className = '',
}, ref): ReactElement => (
    <Wrapper hideGrid className={`parallax-line-section-header ${className}`}>
        <div
            ref={ref}
            className="parallax-line-section-header__content"
        >
            <ThemeTitle
                titleHtml={titleHtml}
                className="parallax-line-section-header__title"
            />

            <h2 className="parallax-line-section-header__caption">
                {caption}
            </h2>

            <div className="parallax-line-section-header__line" />
        </div>
    </Wrapper>
));

export default ParallaxLineSectionHeader;
