import {
    FC,
    ReactElement,
    useCallback,
    useRef,
    useState,
} from 'react';

import { Section } from '../../../components';
import { Carousel, ChevronNavigation, SectionWrapper } from '../../../compositions';
import { SdgCarouselInterface } from '../../../entities/@sections/SdgCarousel/SdgCarousel';
import { clamp } from '../../../helpers/number';
import useDeviceWidth from '../../../hooks/useDeviceWidth';
import useElementSize from '../../../hooks/useElementSize';
import { Sdg } from './subcomponents';

import './SdgCarousel.scss';

interface SdgCarouselProps extends SdgCarouselInterface {
    className?: string;
}

const SdgCarousel: FC<SdgCarouselProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    slides,
    className = '',
}): ReactElement => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const carouselRef = useRef<HTMLDivElement>(null);
    const { isMobile } = useDeviceWidth();
    const { width: slideWidth } = useElementSize(carouselRef);

    const [activeIndex, setActiveIndex] = useState(0);

    const handleChevronClick = useCallback((value: -1 | 1): void => {
        const newIndex = activeIndex + value;
        const clampedValue = clamp(newIndex, 0, slides.length - 1);

        if (!isMobile) {
            setActiveIndex(clampedValue);

            return;
        }

        if (carouselRef.current) {
            carouselRef.current.scroll({
                left: newIndex * slideWidth,
                behavior: 'smooth',
            });
        }
    }, [isMobile, activeIndex]);

    return (
        <Section
            ref={wrapperRef}
            isDark={isDark}
            className={`sdg-carousel ${className}`}
        >
            <SectionWrapper noSpacingTop={noSpacingTop} noSpacingBottom={noSpacingBottom}>
                <div className="sdg-carousel__inner-wrapper">
                    <Carousel
                        ref={carouselRef}
                        activeIndex={activeIndex}
                        items={slides.map(sdg => <Sdg {...sdg} />)}
                        slidesLength={slides.length}
                        onMobileActiveIndexChange={setActiveIndex}
                    />

                    <div data-dark="true" className="sdg-carousel__navigation">
                        <ChevronNavigation
                            isPrevButtonDisabled={activeIndex === 0}
                            isNextButtonDisabled={activeIndex === slides.length - 1}
                            onClick={handleChevronClick}
                        />
                    </div>
                </div>
            </SectionWrapper>
        </Section>
    );
};

export default SdgCarousel;
