import {
    DefaultComponent,
    DefaultComponentAppearance,
    DefaultComponentAppearanceResource,
    DefaultComponentResource,
} from '../../Component/Component';

export enum ParallaxLineSectionTypeResource {
    vision = 'Vision',
    manufacturing = 'Manufacturing',
    connectivity = 'Connectivity',
    people = 'People',
    sustainability = 'Sustainability',
    innovation = 'Innovation',
}

export interface ParallaxLineSectionResource extends DefaultComponentResource, DefaultComponentAppearanceResource {
    id: string;
    title: string;
    caption: string;
    lineImage: ParallaxLineSectionTypeResource;
}

export enum ParallaxLineSectionType {
    vision = 'vision',
    manufacturing = 'manufacturing',
    connectivity = 'connectivity',
    people = 'people',
    sustainability = 'sustainability',
    innovation = 'innovation',
}

export interface ParallaxLineSectionInterface extends DefaultComponent, DefaultComponentAppearance {
    imageType: ParallaxLineSectionType;
    titleHtml: string;
    caption: string;
}
