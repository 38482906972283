import { FC, ReactElement } from 'react';

import { Icon, LinkButton, Section } from '../../../components';
import { SectionWrapper } from '../../../compositions';
import { NewsletterCallToActionInterface } from '../../../entities/@sections/NewsletterCallToAction/NewsletterCallToAction';

import './NewsletterCallToAction.scss';

interface NewsletterCallToActionProps extends NewsletterCallToActionInterface {
    newsletterPath: string;
    className?: string;
}

const NewsletterCallToAction: FC<NewsletterCallToActionProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    title,
    newsletterPath,
    buttonLabel,
    className = '',
}): ReactElement => (
    <Section isDark={isDark} className={`newsletter-call-to-action ${className}`}>
        <SectionWrapper
            hideGrid
            noSpacingTop={noSpacingTop}
            noSpacingBottom={noSpacingBottom}
        >
            <div className="newsletter-call-to-action__content-wrapper">
                <div className="newsletter-call-to-action__title-wrapper">
                    <Icon
                        name="mail"
                        className="newsletter-call-to-action__icon"
                    />

                    <h2 className="newsletter-call-to-action__title">
                        {title}
                    </h2>
                </div>

                <LinkButton
                    to={newsletterPath}
                    text={buttonLabel}
                    className="newsletter-call-to-action__link-button"
                />
            </div>
        </SectionWrapper>
    </Section>
);

export default NewsletterCallToAction;
