import { FC, ReactElement } from 'react';

import { Skeleton } from '../../../../components';
import { generateIdArray } from '../../../../helpers/array';
import { randomInBetweenValue } from '../../../../helpers/number';
import useTrans from '../../../../hooks/useTrans';

import './SearchResultsSkeletons.scss';

interface SearchResultsSkeletonsProps {
    className?: string;
}

const SearchResultsSkeletons: FC<SearchResultsSkeletonsProps> = ({
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const amountOfPageResults = randomInBetweenValue(4, 6);
    const pageResultsSkeletons = generateIdArray(amountOfPageResults);

    const amountOfArticleResults = randomInBetweenValue(4, 6);
    const articleResultsSkeletons = generateIdArray(amountOfArticleResults);

    return (
        <div className={`search-results-skeletons ${className}`}>
            <div className="search-results-skeletons__group">
                <div className="search-results-skeletons__amount-title">
                    <Skeleton />
                </div>

                <h3 className="search-results-skeletons__title">
                    {trans('containers.searchResults.pages')}
                </h3>

                <ul className="search-results-skeletons__page-list">
                    {pageResultsSkeletons.map(pageResult => {
                        const amountOfDescriptionLines = randomInBetweenValue(2, 4);
                        const descriptionLines = generateIdArray(amountOfDescriptionLines);

                        return (
                            <li key={pageResult} className="search-results-skeletons__page-result">
                                <Skeleton className="search-results-skeletons__page-title" />

                                <Skeleton className="search-results-skeletons__page-category" />

                                <div className="search-results-skeletons__page-description">
                                    {descriptionLines.map(descriptionLine => (
                                        <Skeleton
                                            key={descriptionLine}
                                            className="search-results-skeletons__page-description-line"
                                        />
                                    ))}
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>

            <div className="search-results-skeletons__group">
                <div className="search-results-skeletons__amount-title">
                    <Skeleton />
                </div>

                <h3 className="search-results-skeletons__title">
                    {trans('containers.searchResults.articles')}
                </h3>

                <ul className="search-results-skeletons__article-list">
                    {articleResultsSkeletons.map(articleResult => (
                        <li key={articleResult} className="search-results-skeletons__article-result">
                            <div className="search-results-skeletons__article-title-wrapper">
                                <Skeleton className="search-results-skeletons__article-title" />
                            </div>

                            <div className="search-results-skeletons__article-meta-wrapper">
                                <Skeleton className="search-results-skeletons__article-category" />

                                <Skeleton className="search-results-skeletons__article-date" />
                            </div>

                            <Skeleton className="search-results-skeletons__article-link-button" />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default SearchResultsSkeletons;
