import { FC, PropsWithChildren, ReactElement } from 'react';

import './Card.scss';

interface CardProps {
    className?: string;
}

const Card: FC<PropsWithChildren<CardProps>> = ({ className = '', children }): ReactElement => (
    <div
        data-dark="true"
        className={`card ${className}`}
    >
        {children}
    </div>
);

export default Card;
