import { FC, ReactElement, RefObject } from 'react';

import { scrollIntoView } from '../../../helpers/scroll';
import useTrans from '../../../hooks/useTrans';

import './ScrollDownIndicator.scss';

interface ScrollDownIndicatorProps {
    scrollTarget?: RefObject<HTMLElement>;
    className?: string;
}

const ScrollDownIndicator: FC<ScrollDownIndicatorProps> = ({ className = '', scrollTarget }): ReactElement => {
    const trans = useTrans();

    const handleButtonClick = (): void => {
        scrollIntoView(scrollTarget);
    };

    return (
        <button
            type="button"
            onClick={handleButtonClick}
            className={`scroll-down-indicator ${className}`}
        >
            <div className="scroll-down-indicator__label">
                {trans('components.scrollDownIndicator.label')}
            </div>

            <div className="scroll-down-indicator__line" />
        </button>
    );
};

export default ScrollDownIndicator;
