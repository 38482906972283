import {
    DefaultComponent,
    DefaultComponentAppearance,
    DefaultComponentAppearanceResource,
    DefaultComponentResource,
} from '../../Component/Component';
import { Link, LinkResource } from '../../Link/Link';

export enum ColumnWidthResource {
    two = 'Two',
    three = 'Three',
    four = 'Four',
    six = 'Six',
}

export interface ContentColumnResource {
    id: string;
    title?: string;
    intro?: string;
    paragraph?: string;
    link?: LinkResource;
}

export interface ContentColumnsResource extends DefaultComponentResource, DefaultComponentAppearanceResource {
    title?: string;
    paragraph?: string;
    columnWidth: ColumnWidthResource;
    columns: ContentColumnResource[];
}

export type ColumnWidth = 2 | 3 | 4 | 6;

export interface ContentColumnInterface {
    id: string;
    titleHtml?: string;
    introHtml?: string;
    paragraphHtml?: string;
    link?: Link;
}

export interface ContentColumnsInterface extends DefaultComponent, DefaultComponentAppearance {
    titleHtml?: string;
    paragraphHtml?: string;
    columnWidth: ColumnWidth;
    columns: ContentColumnInterface[];
}
