import { RefObject, useEffect, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useResizeObserver = (ref: RefObject<any>, deps: any[] = []): DOMRect | undefined => {
    const [domRect, setDomRect] = useState<DOMRect>();

    useEffect((): () => void => {
        const resizeObserver = new ResizeObserver((): void => {
            const boundingClientRect = ref.current.getBoundingClientRect();

            setDomRect(boundingClientRect);
        });

        if (ref.current) {
            resizeObserver.observe(ref.current);
        }

        return () => resizeObserver.disconnect();
    }, deps);

    return domRect;
};

export default useResizeObserver;
