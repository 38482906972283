import { FC, ReactElement } from 'react';

import { KeyFigure, Section } from '../../../components';
import { SectionWrapper } from '../../../compositions';
import { HighlightedKeyFigureInterface } from '../../../entities/@sections/HighlightedKeyFigure/HighlightedKeyFigure';

import './HighlightedKeyFigure.scss';

interface HighlightedKeyFigureProps extends HighlightedKeyFigureInterface {
    className?: string;
}

const HighlightedKeyFigure: FC<HighlightedKeyFigureProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    title,
    paragraph,
    keyFigure,
    className = '',
}): ReactElement => (
    <Section isDark={isDark} className={`highlighted-key-figure ${className}`}>
        <SectionWrapper noSpacingTop={noSpacingTop} noSpacingBottom={noSpacingBottom}>
            <div className="highlighted-key-figure__wrapper">
                <section className="highlighted-key-figure__content-wrapper">
                    <h2 className="highlighted-key-figure__title">
                        {title}
                    </h2>

                    <p className="highlighted-key-figure__paragraph">
                        {paragraph}
                    </p>
                </section>

                <KeyFigure
                    isAnimated
                    amount={keyFigure.amount}
                    label={keyFigure.label}
                    className="highlighted-key-figure__figure"
                />
            </div>
        </SectionWrapper>
    </Section>
);

export default HighlightedKeyFigure;

