import { combineSlices } from '@reduxjs/toolkit';

import appReducer, { AppState } from './app/appReducer';
import articleCategoriesReducer, { ArticleCategoriesState } from './articleCategories/articleCategoriesReducer';
import articleDetailReducer, { ArticleDetailState } from './articleDetail/articleDetailReducer';
import contactFormReducer, { ContactFormState } from './contactForm/contactFormReducer';
import eventsReducer, { EventsState } from './events/eventsReducer';
import globalSettingsReducer, { GlobalSettingsState } from './globalSettings/globalSettingsReducer';
import navigationReducer, { NavigationState } from './navigation/navigationReducer';
import newsletterFormReducer, { NewsletterFormState } from './newsletterForm/newsletterFormReducer';
import pageReducer, { PageState } from './page/pageReducer';
import pressReleaseFormReducer, { PressReleaseFormState } from './pressReleaseForm/pressReleaseFormReducer';
import searchReducer, { SearchState } from './search/searchReducer';
import sharePriceReducer, { SharePriceState } from './sharePrice/sharePriceReducer';
import solutionDetailReducer, { SolutionDetailState } from './solutionDetail/solutionDetailReducer';
import solutionTagsReducer, { SolutionTagsState } from './solutionTags/solutionTagsReducer';
import toastReducer, { ToastState } from './toast/toastReducer';
import vacanciesReducer, { VacanciesState } from './vacancies/vacanciesReducer';

export interface Reducers {
    appReducer: AppState;
    articleCategoriesReducer: ArticleCategoriesState;
    articleDetailReducer: ArticleDetailState;
    contactFormReducer: ContactFormState;
    eventsReducer: EventsState;
    globalSettingsReducer: GlobalSettingsState;
    navigationReducer: NavigationState;
    newsletterFormReducer: NewsletterFormState;
    pageReducer: PageState;
    pressReleaseFormReducer: PressReleaseFormState;
    searchReducer: SearchState;
    sharePriceReducer: SharePriceState;
    solutionDetailReducer: SolutionDetailState;
    solutionTagsReducer: SolutionTagsState;
    toastReducer: ToastState;
    vacanciesReducer: VacanciesState;
}

export default combineSlices(
    appReducer,
    articleCategoriesReducer,
    articleDetailReducer,
    contactFormReducer,
    eventsReducer,
    globalSettingsReducer,
    navigationReducer,
    newsletterFormReducer,
    pageReducer,
    pressReleaseFormReducer,
    searchReducer,
    sharePriceReducer,
    solutionDetailReducer,
    solutionTagsReducer,
    toastReducer,
    vacanciesReducer,
);
