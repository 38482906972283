import { FC, ReactElement } from 'react';

import trans from '../../helpers/trans';

import './VideoEmbed.scss';

export interface VideoEmbedProps {
    embedUrl: string;
    className?: string;
    iframeClassName?: string;
}

const VideoEmbed: FC<VideoEmbedProps> = ({
    embedUrl,
    className = '',
    iframeClassName = '',
}): ReactElement => (
    <div className={`video-embed ${className}`}>
        <iframe
            title={trans('compositions.videoEmbed.title')}
            src={embedUrl}
            className={`video-embed__iframe ${iframeClassName}`}
        />
    </div>
);

export default VideoEmbed;
