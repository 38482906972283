import { FC, ReactElement, useState } from 'react';

import { Section } from '../../../components';
import { IconButton, LinkIconButton, SectionWrapper } from '../../../compositions';
import { DefaultComponentAppearance } from '../../../entities/Component/Component';
import { isSSR } from '../../../helpers';
import { writeTextToClipboard } from '../../../helpers/browser';
import useTimeout from '../../../hooks/useTimeout';
import useTrans from '../../../hooks/useTrans';

import './Share.scss';

interface ShareProps extends DefaultComponentAppearance {
    className?: string;
}

const Share: FC<ShareProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const [isCopySuccess, setIsCopySuccess] = useState<boolean>(false);

    const url = !isSSR ? window.location.href : '';

    const linkedInUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${url}`;
    const emailUrl = `mailto:?body=${url}`;

    useTimeout((): void => {
        if (isCopySuccess) {
            setIsCopySuccess(false);
        }
    }, 2000, [isCopySuccess]);

    const handleShareLinkButtonClick = async (): Promise<void> => {
        const isSuccess = await writeTextToClipboard(window.location.href);

        if (!isSuccess) {
            // TODO: Add error toast message?
        }

        setIsCopySuccess(isSuccess);
    };

    return (
        <Section isDark={isDark} className={`share ${className}`}>
            <SectionWrapper noSpacingTop={noSpacingTop} noSpacingBottom={noSpacingBottom}>
                <div className="share__content">
                    <h3 className="share__title">
                        {`${trans('containers.share.title')}:`}
                    </h3>

                    <LinkIconButton
                        hideLabel
                        icon="linked-in"
                        target="_blank"
                        text={trans('containers.share.shareOn', { platform: 'LinkedIn' })}
                        to={linkedInUrl}
                        className="share__button share__button--linked-in"
                    />

                    <LinkIconButton
                        hideLabel
                        icon="mail"
                        target="_blank"
                        text={trans('containers.share.shareByEmail')}
                        to={emailUrl}
                        className="share__button share__button--mail"
                    />

                    <IconButton
                        hideLabel
                        icon={isCopySuccess ? 'check' : 'link'}
                        text={trans('containers.share.copyLink')}
                        disabled={isCopySuccess}
                        onClick={handleShareLinkButtonClick}
                        className="share__button share__button--link"
                    />
                </div>
            </SectionWrapper>
        </Section>
    );
};

export default Share;
