import { FC, ReactElement } from 'react';

import { SolutionDetailBody } from '../../containers';
import { SolutionDetailBodyInterface } from '../../entities/@sections/SolutionDetailBody/SolutionDetailBody';
import { useTypedSelector } from '../../redux/store';

interface ConnectedSolutionDetailBodyProps extends SolutionDetailBodyInterface {
    className?: string;
}

const ConnectedSolutionDetailBody: FC<ConnectedSolutionDetailBodyProps> = ({
    className = '',
    ...solutionDetailBodyProps
}): ReactElement => {
    const globalSettings = useTypedSelector(state => state.globalSettingsReducer.settings);

    return (
        <SolutionDetailBody
            {...solutionDetailBodyProps}
            linkedInPath={globalSettings.linkedInUrl || ''}
            className={className}
        />
    );
};

export default ConnectedSolutionDetailBody;
