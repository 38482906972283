import { Entity } from '../../../types';
import { HorizontalAlignment, HorizontalAlignmentResource } from '../../Alignment/Alignment';
import {
    DefaultComponent,
    DefaultComponentAppearance,
    DefaultComponentAppearanceResource,
    DefaultComponentResource,
} from '../../Component/Component';
import { Link, LinkResource } from '../../Link/Link';
import { Image, ImageObjectFitResource, MediaEntityResource } from '../../Media/Media';

export enum ImageViewResource {
    default = 'default',
    wideImage = 'wide-image',
    narrowImage = 'narrow-image',
}

export interface TextImageCardResource {
    id: string;
    title: string;
    text?: string;
}

export interface TextImageResource extends DefaultComponentResource, DefaultComponentAppearanceResource {
    id: string;
    title: string;
    imageView?: ImageViewResource;
    imageAlignment: HorizontalAlignmentResource;
    isCompact: boolean;
    hasBigTitle: boolean;
    paragraphHtml?: string;
    caption?: string;
    links?: LinkResource[];
    image?: Entity<MediaEntityResource>;
    imageObjectFit?: ImageObjectFitResource;
    card?: TextImageCardResource;
}

export enum TextImageType {
    default = 'default',
    wideImage = 'wide-image',
    narrowImage = 'narrow-image',
}

export enum ImageObjectFit {
    fill = 'fill',
    cover = 'cover',
    contain = 'contain',
}

export interface TextImageCardInterface {
    title: string;
    text: string;
}

export interface TextImageInterface extends DefaultComponent, DefaultComponentAppearance {
    imageType: TextImageType;
    imageAlignment: HorizontalAlignment;
    isCompact?: boolean;
    hasBigTitle?: boolean;
    titleHtml: string;
    paragraphHtml: string;
    caption?: string;
    links: Link[];
    image?: Image;
    imageObjectFit?: ImageObjectFit;
    card?: TextImageCardInterface;
}
