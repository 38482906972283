import {
    CSSProperties,
    FC,
    FormEvent,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import { Input } from '../../../../components';
import { IconButton } from '../../../../compositions';
import { AppRoute, appRoutes } from '../../../../constants/routing';
import { replaceUrlParamKeysWithValues } from '../../../../helpers/url';
import useDeviceWidth from '../../../../hooks/useDeviceWidth';
import useHandleClickOutside from '../../../../hooks/useHandleClickOutside';
import usePreferReducedMotion from '../../../../hooks/usePreferReducedMotion';
import useTrans from '../../../../hooks/useTrans';
import useWindowResizing from '../../../../hooks/useWindowResizing';

import './MenuSearchInput.scss';

interface MenuSearchInputProps {
    isExpanded: boolean;
    availableSpace: number;
    animationDuration: number;
    onExpand: () => void;
    onCollapse: () => void;
    className?: string;
}

const maxExpandedWidth = 560;

const MenuSearchInput: FC<MenuSearchInputProps> = ({
    isExpanded,
    availableSpace,
    animationDuration,
    onExpand,
    onCollapse,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const navigate = useNavigate();
    const { isMobile } = useDeviceWidth();
    const prefersReducedMotion = usePreferReducedMotion();
    const isWindowResizing = useWindowResizing(animationDuration);

    const formRef = useRef<HTMLFormElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const [query, setQuery] = useState<string>('');

    const handleSubmit = (): void => {
        if (!query) {
            onCollapse();
            return;
        }

        const paramPath = trans(appRoutes[AppRoute.searchResults].path);
        const searchQueryPath = replaceUrlParamKeysWithValues(paramPath, { query });

        navigate(searchQueryPath);
        onCollapse();
    };

    const handleFormSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        handleSubmit();
    };

    const handleChangeQuery = (event: FormEvent<HTMLInputElement>): void => {
        setQuery(event.currentTarget.value);
    };

    const handleBlurForm = (): void => {
        if (isMobile && isExpanded) {
            onCollapse();
        }
    };

    const handleCancelClick = (): void => {
        setQuery('');
        onCollapse();
    };

    useHandleClickOutside(formRef, handleBlurForm);

    useEffect((): void => {
        if (isExpanded && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isExpanded]);

    const formClassNames = classNames('menu-search-input', {
        'menu-search-input--is-expanded': isExpanded,
        'menu-search-input--is-animation-disabled': prefersReducedMotion || isWindowResizing,
    }, className);

    const cssVariables = {
        '--expanded-width': `${Math.min(availableSpace, maxExpandedWidth)}px`,
    } as CSSProperties;

    return (
        <form
            ref={formRef}
            style={cssVariables}
            onSubmit={handleFormSubmit}
            className={formClassNames}
        >
            <div className="menu-search-input__wrapper">
                <IconButton
                    hideLabel
                    icon="search"
                    text={trans('common.search')}
                    onClick={isExpanded ? handleSubmit : onExpand}
                    className="menu-search-input__open-button"
                    iconClassName="menu-search-input__open-button-icon"
                />

                <Input
                    placeholder={trans('common.search')}
                    ref={inputRef}
                    value={query}
                    disabled={!isExpanded}
                    onChange={handleChangeQuery}
                    className="menu-search-input__input"
                />

                <div className="menu-search-input__input-border" />

                <IconButton
                    icon="close-circle"
                    text={trans('common.cancel')}
                    hideLabel={isMobile}
                    disabled={!isExpanded}
                    onClick={handleCancelClick}
                    className="menu-search-input__close-button"
                    iconClassName="menu-search-input__close-button-icon"
                />
            </div>
        </form>
    );
};

export default MenuSearchInput;
