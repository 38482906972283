import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { transformToEmbedUrl } from '../../Embed/EmbedTransformers';
import { transformToDownloadableFile, transformToImage, transformToVideo as transformToVideoType } from '../../Media/MediaTransformers';
import { PageSection } from '../../PageSection/PageSection';
import { VideoSectionInterface, VideoSectionResource } from './VideoSection';

export const transformToVideo = (resource: VideoSectionResource): VideoSectionInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    const video = resource.video?.data
        ? transformToVideoType(resource.video.data)
        : undefined;

    const poster = resource.poster?.data
        ? transformToImage(resource.poster.data)
        : undefined;

    const closedCaptions = resource.closedCaptions?.data
        ? transformToDownloadableFile(resource.closedCaptions.data)
        : undefined;

    const embedUrl = !video && resource.iframeUrl
        ? transformToEmbedUrl(resource.iframeUrl)
        : '';

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.videoSection,
        titleHtml: resource.title || '',
        paragraphHtml: resource.paragraph || '',
        video,
        poster,
        closedCaptions,
        embedUrl,
    };
};
