import { FC, ReactElement } from 'react';

import { Section } from '../../../components';
import { SectionWrapper } from '../../../compositions';
import { ShareholderMeetingsArchiveInterface } from '../../../entities/@sections/ShareholderMeetingsArchive/ShareholderMeetingsArchive';
import { ShareholderMeetingsArchiveYear } from './subcomponents';

import './ShareholderMeetingsArchive.scss';

interface ShareholderMeetingsArchiveProps extends ShareholderMeetingsArchiveInterface {
    className?: string;
}

const ShareholderMeetingsArchive: FC<ShareholderMeetingsArchiveProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    title,
    years,
    className = '',
}): ReactElement => (
    <Section isDark={isDark} className={`shareholder-meetings-archive ${className}`}>
        <SectionWrapper noSpacingTop={noSpacingTop} noSpacingBottom={noSpacingBottom}>
            <h2 className="shareholder-meetings-archive__title">
                {title}
            </h2>

            {years.map(year => (
                <ShareholderMeetingsArchiveYear
                    {...year}
                    key={year.year}
                    className="shareholder-meetings-archive__year"
                    summaryClassName="shareholder-meetings-archive__year-summary"
                />
            ))}

            <div className="shareholder-meetings-archive__bottom-border" />
        </SectionWrapper>
    </Section>

);

export default ShareholderMeetingsArchive;
