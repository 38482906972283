import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PaginationInterface } from '../../entities/Pagination/Pagination';
import { Vacancy } from '../../entities/Vacancy/Vacancy';
import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type VacanciesState = AsyncReduxState<{
    vacancies: Vacancy[];
    pagination?: PaginationInterface;
}>;

const initialState: VacanciesState = {
    ...initialAsyncReduxState,
    vacancies: [],
};

const vacanciesSlice = createSlice({
    name: 'vacanciesReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): VacanciesState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): VacanciesState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): VacanciesState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setVacancies(state, action: PayloadAction<Vacancy[]>): VacanciesState {
            return {
                ...state,
                vacancies: action.payload,
            };
        },
        setPagination(state, action: PayloadAction<PaginationInterface | undefined>): VacanciesState {
            return {
                ...state,
                pagination: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsSuccessful,
    setError,
    setVacancies,
    setPagination,
} = vacanciesSlice.actions;

export default vacanciesSlice;
