import { FC, ReactElement } from 'react';

import { Article } from '../../../../entities/Article/Article';
import useTrans from '../../../../hooks/useTrans';
import { ArticleSearchResultItem } from '..';

import './ArticleSearchResults.scss';

interface ArticleSearchResultsProps {
    articleResults: Article[];
    className?: string;
}

const ArticleSearchResults: FC<ArticleSearchResultsProps> = ({
    articleResults,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <div className={`article-search-results ${className}`}>
            <h2 className="article-search-results__amount-title">
                {trans('containers.searchResults.amountOfResults', {
                    amount: articleResults.length,
                })}
            </h2>

            <h3 className="article-search-results__title">
                {trans('containers.searchResults.articles')}
            </h3>

            <ul className="article-search-results__list">
                {articleResults.map(result => (
                    <li key={result.id} className="article-search-results__list-item">
                        <ArticleSearchResultItem {...result} />
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ArticleSearchResults;
