import { DateIsoString } from '../entities/Date/Date';

export const getDateTime = (date: DateIsoString): string => {
    const dateObject = new Date(date);

    const year = dateObject.getFullYear();
    const month = dateObject.getMonth() + 1;
    const day = dateObject.getDate();

    return `${year}-${month}-${day}`;
};
