import { Navigation, NavigationItem } from '../../../entities/Navigation/Navigation';

interface ActiveNavigation {
    group?: NavigationItem;
    subGroup?: NavigationItem;
}

export const getActiveNavigationItem = (navigation: Navigation, pathname: string): ActiveNavigation => {
    let group;
    let subGroup;

    for (let i = 0; i < navigation.list.length; i += 1) {
        const currentGroup = navigation.list[i];
        const groupMatch = currentGroup.subNavigation.find(item => item.href === pathname);

        if (groupMatch) {
            group = currentGroup;
        }

        for (let j = 0; j < currentGroup.subNavigation.length; j += 1) {
            const currentSubGroup = currentGroup.subNavigation[j];
            const subGroupMatch = currentSubGroup.subNavigation.find(item => item.href === pathname);

            if (subGroupMatch) {
                group = currentGroup;
                subGroup = currentSubGroup;
            }
        }
    }

    return { group, subGroup };
};
