import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SharePriceData } from '../../entities/SharePrice/SharePrice';
import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type SharePriceState = AsyncReduxState<{
    sharePrice?: SharePriceData;
}>;

const initialState: SharePriceState = {
    ...initialAsyncReduxState,
};

const sharePriceSlice = createSlice({
    name: 'sharePriceReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): SharePriceState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): SharePriceState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): SharePriceState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setSharePrice(state, action: PayloadAction<SharePriceData>): SharePriceState {
            return {
                ...state,
                sharePrice: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsSuccessful,
    setError,
    setSharePrice,
} = sharePriceSlice.actions;

export default sharePriceSlice;
