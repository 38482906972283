import { isSSR } from '../../helpers';
import trans from '../../helpers/trans';
import { EurolandTickerResponse } from '../Euroland/Euroland';
import { FetchResult, FetchResultType } from '../FetchResult';

export const getSharePriceApiCall = async (): Promise<FetchResult<EurolandTickerResponse, string>> => {
    try {
        const apiUrl = isSSR
            ? `${process.env.PUBLIC_URL}/euroland`
            : `${window.location.origin}/euroland`;

        const response = await fetch(`${apiUrl}/ticker`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });

        const data = await response.json();

        return {
            status: 200,
            type: FetchResultType.Success,
            data,
        };
    } catch (error: unknown) {
        console.error('[getSharePriceApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

