import { FC, ReactElement, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { ArticleDetailRenderer } from '../../containers';
import { NotFoundPage } from '../../pages';
import { setHasDataFromServer } from '../../redux/app/appReducer';
import { fetchArticleDetail } from '../../redux/articleDetail/articleDetailActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

interface ConnectedArticleDetailRendererProps {
    className?: string;
}

const ConnectedArticleDetailRenderer: FC<ConnectedArticleDetailRendererProps> = ({
    className = '',
}): ReactElement => {
    const { slug = '' } = useParams();

    const dispatch = useTypedDispatch();

    const hasDataFromServer = useTypedSelector(state => state.appReducer.hasDataFromServer);

    const hasFetched = useTypedSelector(state => state.articleDetailReducer.hasFetched);
    const isLoading = useTypedSelector(state => state.articleDetailReducer.isLoading);
    const articleDetail = useTypedSelector(state => state.articleDetailReducer.articleDetail);

    const globalSettings = useTypedSelector(state => state.globalSettingsReducer.settings);

    useEffect((): void => {
        if (hasDataFromServer) {
            dispatch(setHasDataFromServer(false));
            return;
        }

        dispatch(fetchArticleDetail(slug));
    }, [slug]);

    if (hasFetched && !articleDetail) {
        return (
            <NotFoundPage />
        );
    }

    return (
        <ArticleDetailRenderer
            isLoading={isLoading}
            article={articleDetail}
            newsletterPath={globalSettings.newsletterLink?.path || ''}
            linkedInPath={globalSettings.linkedInUrl || ''}
            className={className}
        />
    );
};

export default ConnectedArticleDetailRenderer;
