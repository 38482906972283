import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
    RefAttributes,
} from 'react';

import { LinkButton, SafeHtml, ThemeTitle } from '../../components';
import { ContentColumnInterface } from '../../entities/@sections/ContentColumns/ContentColumns';
import { SanitizeOptions } from '../../helpers/sanitizeHtml';

import './ContentColumn.scss';

export interface ContentColumnProps extends Omit<ContentColumnInterface, 'id'>, RefAttributes<HTMLDivElement> {
    sanitizeOptions?: SanitizeOptions;
    className?: string;
}

const ContentColumn: ForwardRefExoticComponent<ContentColumnProps> = forwardRef(({
    titleHtml,
    introHtml,
    paragraphHtml,
    link,
    sanitizeOptions,
    className = '',
}, ref: Ref<HTMLDivElement>): ReactElement => (
    <div ref={ref} className={`content-column ${className}`}>
        {titleHtml && (
            <ThemeTitle
                titleHtml={titleHtml}
                className="content-column__title"
            />
        )}

        {introHtml && (
            <SafeHtml
                html={introHtml}
                sanitizeOptions={sanitizeOptions}
                className="content-column__intro"
            />
        )}

        {paragraphHtml && (
            <SafeHtml
                html={paragraphHtml}
                sanitizeOptions={sanitizeOptions}
                className="content-column__paragraph"
            />
        )}

        {link && (
            <LinkButton
                to={link.path}
                text={link.label}
                className="content-column__link"
            />
        )}
    </div>
));

export default ContentColumn;
