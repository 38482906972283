import { FC, ReactElement } from 'react';

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '../../../components';
import { DownloadableFile } from '../../../entities/Media/Media';
import { isDownloadableFile } from '../../../entities/Media/MediaHelpers';
import { generateIdArray } from '../../../helpers/array';
import useDeviceWidth from '../../../hooks/useDeviceWidth';
import { DownloadButton } from '../..';

import './GenericTable.scss';

export interface GenericTableProps {
    columns?: number[];
    tableHeaders: string[];
    rows: (string | DownloadableFile | undefined)[][];
    className?: string;
}

const GenericTable: FC<GenericTableProps> = ({
    columns = [1, 1, 1, 1, 1, 1],
    tableHeaders,
    rows,
    className = '',
}): ReactElement => {
    const { isMobile } = useDeviceWidth();

    const rowKeys = generateIdArray(rows.length);
    const cellKeys = generateIdArray(rows[0].length || 10);

    const responsiveColumns = isMobile ? [1, 1, 1, 1, 1, 1] : columns;

    return (
        <Table className={`generic-table ${className}`}>
            <TableHead>
                <TableRow className="generic-table__header-row">
                    {tableHeaders.map((header, index) => (
                        <TableCell
                            key={cellKeys[index]}
                            isHead
                            style={{
                                maxWidth: `${responsiveColumns[index] * 12.5}rem`,
                                minWidth: `${responsiveColumns[index] * 12.5}rem`,
                            }}
                            className="generic-table__table-cell"
                        >
                            {header}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>

            <TableBody>
                {rows.map((cells, rowIndex) => (
                    <TableRow
                        key={rowKeys[rowIndex]}
                        className="generic-table__row"
                    >
                        {cells.map((cell, cellIndex) => (
                            <TableCell
                                key={cellKeys[cellIndex]}
                                style={{
                                    maxWidth: `${responsiveColumns[cellIndex] * 12.5}rem`,
                                    minWidth: `${responsiveColumns[cellIndex] * 12.5}rem`,
                                }}
                                className="generic-table__table-cell"
                            >
                                {isDownloadableFile(cell) ? (
                                    <DownloadButton
                                        href={cell.src}
                                        extension={cell.extension}
                                    />
                                ) : cell}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default GenericTable;
