import { generateUuid } from '../../helpers/string';
import { transformToQuickLinks } from '../@sections/QuickLinks/QuickLinksTransformers';
import { defaultDateIsoString } from '../Date/Date';
import { transformToDateIsoString } from '../Date/DateTransformers';
import { transformToImage } from '../Media/MediaTransformers';
import { transformToSeo } from '../Seo/SeoTransformers';
import { transformToTag } from '../Tag/TagTransformers';
import {
    Solution,
    SolutionEntityResource,
    SolutionSidebar,
    SolutionSidebarResource,
} from './Solution';

const transformToSolutionSidebar = (resource: SolutionSidebarResource): SolutionSidebar => ({
    title: resource.title,
    description: resource.description,
});

export const transformToSolution = (resource: SolutionEntityResource): Solution => {
    const tag = resource.attributes?.tags
        ? resource.attributes.tags.data?.map(transformToTag)[0]
        : undefined;

    const createdAt = resource.attributes?.createdAt
        ? transformToDateIsoString(resource.attributes.createdAt)
        : defaultDateIsoString;

    const updatedAt = resource.attributes?.updatedAt
        ? transformToDateIsoString(resource.attributes.updatedAt)
        : defaultDateIsoString;

    const image = resource.attributes?.image?.data
        ? transformToImage(resource.attributes.image.data)
        : undefined;

    const relatedContent = resource.attributes?.relatedContent
        ? transformToQuickLinks(resource.attributes.relatedContent)
        : undefined;

    const sidebar = resource.attributes?.sidebar
        ? transformToSolutionSidebar(resource.attributes.sidebar)
        : undefined;

    const seo = resource.attributes?.seo
        ? transformToSeo(resource.attributes.seo)
        : undefined;

    return {
        id: resource.id?.toString() || resource.attributes?.slug || generateUuid(),
        tag: tag || '',
        title: resource.attributes?.title || '',
        slug: resource.attributes?.slug || '',
        headerTitleHtml: resource.attributes?.headerTitle || '',
        hideHeaderImage: resource.attributes?.hideHeaderImage || false,
        image,
        body: resource.attributes?.description || '',
        relatedContent,
        sidebar,
        buttonLabel: resource.attributes?.buttonLabel || '',
        seo,
        createdAt,
        updatedAt,
    };
};
