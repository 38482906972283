import { FC, ReactElement, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import {
    ConnectedAppWrapper,
    ConnectedCacheBuster,
    ConnectedCookieSettings,
    ConnectedFooter,
    ConnectedMenu,
    ConnectedToastList,
} from './connectors';
import { useCookieSettings } from './context/CookieContext';
import { initMatomo } from './helpers/matomo';
import { scrollToTop } from './helpers/scroll';
import LocalizedRoutes from './LocalizedRoutes';

const App: FC = (): ReactElement => {
    const { pathname, hash } = useLocation();
    const { cookieSettingsAccepted } = useCookieSettings();

    useEffect((): void => {
        if (cookieSettingsAccepted) {
            initMatomo();
        }
    }, [cookieSettingsAccepted]);

    useEffect((): void => {
        if (!hash) {
            scrollToTop('auto');
        }
    }, [pathname]);

    return (
        <ConnectedAppWrapper>
            <ConnectedMenu />
            <LocalizedRoutes />
            <ConnectedFooter />

            <ConnectedToastList />
            <ConnectedCookieSettings />
            <ConnectedCacheBuster />
        </ConnectedAppWrapper>
    );
};

export default App;
