import React, {
    CSSProperties,
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { useToggle, useWindowSize } from 'react-use';

import { Logo, Wrapper } from '../../components';
import { IconButton } from '../../compositions';
import { AppRoute, appRoutes } from '../../constants/routing';
import { Navigation as NavigationGroup } from '../../entities/Navigation/Navigation';
import { isSSR } from '../../helpers';
import useTrans from '../../hooks/useTrans';
import { MenuSearchInput, Navigation, SsrNavigation } from './subcomponents';

import './Menu.scss';

interface MenuProps {
    mainNavigation?: NavigationGroup;
    legalNavigation?: NavigationGroup;
    className?: string;
}

const Menu: FC<MenuProps> = ({
    mainNavigation,
    legalNavigation,
    className = '',
}): ReactElement => {
    const searchButtonWidth = 48;
    const animationDuration = 300;

    const trans = useTrans();
    const { width: windowWidth } = useWindowSize();

    const searchSizerRef = useRef<HTMLDivElement>(null);
    const controlsWrapperRef = useRef<HTMLDivElement>(null);

    const [availableSearchSpace, setAvailableSearchSpace] = useState<number>(searchButtonWidth);
    const [showNavigation, toggleShowNavigation] = useToggle(false);
    const [showSearch, toggleShowSearch] = useToggle(false);

    const handleCloseNavigation = (): void => toggleShowNavigation(false);

    useEffect((): void => {
        if (searchSizerRef.current) {
            const { width } = searchSizerRef.current.getBoundingClientRect();

            setAvailableSearchSpace(width - searchButtonWidth);
        }
    }, [windowWidth, searchSizerRef]);

    const cssVariables = {
        '--menu-animation-duration': `${animationDuration}ms`,
    } as CSSProperties;

    const menuClassNames = classNames('menu', {
        'menu--show-search': showSearch,
    }, className);

    if (isSSR && mainNavigation && legalNavigation) {
        return (
            <SsrNavigation
                mainNavigation={mainNavigation}
                legalNavigation={legalNavigation}
            />
        );
    }

    return (
        <header style={cssVariables} className={menuClassNames}>
            <Wrapper hideGrid className="menu__wrapper">
                <NavLink
                    to={trans(appRoutes[AppRoute.home].path)}
                    title={trans('containers.menu.homepageLink')}
                    className="menu__home-link"
                >
                    <Logo className="menu__logo" />
                </NavLink>

                <div ref={searchSizerRef} className="menu__search-sizer">
                    <div ref={controlsWrapperRef} className="menu__controls-wrapper">
                        <MenuSearchInput
                            isExpanded={showSearch}
                            availableSpace={availableSearchSpace}
                            animationDuration={animationDuration}
                            onExpand={toggleShowSearch}
                            onCollapse={toggleShowSearch}
                        />

                        <div className="menu__hamburger-wrapper">
                            <IconButton
                                hideLabel
                                icon="hamburger"
                                text={trans('containers.menu.openNavigation')}
                                onClick={toggleShowNavigation}
                                className="menu__hamburger-button"
                                iconClassName="menu__hamburger-icon"
                            />
                        </div>
                    </div>
                </div>

                {mainNavigation && legalNavigation && (
                    <Navigation
                        isHidden={!showNavigation}
                        navigationLabel={trans('containers.menu.navigation.ariaLabel')}
                        mainNavigation={mainNavigation}
                        legalNavigation={legalNavigation}
                        onCloseNavigation={handleCloseNavigation}
                        className="menu__navigation"
                    />
                )}
            </Wrapper>
        </header>
    );
};

export default Menu;
