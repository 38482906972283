import { FC, ReactElement } from 'react';

import { PeopleListType } from '../../../entities/@sections/PeopleList/PeopleList';
import { Person } from '../../../entities/Person/Person';
import { PersonPortrait } from './subcomponents';

import './PeopleList.scss';

interface PeopleListProps {
    hidePortraits?: boolean;
    hideDescriptions?: boolean;
    layoutType?: PeopleListType;
    people: Person[];
    className?: string;
    listItemClassName?: string;
    personClassName?: string;
}

const PeopleList: FC<PeopleListProps> = ({
    hidePortraits,
    hideDescriptions,
    layoutType,
    people,
    className = '',
    listItemClassName = '',
    personClassName = '',
}): ReactElement => (
    <ul className={`people-list ${className}`}>
        {people.map(person => (
            <li
                key={person.id}
                className={`people-list__list-item ${listItemClassName}`}
            >
                <PersonPortrait
                    hidePortrait={layoutType === PeopleListType.hidePortraits || !!hidePortraits}
                    hideDescription={layoutType === PeopleListType.hideDescription || !!hideDescriptions}
                    person={person}
                    className={personClassName}
                />
            </li>
        ))}
    </ul>
);

export default PeopleList;
