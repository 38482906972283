import { transformToArticle } from '../../entities/Article/ArticleTransformers';
import { ArticleCategoryRequestConfig, ArticleCategoryResponse } from '../../entities/ArticleCategory/ArticleCategory';
import { generateArticleCategoryQuery } from '../../entities/ArticleCategory/ArticleCategoryRequests';
import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { transformToPagination } from '../../entities/Pagination/PaginationTransformers';
import { getRestApiCall } from '../../entities/Rest/RestService';
import { ReducerGetter, TypedDispatch } from '../store';
import {
    setCategories,
    setError,
    setIsLoading,
    setIsSuccessful,
} from './articleCategoriesReducer';

export const fetchArticleCategory = (config: ArticleCategoryRequestConfig) => async (
    dispatch: TypedDispatch,
    getState: ReducerGetter,
): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const query = generateArticleCategoryQuery(config);

        const categoryResponse = await getRestApiCall<ArticleCategoryResponse>(`/articles?${query}&populate=*`);

        if (!isFetchResultSuccessful(categoryResponse)) {
            dispatch(setError(categoryResponse.error));
            return;
        }

        const { data: articlesData, meta: articlesMeta } = categoryResponse.data;

        const categoryArticles = articlesData
            ? articlesData.map(transformToArticle)
            : [];

        const categoryPagination = articlesMeta
            ? transformToPagination(articlesMeta.pagination)
            : undefined;

        const state = getState();
        const { categories } = state.articleCategoriesReducer;

        const updatedCategories = {
            ...categories,
            [config.key]: {
                articles: categoryArticles,
                pagination: categoryPagination,
            },
        };

        dispatch(setCategories(updatedCategories));

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[fetchArticleCategory]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
