import { FC, ReactElement, useEffect } from 'react';

import { VacancyCardList } from '../../containers';
import { DefaultComponentAppearance } from '../../entities/Component/Component';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';
import { fetchVacancies } from '../../redux/vacancies/vacanciesActions';

interface ConnectedVacancyCardListProps extends DefaultComponentAppearance {
    className?: string;
}

const ConnectedVacancyCardList: FC<ConnectedVacancyCardListProps> = ({
    className = '',
    ...defaultComponent
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const isFetched = useTypedSelector(state => state.vacanciesReducer.isSuccessful);
    const isLoading = useTypedSelector(state => state.vacanciesReducer.isLoading);
    const vacancies = useTypedSelector(state => state.vacanciesReducer.vacancies);

    useEffect((): void => {
        if (!isFetched) {
            dispatch(fetchVacancies());
        }
    }, []);

    return (
        <VacancyCardList
            {...defaultComponent}
            isLoading={isLoading}
            vacancies={vacancies}
            className={className}
        />
    );
};

export default ConnectedVacancyCardList;
