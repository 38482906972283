import { FC, ReactElement } from 'react';

import { Section, ThemeTitle } from '../../../components';
import { DownloadButton, PdfReader, SectionWrapper } from '../../../compositions';
import { PresentationInterface } from '../../../entities/@sections/Presentation/Presentation';

import './Presentation.scss';

interface PresentationProps extends PresentationInterface {
    className?: string;
}

const Presentation: FC<PresentationProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    caption,
    titleHtml,
    file,
    className = '',
}): ReactElement => (
    <Section isDark={isDark} className={`presentation ${className}`}>
        <SectionWrapper
            noSpacingTop={noSpacingTop}
            noSpacingBottom={noSpacingBottom}
            className="presentation__wrapper"
        >
            <div className="presentation__presentation">
                <div className="presentation__pdf-reader-wrapper">
                    {file && (
                        <PdfReader
                            file={file}
                            className="presentation__pdf-reader"
                        />
                    )}
                </div>
            </div>

            <div className="presentation__content">
                <ThemeTitle
                    titleHtml={titleHtml}
                    className="presentation__title"
                />

                <h3 className="presentation__caption">
                    {caption}
                </h3>

                {file && (
                    <div className="presentation__download-button-wrapper">
                        <DownloadButton
                            href={file.src}
                            extension={file.extension}
                            className="presentation__download-button"
                        />

                        <div className="presentation__download-button-title">
                            {file.title}
                        </div>
                    </div>
                )}
            </div>
        </SectionWrapper>
    </Section>
);

export default Presentation;
