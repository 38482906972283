import {
    CSSProperties,
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    RefAttributes,
    useEffect,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';
import gsap, { Linear } from 'gsap';
import { useWindowSize } from 'react-use';
import { noop } from 'react-use/lib/misc/util';

import {
    GridLines,
    LinkButton,
    Picture,
    Section,
    ThemeTitle,
    Wrapper,
} from '../../components';
import { PageCallToActionInterface } from '../../entities/@sections/PageCallToAction/PageCallToAction';
import useElementSize from '../../hooks/useElementSize';

import './HighlightedPage.scss';

export interface HighlightedPageProps extends RefAttributes<HTMLDivElement>, PageCallToActionInterface {
    isAnimationDisabled?: boolean;
    imageOffset: number;
    timeline?: gsap.core.Timeline;
    className?: string;
}

const HighlightedPage: ForwardRefExoticComponent<HighlightedPageProps> = forwardRef(({
    isDark,
    isAnimationDisabled = false,
    paragraph,
    titleHtml,
    image,
    imageOffset,
    link,
    timeline,
    className = '',
}, ref): ReactElement => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const introRef = useRef<HTMLParagraphElement>(null);

    const { width: windowWidth } = useWindowSize();
    const { width: wrapperWidth } = useElementSize(wrapperRef);
    const { height: introHeight } = useElementSize(introRef);

    const [id] = useState(Math.random().toString().substring(2));

    const coverTopId = `highlighted-page-${id}-cover-top`;
    const coverRightId = `highlighted-page-${id}-cover-right`;
    const coverBottomId = `highlighted-page-${id}-cover-bottom`;
    const coverLeftId = `highlighted-page-${id}-cover-left`;

    const pictureId = `highlighted-page-${id}-picture`;
    const textInnerWrapperId = `highlighted-page-${id}-text-inner-wrapper`;
    const introId = `highlighted-page-${id}-intro`;
    const linkId = `highlighted-page-${id}-link`;

    const coverWidth = (windowWidth - wrapperWidth) / 2;

    useEffect((): () => void => {
        if (isAnimationDisabled || !timeline) {
            return noop;
        }

        const timeline2 = gsap.timeline({ paused: true });

        timeline2.to(`#${coverTopId}`, { y: '-100%', duration: 1, ease: Linear.easeNone }, 0);
        timeline2.to(`#${coverRightId}`, { x: '100%', duration: 1, ease: Linear.easeNone }, 0);
        timeline2.to(`#${coverBottomId}`, { y: '100%', duration: 1, ease: Linear.easeNone }, 0);
        timeline2.to(`#${coverLeftId}`, { x: '-100%', duration: 1, ease: Linear.easeNone }, 0);

        timeline2.to(`#${pictureId}`, { scale: 1, duration: 1, ease: Linear.easeNone }, 0);
        timeline2.to(`#${textInnerWrapperId}`, { y: 0, duration: 1, ease: Linear.easeNone }, 0);
        timeline2.to(`#${introId}`, { opacity: 1, duration: 1, ease: Linear.easeNone }, 0);
        timeline2.to(`#${linkId}`, { opacity: 1, duration: 0.5, ease: Linear.easeNone }, 0.5);

        timeline.to(timeline2, { time: timeline2.totalDuration(), duration: 3, ease: Linear.easeNone });
        timeline.to(timeline2, { time: 0, duration: 3, ease: Linear.easeNone }, '+=4');

        return () => timeline2.kill();
    }, [timeline]);

    const cssVariables = {
        '--title-translate-y': `${introHeight / 2}px`,
        '--cover-width': `${coverWidth}px`,
        '--image-offset': `${imageOffset}px`,
    } as CSSProperties;

    const sectionClassName = classNames('highlighted-page', {
        'highlighted-page--is-animation-disabled': isAnimationDisabled,
    }, className);

    return (
        <Section
            ref={ref}
            isDark={isDark}
            style={cssVariables}
            className={sectionClassName}
        >
            <div className="highlighted-page__picture-wrapper">
                {image && (
                    <Picture
                        id={pictureId}
                        src={image.src}
                        alt={image.alt}
                        formats={image.formats}
                        className="highlighted-page__picture"
                        imageClassName="highlighted-page__image"
                    />
                )}
            </div>

            <div
                data-dark="true"
                className="highlighted-page__text-wrapper"
            >
                <Wrapper
                    hideGrid
                    id={textInnerWrapperId}
                    ref={wrapperRef}
                    className="highlighted-page__text-inner-wrapper"
                >
                    <ThemeTitle
                        titleHtml={titleHtml}
                        className="highlighted-page__title"
                    />

                    <p
                        ref={introRef}
                        id={introId}
                        className="highlighted-page__paragraph"
                    >
                        {paragraph}
                    </p>

                    {link && (
                        <LinkButton
                            id={linkId}
                            to={link.path}
                            className="highlighted-page__nav-link"
                        >
                            {link.label}
                        </LinkButton>
                    )}

                    <div className="highlighted-page__cover-right-wrapper">
                        <div id={coverRightId} className="highlighted-page__cover-right" />
                    </div>

                    <div className="highlighted-page__cover-left-wrapper">
                        <div id={coverLeftId} className="highlighted-page__cover-left" />
                    </div>
                </Wrapper>
            </div>

            <div id={coverTopId} className="highlighted-page__cover-top">
                <GridLines />
            </div>

            <div id={coverBottomId} className="highlighted-page__cover-bottom">
                <GridLines />
            </div>
        </Section>
    );
});

export default HighlightedPage;
