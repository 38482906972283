import { FC, ReactElement } from 'react';

import { Picture } from '../../../../../components';
import { ImageFormatSize } from '../../../../../entities/Media/Media';
import { Person } from '../../../../../entities/Person/Person';

import './PersonPortrait.scss';

export interface PersonPortraitProps {
    hidePortrait: boolean;
    hideDescription: boolean;
    person: Person;
    className?: string;
}

const PersonPortrait: FC<PersonPortraitProps> = ({
    hidePortrait,
    hideDescription,
    person,
    className = '',
}): ReactElement => (
    <div className={`person-portrait ${className}`}>
        {!hidePortrait && (
            <Picture
                hasScaling
                src={person.image?.src}
                alt={person.image?.alt}
                formats={person.image?.formats}
                formatOverride={ImageFormatSize.small}
                className="person-portrait__picture"
                imageClassName="person-portrait__image"
            />
        )}

        <div className="person-portrait__text-wrapper">
            <h2 className="person-portrait__title">
                {person.name}
            </h2>

            <h3 className="person-portrait__job-title">
                {person.jobTitle}
            </h3>

            {person.paragraph && !hideDescription && (
                <p className="person-portrait__description">
                    {person.paragraph}
                </p>
            )}
        </div>
    </div>
);

export default PersonPortrait;
