import { FC, ReactElement } from 'react';

import { SafeHtml, Section, ThemeTitle } from '../../../components';
import { SectionWrapper, VideoModal } from '../../../compositions';
import { VideoSectionInterface } from '../../../entities/@sections/VideoSection/VideoSection';

import './VideoSection.scss';

interface VideoSectionProps extends VideoSectionInterface {
    className?: string;
}

const VideoSection: FC<VideoSectionProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    titleHtml,
    paragraphHtml,
    video,
    embedUrl,
    poster,
    className = '',
}): ReactElement => (
    <Section isDark={isDark} className={`video-section ${className}`}>
        <SectionWrapper
            noSpacingTop={noSpacingTop}
            noSpacingBottom={noSpacingBottom}
            className="video-section__wrapper"
        >
            {titleHtml && (
                <ThemeTitle
                    titleHtml={titleHtml}
                    className="video-section__title"
                />
            )}

            {paragraphHtml && (
                <SafeHtml
                    html={paragraphHtml}
                    className="video-section__paragraph"
                />
            )}

            <div className="video-section__video-wrapper">
                {(video || embedUrl) && (
                    <VideoModal
                        showPoster
                        video={video}
                        embedUrl={embedUrl}
                        poster={poster}
                        className="video-section__video"
                    />
                )}
            </div>
        </SectionWrapper>
    </Section>
);

export default VideoSection;
