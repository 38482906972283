import {
    CSSProperties,
    FC,
    ReactElement,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';
import { useDebounce, useWindowSize } from 'react-use';

import { Section } from '../../../components';
import {
    Carousel,
    ChevronNavigation,
    SectionWrapper,
    TestimonialQuote,
} from '../../../compositions';
import { TestimonialCarouselInterface } from '../../../entities/@sections/TestimonialCarousel/TestimonialCarousel';
import { clamp } from '../../../helpers/number';
import useDeviceWidth from '../../../hooks/useDeviceWidth';
import useElementSize from '../../../hooks/useElementSize';
import { getActiveChildHeight, getHighestChildrenHeight } from './helpers';
import { TestimonialMedia } from './subcomponents';

import './TestimonialsCarousel.scss';

interface TestimonialsCarouselProps extends TestimonialCarouselInterface {
    className?: string;
}

const TestimonialsCarousel: FC<TestimonialsCarouselProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    title,
    subtitle,
    testimonials,
    className = '',
}): ReactElement => {
    const quotesListRef = useRef<HTMLUListElement>(null);
    const carouselRef = useRef<HTMLDivElement>(null);

    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [activeQuoteIndex, setActiveQuoteIndex] = useState<number | undefined>(0);
    const [maxHeight, setMaxHeight] = useState<number>(0);
    const [activeQuoteHeight, setActiveQuoteHeight] = useState<number>(0);
    const { width: windowWidth } = useWindowSize();
    const { isMobile } = useDeviceWidth();
    const { width: slideWidth } = useElementSize(carouselRef);

    const handleChevronClick = useCallback((value: -1 | 1): void => {
        const newIndex = activeIndex + value;
        const clampedValue = clamp(newIndex, 0, testimonials.length - 1);

        if (!isMobile) {
            setActiveIndex(clampedValue);

            return;
        }

        if (carouselRef.current) {
            carouselRef.current.scroll({
                left: newIndex * slideWidth,
                behavior: 'smooth',
            });
        }
    }, [isMobile, activeIndex]);

    useEffect((): void => {
        setActiveQuoteIndex(undefined);
    }, [activeIndex]);

    useDebounce((): void => {
        if (!quotesListRef.current) {
            return;
        }

        setActiveQuoteIndex(activeIndex);
        setActiveQuoteHeight(getActiveChildHeight(quotesListRef.current, activeIndex));
    }, 400, [activeIndex]);

    useEffect((): void => {
        if (!quotesListRef.current) {
            return;
        }

        setMaxHeight(getHighestChildrenHeight(quotesListRef.current));
    }, [windowWidth, activeIndex]);

    const cssVariables = {
        '--testimonials-carousel-max-height': `${maxHeight}px`,
        '--testimonials-carousel-active-quote-height': `${activeQuoteHeight}px`,
    } as CSSProperties;

    return (
        <Section
            isDark={isDark}
            style={cssVariables}
            className={`testimonials-carousel ${className}`}
        >
            <SectionWrapper noSpacingTop={noSpacingTop} noSpacingBottom={noSpacingBottom}>
                <div className="testimonials-carousel__header">
                    <h2 className="testimonials-carousel__title">{title}</h2>

                    <h3 className="testimonials-carousel__sub-title">{subtitle}</h3>
                </div>

                <div className="testimonials-carousel__content-wrapper">
                    <ul ref={quotesListRef} className="testimonials-carousel__quotes-list">
                        {testimonials.map(((testimonial, index) => {
                            const itemClassName = classNames('testimonials-carousel__quote-item', {
                                'testimonials-carousel__quote-item--is-active': index === activeQuoteIndex,
                            });

                            return (
                                <li key={testimonial.id} className={itemClassName}>
                                    <TestimonialQuote {...testimonial} />
                                </li>
                            );
                        }))}
                    </ul>

                    <Carousel
                        ref={carouselRef}
                        activeIndex={activeIndex}
                        items={testimonials.map(((testimonial, index) => (
                            <TestimonialMedia
                                {...testimonial}
                                isDisabled={index !== activeIndex}
                            />
                        )))}
                        slidesLength={testimonials.length}
                        onMobileActiveIndexChange={setActiveIndex}
                        className="testimonials-carousel__carousel"
                    />
                </div>

                <ChevronNavigation
                    isPrevButtonDisabled={activeIndex === 0}
                    isNextButtonDisabled={activeIndex === testimonials.length - 1}
                    onClick={handleChevronClick}
                    className="testimonials-carousel__navigation"
                />
            </SectionWrapper>
        </Section>
    );
};

export default TestimonialsCarousel;
