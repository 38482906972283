import {
    CSSProperties,
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import { IconButton } from '../../../../compositions';
import { Navigation as NavigationGroup, NavigationLevel } from '../../../../entities/Navigation/Navigation';
import useDeviceWidth from '../../../../hooks/useDeviceWidth';
import useHandleClickOutside from '../../../../hooks/useHandleClickOutside';
import usePreferReducedMotion from '../../../../hooks/usePreferReducedMotion';
import useTimeout from '../../../../hooks/useTimeout';
import useTrans from '../../../../hooks/useTrans';
import useWindowResizing from '../../../../hooks/useWindowResizing';
import { getActiveNavigationItem } from '../../helpers';
import { NavigationItemList, SubNavigationItemList } from '..';

import './Navigation.scss';

interface NavigationProps {
    isHidden: boolean;
    navigationLabel: string;
    mainNavigation: NavigationGroup;
    legalNavigation: NavigationGroup;
    onCloseNavigation: () => void;
    className?: string;
}

const navigationTransitionDuration = 300;

const Navigation: FC<NavigationProps> = ({
    isHidden,
    navigationLabel,
    mainNavigation,
    legalNavigation,
    onCloseNavigation,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const { isMobile } = useDeviceWidth();
    const isWindowResizing = useWindowResizing(navigationTransitionDuration);
    const prefersReducedMotion = usePreferReducedMotion();
    const { pathname } = useLocation();

    const wrapperRef = useRef<HTMLDivElement>(null);

    const [isExpanding, setIsExpanding] = useState<boolean>(false);
    const [navigationLevel, setNavigationLevel] = useState(NavigationLevel.hidden);

    const [activeGroupId, setActiveGroupId] = useState<string>();
    const [activeSubGroupId, setActiveSubGroupId] = useState<string>();

    const activeGroup = mainNavigation.list.find(item => item.id === activeGroupId);
    const activeSubNavigation = activeGroup?.subNavigation || [];
    const activeSubGroup = activeSubNavigation.find(item => item.subNavigation.length > 0 && item.id === activeSubGroupId);
    const activeSubGroupNavigation = activeSubGroup?.subNavigation || [];

    const tabIndex = isHidden ? -1 : 0;

    const handleGroupClick = (id: string): void => {
        setActiveGroupId(id);
        setNavigationLevel(NavigationLevel.group);
    };

    const handleSubGroupClick = (id: string): void => {
        setActiveSubGroupId(id);
        setNavigationLevel(NavigationLevel.subGroup);
    };

    const handleBackButtonClick = (): void => setNavigationLevel(Math.max(1, navigationLevel - 1));

    const resetNavigation = (): void => {
        if (isHidden) {
            setNavigationLevel(NavigationLevel.hidden);
            setActiveGroupId(undefined);
            setActiveSubGroupId(undefined);
            return;
        }

        const { group, subGroup } = getActiveNavigationItem(mainNavigation, pathname);

        if (group) setActiveGroupId(group.id);
        if (subGroup) setActiveSubGroupId(subGroup.id);

        const fallbackNavigationLevel = group ? NavigationLevel.group : NavigationLevel.base;
        setNavigationLevel(subGroup ? NavigationLevel.subGroup : fallbackNavigationLevel);
    };

    useHandleClickOutside(wrapperRef, onCloseNavigation);

    useTimeout((): void => {
        if (!isMobile) return;

        resetNavigation();
        setIsExpanding(false);
    }, navigationTransitionDuration, [isHidden]);

    useEffect((): void => {
        setIsExpanding(true);

        if (isMobile && isHidden) return;

        resetNavigation();
    }, [isHidden]);

    useEffect((): void => {
        onCloseNavigation();
    }, [pathname]);

    const navigationCssVariables = {
        '--navigation-level': navigationLevel,
        '--navigation-transition-duration': `${navigationTransitionDuration}ms`,
    } as CSSProperties;

    const navigationClassName = classNames('navigation', {
        'navigation--is-hidden': isHidden,
        'navigation--is-main-route-active': navigationLevel > 1,
        'navigation--is-sub-route-active': navigationLevel > 2,
        'navigation--is-animation-disabled': prefersReducedMotion || isWindowResizing,
        'navigation--is-routes-animation-disabled': isMobile && isExpanding,
    }, className);

    return (
        <div
            aria-label={navigationLabel}
            style={navigationCssVariables}
            className={navigationClassName}
        >
            <div ref={wrapperRef} className="navigation__wrapper">
                <IconButton
                    hideLabel
                    icon="cross"
                    tabIndex={tabIndex}
                    text={trans('common.close')}
                    onClick={onCloseNavigation}
                    className="navigation__close-button"
                    iconClassName="navigation__close-button-icon"
                />

                <div className="navigation__routes">
                    <div className="navigation__routes-list-wrapper">
                        <NavigationItemList
                            activeGroup={activeGroupId}
                            navigationItems={mainNavigation.list}
                            tabIndex={tabIndex}
                            onGroupClick={handleGroupClick}
                            onItemClick={handleBackButtonClick}
                            className="navigation__routes-list"
                        />

                        <SubNavigationItemList
                            navigationItems={legalNavigation.list}
                            tabIndex={tabIndex}
                            className="navigation__sub-routes-list"
                        />
                    </div>

                    <div className="navigation__routes-list-wrapper">
                        {activeSubNavigation.length > 0 && (
                            <NavigationItemList
                                isSubNavigation
                                activeGroup={navigationLevel > 2 ? activeSubGroupId : undefined}
                                navigationItems={activeSubNavigation}
                                tabIndex={tabIndex}
                                onBackButtonClick={handleBackButtonClick}
                                onGroupClick={handleSubGroupClick}
                                className="navigation__routes-list navigation__routes-list--is-sub-navigation"
                            />
                        )}
                    </div>

                    <div className="navigation__routes-list-wrapper">
                        {activeSubGroupNavigation.length > 0 && (
                            <NavigationItemList
                                isSubNavigation
                                navigationItems={activeSubGroupNavigation}
                                tabIndex={tabIndex}
                                onBackButtonClick={handleBackButtonClick}
                                className="navigation__routes-list navigation__routes-list--is-sub-navigation"
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Navigation;
