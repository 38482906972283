import { transformToHeader } from '../Header/HeaderTransformers';
import { Link } from '../Link/Link';
import { transformToPageSection } from '../PageSection/PageSectionTransformers';
import { transformToSeo } from '../Seo/SeoTransformers';
import { Page, PageResource } from './Page';

export const transformToPageRelationLink = (resource: Pick<PageResource, 'title' | 'slug'>): Link => ({
    label: resource.title || '',
    path: `/${resource.slug || ''}`,
});

export const transformToPage = (resource: PageResource): Page => {
    const header = transformToHeader(resource.header);
    const sections = resource.elements
        ? resource.elements.map(transformToPageSection)
        : [];

    const seo = transformToSeo(resource.seo);

    return {
        title: resource.title || '',
        slug: resource.slug || '',
        header,
        sections,
        seo,
    };
};
