import { FC, ReactElement } from 'react';

import './MapCtaCircle.scss';

interface MapCtaCircleProps {
    className?: string;
}

const MapCtaCircle: FC<MapCtaCircleProps> = ({ className = '' }): ReactElement => (
    <div className={`map-cta-circle ${className}`}>
        <div className="map-cta-circle__overlay" />

        <div className="map-cta-circle__circles">
            <div className="map-cta-circle__circle" />

            <div className="map-cta-circle__circle" />

            <div className="map-cta-circle__circle" />

            <div className="map-cta-circle__circle" />
        </div>
    </div>
);

export default MapCtaCircle;
