import { FC, ReactElement } from 'react';

import { NewsletterForm } from '../../../containers';
import { NewsletterFormData } from '../../../entities/@forms/NewsletterForm/NewsletterForm';
import { sendNewsletterForm } from '../../../redux/newsletterForm/newsletterFormActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedNewsletterFormProps {
    formTitle?: string;
    formButtonLabel?: string;
    className?: string;
}

const ConnectedNewsletterForm: FC<ConnectedNewsletterFormProps> = ({
    formTitle,
    formButtonLabel,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const isLoading = useTypedSelector(state => state.newsletterFormReducer.isLoading);
    const isSuccessful = useTypedSelector(state => state.newsletterFormReducer.isSuccessful);

    const handleSubmit = (formData: NewsletterFormData): void => {
        dispatch(sendNewsletterForm(formData));
    };

    return (
        <NewsletterForm
            isLoading={isLoading}
            isSuccessful={isSuccessful}
            title={formTitle}
            submitButtonLabel={formButtonLabel}
            onSubmit={handleSubmit}
            className={className}
        />
    );
};

export default ConnectedNewsletterForm;
