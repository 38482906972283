import { useCallback, useEffect, useState } from 'react';

const useKeyPress = (key: string): boolean => {
    const [isPressed, setPressedState] = useState<boolean>(false);

    const keyDown = useCallback((e: KeyboardEvent): void => {
        if (e.key === key) setPressedState(true);
    }, [key]);

    const keyUp = useCallback((e: KeyboardEvent): void => {
        if (e.key === key) setPressedState(false);
    }, [key]);

    useEffect((): () => void => {
        document.addEventListener('keydown', keyDown);
        document.addEventListener('keyup', keyUp);

        return (): void => {
            document.removeEventListener('keydown', keyDown);
            document.removeEventListener('keyup', keyUp);
        };
    }, [keyDown, keyUp]);

    return isPressed;
};

export default useKeyPress;
