import { SdgIcon, SdgType } from '../entities/Sdg/Sdg';

export const sdgIcons: Record<SdgType, SdgIcon> = {
    noPoverty: {
        color: '#e5243b',
        image: '/images/sdg/goal1.png',
        name: 'No poverty',
    },
    zeroHunger: {
        color: '#DDA63A',
        image: '/images/sdg/goal2.png',
        name: 'Zero hunger',
    },
    goodHealthAndWellBeing: {
        color: '#4C9F38',
        image: '/images/sdg/goal3.png',
        name: 'Good health and well-being',
    },
    qualityEducation: {
        color: '#C5192D',
        image: '/images/sdg/goal4.png',
        name: 'Quality education',
    },
    genderEquality: {
        color: '#FF3A21',
        image: '/images/sdg/goal5.png',
        name: 'Gender equality',
    },
    cleanWaterAndSanitation: {
        color: '#26BDE2',
        image: '/images/sdg/goal6.png',
        name: 'Clean water and sanitation',
    },
    affordableAndCleanEnergy: {
        color: '#FCC30B',
        image: '/images/sdg/goal7.png',
        name: 'Affordable and clean energy',
    },
    decentWorkAndEconomicGrowth: {
        color: '#A21942',
        image: '/images/sdg/goal8.png',
        name: 'Decent work and economic growth',
    },
    industryInnovationAndInfrastructure: {
        color: '#FD6925',
        image: '/images/sdg/goal9.png',
        name: 'Industry, innovation and infrastructure',
    },
    reducedInequalities: {
        color: '#DD1367',
        image: '/images/sdg/goal10.png',
        name: 'Reduced inequalities',
    },
    sustainableCitiesAndCommunities: {
        color: '#FD9D24',
        image: '/images/sdg/goal11.png',
        name: 'Sustainable cities and communities',
    },
    responsibleConsumptionAndProduction: {
        color: '#BF8B2E',
        image: '/images/sdg/goal12.png',
        name: 'Responsible consumption and production',
    },
    climateAction: {
        color: '#3F7E44',
        image: '/images/sdg/goal13.png',
        name: 'Climate action',
    },
    lifeBelowWater: {
        color: '#0A97D9',
        image: '/images/sdg/goal14.png',
        name: 'Life below water',
    },
    lifeOnLand: {
        color: '#56C02B',
        image: '/images/sdg/goal15.png',
        name: 'Life on land',
    },
    peaceAndJusticeStrongInstitutions: {
        color: '#00689D',
        image: '/images/sdg/goal16.png',
        name: 'Peace, justice and strong institutions',
    },
    partnershipsForTheGoals: {
        color: '#19486A',
        image: '/images/sdg/goal17.png',
        name: 'Partnerships for the goals',
    },
};
