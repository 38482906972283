import { FC, ReactElement } from 'react';

import { Section } from '../../../components';
import { FinancialOverviewTable, SectionWrapper } from '../../../compositions';
import { FinancialOverviewTableInterface } from '../../../entities/@sections/FinancialOverviewTable/FinancialOverviewTable';

interface FinancialOverviewProps extends FinancialOverviewTableInterface {
    className?: string;
}

const FinancialOverview: FC<FinancialOverviewProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    headerTitle,
    years,
    rows,
    className = '',
}): ReactElement => (
    <Section isDark={isDark} className={`financial-overview-table ${className}`}>
        <SectionWrapper noSpacingTop={noSpacingTop} noSpacingBottom={noSpacingBottom}>
            <FinancialOverviewTable
                headerTitle={headerTitle}
                years={years}
                rows={rows}
            />
        </SectionWrapper>
    </Section>
);

export default FinancialOverview;
