import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { getSharePriceApiCall } from '../../entities/SharePrice/SharePriceService';
import { transformToSharePrice } from '../../entities/SharePrice/SharePriceTransformers';
import { TypedDispatch } from '../store';
import {
    setError,
    setIsLoading,
    setIsSuccessful,
    setSharePrice,
} from './sharePriceReducer';

export const fetchSharePrice = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const response = await getSharePriceApiCall();

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));
            return;
        }

        const sharePriceData = response.data;

        const sharePrice = transformToSharePrice(sharePriceData);

        dispatch(setSharePrice(sharePrice));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[fetchSharePrice]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
