import { FC, ReactElement } from 'react';

import { LinkButton, SafeHtml, Section } from '../../../components';
import { ImpactCard, SectionWrapper } from '../../../compositions';
import { HighlightedImpactCardInterface } from '../../../entities/@sections/HighlightedImpactCard/HighlightedImpactCard';

import './HighlightedImpactCard.scss';

interface HighlightedImpactCardProps extends HighlightedImpactCardInterface {
    className?: string;
}

const HighlightedImpactCard: FC<HighlightedImpactCardProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    intro,
    paragraphHtml,
    card,
    link,
    className = '',
}): ReactElement => (
    <Section isDark={isDark} className={`highlighted-impact-card ${className}`}>
        <SectionWrapper
            noSpacingTop={noSpacingTop}
            noSpacingBottom={noSpacingBottom}
            className="highlighted-impact-card__wrapper"
        >
            <div className="highlighted-impact-card__column">
                <p className="highlighted-impact-card__intro">
                    {intro}
                </p>
            </div>

            <div className="highlighted-impact-card__column">
                <SafeHtml
                    html={paragraphHtml}
                    className="highlighted-impact-card__paragraph"
                />

                {link && (
                    <LinkButton
                        to={link.path}
                        text={link.label}
                        className="highlighted-impact-card__link"
                    />
                )}
            </div>

            <div className="highlighted-impact-card__column">
                <ImpactCard
                    text={card.caption}
                    title={card.title}
                    className="highlighted-impact-card__card"
                />
            </div>
        </SectionWrapper>
    </Section>
);

export default HighlightedImpactCard;
