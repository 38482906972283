import { FC, ReactElement, useEffect } from 'react';

import { Section } from '../../../components';
import { SectionWrapper } from '../../../compositions';
import { DefaultComponentAppearance } from '../../../entities/Component/Component';
import useTrans from '../../../hooks/useTrans';

import './SharePerformance.scss';

interface EurolandToolIntegration {
    set(EurolandToolID: string, iFrameDOM: string): void;
}

interface SharePerformanceProps extends DefaultComponentAppearance {
    className?: string;
}

const SharePerformance: FC<SharePerformanceProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const euroLandToolId = 'euroLandToolId';

    useEffect((): () => void => {
        const script = document.createElement('script');

        script.src = '//tools.euroland.com/tools/common/eurolandiframeautoheight/eurolandtoolsintegrationobject.js';
        script.async = true;

        script.onload = () => {
            if ('EurolandToolIntegrationObject' in window) {
                (window.EurolandToolIntegrationObject as EurolandToolIntegration).set(euroLandToolId, 'iFrameDOM');
            }
        };

        document.body.appendChild(script);

        return (): void => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <Section isDark={isDark} className={`share-performance ${className}`}>
            <SectionWrapper noSpacingTop={noSpacingTop} noSpacingBottom={noSpacingBottom}>
                <div className="share-performance__wrapper">
                    <h2 className="share-performance__title">
                        {trans('containers.sharePerformance.title')}
                    </h2>

                    <div className="share-performance__iframe-wrapper">
                        <iframe
                            id={euroLandToolId}
                            src="https://tools.eurolandir.com/tools/sharegraph/?s=3016&companycode=NL-TKABEL&lang=en-GB"
                            title={trans('containers.sharePerformance.iframeTitle')}
                            className="share-performance__iframe"
                        />
                    </div>
                </div>
            </SectionWrapper>
        </Section>
    );
};

export default SharePerformance;

