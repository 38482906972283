import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SolutionTagInterface } from '../../entities/SolutionTag/SolutionTag';
import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type SolutionTagsState = AsyncReduxState<{
    tags: Record<string, SolutionTagInterface>;
}>;

const initialState: SolutionTagsState = {
    ...initialAsyncReduxState,
    tags: {},
};

const solutionTagsSlice = createSlice({
    name: 'solutionTagsReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): SolutionTagsState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): SolutionTagsState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): SolutionTagsState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setTags(state, action: PayloadAction<Record<string, SolutionTagInterface>>): SolutionTagsState {
            return {
                ...state,
                tags: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsSuccessful,
    setError,
    setTags,
} = solutionTagsSlice.actions;

export default solutionTagsSlice;
