import { FC, ReactElement } from 'react';

import { Section } from '../../../components';
import { SectionWrapper } from '../../../compositions';
import { DefaultComponentAppearance } from '../../../entities/Component/Component';
import { Event } from '../../../entities/Event/Event';
import useTrans from '../../../hooks/useTrans';
import { CalendarSkeletons } from './skeletons';
import { CalendarEvent } from './subcomponents';

import './Calendar.scss';

export interface CalendarProps extends DefaultComponentAppearance {
    isLoading: boolean;
    events: Event[];
    pastEvents: Event[];
    className?: string;
}

const Calendar: FC<CalendarProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    isLoading,
    events,
    pastEvents,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <Section isDark={isDark} className={`calendar ${className}`}>
            <SectionWrapper noSpacingTop={noSpacingTop} noSpacingBottom={noSpacingBottom}>
                {isLoading && (
                    <CalendarSkeletons />
                )}

                {!isLoading && (
                    <div className="calendar__list-wrapper">
                        <h2 className="calendar__title">
                            {trans('containers.calendar.upcomingEvents')}
                        </h2>

                        {events.length === 0 && (
                            <div className="calendar__no-results-wrapper">
                                <p className="calendar__no-results">
                                    {trans('containers.calendar.noResults')}
                                </p>
                            </div>
                        )}

                        {events.length > 0 && (
                            <ul className="calendar__list">
                                {events.map(event => (
                                    <li key={event.id} className="calendar__list-item">
                                        <CalendarEvent
                                            {...event}
                                            className="calendar__event"
                                        />
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                )}

                {!isLoading && (
                    <div className="calendar__list-wrapper">
                        <h2 className="calendar__title">
                            {trans('containers.calendar.pastEvents')}
                        </h2>

                        {pastEvents.length === 0 && (
                            <div className="calendar__no-results-wrapper">
                                <p className="calendar__no-results">
                                    {trans('containers.calendar.noResults')}
                                </p>
                            </div>
                        )}

                        {pastEvents.length > 0 && (
                            <ul className="calendar__list">
                                {pastEvents.map(event => (
                                    <li key={event.id} className="calendar__list-item">
                                        <CalendarEvent
                                            {...event}
                                            className="calendar__event"
                                        />
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                )}
            </SectionWrapper>
        </Section>
    );
};

export default Calendar;
