import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { transformToLink } from '../../Link/LinkTransformers';
import { PageSection } from '../../PageSection/PageSection';
import {
    TextColumnsInterface,
    TextColumnsResource,
    TextColumnsType,
    TextColumnsTypeResource,
    textColumnsTypes,
} from './TextColumns';

const transformToTextColumnsType = (resource?: TextColumnsTypeResource): TextColumnsType => {
    if (resource === TextColumnsTypeResource.singleWideColumn) return TextColumnsType.singleWideColumn;
    if (resource === TextColumnsTypeResource.twoColumns) return TextColumnsType.twoColumns;
    if (resource === TextColumnsTypeResource.twoWideColumns) return TextColumnsType.twoWideColumns;
    if (resource === TextColumnsTypeResource.threeColumns) return TextColumnsType.threeColumns;

    return TextColumnsType.singleColumn;
};

export const transformToTextColumns = (resource: TextColumnsResource): TextColumnsInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    const textColumnsType = transformToTextColumnsType(resource.formatting);
    const columnsType = textColumnsTypes[textColumnsType];

    const link = resource.link
        ? transformToLink(resource.link)
        : undefined;

    const outerLink = resource.outerLink
        ? transformToLink(resource.outerLink)
        : undefined;

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.textColumns,
        columnsType,
        titleHtml: resource.title || '',
        intro: resource.intro,
        paragraphHtml: resource.paragraph || '',
        link,
        outerLink,
    };
};
