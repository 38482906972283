import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { transformToDownloadableFile } from '../../Media/MediaTransformers';
import { PageSection } from '../../PageSection/PageSection';
import {
    ShareBuybackHistoryTableInterface,
    ShareBuybackHistoryTableResource,
    ShareBuybackHistoryTableRowInterface,
    ShareBuybackHistoryTableRowResource,
} from './ShareBuybackHistoryTable';

const transformToShareBuybackHistoryTableRow = (resource: ShareBuybackHistoryTableRowResource): ShareBuybackHistoryTableRowInterface => {
    const file = resource.file?.data
        ? transformToDownloadableFile(resource.file.data)
        : undefined;

    return {
        yearAnnounced: resource.yearAnnounced,
        totalAmountPaid: resource.totalAmountPaid,
        totalSharesPurchased: resource.totalSharesPurchased,
        averagePricePaid: resource.averagePricePaid,
        file,
    };
};

export const transformToShareBuybackHistoryTable = (resource: ShareBuybackHistoryTableResource): ShareBuybackHistoryTableInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    const rows = resource.shareBuybackHistory
        ? resource.shareBuybackHistory.map(transformToShareBuybackHistoryTableRow)
        : [];

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.shareBuybackHistoryTable,
        title: resource.title,
        paragraph: resource.paragraph || '',
        rows,
    };
};
