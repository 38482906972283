import { transformToArticle } from '../../Article/ArticleTransformers';
import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { PageSection } from '../../PageSection/PageSection';
import { HighlightedArticleInterface, HighlightedArticleResource } from './HighlightedArticle';

export const transformToHighlightedArticle = (resource: HighlightedArticleResource): HighlightedArticleInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    const article = resource.article?.data
        ? transformToArticle(resource.article.data)
        : undefined;

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.highlightedArticle,
        showImage: resource.showImage,
        title: resource.title,
        article,
    };
};
