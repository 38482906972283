import {
    FC,
    MouseEvent,
    ReactElement,
    useMemo,
} from 'react';

import { useLocation } from 'react-router-dom';

import { PaginationInterface } from '../../entities/Pagination/Pagination';
import trans from '../../helpers/trans';
import { FormOption } from '../../types';
import { LinkIconButton, SelectInput } from '..';

import './Pagination.scss';

interface PaginationProps extends PaginationInterface {
    onChange: (newPage: number) => void;
    className?: string;
}

const Pagination: FC<PaginationProps> = ({
    pageCount,
    page,
    onChange,
    className = '',
}): ReactElement => {
    const location = useLocation();

    const handlePrevButtonClick = (event: MouseEvent<HTMLAnchorElement>): void => {
        event.preventDefault();

        onChange(page - 1);
    };

    const handleNextButtonClick = (event: MouseEvent<HTMLAnchorElement>): void => {
        event.preventDefault();

        onChange(page + 1);
    };

    const handleSelectInputChange = (value: string) => {
        if (!Number.isNaN(+value)) {
            onChange(+value);
        }
    };

    const formOptions: FormOption[] = useMemo(() => (
        Array.from({ length: pageCount }, (_, index) => ({
            value: String(index + 1),
            label: String(index + 1),
        }))
    ), [pageCount]);

    const prevPageLink = useMemo((): string => {
        const { search } = location;
        const params = new URLSearchParams(search);

        params.set('page', page !== 1 ? `${page - 1}` : '1');

        return `?${params.toString()}`;
    }, [page, location]);

    const nextPageLink = useMemo((): string => {
        const { search } = location;
        const params = new URLSearchParams(search);

        params.set('page', page < pageCount ? `${page + 1}` : `${page}`);

        return `?${params.toString()}`;
    }, [page, location]);

    return (
        <div className={`pagination ${className}`}>
            <LinkIconButton
                to={prevPageLink}
                hideLabel
                disabled={page === 1}
                icon="chevron-left"
                text={trans('common.prev')}
                onClick={handlePrevButtonClick}
                className="pagination__button"
            />

            <div className="pagination__input-wrapper">
                <SelectInput
                    hideLabel
                    name="select"
                    label="Select input"
                    options={formOptions}
                    value={formOptions.find(option => option.value === String(page))}
                    onChange={handleSelectInputChange}
                    className="pagination__input"
                />
                {`of ${pageCount} pages`}
            </div>

            <LinkIconButton
                to={nextPageLink}
                hideLabel
                disabled={page >= pageCount}
                icon="chevron-right"
                text={trans('common.next')}
                onClick={handleNextButtonClick}
                className="pagination__button"
            />
        </div>
    );
};

export default Pagination;
