import {
    ChangeEvent,
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
    RefAttributes,
} from 'react';

import { ErrorLabel, Input, InputLabel } from '../../../components';
import { InputProps } from '../../../components/@inputs/Input/Input';

import './TextInput.scss';

export interface TextInputProps extends Omit<InputProps, 'onChange'>, RefAttributes<HTMLInputElement> {
    label: string;
    additionalLabel?: string;
    hideLabel?: boolean;
    error?: string;
    onChange: (value: string) => void;
    fieldClassName?: string;
}

const TextInput: ForwardRefExoticComponent<TextInputProps> = forwardRef(({
    label,
    additionalLabel,
    hideLabel = false,
    type = 'text',
    error = '',
    onChange,
    className = '',
    fieldClassName = '',
    ...inputProps
}, ref: Ref<HTMLInputElement>): ReactElement => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        onChange(event.currentTarget.value);
    };

    return (
        <label
            aria-label={hideLabel ? label : undefined}
            className={`text-input ${className}`}
        >
            {!hideLabel && (
                <InputLabel
                    label={label}
                    additionalLabel={additionalLabel}
                    className="text-input__label"
                />
            )}

            <Input
                {...inputProps}
                ref={ref}
                type={type}
                error={error}
                onChange={handleChange}
                className={fieldClassName}
            />

            {error && (
                <ErrorLabel
                    text={error}
                    className="text-input__error-label"
                />
            )}
        </label>
    );
});

export default TextInput;
