import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { CallToActionCardInterface } from '../../../entities/@sections/CallToActionCardList/CallToActionCardList';

import './CtaCard.scss';

export interface CtaCardProps extends CallToActionCardInterface {
    className?: string;
    titleClassName?: string;
}

const CtaCard: FC<CtaCardProps> = ({
    title,
    paragraph,
    link,
    className = '',
    titleClassName = '',
}): ReactElement => (
    <div className={`cta-card ${className}`}>
        <h2 className={`cta-card__title ${titleClassName}`}>
            <Link to={link.path} className="cta-card__link">
                {title || link.label}
            </Link>
        </h2>

        <p className="cta-card__description">
            {paragraph}
        </p>
    </div>
);

export default CtaCard;
