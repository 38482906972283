import { FC, ReactElement } from 'react';

import { ShareholderDocumentsInterface } from '../../../entities/@sections/ShareholderDocuments/ShareholderDocuments';
import useTrans from '../../../hooks/useTrans';
import { NonSortTable } from '../..';
import { ShareholderDocumentsTableRow } from './subcomponents';

import './ShareholderDocumentsTable.scss';

interface ShareholderDocumentsTableProps extends ShareholderDocumentsInterface {
    className?: string;
}

const ShareholderDocumentsTable: FC<ShareholderDocumentsTableProps> = ({
    tableRows,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const tableHeaders = [
        trans('compositions.shareholderDocumentsTable.headers.category'),
        trans('compositions.shareholderDocumentsTable.headers.files'),
    ];

    return (
        <NonSortTable
            tableHeaders={tableHeaders}
            cellClassName="shareholder-documents-table__cell"
            cellContentClassName="shareholder-documents-table__cell-content"
            className={`shareholder-documents-table ${className}`}
            headerRowClassName="shareholder-documents-table__header-row"
            tableHeadClassName="shareholder-documents-table__table-head"
        >
            {tableRows.map(row => (
                <ShareholderDocumentsTableRow
                    key={row.category}
                    category={row.category}
                    files={row.files}
                    cellClassName="shareholder-documents-table__cell"
                />
            ))}
        </NonSortTable>
    );
};

export default ShareholderDocumentsTable;
