import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
    RefAttributes,
} from 'react';

import { hyphenString } from '../../constants';
import { SafeHtml } from '..';

import './ThemeTitle.scss';

interface ThemeTitleProps extends RefAttributes<HTMLDivElement> {
    titleHtml: string;
    className?: string;
}

const ThemeTitle: ForwardRefExoticComponent<ThemeTitleProps> = forwardRef(({
    titleHtml,
    className = '',
}, ref: Ref<HTMLDivElement>): ReactElement => {
    const hyphenatedTitleHtml = titleHtml.replaceAll(hyphenString, '&shy;');

    const sanitizeOptions = {
        allowedTags: ['h1', 'h2', 'h3', 'h4', 'strong', 'small', 'em'],
    };

    return (
        <SafeHtml
            ref={ref}
            html={hyphenatedTitleHtml}
            sanitizeOptions={sanitizeOptions}
            className={`theme-title ${className}`}
        />
    );
});

export default ThemeTitle;
