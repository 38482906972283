import { FC, memo, ReactElement } from 'react';

import SlotCounter from 'react-slot-counter';

import { KeyFigure as KeyFigureInterface } from '../../entities/KeyFigure/KeyFigure';
import usePreferReducedMotion from '../../hooks/usePreferReducedMotion';
import { getStringPrefixOrSuffix } from './helpers';

import './KeyFigure.scss';

export interface KeyFigureProps extends KeyFigureInterface {
    isAnimated?: boolean;
    className?: string;
    labelClassName?: string;
}

const KeyFigure: FC<KeyFigureProps> = ({
    isAnimated = false,
    amount,
    label,
    className = '',
    labelClassName = '',
}): ReactElement => {
    const preferReducedMotion = usePreferReducedMotion();

    const { value, prefix, suffix } = getStringPrefixOrSuffix(amount);

    return (
        <div className={`key-figure ${className}`}>
            <h3 aria-label={amount} className="key-figure__title">
                <div className="key-figure__amount">
                    {prefix}

                    {(!preferReducedMotion && isAnimated) ? (
                        <SlotCounter
                            animateOnVisible={{ triggerOnce: true }}
                            value={value}
                            duration={1.5}
                            direction="top-down"
                        />
                    ) : (
                        value
                    )}

                    {suffix}
                </div>

                <div className={`key-figure__label ${labelClassName}`}>
                    {label}
                </div>
            </h3>
        </div>
    );
};

export default memo(KeyFigure);
