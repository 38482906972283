import { FC, ReactElement } from 'react';

import { Section } from '../../../components';
import { SectionWrapper } from '../../../compositions';
import { ConnectedContactForm } from '../../../connectors';
import { ContactPersonFormInterface } from '../../../entities/@sections/ContactPersonForm/ContactPersonForm';
import { ContactPersonCard } from './subcomponents';

import './ContactPersonForm.scss';

interface ContactPersonFormProps extends ContactPersonFormInterface {
    className?: string;
}

const ContactPersonForm: FC<ContactPersonFormProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    title,
    person,
    className = '',
}): ReactElement => (
    <Section isDark={isDark} className={`contact-person-form ${className}`}>
        <SectionWrapper
            noSpacingTop={noSpacingTop}
            noSpacingBottom={noSpacingBottom}
            className="contact-person-form__wrapper"
        >
            <h2 className="contact-person-form__title">
                {title}
            </h2>

            <div className="contact-person-form__content">
                {person && (
                    <ContactPersonCard
                        person={person}
                        className="contact-person-form__contact-person-card"
                    />
                )}

                <ConnectedContactForm />
            </div>
        </SectionWrapper>
    </Section>
);

export default ContactPersonForm;
