import {
    DefaultComponent,
    DefaultComponentAppearance,
    DefaultComponentAppearanceResource,
    DefaultComponentResource,
} from '../../Component/Component';

export enum FixedComponentTypeResource {
    map = 'Map',
    share = 'Share',
    sharePerformance = 'Share - Performance',
    calendar = 'Calendar',
    vacancies = 'Vacancies',
}

export interface FixedComponentResource extends DefaultComponentResource, DefaultComponentAppearanceResource {
    id: string;
    component: FixedComponentTypeResource;
}

export enum FixedComponentType {
    map = 'map',
    share = 'share',
    sharePerformance = 'share-performance',
    calendar = 'calendar',
    vacancies = 'vacancies',
}

export interface FixedComponentInterface extends DefaultComponent, DefaultComponentAppearance {
    component: FixedComponentType;
}

