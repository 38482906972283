/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconSearch: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={30} viewBoxHeight={31} className={className}>
        <path d="M20.25 13.25C20.25 10.5781 18.7969 8.14062 16.5 6.78125C14.1562 5.42188 11.2969 5.42188 9 6.78125C6.65625 8.14062 5.25 10.5781 5.25 13.25C5.25 15.9688 6.65625 18.4062 9 19.7656C11.2969 21.125 14.1562 21.125 16.5 19.7656C18.7969 18.4062 20.25 15.9688 20.25 13.25ZM18.7969 20.9375C17.1094 22.25 15 23 12.75 23C7.35938 23 3 18.6406 3 13.25C3 7.90625 7.35938 3.5 12.75 3.5C18.0938 3.5 22.5 7.90625 22.5 13.25C22.5 15.5469 21.7031 17.6562 20.3906 19.3438L26.6719 25.5781C27.0938 26.0469 27.0938 26.75 26.6719 27.1719C26.2031 27.6406 25.5 27.6406 25.0781 27.1719L18.7969 20.9375Z" />
    </Svg>
);

export default IconSearch;
/* eslint-enable max-len */
