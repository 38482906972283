import { ArticleResponse } from '../../entities/Article/Article';
import { generateArticleDetailQuery } from '../../entities/Article/ArticleRequests';
import { transformToArticle } from '../../entities/Article/ArticleTransformers';
import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { getRestApiCall } from '../../entities/Rest/RestService';
import { TypedDispatch } from '../store';
import {
    setArticleDetail,
    setError,
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
} from './articleDetailReducer';

export const fetchArticleDetail = (slug: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setArticleDetail(undefined));
    dispatch(setHasFetched(false));
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const query = generateArticleDetailQuery(slug);

        const articleResponse = await getRestApiCall<ArticleResponse>(`/articles?${query}&populate=*`);

        if (!isFetchResultSuccessful(articleResponse)) {
            dispatch(setError(articleResponse.error));
            return;
        }

        const { data: articleData } = articleResponse.data;

        const articleDataResource = articleData
            ? articleData[0]
            : undefined;

        const articleDetail = articleDataResource?.attributes
            ? transformToArticle(articleDataResource)
            : undefined;

        dispatch(setArticleDetail(articleDetail));

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[fetchArticleDetail]', error);
    } finally {
        dispatch(setHasFetched(true));
        dispatch(setIsLoading(false));
    }
};
