import { FC, ReactElement } from 'react';

import { Page } from '../../components';
import { ConnectedCustomPageHelmet, ConnectedPageSectionRenderer } from '../../connectors';

import './CustomPage.scss';

const CustomPage: FC = (): ReactElement => (
    <Page className="custom-page">
        <ConnectedCustomPageHelmet />

        <ConnectedPageSectionRenderer />
    </Page>
);

export default CustomPage;
