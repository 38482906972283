import { getFileExtensionFromUrl } from '../../helpers/url';
import {
    DownloadableFile,
    Image,
    ImageFormat,
    ImageFormatSize,
    MediaEntityResource,
    MediaFormatResource,
    Video,
} from './Media';

export const transformToImageFormat = (resource: MediaFormatResource, format: ImageFormatSize): ImageFormat => {
    const mediaSizes = {
        [ImageFormatSize.original]: '(min-width: 1024px)',
        [ImageFormatSize.large]: '(min-width: 768px)',
        [ImageFormatSize.medium]: '(min-width: 480px)',
        [ImageFormatSize.small]: '(min-width: 320px)',
        [ImageFormatSize.thumbnail]: '(max-width: 320px)',
    };

    return {
        srcSet: resource.url,
        type: resource.mime,
        media: mediaSizes[format],
    };
};

export const transformToImageFormats = (
    resource: Partial<Record<ImageFormatSize, MediaFormatResource>>,
): Partial<Record<ImageFormatSize, ImageFormat>> => {
    const original = resource.original
        ? transformToImageFormat(resource.original, ImageFormatSize.original)
        : undefined;

    const large = resource.large
        ? transformToImageFormat(resource.large, ImageFormatSize.large)
        : undefined;

    const medium = resource.medium
        ? transformToImageFormat(resource.medium, ImageFormatSize.medium)
        : undefined;

    const small = resource.small
        ? transformToImageFormat(resource.small, ImageFormatSize.small)
        : undefined;

    const thumbnail = resource.thumbnail
        ? transformToImageFormat(resource.thumbnail, ImageFormatSize.thumbnail)
        : undefined;

    return {
        original,
        large,
        medium,
        small,
        thumbnail,
    };
};

export const transformToImage = (resource: MediaEntityResource): Image => {
    const { attributes } = resource;

    const formats = attributes?.formats
        ? transformToImageFormats({ ...attributes.formats, [ImageFormatSize.original]: attributes })
        : {};

    return {
        src: attributes?.url || '',
        formats,
        mime: attributes?.mime || '',
        alt: attributes?.alternativeText || attributes?.caption || attributes?.name || '',
    };
};

export const transformToVideo = (resource: MediaEntityResource): Video => {
    const { attributes } = resource;

    return {
        src: attributes?.url || '',
        type: attributes?.mime || '',
    };
};

export const transformToDownloadableFile = (resource: MediaEntityResource): DownloadableFile => {
    const { attributes } = resource;

    const extensionFromResource = attributes?.ext
        ? attributes.ext.slice(1)
        : attributes?.mime?.split('/')[1];

    const extensionFromUrl = attributes?.url
        ? getFileExtensionFromUrl(attributes.url)
        : '';

    return {
        src: attributes?.url || '',
        title: attributes?.caption || attributes?.name || '',
        extension: extensionFromResource || extensionFromUrl || '',
    };
};
