import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { transformToPagination } from '../../entities/Pagination/PaginationTransformers';
import { getRestApiCall } from '../../entities/Rest/RestService';
import { transformToSolution } from '../../entities/Solution/SolutionTransformers';
import { SolutionTagRequestConfig, SolutionTagResponse } from '../../entities/SolutionTag/SolutionTag';
import { generateSolutionTagQuery } from '../../entities/SolutionTag/SolutionTagRequests';
import { ReducerGetter, TypedDispatch } from '../store';
import {
    setError,
    setIsLoading,
    setIsSuccessful,
    setTags,
} from './solutionTagsReducer';

export const fetchSolutionTag = (config: SolutionTagRequestConfig) => async (
    dispatch: TypedDispatch,
    getState: ReducerGetter,
): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const query = generateSolutionTagQuery(config);

        const tagResponse = await getRestApiCall<SolutionTagResponse>(`/solutions?${query}`);

        if (!isFetchResultSuccessful(tagResponse)) {
            dispatch(setError(tagResponse.error));
            return;
        }

        const { data: solutionsData, meta: solutionsMeta } = tagResponse.data;

        const tagSolutions = solutionsData
            ? solutionsData.map(transformToSolution)
            : [];

        const tagPagination = solutionsMeta
            ? transformToPagination(solutionsMeta.pagination)
            : undefined;

        const state = getState();
        const { tags } = state.solutionTagsReducer;

        const updatedTags = {
            ...tags,
            [config.key]: {
                solutions: tagSolutions,
                pagination: tagPagination,
            },
        };

        dispatch(setTags(updatedTags));

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[fetchSolutionTag]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
