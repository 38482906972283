import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Link } from '../../entities/Link/Link';
import { Page } from '../../entities/Page/Page';
import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type PageState = AsyncReduxState<{
    hasFetched: boolean;
    pageData?: Page;
    breadcrumbs: Link[];
}>;

const initialState: PageState = {
    ...initialAsyncReduxState,
    hasFetched: false,
    breadcrumbs: [],
};

const pageSlice = createSlice({
    name: 'pageReducer',
    initialState,
    reducers: {
        setHasFetched(state, action: PayloadAction<boolean>): PageState {
            return {
                ...state,
                hasFetched: action.payload,
            };
        },
        setIsLoading(state, action: PayloadAction<boolean>): PageState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): PageState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): PageState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setPageData(state, action: PayloadAction<Page | undefined>): PageState {
            return {
                ...state,
                pageData: action.payload,
            };
        },
        setBreadcrumbs(state, action: PayloadAction<Link[]>): PageState {
            return {
                ...state,
                breadcrumbs: action.payload,
            };
        },
    },
});

export const {
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
    setError,
    setPageData,
    setBreadcrumbs,
} = pageSlice.actions;

export default pageSlice;
