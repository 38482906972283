import { FC, ReactElement } from 'react';

import './GroupItem.scss';

interface GroupItemType {
    name: string;
}

interface GroupItemProps {
    title: string;
    items: GroupItemType[];
    titleClassName?: string;
    className?: string;
}

const GroupItem: FC<GroupItemProps> = ({
    title,
    items,
    titleClassName,
    className = '',
}): ReactElement => (
    <section className={`group-item ${className}`}>
        <span className={`group-item__title ${titleClassName}`}>
            {title}
        </span>

        <ul className="group-item__list">
            {items.map(item => (
                <li key={item.name} className="group-item__list-item">
                    {item.name}
                </li>
            ))}
        </ul>
    </section>
);

export default GroupItem;
