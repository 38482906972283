import trans from '../../../helpers/trans';
import { ArticleCategoryKeyResource } from '../../ArticleCategory/ArticleCategory';
import { transformToArticleCategory } from '../../ArticleCategory/ArticleCategoryTransformers';
import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { PageSection } from '../../PageSection/PageSection';
import { LatestArticlesInterface, LatestArticlesPageSizeResource, LatestArticlesResource } from './LatestArticles';

const transformToLatestArticlesPageSize = (resource?: LatestArticlesPageSizeResource): number => {
    if (resource === LatestArticlesPageSizeResource.items6) return 6;
    if (resource === LatestArticlesPageSizeResource.items9) return 9;
    if (resource === LatestArticlesPageSizeResource.items12) return 12;

    return 3;
};

export const transformToLatestArticles = (resource: LatestArticlesResource): LatestArticlesInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    const categories = resource.categories?.data
        ? resource.categories.data.map(transformToArticleCategory)
        : [];

    const pageSize = transformToLatestArticlesPageSize(resource?.showNumberOfItems);

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.latestArticles,
        title: resource.title,
        categories,
        pageSize,
    };
};

export const transformToLatestArticlesTitle = (resource: string): string => {
    if (resource === ArticleCategoryKeyResource.newsRelease) return trans('entities.latestArticles.title.newsRelease');
    if (resource === ArticleCategoryKeyResource.pressRelease) return trans('entities.latestArticles.title.pressRelease');
    if (resource === ArticleCategoryKeyResource.shareBuyback) return trans('entities.latestArticles.title.shareBuyback');

    return trans('entities.latestArticles.title.fallback');
};
