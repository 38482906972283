import { generateUuid } from '../../helpers/string';
import { transformToDownloadableFile } from '../Media/MediaTransformers';
import { Vacancy, VacancyEntityResource } from './Vacancy';

export const transformToVacancy = (resource: VacancyEntityResource): Vacancy => {
    const file = resource.attributes?.file?.data
        ? transformToDownloadableFile(resource.attributes.file.data)
        : undefined;

    return {
        id: resource.id || resource.attributes?.title || generateUuid(),
        title: resource.attributes?.title || '',
        subtitle: resource.attributes?.subtitle || '',
        descriptionHtml: resource.attributes?.description || '',
        file,
    };
};
