import { FC, ReactElement } from 'react';

import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import { Icon } from '../../components';
import { Link } from '../../entities/Link/Link';
import { NavLinkClassName } from '../../types';

import './Breadcrumbs.scss';

interface BreadcrumbsProps {
    links: Link[];
    className?: string;
}

const Breadcrumbs: FC<BreadcrumbsProps> = ({ links, className = '' }): ReactElement => (
    <ul className={`breadcrumbs ${className}`}>
        {links.map((link, index) => {
            const isCurrentPage = index === links.length - 1;

            const navLinkClassName: NavLinkClassName = ({ isActive }): string => classNames('breadcrumbs__nav-link', {
                'breadcrumbs__nav-link--is-active': isActive,
            });

            return (
                <li key={link.path}>
                    <NavLink
                        end
                        to={link.path}
                        className={navLinkClassName}
                    >
                        {link.label}
                    </NavLink>

                    {!isCurrentPage && (
                        <Icon
                            name="chevron-bold-right"
                            className="breadcrumbs__separator"
                        />
                    )}
                </li>
            );
        })}
    </ul>
);

export default Breadcrumbs;
