import { FC, ReactElement } from 'react';

import useTrans from '../../../../hooks/useTrans';
import { IconButton } from '../../..';

import './PresentationControls.scss';

interface PresentationControlsProps {
    pageNumber: number;
    totalPages: number;
    onNextButtonClick: () => void;
    onPrevButtonClick: () => void;
    className?: string;
}

const PresentationControls: FC<PresentationControlsProps> = ({
    pageNumber,
    totalPages,
    onNextButtonClick,
    onPrevButtonClick,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <div className={`presentation-controls ${className}`}>
            <IconButton
                disabled={pageNumber === 1}
                hideLabel
                icon="arrow-left"
                text={trans('common.prev')}
                onClick={onPrevButtonClick}
                className="presentation-controls__prev-button"
            />

            <div className="presentation-controls__page-number">
                {pageNumber}
            </div>

            <div className="xwpresentation-controls__total-pages">
                {`${trans('compositions.pdfReader.of')} ${totalPages}`}
            </div>

            <IconButton
                disabled={pageNumber === totalPages}
                hideLabel
                icon="arrow-right"
                text={trans('common.next')}
                onClick={onNextButtonClick}
                className="presentation-controls__next-button"
            />
        </div>
    );
};

export default PresentationControls;
