import { FC, ReactElement, RefObject } from 'react';

import classNames from 'classnames';

import { LinkButton, ThemeTitle, Wrapper } from '../../../components';
import { DateIsoString } from '../../../entities/Date/Date';
import { Link } from '../../../entities/Link/Link';
import { Image } from '../../../entities/Media/Media';
import useFormatDate from '../../../hooks/useFormatDate';
import {
    Breadcrumbs,
    HeaderQuickLinksGroup,
    HomeQuickLinksGroup,
    ScrollDownIndicator,
} from '../..';
import { VisualHeaderCarousel } from './subcomponents';

import './VisualHeader.scss';

interface VisualHeaderProps {
    isAnimated?: boolean;
    isFullScreen?: boolean;
    isInView?: boolean;
    breadcrumbs?: Link[];
    titleHtml: string;
    caption?: string;
    link?: Link;
    quickLinks?: Link[];
    location?: string;
    date?: DateIsoString;
    images: Image[];
    scrollDownIndicatorTargetRef?: RefObject<HTMLElement>;
    className?: string;
}

const VisualHeader: FC<VisualHeaderProps> = ({
    isFullScreen,
    isAnimated,
    isInView,
    breadcrumbs,
    titleHtml,
    caption,
    link,
    quickLinks = [],
    location,
    date,
    images,
    scrollDownIndicatorTargetRef,
    className = '',
}): ReactElement => {
    const formatDate = useFormatDate();

    const headerClassName = classNames('visual-header', {
        'visual-header--has-quick-links': !!quickLinks.length,
        'visual-header--is-full-screen': isFullScreen,
    }, className);

    return (
        <header data-dark="true" className={headerClassName}>
            <Wrapper hideGrid className="visual-header__wrapper">
                <div className="visual-header__content-wrapper">
                    {breadcrumbs && (
                        <Breadcrumbs
                            links={breadcrumbs}
                            className="visual-header__breadcrumbs"
                        />
                    )}

                    <ThemeTitle
                        titleHtml={titleHtml}
                        className="visual-header__title"
                    />

                    {caption && (
                        <h2 className="visual-header__caption">
                            {caption}
                        </h2>
                    )}

                    {location && (
                        <p className="visual-header__location">
                            {location}
                        </p>
                    )}

                    {date && (
                        <p className="visual-header__date">
                            {formatDate(date)}
                        </p>
                    )}

                    {link && (
                        <LinkButton
                            to={link.path}
                            text={link.label}
                            className="visual-header__link-button"
                        />
                    )}

                    {!!quickLinks.length && !isFullScreen && (
                        <HeaderQuickLinksGroup
                            links={quickLinks}
                            className="visual-header__default-quick-links"
                        />
                    )}
                </div>

                {!!quickLinks.length && isFullScreen && (
                    <HomeQuickLinksGroup
                        links={quickLinks}
                        className="visual-header__home-quick-links"
                    />
                )}
            </Wrapper>

            {images.length > 0 && (
                <VisualHeaderCarousel
                    isAnimated={isAnimated}
                    isInView={isInView}
                    images={images}
                    className="visual-header__carousel"
                />
            )}

            {isFullScreen && (
                <Wrapper>
                    <ScrollDownIndicator
                        scrollTarget={scrollDownIndicatorTargetRef}
                        className="visual-header__scroll-down-indicator"
                    />
                </Wrapper>
            )}
        </header>
    );
};

export default VisualHeader;
