export const generateStoreKey = (keyWords: string[], amount?: number): string => {
    const slugs = keyWords.map(keyWord => keyWord.replaceAll(' ', '-').toLowerCase());
    const keyWordString = slugs.join('/');

    if (!keyWordString) {
        return amount
            ? `${amount}`
            : 'all';
    }

    return amount
        ? `${keyWordString}:${amount}`
        : keyWordString;
};
