import { FC, ReactElement } from 'react';

import { SafeHtml, Section } from '../../../components';
import { DownloadCard, SectionWrapper } from '../../../compositions';
import { HighlightedDownloadCardInterface } from '../../../entities/@sections/HighlightedDownloadCard/HighlightedDownloadCard';

import './HighlightedDownloadCard.scss';

export interface HighlightedDownloadCardProps extends HighlightedDownloadCardInterface {
    className?: string;
}

const HighlightedDownloadCard: FC<HighlightedDownloadCardProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    title,
    paragraphHtml,
    file,
    className = '',
}): ReactElement => (
    <Section isDark={isDark} className={`highlighted-download-card ${className}`}>
        <SectionWrapper
            noSpacingTop={noSpacingTop}
            noSpacingBottom={noSpacingBottom}
            className="highlighted-download-card__wrapper"
        >
            <DownloadCard
                title={title}
                file={file}
                className="highlighted-download-card__card"
            />

            <SafeHtml
                html={paragraphHtml}
                className="highlighted-download-card__paragraph"
            />
        </SectionWrapper>
    </Section>
);

export default HighlightedDownloadCard;
