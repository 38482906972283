import { isSSR } from '../../../helpers';
import trans from '../../../helpers/trans';
import { SendMailResponse } from '../../../types';
import { FetchResult, FetchResultType } from '../../FetchResult';
import { PressReleaseFormData } from './PressReleaseForm';

export const sendPressReleaseFormEmail = async (formData: PressReleaseFormData): Promise<FetchResult<SendMailResponse>> => {
    try {
        const apiUrl = isSSR
            ? `${process.env.PUBLIC_URL}/email`
            : `${window.location.origin}/email`;

        const response = await fetch(`${apiUrl}/press-release`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify(formData),
        });

        if (!response.ok) {
            return {
                status: 500,
                type: FetchResultType.Error,
                error: {
                    title: trans('entities.pressReleaseForm.errorToast.title'),
                    description: trans('entities.pressReleaseForm.errorToast.description'),
                },
            };
        }

        return {
            status: 200,
            type: FetchResultType.Success,
            data: {
                title: trans('entities.pressReleaseForm.successToast.title'),
                description: trans('entities.pressReleaseForm.successToast.description'),
            },
        };
    } catch (error) {
        console.error('Error sending press release form email:', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: {
                title: trans('entities.pressReleaseForm.errorToast.title'),
                description: trans('entities.pressReleaseForm.errorToast.description'),
            },
        };
    }
};
