import { isLocal, isSSR } from '../../helpers';
import trans from '../../helpers/trans';
import { FetchResult, FetchResultType } from '../FetchResult';

export const getRestApiCall = async <ResponseData>(endpoint: string): Promise<FetchResult<ResponseData, string>> => {
    let data: ResponseData | null;

    try {
        const localApiUrl = process.env.REACT_APP_REST_URL || '';
        const serverApiUrl = isSSR
            ? `${process.env.PUBLIC_URL}/api`
            : `${window.location.origin}/api`;

        const apiUrl = isLocal
            ? localApiUrl
            : serverApiUrl;

        const response = await fetch(apiUrl + endpoint);

        data = await response.json();

        return {
            status: 200,
            type: FetchResultType.Success,
            data: data as ResponseData,
        };
    } catch (error: unknown) {
        console.error('[getRestApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    } finally {
        data = null;
    }
};

