import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { transformToKeyFigure } from '../../KeyFigure/KeyFigureTransformers';
import { transformToImage } from '../../Media/MediaTransformers';
import { PageSection } from '../../PageSection/PageSection';
import {
    FlipCardInterface,
    FlipCardListInterface,
    FlipCardListResource,
    FlipCardResource,
} from './FlipCardList';

const transformToFlipCard = (resource: FlipCardResource): FlipCardInterface => {
    const keyFigure = transformToKeyFigure(resource.keyFigure[0]);

    const frontImage = resource.frontImage?.data
        ? transformToImage(resource.frontImage.data)
        : undefined;

    const backImage = resource.backImage?.data
        ? transformToImage(resource.backImage.data)
        : undefined;

    return {
        id: resource.id,
        title: resource.title,
        keyFigure,
        frontBody: resource.frontBody || '',
        backBody: resource.backBody || '',
        frontImage,
        backImage,
    };
};

export const transformToFlipCardList = (resource: FlipCardListResource): FlipCardListInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    const cards = resource.flipCards.map(transformToFlipCard);

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.flipCardList,
        title: resource.title,
        cards,
    };
};
