import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Picture, Wrapper } from '../../components';
import { Image } from '../../entities/Media/Media';
import { getGalleryType } from './helpers';

import './Gallery.scss';

export enum GalleryLayoutType {
    triplet = 'triplet',
    quartet = 'quartet',
}

export interface ImageItem extends Image {
    key: string;
}

interface GalleryProps {
    type?: GalleryLayoutType;
    images: ImageItem[];
    className?: string;
}

const Gallery: FC<GalleryProps> = ({ type, images, className = '' }): ReactElement => {
    const defaultType = getGalleryType(images.length);

    const galleryClassName = classNames('gallery', {
        [`gallery--is-${type || defaultType}-type`]: type || defaultType,
    }, className);

    return (
        <Wrapper className={galleryClassName}>
            {images.map(image => (
                <Picture
                    key={image.key}
                    src={image.src}
                    alt={image.alt}
                    formats={image.formats}
                    className="gallery__picture"
                    imageClassName="gallery__image"
                />
            ))}
        </Wrapper>
    );
};

export default Gallery;
