import {
    DetailsHTMLAttributes,
    FC,
    PropsWithChildren,
    ReactElement,
} from 'react';

import { Icon } from '..';

import './Details.scss';

interface DetailsProps extends DetailsHTMLAttributes<HTMLDetailsElement> {
    summary: string;
    className?: string;
    summaryClassName?: string;
}

const Details: FC<PropsWithChildren<DetailsProps>> = ({
    summary,
    className = '',
    summaryClassName = '',
    children,
    ...detailsProps
}): ReactElement => (
    <details {...detailsProps} className={`details ${className}`}>
        <summary className={`details__summary ${summaryClassName}`}>
            <div className="details__summary-text">
                {summary}
            </div>

            <Icon name="chevron-down" className="details__summary-icon" />
        </summary>

        {children}
    </details>
);

export default Details;
