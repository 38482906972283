import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { transformToDownloadableFile } from '../../Media/MediaTransformers';
import { PageSection } from '../../PageSection/PageSection';
import {
    AnnualDownloadsInterface,
    AnnualDownloadsResource,
    AnnualDownloadsYear,
    AnnualDownloadsYearResource,
} from './AnnualDownloads';

const transformToAnnualDownloadsYear = (resource: AnnualDownloadsYearResource): AnnualDownloadsYear => {
    const files = resource.files?.data
        ? resource.files.data.map(transformToDownloadableFile)
        : [];

    const files10To20 = resource.files10To20?.data
        ? resource.files10To20.data.map(transformToDownloadableFile)
        : [];

    return {
        year: resource.year,
        files: [...files, ...files10To20],
    };
};

export const transformToAnnualDownloads = (resource: AnnualDownloadsResource): AnnualDownloadsInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    const years = resource.years
        ? resource.years.map(transformToAnnualDownloadsYear)
        : [];

    const next10Years = resource.yearsNext10
        ? resource.yearsNext10.map(transformToAnnualDownloadsYear)
        : [];

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.annualDownloads,
        title: resource.title || '',
        years: [...years, ...next10Years],
    };
};
