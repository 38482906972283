import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { PageSection } from '../../PageSection/PageSection';
import { NewsletterFormInterface, NewsletterFormResource } from './NewsletterForm';

export const transformToNewsletterForm = (resource: NewsletterFormResource): NewsletterFormInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.newsletterForm,
        titleHtml: resource.title,
        paragraphHtml: resource.paragraph || '',
        formTitle: resource.newsLetterTitle || '',
        formButtonLabel: resource.newsLetterButton || '',
    };
};
