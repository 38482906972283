/* eslint-disable max-len */
import { AnalystCoverageTableResource } from '../@sections/AnalystCoverageTable/AnalystCoverageTable';
import { transformToAnalystCoverageTable } from '../@sections/AnalystCoverageTable/AnalystCoverageTableTransformers';
import { AnnualDownloadsResource } from '../@sections/AnnualDownloads/AnnualDownloads';
import { transformToAnnualDownloads } from '../@sections/AnnualDownloads/AnnualDownloadsTransformers';
import { ArticlesCarouselResource } from '../@sections/ArticlesCarousel/ArticlesCarousel';
import { transformToArticlesCarousel } from '../@sections/ArticlesCarousel/ArticlesCarouselTransformers';
import { ArticlesOverviewResource } from '../@sections/ArticlesOverview/ArticlesOverview';
import { transformToArticlesOverview } from '../@sections/ArticlesOverview/ArticlesOverviewTransformers';
import { BackgroundIllustrationResource } from '../@sections/BackgroundIllustration/BackgroundIllustration';
import { transformToBackgroundIllustration } from '../@sections/BackgroundIllustration/BackgroundIllustrationTransformers';
import { CallToActionCardListResource } from '../@sections/CallToActionCardList/CallToActionCardList';
import { transformToCallToActionCardList } from '../@sections/CallToActionCardList/CallToActionCardListTransformers';
import { CommitteeResource } from '../@sections/Committee/Committee';
import { transformToCommittee } from '../@sections/Committee/CommitteeTransformers';
import { ContactCompanyFormResource } from '../@sections/ContactCompanyForm/ContactCompanyForm';
import { transformToContactCompanyForm } from '../@sections/ContactCompanyForm/ContactCompanyFormTransformers';
import { ContactInvestorRelationsResource } from '../@sections/ContactInvestorRelations/ContactInvestorRelations';
import { transformToContactInvestorRelations } from '../@sections/ContactInvestorRelations/ContactInvestorRelationsTransformers';
import { ContactPersonFormResource } from '../@sections/ContactPersonForm/ContactPersonForm';
import { transformToContactPersonForm } from '../@sections/ContactPersonForm/ContactPersonFormTransformers';
import { ContentColumnsResource } from '../@sections/ContentColumns/ContentColumns';
import { transformToContentColumns } from '../@sections/ContentColumns/ContentColumnsTransformers';
import { DisclaimerResource } from '../@sections/Disclaimer/Disclaimer';
import { transformToDisclaimer } from '../@sections/Disclaimer/DisclaimerTransformers';
import { DownloadListResource } from '../@sections/DownloadList/DownloadList';
import { transformToDownloadList } from '../@sections/DownloadList/DownloadListTransformers';
import { FinancialOverviewTableResource } from '../@sections/FinancialOverviewTable/FinancialOverviewTable';
import { transformToFinancialOverviewTable } from '../@sections/FinancialOverviewTable/FinancialOverviewTableTransformers';
import { FinancialResultsTableListResource } from '../@sections/FinancialResultsTableList/FinancialResultsTableList';
import { transformToFinancialResultsTableList } from '../@sections/FinancialResultsTableList/FinancialResultsTableListTransformers';
import { FixedComponentResource } from '../@sections/FixedComponent/FixedComponent';
import { transformToFixedComponent } from '../@sections/FixedComponent/FixedComponentTransformers';
import { FlipCardListResource } from '../@sections/FlipCardList/FlipCardList';
import { transformToFlipCardList } from '../@sections/FlipCardList/FlipCardListTransformers';
import { GoalCardListResource } from '../@sections/GoalCardList/GoalCardList';
import { transformToGoalCardList } from '../@sections/GoalCardList/GoalCardListTransformers';
import { HighlightedArticleResource } from '../@sections/HighlightedArticle/HighlightedArticle';
import { transformToHighlightedArticle } from '../@sections/HighlightedArticle/HighlightedArticleTransformers';
import { HighlightedDownloadCardResource } from '../@sections/HighlightedDownloadCard/HighlightedDownloadCard';
import { transformToHighlightedDownloadCard } from '../@sections/HighlightedDownloadCard/HighlightedDownloadCardTransformers';
import { HighlightedImpactCardResource } from '../@sections/HighlightedImpactCard/HighlightedImpactCard';
import { transformToHighlightedImpactCard } from '../@sections/HighlightedImpactCard/HighlightedImpactCardTransformers';
import { HighlightedKeyFigureResource } from '../@sections/HighlightedKeyFigure/HighlightedKeyFigure';
import { transformToHighlightedKeyFigure } from '../@sections/HighlightedKeyFigure/HighlightedKeyFigureTransformers';
import { IllustratedContentBridgeResource } from '../@sections/IllustratedContentBridge/IllustratedContentBridge';
import { transformToIllustratedContentBridge } from '../@sections/IllustratedContentBridge/IllustratedContentBridgeTransformers';
import { ImageSectionResource } from '../@sections/ImageSection/ImageSection';
import { transformToImageSection } from '../@sections/ImageSection/ImageSectionTransformers';
import { KeyFiguresResource } from '../@sections/KeyFigures/KeyFigures';
import { transformToKeyFigures } from '../@sections/KeyFigures/KeyFiguresTransformers';
import { LatestArticlesResource } from '../@sections/LatestArticles/LatestArticles';
import { transformToLatestArticles } from '../@sections/LatestArticles/LatestArticlesTransformers';
import { MapCallToActionResource } from '../@sections/MapCallToAction/MapCallToAction';
import { transformToMapCallToAction } from '../@sections/MapCallToAction/MapCallToActionTransformers';
import { NewsletterCallToActionResource } from '../@sections/NewsletterCallToAction/NewsletterCallToAction';
import { transformToNewsletterCallToAction } from '../@sections/NewsletterCallToAction/NewsletterCallToActionTransformers';
import { NewsletterFormResource } from '../@sections/NewsletterForm/NewsletterForm';
import { transformToNewsletterForm } from '../@sections/NewsletterForm/NewsletterFormTransformers';
import { PageCallToActionResource } from '../@sections/PageCallToAction/PageCallToAction';
import { transformToPageCallToAction } from '../@sections/PageCallToAction/PageCallToActionTransformers';
import { PageIntroResource } from '../@sections/PageIntro/PageIntro';
import { transformToPageIntro } from '../@sections/PageIntro/PageIntroTransformers';
import { ParallaxLineSectionResource } from '../@sections/ParallaxLineSection/ParallaxLineSection';
import { transformToParallaxLineSection } from '../@sections/ParallaxLineSection/ParallaxLineSectionTransformers';
import { PeopleListResource } from '../@sections/PeopleList/PeopleList';
import { transformToPeopleList } from '../@sections/PeopleList/PeopleListTransformers';
import { PresentationResource } from '../@sections/Presentation/Presentation';
import { transformToPresentation } from '../@sections/Presentation/PresentationTransformers';
import { QuickLinksResource } from '../@sections/QuickLinks/QuickLinks';
import { transformToQuickLinks } from '../@sections/QuickLinks/QuickLinksTransformers';
import { SdgCarouselResource } from '../@sections/SdgCarousel/SdgCarousel';
import { transformToSdgCarousel } from '../@sections/SdgCarousel/SdgCarouselTransformers';
import { ShareBuybackResource } from '../@sections/ShareBuyback/ShareBuyback';
import { transformToShareBuyback } from '../@sections/ShareBuyback/ShareBuybackTransformers';
import { ShareBuybackHistoryTableResource } from '../@sections/ShareBuybackHistoryTable/ShareBuybackHistoryTable';
import { transformToShareBuybackHistoryTable } from '../@sections/ShareBuybackHistoryTable/ShareBuybackHistoryTableTransformers';
import { ShareholderDocumentsResource } from '../@sections/ShareholderDocuments/ShareholderDocuments';
import { transformToShareholderDocuments } from '../@sections/ShareholderDocuments/ShareholderDocumentsTransformers';
import { ShareholderMeetingsArchiveResource } from '../@sections/ShareholderMeetingsArchive/ShareholderMeetingsArchive';
import { transformToShareholderMeetingsArchive } from '../@sections/ShareholderMeetingsArchive/ShareholderMeetingsArchiveTransformers';
import { ShareholderTableResource } from '../@sections/ShareholderTable/ShareholderTable';
import { transformToShareholderTable } from '../@sections/ShareholderTable/ShareholderTableTransformers';
import { SolutionDetailBodyResource } from '../@sections/SolutionDetailBody/SolutionDetailBody';
import { transformToSolutionDetailBody } from '../@sections/SolutionDetailBody/SolutionDetailBodyTransformers';
import { SolutionsCarouselResource } from '../@sections/SolutionsCarousel/SolutionsCarousel';
import { transformToSolutionsCarousel } from '../@sections/SolutionsCarousel/SolutionsCarouselTransformers';
import { TellerSharePriceResource } from '../@sections/TellerSharePrice/TellerSharePrice';
import { transformToTellerSharePrice } from '../@sections/TellerSharePrice/TellerSharePriceTransformers';
import { TestimonialResource } from '../@sections/Testimonial/Testimonial';
import { transformToTestimonial } from '../@sections/Testimonial/TestimonialTransformers';
import { TestimonialCarouselResource } from '../@sections/TestimonialCarousel/TestimonialCarousel';
import { transformToTestimonialCarousel } from '../@sections/TestimonialCarousel/TestimonialCarouselTransformers';
import { TextColumnsResource } from '../@sections/TextColumns/TextColumns';
import { transformToTextColumns } from '../@sections/TextColumns/TextColumnsTransformers';
import { TextImageResource } from '../@sections/TextImage/TextImage';
import { transformToTextImage } from '../@sections/TextImage/TextImageTransformers';
import { ValueCardsResource } from '../@sections/ValueCards/ValueCards';
import { transformToValueCards } from '../@sections/ValueCards/ValueCardsTransformers';
import { VideoSectionResource } from '../@sections/VideoSection/VideoSection';
import { transformToVideo } from '../@sections/VideoSection/VideoSectionTransformers';
import { VisualCarouselResource } from '../@sections/VisualCarousel/VisualCarousel';
import { transformToVisualCarousel } from '../@sections/VisualCarousel/VisualCarouselTransformers';
import { WebcastResource } from '../@sections/Webcast/Webcast';
import { transformToWebcast } from '../@sections/Webcast/WebcastTransformers';
import { PageSectionInterface, PageSectionResource } from './PageSection';

export const transformToPageSection = (resource: PageSectionResource): PageSectionInterface => {
    const component = resource.__component;

    if (component === 'text-image.text-image') return transformToTextImage(resource as TextImageResource);
    if (component === 'text-image.page-intro') return transformToPageIntro(resource as PageIntroResource);
    if (component === 'data-display.key-figures') return transformToKeyFigures(resource as KeyFiguresResource);
    if (component === 'content-display.articles') return transformToArticlesCarousel(resource as ArticlesCarouselResource);
    if (component === 'text-image.parallax-line-section') return transformToParallaxLineSection(resource as ParallaxLineSectionResource);
    if (component === 'text-image.text-columns') return transformToTextColumns(resource as TextColumnsResource);
    if (component === 'text-image.content-columns') return transformToContentColumns(resource as ContentColumnsResource);
    if (component === 'call-to-action.high-lighted-impact-card') return transformToHighlightedImpactCard(resource as HighlightedImpactCardResource);
    if (component === 'data-display.goal-card-list') return transformToGoalCardList(resource as GoalCardListResource);
    if (component === 'call-to-action.map-call-to-action') return transformToMapCallToAction(resource as MapCallToActionResource);
    if (component === 'text-image.video') return transformToVideo(resource as VideoSectionResource);
    if (component === 'call-to-action.page-call-to-action') return transformToPageCallToAction(resource as PageCallToActionResource);
    if (component === 'text-image.visual-carousel') return transformToVisualCarousel(resource as VisualCarouselResource);
    if (component === 'call-to-action.quick-links') return transformToQuickLinks(resource as QuickLinksResource);
    if (component === 'text-image.background-illustration') return transformToBackgroundIllustration(resource as BackgroundIllustrationResource);
    if (component === 'financials.shareholder-documents') return transformToShareholderDocuments(resource as ShareholderDocumentsResource);
    if (component === 'financials.shareholder-meetings-archive') return transformToShareholderMeetingsArchive(resource as ShareholderMeetingsArchiveResource);
    if (component === 'content-display.people-list') return transformToPeopleList(resource as PeopleListResource);
    if (component === 'text-image.download-list') return transformToDownloadList(resource as DownloadListResource);
    if (component === 'content-display.committee') return transformToCommittee(resource as CommitteeResource);
    if (component === 'text-image.image') return transformToImageSection(resource as ImageSectionResource);
    if (component === 'data-display.value-cards') return transformToValueCards(resource as ValueCardsResource);
    if (component === 'content-display.testimonial-carousel') return transformToTestimonialCarousel(resource as TestimonialCarouselResource);
    if (component === 'form.fixed-component') return transformToFixedComponent(resource as FixedComponentResource);
    if (component === 'content-display.testimonial') return transformToTestimonial(resource as TestimonialResource);
    if (component === 'interactive.sdg-carousel') return transformToSdgCarousel(resource as SdgCarouselResource);
    if (component === 'interactive.flip-card-list') return transformToFlipCardList(resource as FlipCardListResource);
    if (component === 'interactive.presentation') return transformToPresentation(resource as PresentationResource);
    if (component === 'form.contact-investor-relations') return transformToContactInvestorRelations(resource as ContactInvestorRelationsResource);
    if (component === 'financials.financial-results-table-list') return transformToFinancialResultsTableList(resource as FinancialResultsTableListResource);
    if (component === 'financials.annual-downloads') return transformToAnnualDownloads(resource as AnnualDownloadsResource);
    if (component === 'text-image.webcast') return transformToWebcast(resource as WebcastResource);
    if (component === 'financials.financial-overview-table') return transformToFinancialOverviewTable(resource as FinancialOverviewTableResource);
    if (component === 'data-display.highlighted-download-card') return transformToHighlightedDownloadCard(resource as HighlightedDownloadCardResource);
    if (component === 'data-display.highlighted-key-figure') return transformToHighlightedKeyFigure(resource as HighlightedKeyFigureResource);
    if (component === 'data-display.teller-share-price') return transformToTellerSharePrice(resource as TellerSharePriceResource);
    if (component === 'financials.shareholder-table') return transformToShareholderTable(resource as ShareholderTableResource);
    if (component === 'financials.analyst-coverage-table') return transformToAnalystCoverageTable(resource as AnalystCoverageTableResource);
    if (component === 'financials.share-buyback') return transformToShareBuyback(resource as ShareBuybackResource);
    if (component === 'financials.share-buyback-history-table') return transformToShareBuybackHistoryTable(resource as ShareBuybackHistoryTableResource);
    if (component === 'call-to-action.card-list-call-to-action') return transformToCallToActionCardList(resource as CallToActionCardListResource);
    if (component === 'form.newsletter-form') return transformToNewsletterForm(resource as NewsletterFormResource);
    if (component === 'form.contact-person-form') return transformToContactPersonForm(resource as ContactPersonFormResource);
    if (component === 'text-image.illustrated-content-bridge') return transformToIllustratedContentBridge(resource as IllustratedContentBridgeResource);
    if (component === 'form.solution-detail-body') return transformToSolutionDetailBody(resource as SolutionDetailBodyResource);
    if (component === 'content-display.solutions-carousel') return transformToSolutionsCarousel(resource as SolutionsCarouselResource);
    if (component === 'content-display.highlighted-article') return transformToHighlightedArticle(resource as HighlightedArticleResource);
    if (component === 'content-display.articles-overview') return transformToArticlesOverview(resource as ArticlesOverviewResource);
    if (component === 'call-to-action.newsletter-call-to-action') return transformToNewsletterCallToAction(resource as NewsletterCallToActionResource);
    if (component === 'content-display.latest-articles') return transformToLatestArticles(resource as LatestArticlesResource);
    if (component === 'form.contact-company-form') return transformToContactCompanyForm(resource as ContactCompanyFormResource);
    if (component === 'text-image.disclaimer') return transformToDisclaimer(resource as DisclaimerResource);

    return { type: '' };
};
/* eslint-enable max-len */
