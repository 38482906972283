export enum SharePriceChangeDirection {
    up = 'up',
    down = 'down',
    equal = 'equal',
}

export interface SharePriceData {
    value: string;
    change: string;
    changePercentage: string;
    changeDirection: SharePriceChangeDirection;
    date: string;
}
