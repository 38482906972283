import { FC, ReactElement, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { SolutionDetailRenderer } from '../../containers';
import { NotFoundPage } from '../../pages';
import { setHasDataFromServer } from '../../redux/app/appReducer';
import { fetchSolutionDetail } from '../../redux/solutionDetail/solutionDetailActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

interface ConnectedSolutionDetailRendererProps {
    className?: string;
}

const ConnectedSolutionDetailRenderer: FC<ConnectedSolutionDetailRendererProps> = ({
    className = '',
}): ReactElement => {
    const { slug = '' } = useParams();

    const dispatch = useTypedDispatch();

    const hasDataFromServer = useTypedSelector(state => state.appReducer.hasDataFromServer);

    const hasFetched = useTypedSelector(state => state.solutionDetailReducer.hasFetched);
    const isLoading = useTypedSelector(state => state.solutionDetailReducer.isLoading);
    const solutionDetail = useTypedSelector(state => state.solutionDetailReducer.solutionDetail);

    useEffect((): void => {
        if (hasDataFromServer) {
            dispatch(setHasDataFromServer(false));
            return;
        }

        dispatch(fetchSolutionDetail(slug));
    }, [slug]);

    if (hasFetched && !solutionDetail) {
        return (
            <NotFoundPage />
        );
    }

    return (
        <SolutionDetailRenderer
            isLoading={isLoading}
            solution={solutionDetail}
            className={className}
        />
    );
};

export default ConnectedSolutionDetailRenderer;
