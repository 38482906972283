import { FC, ReactElement } from 'react';

import { Link } from '../../entities/Link/Link';
import { generateIdArray } from '../../helpers/array';
import { HomeQuickLink } from '..';

import './HomeQuickLinksGroup.scss';

interface HomeQuickLinkGroupProps {
    links: Link[];
    className?: string;
}

const HomeQuickLinksGroup: FC<HomeQuickLinkGroupProps> = ({ links, className = '' }): ReactElement => {
    const quickLinkKeys = generateIdArray(links.length);

    return (
        <ul className={`home-quick-links-group ${className}`}>
            {links.map((link, index) => (
                <li key={quickLinkKeys[index]}>
                    <HomeQuickLink
                        to={link.path}
                        text={link.label}
                    />
                </li>
            ))}
        </ul>
    );
};

export default HomeQuickLinksGroup;
