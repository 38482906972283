import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import {
    LinkButton,
    SafeHtml,
    Section,
    ThemeTitle,
} from '../../../components';
import { SectionWrapper } from '../../../compositions';
import { TextColumnsInterface } from '../../../entities/@sections/TextColumns/TextColumns';

import './TextColumns.scss';

interface TextColumnsProps extends TextColumnsInterface {
    className?: string;
}

const TextColumns: FC<TextColumnsProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    columnsType,
    titleHtml,
    intro,
    paragraphHtml,
    link,
    outerLink,
    className = '',
}): ReactElement => {
    const textColumnsClassName = classNames('text-columns', {
        [`text-columns--col-${columnsType.join('-')}`]: columnsType,
    }, className);

    return (
        <Section isDark={isDark} className={textColumnsClassName}>
            <SectionWrapper noSpacingTop={noSpacingTop} noSpacingBottom={noSpacingBottom}>
                <div className="text-columns__content">
                    {titleHtml && (
                        <ThemeTitle
                            titleHtml={titleHtml}
                            className="text-columns__title"
                        />
                    )}

                    {intro && (
                        <p className="text-columns__intro">
                            {intro}
                        </p>
                    )}

                    <div className="text-columns__columns">
                        <SafeHtml
                            html={paragraphHtml}
                            className="text-columns__paragraph"
                        />

                        {link && (
                            <LinkButton
                                text={link.label}
                                to={link.path}
                                className="text-columns__link-button"
                            />
                        )}

                        {outerLink && (
                            <div className="text-columns__outer-link-wrapper">
                                <LinkButton
                                    text={outerLink.label}
                                    to={outerLink.path}
                                    className="text-columns__outer-link"
                                />
                            </div>
                        )}
                    </div>
                </div>
            </SectionWrapper>
        </Section>
    );
};

export default TextColumns;
