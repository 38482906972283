import { FC, memo, ReactElement } from 'react';

import { Skeleton, Wrapper } from '../../../../components';
import { generateIdArray } from '../../../../helpers/array';
import { randomInBetweenValue } from '../../../../helpers/number';

import './SolutionDetailSkeletons.scss';

interface SolutionDetailSkeletonsProps {
    className?: string;
}

const SolutionDetailSkeletons: FC<SolutionDetailSkeletonsProps> = ({
    className = '',
}): ReactElement => {
    const amountOfParagraphs = randomInBetweenValue(4, 6);
    const paragraphSkeletons = generateIdArray(amountOfParagraphs);

    return (
        <div className={`solution-detail-skeletons ${className}`}>
            <div className="solution-detail-skeletons__header">
                <div className="solution-detail-skeletons__header-wrapper">
                    <div className="solution-detail-skeletons__header-text-wrapper">
                        <Skeleton className="solution-detail-skeletons__category" />

                        <div className="solution-detail-skeletons__title-wrapper">
                            <Skeleton className="solution-detail-skeletons__title" />
                            <Skeleton className="solution-detail-skeletons__title" />
                        </div>
                    </div>

                    <div className="solution-detail-skeletons__header-image-wrapper">
                        <div className="solution-detail-skeletons__header-image" />
                    </div>
                </div>
            </div>

            <Wrapper className="solution-detail-skeletons__body-wrapper">
                <div className="solution-detail-skeletons__body">
                    {paragraphSkeletons.map(paragraph => {
                        const amountOfLines = randomInBetweenValue(3, 5);
                        const lines = generateIdArray(amountOfLines);

                        return (
                            <div key={paragraph} className="solution-detail-skeletons__paragraph">
                                {lines.map(line => (
                                    <Skeleton key={line} className="solution-detail-skeletons__paragraph-line" />
                                ))}
                            </div>
                        );
                    })}
                </div>

                <div className="solution-detail-skeletons__sidebar">
                    <div className="solution-detail-skeletons__sidebar-card-wrapper">
                        <div className="solution-detail-skeletons__sidebar-card">
                            <Skeleton className="solution-detail-skeletons__sidebar-card-title" />

                            <Skeleton className="solution-detail-skeletons__sidebar-card-description" />
                        </div>
                    </div>
                </div>
            </Wrapper>
        </div>
    );
};

export default memo(SolutionDetailSkeletons);
