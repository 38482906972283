import { FC, PropsWithChildren, ReactElement } from 'react';

import { Wrapper } from '../../components';
import { WrapperProps } from '../../components/Wrapper/Wrapper';
import { DefaultComponentAppearance } from '../../entities/Component/Component';

import './SectionWrapper.scss';

interface SectionWrapperProps extends Pick<DefaultComponentAppearance, 'noSpacingTop' | 'noSpacingBottom'>, WrapperProps {
    className?: string;
}

const SectionWrapper: FC<PropsWithChildren<SectionWrapperProps>> = ({
    noSpacingTop,
    noSpacingBottom,
    hideGrid,
    id,
    className = '',
    children,
}): ReactElement => (
    <Wrapper
        data-no-spacing-top={noSpacingTop}
        data-no-spacing-bottom={noSpacingBottom}
        hideGrid={hideGrid}
        id={id}
        className={`section-wrapper ${className}`}
    >
        {children}
    </Wrapper>
);

export default SectionWrapper;
