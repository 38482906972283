/* eslint-disable max-len */
import React, { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconUnmute: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={100} viewBoxHeight={100} className={className}>
        <path d="M49.7,10.9L23.8,31.5h-11C5.7,31.5,0,37.2,0,44.2v11.5c0,7.1,5.7,12.8,12.8,12.8h11l25.8,20.5c2.6,2.1,6.4,0.2,6.4-3.1V14C56.1,10.7,52.3,8.9,49.7,10.9z" />
        <path d="M70.3,75.7c-0.6,0-1.2-0.1-1.8-0.3c-2.6-1-3.9-3.9-2.9-6.5c4.7-12.2,4.7-25.6,0-37.7c-1-2.6,0.3-5.5,2.9-6.5c2.6-1,5.5,0.3,6.5,2.9c5.6,14.5,5.6,30.5,0,45C74.2,74.5,72.3,75.7,70.3,75.7z" />
        <path d="M84,94.9c-0.7,0-1.5-0.2-2.2-0.5c-2.5-1.2-3.5-4.2-2.3-6.7c11.6-23.6,11.6-51.8,0-75.4c-1.2-2.5-0.2-5.5,2.3-6.7c2.5-1.2,5.5-0.2,6.7,2.3c13,26.4,13,57.9,0,84.3C87.7,93.9,85.9,94.9,84,94.9z" />
    </Svg>
);

export default IconUnmute;
/* eslint-enable max-len */
