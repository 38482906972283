import { FC, ReactElement } from 'react';

import { CtaCard, Section } from '../../../components';
import { SectionWrapper } from '../../../compositions';
import { CallToActionCardListInterface } from '../../../entities/@sections/CallToActionCardList/CallToActionCardList';

import './CtaCardList.scss';

interface CtaCardListProps extends CallToActionCardListInterface {
    className?: string;
    listClassName?: string;
}

const CtaCardList: FC<CtaCardListProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    title,
    cards,
    className = '',
}): ReactElement => (
    <Section isDark={isDark} className={`cta-card-list ${className}`}>
        <SectionWrapper noSpacingTop={noSpacingTop} noSpacingBottom={noSpacingBottom}>
            {title && (
                <h2 className="cta-card-list__title">
                    {title}
                </h2>
            )}

            <ul className="cta-card-list__list">
                {cards.map(ctaCard => (
                    <li key={ctaCard.id} className="cta-card-list__list-item">
                        <CtaCard
                            {...ctaCard}
                            className="cta-card-list__card"
                        />
                    </li>
                ))}
            </ul>
        </SectionWrapper>
    </Section>
);

export default CtaCardList;
