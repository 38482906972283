import { FC, ReactElement } from 'react';

import { Details, Section } from '../../../components';
import { SectionWrapper, ShareholderDocumentsTable } from '../../../compositions';
import { ShareholderDocumentsInterface } from '../../../entities/@sections/ShareholderDocuments/ShareholderDocuments';
import useTrans from '../../../hooks/useTrans';

import './ShareholderDocuments.scss';

export interface ShareholderDocumentsProps extends ShareholderDocumentsInterface {
    className?: string;
}

const ShareholderDocuments: FC<ShareholderDocumentsProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    tableRows,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <Section isDark={isDark} className={`shareholder-documents ${className}`}>
            <SectionWrapper noSpacingTop={noSpacingTop} noSpacingBottom={noSpacingBottom}>
                <Details
                    summary={trans('containers.shareholderDocuments.title')}
                    className="shareholder-documents__details"
                    summaryClassName="shareholder-documents__details-summary"
                >
                    <ShareholderDocumentsTable
                        tableRows={tableRows}
                        className="shareholder-documents__table"
                    />
                </Details>
            </SectionWrapper>
        </Section>
    );
};

export default ShareholderDocuments;
