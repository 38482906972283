import { ContactFormData } from '../../entities/@forms/ContactForm/ContactForm';
import { sendContactFormEmail } from '../../entities/@forms/ContactForm/ContactFormService';
import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { TypedDispatch } from '../store';
import { addNegativeToast, addPositiveToast } from '../toast/toastActions';
import { setIsLoading, setIsSuccessful } from './contactFormReducer';

export const sendContactForm = (formData: ContactFormData) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));

    try {
        const response = await sendContactFormEmail(formData);

        if (!isFetchResultSuccessful(response)) {
            console.error('[sendContactForm]', response.error.description);

            dispatch(addNegativeToast(response.error));

            return;
        }

        dispatch(setIsSuccessful(true));
        dispatch(addPositiveToast(response.data));
    } catch (error) {
        console.error('[sendContactForm]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
