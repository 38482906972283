import { FC, ReactElement } from 'react';

import trans from '../../helpers/trans';
import { IconButton } from '..';

import './ChevronNavigation.scss';

interface ChevronNavigationProps {
    isPrevButtonDisabled?: boolean;
    isNextButtonDisabled?: boolean;
    onClick: (value: -1 | 1) => void;
    className?: string;
}

const ChevronNavigation: FC<ChevronNavigationProps> = ({
    isPrevButtonDisabled,
    isNextButtonDisabled,
    onClick,
    className = '',
}): ReactElement => {
    const handlePrevButtonClick = (): void => onClick(-1);
    const handleNextButtonClick = (): void => onClick(1);

    return (
        <div className={`chevron-navigation ${className}`}>
            <IconButton
                disabled={isPrevButtonDisabled}
                hideLabel
                icon="chevron-bold-left"
                text={trans('common.prev')}
                onClick={handlePrevButtonClick}
                className="chevron-navigation__button"
                iconClassName="chevron-navigation__icon"
            />
            <IconButton
                disabled={isNextButtonDisabled}
                hideLabel
                icon="chevron-bold-right"
                text={trans('common.next')}
                onClick={handleNextButtonClick}
                className="chevron-navigation__button"
                iconClassName="chevron-navigation__icon"
            />
        </div>
    );
};

export default ChevronNavigation;
