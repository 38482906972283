import { FC, ReactElement } from 'react';

import { KeyFigure, Section } from '../../../components';
import { SectionWrapper } from '../../../compositions';
import { KeyFiguresInterface } from '../../../entities/@sections/KeyFigures/KeyFigures';

import './KeyFigures.scss';

interface KeyFiguresProps extends KeyFiguresInterface {
    className?: string;
}

const KeyFigures: FC<KeyFiguresProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    isAnimated,
    figures,
    className = '',
}): ReactElement => (
    <Section isDark={isDark} className={`key-figures ${className}`}>
        <SectionWrapper
            noSpacingTop={noSpacingTop}
            noSpacingBottom={noSpacingBottom}
            className="key-figures__wrapper"
        >
            <ul className="key-figures__list">
                {figures.map(figure => (
                    <li key={figure.label} className="key-figures__item">
                        <KeyFigure
                            {...figure}
                            isAnimated={isAnimated}
                        />
                    </li>
                ))}
            </ul>
        </SectionWrapper>
    </Section>
);

export default KeyFigures;
