import { FC, ReactElement } from 'react';

import { NewsletterCallToAction } from '../../containers';
import { NewsletterCallToActionInterface } from '../../entities/@sections/NewsletterCallToAction/NewsletterCallToAction';
import { useTypedSelector } from '../../redux/store';

interface ConnectedNewsletterCallToActionProps extends NewsletterCallToActionInterface {
    className?: string;
}

const ConnectedNewsletterCallToAction: FC<ConnectedNewsletterCallToActionProps> = ({
    className = '',
    ...newsletterCallToActionProps
}): ReactElement => {
    const globalSettings = useTypedSelector(state => state.globalSettingsReducer.settings);

    return (
        <NewsletterCallToAction
            {...newsletterCallToActionProps}
            newsletterPath={globalSettings.newsletterLink?.path || ''}
            className={className}
        />
    );
};

export default ConnectedNewsletterCallToAction;
