export enum LinkTargetResource {
    blank = 'blank',
    self = 'self',
}

export interface LinkResource {
    id: string;
    link: string;
    label: string;
    target?: LinkTargetResource;
}

export enum LinkTarget {
    blank = '_blank',
    self = '_self',
}

export interface Link {
    label: string;
    path: string;
    target?: LinkTarget;
}
