import { Entity } from '../../types';
import { Image, MediaEntityResource } from '../Media/Media';

export enum SeoMetaSocialTypeResource {
    facebook = 'Facebook',
    twitter = 'Twitter',
}

export interface SeoMetaSocialResource {
    id: string;
    socialNetwork: SeoMetaSocialTypeResource;
    title: string;
    description: string;
    image?: Entity<MediaEntityResource>;
}

export interface SeoResource {
    id: string;
    metaTitle: string;
    metaDescription: string;
    metaImage?: Entity<MediaEntityResource>;
    metaSocial?: SeoMetaSocialResource[];
    keywords?: string;
    metaRobots?: string;
    structuredData: unknown;
    metaViewport?: string;
    canonicalURL?: string;
}

export enum SeoMetaSocialType {
    facebook = 'facebook',
    twitter = 'twitter',
}

export interface SeoMetaSocial {
    title: string;
    description: string;
    image?: Image;
}

export interface Seo {
    title: string;
    description: string;
    image?: Image;
    keywords: string;
    canonicalURL: string;
    structuredData?: string;
    social: Partial<Record<SeoMetaSocialType, SeoMetaSocial>>;
}
