/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '..';

import './Logo.scss';

interface LogoProps {
    className?: string;
}

const Logo: FC<LogoProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={1000} viewBoxHeight={1000} className={`logo ${className}`}>
        <path className="logo__background" d="M1000.1-0.3H0.1v1000h1000V-0.3z" />

        <path className="logo__black" d="M374.2,324.7h-267c-18.8,0-33,15.2-33,33c0,18.8,15.2,33,33,33h100v250.9c0,18.8,15.2,33,33,33c18.7,0,33-15.2,33-33v-250h100c18.8,0,33-15.2,33-33C408.1,339.9,392.1,324.7,374.2,324.7z" />
        <path className="logo__white" d="M591.2,674.7c-8,0-17-2.7-23.2-9.8L399.2,499.7l167.9-166.1c13.4-12.5,33.9-12.5,47.3,0c13.4,12.5,12.5,33.9-0.9,47.3L493.8,499.7l119.6,117.9c13.4,12.5,13.4,33.9,0.9,47.3C608.1,671.1,599.2,674.7,591.2,674.7z" />
        <path className="logo__black" d="M874.2,324.7c-18.7,0-33,15.2-33,33v108h-183c-18.7,0-33,15.2-33,33c0,18.8,15.2,33,33,33h183.9v109.8c0,18.8,15.2,33,33,33c18.7,0,33-15.2,33-33v-283C907.2,339.9,892.9,324.7,874.2,324.7z" />
    </Svg>
);

export default Logo;
/* eslint-enable max-len */
