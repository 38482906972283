import { CSSProperties, FC, ReactElement } from 'react';

import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import { NavLinkClassName } from '../../../../types';

import './NavigationItem.scss';

interface NavigationItemProps {
    style?: CSSProperties;
    tabIndex: number;
    to: string;
    label: string;
    onClick?: () => void;
    className?: string;
}

const NavigationItem: FC<NavigationItemProps> = ({
    style,
    tabIndex,
    to = '',
    label,
    onClick,
    className = '',
}): ReactElement => {
    const navLinkClassNames: NavLinkClassName = ({ isActive }): string => classNames('navigation-item__link', {
        'navigation-item__link--is-active': isActive,
    });

    return (
        <li style={style} className={`navigation-item ${className}`}>
            <NavLink
                end
                to={to}
                tabIndex={tabIndex ? -1 : 0}
                onClick={onClick}
                className={navLinkClassNames}
            >
                <span className="navigation-item__label">
                    {label}
                </span>
            </NavLink>
        </li>
    );
};

export default NavigationItem;
