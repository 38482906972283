import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { transformToLink } from '../../Link/LinkTransformers';
import { PageSection } from '../../PageSection/PageSection';
import {
    HighlightedImpactCardCardInterface,
    HighlightedImpactCardCardResource,
    HighlightedImpactCardInterface,
    HighlightedImpactCardResource,
} from './HighlightedImpactCard';

const transformToHighlightedImpactCardCard = (resource: HighlightedImpactCardCardResource): HighlightedImpactCardCardInterface => ({
    caption: resource.text || '',
    title: resource.title,
});

export const transformToHighlightedImpactCard = (resource: HighlightedImpactCardResource): HighlightedImpactCardInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    const link = resource.link
        ? transformToLink(resource.link)
        : undefined;

    const card = transformToHighlightedImpactCardCard(resource.card);

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.highlightedImpactCard,
        intro: resource.intro,
        paragraphHtml: resource.paragraph || '',
        link,
        card,
    };
};
