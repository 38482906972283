import { PressReleaseFormData } from '../../entities/@forms/PressReleaseForm/PressReleaseForm';
import { sendPressReleaseFormEmail } from '../../entities/@forms/PressReleaseForm/PressReleaseFormService';
import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { TypedDispatch } from '../store';
import { addNegativeToast, addPositiveToast } from '../toast/toastActions';
import { setIsLoading, setIsSuccessful } from './pressReleaseFormReducer';

export const sendPressReleaseForm = (formData: PressReleaseFormData) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));

    try {
        const response = await sendPressReleaseFormEmail(formData);

        if (!isFetchResultSuccessful(response)) {
            console.error('[sendPressReleaseForm]', response.error.description);

            dispatch(addNegativeToast(response.error));

            return;
        }

        dispatch(setIsSuccessful(true));
        dispatch(addPositiveToast(response.data));
    } catch (error) {
        console.error('[sendPressReleaseForm]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
