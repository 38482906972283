import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import trans from '../../../../../helpers/trans';
import { camelCaseMarkerType } from '../../helpers';
import { MarkerType } from '../WorldMap/WorldMap';

import './FilterButton.scss';

interface FilterButtonProps {
    isActive?: boolean;
    type: MarkerType;
    onClick: (type: MarkerType) => void;
    departmentClassName?: string;
    className?: string;
}

const FilterButton: FC<FilterButtonProps> = ({
    isActive,
    type,
    onClick,
    className = '',
    departmentClassName = '',
}): ReactElement => {
    const buttonClassName = classNames('filter-button__input', {
        [`filter-button--${type.replace(/\s/g, '-')}`]: type,
    }, departmentClassName);

    return (
        <label className={`filter-button ${className}`}>
            <input
                type="checkbox"
                onClick={() => onClick(type)}
                checked={isActive}
                className={buttonClassName}
            />
            {trans(`containers.interactiveMap.departments.${camelCaseMarkerType(type)}`)}
        </label>
    );
};

export default FilterButton;
