import {
    DefaultComponent,
    DefaultComponentAppearance,
    DefaultComponentAppearanceResource,
    DefaultComponentResource,
} from '../../Component/Component';

export enum IllustratedContentBridgeIllustrationResource {
    connectivity = 'Connectivity',
    manufacturing = 'Manufacturing',
    manufacturingAlternative = 'Manufacturing alternative',
    vision = 'Vision',
    visionAlternative = 'Vision alternative',
    sustainability = 'Sustainability',
    people = 'People',
    innovation = 'Innovation',
}

export interface IllustratedContentBridgeResource extends DefaultComponentResource, DefaultComponentAppearanceResource {
    id: string;
    title: string;
    paragraph?: string;
    illustration?: IllustratedContentBridgeIllustrationResource;
}

export enum IllustratedContentBridgeIllustration {
    connectivity = 'connectivity',
    manufacturing = 'manufacturing',
    manufacturingAlternative = 'manufacturing-alternative',
    vision = 'vision',
    visionAlternative = 'vision-alternative',
    sustainability = 'sustainability',
    people = 'people',
    innovation = 'innovation',
}

export interface IllustratedContentBridgeInterface extends DefaultComponent, DefaultComponentAppearance {
    titleHtml: string;
    paragraph: string;
    illustration: IllustratedContentBridgeIllustration;
}
