import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Picture, Section, ThemeTitle } from '../../../components';
import { SectionWrapper } from '../../../compositions';
import { IllustratedContentBridgeInterface } from '../../../entities/@sections/IllustratedContentBridge/IllustratedContentBridge';
import useTrans from '../../../hooks/useTrans';

import './IllustratedContentBridge.scss';

interface IllustratedContentBridgeProps extends IllustratedContentBridgeInterface {
    className?: string;
    pictureClassName?: string;
}

const IllustratedContentBridge: FC<IllustratedContentBridgeProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    titleHtml,
    paragraph,
    illustration,
    className = '',
    pictureClassName = '',
}): ReactElement => {
    const trans = useTrans();

    const illustratedContentBridgeClassNames = classNames('illustrated-content-bridge', {
        [`illustrated-content-bridge--is-${illustration}-type`]: illustration,
    }, className);

    return (
        <Section isDark={isDark} className={illustratedContentBridgeClassNames}>
            <SectionWrapper
                noSpacingTop={noSpacingTop}
                noSpacingBottom={noSpacingBottom}
                className="illustrated-content-bridge__wrapper"
            >
                {illustration && (
                    <div className="illustrated-content-bridge__picture-wrapper">
                        <Picture
                            src={`/images/illustrated-content-bridge/${illustration}.svg`}
                            alt={trans(`containers.illustratedContentBridge.imageAlts.${illustration}`)}
                            className={`illustrated-content-bridge__picture ${pictureClassName}`}
                            imageClassName="illustrated-content-bridge__image"
                        />
                    </div>
                )}

                <div className="illustrated-content-bridge__content">
                    <ThemeTitle
                        titleHtml={titleHtml}
                        className="illustrated-content-bridge__title"
                    />

                    <p className="illustrated-content-bridge__paragraph">
                        {paragraph}
                    </p>
                </div>
            </SectionWrapper>
        </Section>
    );
};

export default IllustratedContentBridge;
