import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { PageSection } from '../../PageSection/PageSection';
import { transformToTag } from '../../Tag/TagTransformers';
import { SolutionsCarouselInterface, SolutionsCarouselResource } from './SolutionsCarousel';

export const transformToSolutionsCarousel = (resource: SolutionsCarouselResource): SolutionsCarouselInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    const tag = resource.tag?.data
        ? transformToTag(resource.tag.data)
        : '';

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.solutionsCarousel,
        title: resource.title,
        tag,
    };
};
