import { FC, memo, ReactElement } from 'react';

import { Skeleton, Wrapper } from '../../../../components';
import { generateIdArray } from '../../../../helpers/array';
import { randomInBetweenValue } from '../../../../helpers/number';

import './PageSectionSkeletons.scss';

interface PageSectionSkeletonsProps {
    className?: string;
}

const PageSectionSkeletons: FC<PageSectionSkeletonsProps> = ({
    className = '',
}): ReactElement => {
    const sections = ['text-columns', 'text-image', 'cta-card-list'];

    const amountOfSections = randomInBetweenValue(4, 6);
    const sectionSkeletons = generateIdArray(amountOfSections).map(id => {
        const randomIndex = Math.floor(Math.random() * sections.length);
        const section = sections[randomIndex];

        return `${id}-${section}`;
    });

    return (
        <div className={`page-section-skeletons ${className}`}>
            <div data-dark="true" className="page-section-skeletons__header">
                <Wrapper className="page-section-skeletons__header-wrapper">
                    <Skeleton className="page-section-skeletons__header-caption" />

                    <div className="page-section-skeletons__header-title-wrapper">
                        <Skeleton className="page-section-skeletons__header-title" />
                        <Skeleton className="page-section-skeletons__header-title" />
                    </div>
                </Wrapper>
            </div>

            <div className="page-section-skeletons__body">
                <Wrapper className="page-section-skeletons__body-wrapper">
                    {sectionSkeletons.map(section => {
                        const sectionType = section.slice(2);

                        if (sectionType === 'text-image') {
                            const amountOfLines = randomInBetweenValue(4, 6);
                            const lineSkeletons = generateIdArray(amountOfLines);

                            return (
                                <div key={section} className="page-section-skeletons__section page-section-skeletons__text-image">
                                    <div className="page-section-skeletons__text-image-text-wrapper">
                                        <Skeleton className="page-section-skeletons__text-image-title" />

                                        <div className="page-section-skeletons__paragraph">
                                            {lineSkeletons.map(line => (
                                                <Skeleton key={line} className="page-section-skeletons__paragraph-line" />
                                            ))}
                                        </div>
                                    </div>

                                    <div className="page-section-skeletons__text-image-image" />
                                </div>
                            );
                        }

                        if (sectionType === 'cta-card-list') {
                            const amountOfCards = randomInBetweenValue(2, 3);
                            const cardSkeletons = generateIdArray(amountOfCards);

                            return (
                                <div key={section} className="page-section-skeletons__section page-section-skeletons__cta-card-list">
                                    {cardSkeletons.map(card => (
                                        <div key={card} className="page-section-skeletons__cta-card">
                                            <Skeleton className="page-section-skeletons__cta-card-title" />

                                            <div className="page-section-skeletons__paragraph">
                                                <Skeleton className="page-section-skeletons__paragraph-line" />
                                                <Skeleton className="page-section-skeletons__paragraph-line" />
                                            </div>

                                            <Skeleton className="page-section-skeletons__cta-card-link" />
                                        </div>
                                    ))}
                                </div>
                            );
                        }

                        const amountOfParagraphs = randomInBetweenValue(2, 4);
                        const paragraphSkeletons = generateIdArray(amountOfParagraphs);

                        return (
                            <div key={section} className="page-section-skeletons__section page-section-skeletons__text-columns">
                                <div className="page-section-skeletons__text-columns-title-wrapper">
                                    <Skeleton className="page-section-skeletons__text-columns-title" />
                                </div>

                                <div className="page-section-skeletons__text-columns-column">
                                    {paragraphSkeletons.map(paragraph => {
                                        const amountOfLines = randomInBetweenValue(4, 6);
                                        const lineSkeletons = generateIdArray(amountOfLines);

                                        return (
                                            <div key={paragraph} className="page-section-skeletons__paragraph">
                                                {lineSkeletons.map(line => (
                                                    <Skeleton key={line} className="page-section-skeletons__paragraph-line" />
                                                ))}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
                </Wrapper>
            </div>
        </div>
    );
};

export default memo(PageSectionSkeletons);
