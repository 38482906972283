import {
    CSSProperties,
    FC,
    ReactElement,
    useEffect,
    useRef,
} from 'react';

import classNames from 'classnames';
import { useToggle } from 'react-use';

import {
    Button,
    Icon,
    KeyFigure,
    Picture,
} from '../../../components';
import { FlipCardInterface } from '../../../entities/@sections/FlipCardList/FlipCardList';
import { ImageFormatSize } from '../../../entities/Media/Media';
import usePreferReducedMotion from '../../../hooks/usePreferReducedMotion';
import useTouchScreen from '../../../hooks/useTouchScreen';

import './FlipCard.scss';

interface FlipCardProps extends FlipCardInterface {
    heightOverride?: number;
    onLoad?: (faceHeight: number) => void;
    className?: string;
}

const FlipCard: FC<FlipCardProps> = ({
    heightOverride,
    title,
    keyFigure,
    frontBody,
    backBody,
    frontImage,
    backImage,
    onLoad,
    className = '',
}): ReactElement => {
    const isTouchScreen = useTouchScreen();
    const prefersReducedMotion = usePreferReducedMotion();

    const [isFlipped, toggleIsFlipped] = useToggle(false);

    const frontRef = useRef<HTMLDivElement>(null);
    const backRef = useRef<HTMLDivElement>(null);

    const handleFlipToFront = (): void => {
        if (isTouchScreen) return;

        toggleIsFlipped(false);
    };

    const handleFlipToBack = (): void => {
        if (isTouchScreen) return;

        toggleIsFlipped(true);
    };

    const handleLoad = (): void => {
        if (!onLoad) return;

        if (frontRef.current && backRef.current) {
            const { height: frontHeight } = frontRef.current.getBoundingClientRect();
            const { height: backHeight } = backRef.current.getBoundingClientRect();

            onLoad(Math.max(frontHeight, backHeight));
        }
    };

    useEffect((): void => {
        handleLoad();
    }, [frontRef, backRef]);

    const flipCardClassNames = classNames('flip-card', {
        'flip-card--prefers-reduced-motion': prefersReducedMotion,
        'flip-card--is-flipped': isFlipped,
    }, className);

    const cssVariables = {
        '--flip-card-height': heightOverride ? `${heightOverride}px` : undefined,
    } as CSSProperties;

    return (
        <div
            style={cssVariables}
            onMouseEnter={handleFlipToBack}
            onFocus={handleFlipToBack}
            onMouseLeave={handleFlipToFront}
            onBlur={handleFlipToFront}
            className={flipCardClassNames}
        >
            <div className="flip-card__flip-wrapper">
                <div className="flip-card__front-wrapper">
                    <div ref={frontRef} className="flip-card__content">
                        <Button
                            text={title}
                            disabled={!isTouchScreen}
                            onClick={toggleIsFlipped}
                            className="flip-card__flip-button"
                        />

                        <KeyFigure
                            {...keyFigure}
                            className="flip-card__key-figure"
                            labelClassName="flip-card__key-figure-label"
                        />

                        <div className="flip-card__body-wrapper">
                            {frontBody && (
                                <p className="flip-card__body">
                                    {frontBody}
                                </p>
                            )}

                            {frontImage && (
                                <Picture
                                    {...frontImage}
                                    loading="eager"
                                    formatOverride={ImageFormatSize.small}
                                    onLoad={handleLoad}
                                    className="flip-card__image"
                                />
                            )}
                        </div>

                        <Icon
                            name="plus"
                            className="flip-card__flip-icon"
                        />
                    </div>
                </div>

                <div className="flip-card__back-wrapper">
                    <div ref={backRef} className="flip-card__content">
                        <Button
                            text={title}
                            disabled={!isTouchScreen}
                            onClick={toggleIsFlipped}
                            className="flip-card__flip-button"
                        />

                        <KeyFigure
                            {...keyFigure}
                            className="flip-card__key-figure"
                            labelClassName="flip-card__key-figure-label"
                        />

                        <div className="flip-card__body-wrapper">
                            {backBody && (
                                <p className="flip-card__body">
                                    {backBody}
                                </p>
                            )}

                            {backImage && (
                                <Picture
                                    {...backImage}
                                    loading="eager"
                                    formatOverride={ImageFormatSize.small}
                                    onLoad={handleLoad}
                                    className="flip-card__image"
                                />
                            )}
                        </div>

                        <Icon
                            name="cross"
                            className="flip-card__flip-icon flip-card__flip-icon--is-back"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FlipCard;
