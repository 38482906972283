import { FC, useMemo } from 'react';

import { Route, Routes } from 'react-router-dom';

import { AppRoute } from './constants/routing';
import { useLocale } from './context/LocaleContext';
import useTrans from './hooks/useTrans';
import {
    ArticleDetailPage,
    CustomPage,
    SearchResultsPage,
    SolutionDetailPage,
} from './pages';

export interface RouteData<T = string> {
    key: T;
    label: string;
    path: string;
}

const routes = [
    { key: AppRoute.home, element: <CustomPage /> },
    { key: AppRoute.article, element: <ArticleDetailPage /> },
    { key: AppRoute.solution, element: <SolutionDetailPage /> },
    { key: AppRoute.custom, element: <CustomPage /> },
    { key: AppRoute.searchResults, element: <SearchResultsPage /> },
];

const LocalizedRoutes: FC = () => {
    const { language } = useLocale();
    const trans = useTrans();

    // Get route paths and labels from translations, based on active language
    const localizedRoutes = useMemo(() => routes.map(route => {
        const routeData = route.key === AppRoute.custom
            ? { path: '*', label: '' }
            : {
                path: trans(`${route.key}.path`),
                label: trans(`${route.key}.label`),
            };

        return {
            ...route,
            ...routeData,
        };
    }), [language]);

    return (
        <Routes>
            {localizedRoutes.map(route => <Route {...route} key={route.key} />)}
        </Routes>
    );
};

export default LocalizedRoutes;
