import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../Component/ComponentTransformers';
import { transformToDateIsoString } from '../Date/DateTransformers';
import { transformToLink } from '../Link/LinkTransformers';
import { transformToImage } from '../Media/MediaTransformers';
import {
    HeaderInterface,
    HeaderLayout,
    HeaderLayoutResource,
    HeaderResource,
} from './HeaderInterface';

export const transformToHeader = (resource: HeaderResource): HeaderInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    const images = resource.image?.data?.length
        ? resource.image.data.map(transformToImage)
        : [];

    const layout = resource.layout === HeaderLayoutResource.floatingImage
        ? HeaderLayout.floatingImage
        : HeaderLayout.fullScreen;

    const link = resource.link
        ? transformToLink(resource.link)
        : undefined;

    const links = resource.links?.length
        ? resource.links.map(transformToLink)
        : undefined;

    const date = resource.date
        ? transformToDateIsoString(resource.date)
        : undefined;

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        isAnimated: resource.isAnimated,
        isFullScreen: resource.isFullScreen,
        images,
        layout,
        titleHtml: resource.title,
        caption: resource.caption,
        link,
        links,
        location: resource.location,
        date,
    };
};
