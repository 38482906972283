import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { useWindowSize } from 'react-use';

import { generateIdArray } from '../../helpers/array';
import { stringIsNumber } from '../../helpers/number';

import './GridLines.scss';

interface GridLinesProps {
    className?: string;
}

const GridLines: FC<GridLinesProps> = ({ className = '' }): ReactElement => {
    const { width } = useWindowSize();

    const [columns, setColumns] = useState<number>(0);

    useEffect((): void => {
        const rootStyle = getComputedStyle(document.documentElement);
        const configuredColumns = rootStyle.getPropertyValue('--grid-columns');
        const columnAmount = stringIsNumber(configuredColumns)
            ? Number(configuredColumns)
            : 0;

        setColumns(columnAmount);
    }, [width]);

    const gridColumns = generateIdArray(columns);

    return (
        <ul className={`grid-lines ${className}`}>
            {gridColumns.map(column => (
                <li key={column} className="grid-lines__column" />
            ))}
        </ul>
    );
};

export default GridLines;
