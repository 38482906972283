import {
    forwardRef,
    ForwardRefExoticComponent,
    HTMLAttributes,
    PropsWithChildren,
    ReactElement,
    Ref,
    RefAttributes,
} from 'react';

import classNames from 'classnames';

import './Wrapper.scss';

export interface WrapperProps extends HTMLAttributes<HTMLDivElement> {
    hideGrid?: boolean;
    id?: string;
    className?: string;
}

type WrapperWithRefProps = WrapperProps & RefAttributes<HTMLDivElement>;

const Wrapper: ForwardRefExoticComponent<PropsWithChildren<WrapperWithRefProps>> = forwardRef(({
    hideGrid,
    id,
    className = '',
    children,
    ...wrapperProps
}, ref: Ref<HTMLDivElement>): ReactElement => {
    const wrapperClassName = classNames('wrapper', {
        'wrapper--hide-grid': hideGrid,
    }, className);

    return (
        <div
            {...wrapperProps}
            id={id}
            ref={ref}
            className={wrapperClassName}
        >
            {children}
        </div>
    );
});

export default Wrapper;
