import { FC, ReactElement } from 'react';

import { LinkButton, Picture } from '../../../../../components';
import { ImageFormatSize } from '../../../../../entities/Media/Media';
import { Person } from '../../../../../entities/Person/Person';

import './ContactPersonCard.scss';

export interface ContactPersonCardProps {
    person: Person;
    className?: string;
}

const ContactPersonCard: FC<ContactPersonCardProps> = ({
    person,
    className = '',
}): ReactElement => (
    <div className={`contact-person-card ${className}`}>
        {person.image && (
            <Picture
                hasScaling
                src={person.image.src}
                alt={person.image.alt}
                formats={person.image.formats}
                formatOverride={ImageFormatSize.small}
                className="contact-person-card__portrait"
                imageClassName="contact-person-card__portrait-image"
            />
        )}

        <div className="contact-person-card__meta-wrapper">
            <h3 className="contact-person-card__job-title">
                {person.jobTitle}
            </h3>

            <h4 className="contact-person-card__name">
                {person.name}
            </h4>

            {person.email && (
                <LinkButton
                    to={`mailto:${person.email}`}
                    text={person.email}
                    className="contact-person-card__email"
                />
            )}
        </div>
    </div>
);

export default ContactPersonCard;
