import { FC, ReactElement } from 'react';

import { Section } from '../../../components';
import { GoalCard, SectionWrapper } from '../../../compositions';
import { GoalCardListInterface } from '../../../entities/@sections/GoalCardList/GoalCardList';

import './GoalCardList.scss';

export interface GoalCardListProps extends GoalCardListInterface {
    className?: string;
}

const GoalCardList: FC<GoalCardListProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    title,
    cards,
    className = '',
}): ReactElement => (
    <Section isDark={isDark} className={`goal-card-list ${className}`}>
        <SectionWrapper noSpacingTop={noSpacingTop} noSpacingBottom={noSpacingBottom}>
            {title && (
                <h2 className="goal-card-list__title">
                    {title}
                </h2>
            )}

            <ul className={`goal-card-list__list ${className}`}>
                {cards.map(card => (
                    <li key={card.id} className="goal-card-list__list-item">
                        <GoalCard
                            {...card}
                            className="goal-card-list__card"
                        />
                    </li>
                ))}
            </ul>
        </SectionWrapper>
    </Section>
);

export default GoalCardList;
