import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { PageSearchResult } from '../../../../entities/Search/Search';

import './PageSearchResultItem.scss';

export interface PageSearchResultItemProps extends PageSearchResult {
    navigationGroup?: string;
    className?: string;
}

const PageSearchResultItem: FC<PageSearchResultItemProps> = ({
    navigationGroup = '',
    title,
    description,
    slug,
    className = '',
}): ReactElement => (
    <div className={`page-search-result-item ${className}`}>
        <h2 className="page-search-result-item__title">
            <Link to={slug} className="page-search-result-item__link">
                {title}
            </Link>
        </h2>

        <h3 className="page-search-result-item__category">
            {navigationGroup}
        </h3>

        <p className="page-search-result-item__description">
            {description}
        </p>
    </div>
);

export default PageSearchResultItem;
