import {
    forwardRef,
    ForwardRefExoticComponent,
    HTMLAttributes,
    ReactElement,
    Ref,
    RefAttributes,
} from 'react';

import './HomeQuickLinkContent.scss';

interface HomeQuickLinkContentProps extends HTMLAttributes<HTMLDivElement>, RefAttributes<HTMLDivElement> {
    label?: string;
    className?: string;
}

const HomeQuickLinkContent: ForwardRefExoticComponent<HomeQuickLinkContentProps> = forwardRef(
    ({ label, className = '', ...divProps }, ref: Ref<HTMLDivElement>): ReactElement => (
        <div
            {...divProps}
            ref={ref}
            className={`home-quick-link-content ${className}`}
        >
            <div className="home-quick-link-content__label">
                {label}
            </div>
        </div>
    ),
);

export default HomeQuickLinkContent;
