import {
    CSSProperties,
    FC,
    forwardRef,
    HTMLAttributes,
    MouseEvent,
    ReactElement,
    ReactNode,
    RefAttributes,
    useEffect,
    useRef,
} from 'react';

import { clamp } from '../../helpers/number';
import useDeviceWidth from '../../hooks/useDeviceWidth';
import useElementSize from '../../hooks/useElementSize';

import './Carousel.scss';

interface CarouselProps extends HTMLAttributes<HTMLDivElement>, RefAttributes<HTMLDivElement> {
    activeIndex: number;
    items: ReactNode[];
    slidesLength: number;
    onMobileActiveIndexChange: (activeIndex: number) => void;
    className?: string;
}

const Carousel: FC<CarouselProps> = forwardRef(({
    activeIndex,
    items,
    slidesLength,
    onMobileActiveIndexChange,
    className = '',
}, ref): ReactElement => {
    const slidesRef = useRef<HTMLDivElement>(null);
    const { isMobile } = useDeviceWidth();
    const { width: slideWidth } = useElementSize(slidesRef);

    const handleSlidesWrapperScroll = (event: MouseEvent<HTMLDivElement>): void => {
        const { scrollLeft } = event.currentTarget;
        const scrollAdjustment = -0.5;
        const scrollPosition = (scrollLeft + 1) / slideWidth;
        const adjustedScrollPosition = scrollPosition + scrollAdjustment;

        const roundedPosition = Math.round(adjustedScrollPosition);
        const newIndex = clamp(roundedPosition, 0, slidesLength - 1);

        onMobileActiveIndexChange(newIndex);
    };

    useEffect((): void => {
        if (!ref) {
            return;
        }

        let current: HTMLDivElement | null = null;

        if (typeof ref === 'function') {
            ref(current);
        } else if ('current' in ref) {
            current = ref.current;
        }

        onMobileActiveIndexChange(0);
        current?.scroll({ left: 0 });
    }, [isMobile]);

    const carouselCssVariables = {
        '--carousel-active-index': !isMobile ? activeIndex : 0,
        '--carousel-slide-width': `${slideWidth}px`,
    } as CSSProperties;

    return (
        <div
            ref={ref}
            style={carouselCssVariables}
            onScroll={handleSlidesWrapperScroll}
            className={`carousel ${className}`}
        >
            <div
                ref={slidesRef}
                className="carousel__list-wrapper"
            >
                <ul className="carousel__list">
                    {items.map((item, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <li key={index} className="carousel__list-item">
                            {item}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
});

export default Carousel;
