import { AnchorHTMLAttributes, FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { Icon } from '../../../components';

import './DownloadButton.scss';

export interface DownloadButtonProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
    href: string;
    extension: string;
    className?: string;
}

const DownloadButton: FC<DownloadButtonProps> = ({
    href,
    extension,
    className = '',
    ...downloadButtonProps
}): ReactElement => (
    <Link
        {...downloadButtonProps}
        download
        to={href}
        title={extension}
        className={`download-button ${className}`}
    >
        <Icon
            name="download"
            className="download-button__icon"
        />

        {extension}
    </Link>
);

export default DownloadButton;
