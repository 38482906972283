/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconChevronUp: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={17} viewBoxHeight={17} className={className}>
        <path
            d="M 16 11 L 8.5 4 L 1 11"
            className="stroke"
        />
    </Svg>
);

export default IconChevronUp;
/* eslint-enable max-len */
