import { FC, ReactElement } from 'react';

import { Section } from '../../../components';
import { ArticleCardList, ArticleCardListSkeletons, SectionWrapper } from '../../../compositions';
import { Article } from '../../../entities/Article/Article';
import { DefaultComponentAppearance } from '../../../entities/Component/Component';

import './LatestArticles.scss';

interface LatestArticlesProps extends DefaultComponentAppearance {
    isLoading: boolean;
    title: string;
    articles: Article[];
    className?: string;
}

const LatestArticles: FC<LatestArticlesProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    isLoading,
    title,
    articles,
    className = '',
}): ReactElement | null => {
    if (!isLoading && articles.length === 0) {
        return null;
    }

    return (
        <Section isDark={isDark} className={`latest-articles ${className}`}>
            <SectionWrapper
                noSpacingTop={noSpacingTop}
                noSpacingBottom={noSpacingBottom}
                className="latest-articles__wrapper"
            >
                <h2 className="latest-articles__title">
                    {title}
                </h2>

                {isLoading && (
                    <ArticleCardListSkeletons className="latest-articles__list" />
                )}

                {!isLoading && (
                    <ArticleCardList
                        articles={articles}
                        articleCardClassName="latest-articles__article-card"
                        className="latest-articles__list"
                    />
                )}
            </SectionWrapper>
        </Section>
    );
};

export default LatestArticles;
