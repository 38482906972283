import { FC, ReactElement } from 'react';

import {
    ErrorLabel,
    KeyFigure,
    Section,
    Skeleton,
} from '../../../components';
import { SectionWrapper } from '../../../compositions';
import { TellerSharePriceInterface } from '../../../entities/@sections/TellerSharePrice/TellerSharePrice';
import { SharePriceData } from '../../../entities/SharePrice/SharePrice';

import './TellerSharePrice.scss';

interface TellerSharePriceProps extends TellerSharePriceInterface {
    isLoading: boolean;
    sharePrice?: SharePriceData;
    error?: string;
    className?: string;
}

const TellerSharePrice: FC<TellerSharePriceProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    isLoading,
    title,
    paragraph,
    sharePrice,
    error,
    className = '',
}): ReactElement => (
    <Section isDark={isDark} className={`teller-share-price ${className}`}>
        <SectionWrapper noSpacingTop={noSpacingTop} noSpacingBottom={noSpacingBottom}>
            <div className="teller-share-price__wrapper">
                <section className="teller-share-price__content-wrapper">
                    <h2 className="teller-share-price__title">
                        {title}
                    </h2>

                    <p className="teller-share-price__paragraph">
                        {paragraph}
                    </p>
                </section>

                {isLoading && (
                    <div className="teller-share-price__figure">
                        <div className="teller-share-price__skeleton-wrapper">
                            <Skeleton className="teller-share-price__skeleton-amount" />
                            <Skeleton className="teller-share-price__skeleton-label" />
                        </div>
                    </div>
                )}

                {!isLoading && !!error && (
                    <div className="teller-share-price__figure">
                        <ErrorLabel text={error} />
                    </div>
                )}

                {!isLoading && sharePrice && (
                    <KeyFigure
                        isAnimated
                        amount={sharePrice.value}
                        label={sharePrice.date}
                        className="teller-share-price__figure"
                    />
                )}
            </div>
        </SectionWrapper>
    </Section>
);

export default TellerSharePrice;

