import { FC, ReactElement } from 'react';

import { Section } from '../../../components';
import { ContentColumn, LinkedInCard, SectionWrapper } from '../../../compositions';
import { ConnectedContactForm } from '../../../connectors';
import { SolutionDetailBodyInterface } from '../../../entities/@sections/SolutionDetailBody/SolutionDetailBody';

import './SolutionDetailBody.scss';

interface SolutionDetailBodyProps extends SolutionDetailBodyInterface {
    linkedInPath: string;
    className?: string;
}

const SolutionDetailBody: FC<SolutionDetailBodyProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    intro,
    bodyHtml,
    linkedInLabel,
    linkedInPath,
    className = '',
}): ReactElement => (
    <Section isDark={isDark} className={`solution-detail-body ${className}`}>
        <SectionWrapper
            noSpacingTop={noSpacingTop}
            noSpacingBottom={noSpacingBottom}
            className="solution-detail-body__wrapper"
        >
            <ContentColumn
                introHtml={`<p>${intro}</p>`}
                paragraphHtml={bodyHtml}
                className="solution-detail-body__content-column"
            />

            <div className="solution-detail-body__cards-wrapper">
                <ConnectedContactForm className="solution-detail-body__contact-card" />

                <LinkedInCard
                    link={{
                        label: linkedInLabel,
                        path: linkedInPath,
                    }}
                    className="solution-detail-body__linkedin-card"
                />
            </div>
        </SectionWrapper>
    </Section>
);

export default SolutionDetailBody;
