import { generateUuid } from '../../helpers/string';
import { Address, AddressEntityResource } from './Address';

export const transformToAddress = (resource: AddressEntityResource): Address => ({
    id: resource.id || resource.attributes?.name || generateUuid(),
    name: resource.attributes?.name || '',
    streetAddress: resource.attributes?.streetAddress || '',
    city: resource.attributes?.city || '',
    postalCode: resource.attributes?.zipCode || '',
    country: resource.attributes?.country || '',
    phone: resource.attributes?.phone || '',
});
