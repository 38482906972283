import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type PressReleaseFormState = AsyncReduxState;

const initialState: PressReleaseFormState = {
    ...initialAsyncReduxState,
};

const pressReleaseFormSlice = createSlice({
    name: 'pressReleaseFormReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): PressReleaseFormState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): PressReleaseFormState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): PressReleaseFormState {
            return {
                ...state,
                error: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsSuccessful,
    setError,
} = pressReleaseFormSlice.actions;

export default pressReleaseFormSlice;
