import { FC, ReactElement } from 'react';

import {
    IconArrowLeft,
    IconArrowRight,
    IconAt,
    IconBin,
    IconCheck,
    IconChevronBoldLeft,
    IconChevronBoldRight,
    IconChevronDown,
    IconChevronLeft,
    IconChevronRight,
    IconChevronUp,
    IconCloseCircle,
    IconClosedCaptions,
    IconCross,
    IconDownload,
    IconFacebook,
    IconHamburger,
    IconInstagram,
    IconLink,
    IconLinkedIn,
    IconMail,
    IconMinus,
    IconMute,
    IconPause,
    IconPlay,
    IconPlus,
    IconSearch,
    IconUnmute,
    IconVisibilityOff,
    IconVisibilityOn,
    IconX,
} from './set';

import './Icon.scss';

export interface SvgIconProps {
    className?: string;
}

const icons = {
    'arrow-left': IconArrowLeft,
    'arrow-right': IconArrowRight,
    at: IconAt,
    bin: IconBin,
    check: IconCheck,
    'chevron-bold-left': IconChevronBoldLeft,
    'chevron-bold-right': IconChevronBoldRight,
    'chevron-down': IconChevronDown,
    'chevron-left': IconChevronLeft,
    'chevron-right': IconChevronRight,
    'chevron-up': IconChevronUp,
    'close-circle': IconCloseCircle,
    'closed-captions': IconClosedCaptions,
    cross: IconCross,
    download: IconDownload,
    facebook: IconFacebook,
    hamburger: IconHamburger,
    instagram: IconInstagram,
    link: IconLink,
    'linked-in': IconLinkedIn,
    mail: IconMail,
    minus: IconMinus,
    mute: IconMute,
    pause: IconPause,
    play: IconPlay,
    plus: IconPlus,
    search: IconSearch,
    unmute: IconUnmute,
    'visibility-off': IconVisibilityOff,
    'visibility-on': IconVisibilityOn,
    x: IconX,
};

export type IconName = keyof typeof icons;

interface IconProps extends SvgIconProps {
    name: IconName;
}

const Icon: FC<IconProps> = ({
    name,
    className = '',
}): ReactElement | null => {
    const IconComponent = icons[name];

    return IconComponent
        ? <IconComponent className={`icon ${className}`} />
        : null;
};

export default Icon;
