import { transformToHorizontalAlignment } from '../../Alignment/AlignmentTransformers';
import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { transformToImage } from '../../Media/MediaTransformers';
import { PageSection } from '../../PageSection/PageSection';
import {
    VisualCarouselInterface,
    VisualCarouselResource,
    VisualCarouselSlideInterface,
    VisualCarouselSlideResource,
} from './VisualCarousel';

const transformToVisualCarouselSlide = (resource: VisualCarouselSlideResource): VisualCarouselSlideInterface => {
    const align = transformToHorizontalAlignment(resource.align);

    const image = resource.image?.data
        ? transformToImage(resource.image.data)
        : undefined;

    return {
        id: resource.id,
        align,
        title: resource.title,
        intro: resource.intro || '',
        paragraph: resource.paragraph || '',
        label: resource.label || '',
        image,
    };
};

export const transformToVisualCarousel = (resource: VisualCarouselResource): VisualCarouselInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    const slides = resource.slides.map(transformToVisualCarouselSlide);

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.visualCarousel,
        slides,
    };
};
