export const range = (start = 0, end = start + 1, step = 1): number[] => {
    const output = [];

    for (let i = start; i < end; i += step) {
        output.push(i);
    }

    return output;
};

export const generateIdArray = (amount: number): number[] => range(0, amount);

export const retrieveUniqueValues = <T>(array: T[]): T[] => (
    Array.from(new Set(array))
);

type PaginatedArray<T> = T[][];

export const paginateArray = <T>(array: T[], pageSize: number): PaginatedArray<T> => array.reduce((
    pages: PaginatedArray<T>,
    item: T,
    index: number,
) => {
    const pageIndex = Math.floor(index / pageSize);
    const currentPage = pages[pageIndex] ? [...pages[pageIndex], item] : [item];

    return [
        ...pages.slice(0, pageIndex),
        currentPage,
        ...pages.slice(pageIndex + 1),
    ];
}, []);
