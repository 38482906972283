import { transformToArticleCategory } from '../../ArticleCategory/ArticleCategoryTransformers';
import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { PageSection } from '../../PageSection/PageSection';
import { ArticlesOverviewInterface, ArticlesOverviewResource } from './ArticlesOverview';

export const transformToArticlesOverview = (resource: ArticlesOverviewResource): ArticlesOverviewInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    const categories = resource.article_categories?.data
        ? resource.article_categories?.data.map(transformToArticleCategory)
        : [];

    const defaultStartYear = new Date().getFullYear() - 10;

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.articlesOverview,
        categories,
        startYear: resource.startYear || defaultStartYear,
    };
};
