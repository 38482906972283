import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { PageSection } from '../../PageSection/PageSection';
import { transformToShareholderDocumentTableRow } from '../ShareholderDocuments/ShareholderDocumentsTransformers';
import {
    ShareholderMeetingsArchiveInterface,
    ShareholderMeetingsArchiveResource,
    ShareholderMeetingsArchiveYearInterface,
    ShareholderMeetingsArchiveYearResource,
} from './ShareholderMeetingsArchive';

const transformToShareholderMeetingsArchiveYear = (resource: ShareholderMeetingsArchiveYearResource): ShareholderMeetingsArchiveYearInterface => {
    const tableRows = resource.tableRows
        ? resource.tableRows.map(transformToShareholderDocumentTableRow)
        : [];

    return {
        year: resource.year,
        intro: resource.intro,
        paragraphHtml: resource.paragraph || '',
        tableRows,
    };
};

export const transformToShareholderMeetingsArchive = (resource: ShareholderMeetingsArchiveResource): ShareholderMeetingsArchiveInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    const years = resource.years
        ? resource.years.map(transformToShareholderMeetingsArchiveYear)
        : [];

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.shareholderMeetingsArchive,
        title: resource.title || '',
        years,
    };
};
