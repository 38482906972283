import { transformToPageRelationLink } from '../Page/PageTransformers';
import { GlobalSettings, GlobalSettingsResponse } from './GlobalSettings';

export const transformToGlobalSettings = (response: GlobalSettingsResponse): GlobalSettings => {
    const globalSettingsData = response.data?.attributes;

    const contactLink = globalSettingsData?.contact?.data?.attributes
        ? transformToPageRelationLink(globalSettingsData.contact.data.attributes)
        : undefined;

    const cookieLink = globalSettingsData?.cookie?.data?.attributes
        ? transformToPageRelationLink(globalSettingsData.cookie.data.attributes)
        : undefined;

    const newsletterLink = globalSettingsData?.newsLetter?.data?.attributes
        ? transformToPageRelationLink(globalSettingsData.newsLetter.data.attributes)
        : undefined;

    return {
        linkedInUrl: globalSettingsData?.linkedinUrl || '',
        contactLink,
        cookieLink,
        newsletterLink,
    };
};
