import {
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import { useDebounce } from 'react-use';
import { noop } from 'react-use/lib/misc/util';

import { HighlightedPage } from '../../../compositions';
import { PageCallToActionInterface } from '../../../entities/@sections/PageCallToAction/PageCallToAction';
import { gsap, ScrollTrigger } from '../../../entities/Gsap/GsapService';
import useDocumentScrollSize from '../../../hooks/useDocumentScrollSize';
import usePreferReducedMotion from '../../../hooks/usePreferReducedMotion';

interface HighlightedPageSceneProps extends PageCallToActionInterface {
    className?: string;
}

const HighlightedPageScene: FC<HighlightedPageSceneProps> = ({ className = '', ...contentProps }): ReactElement => {
    const preferReducedMotion = usePreferReducedMotion();
    const { height: documentScrollHeight } = useDocumentScrollSize();

    const highlightSectionRef = useRef<HTMLDivElement>(null);
    const timeline = useRef<gsap.core.Timeline | null>(null);

    const [parallaxProgress, setParallaxProgress] = useState<number>(0);

    const imageOffset = -100 + (parallaxProgress * 100);

    useEffect((): () => void => {
        if (!highlightSectionRef.current || preferReducedMotion) {
            return noop;
        }

        const gsapParallaxInScene = gsap.timeline({
            scrollTrigger: {
                invalidateOnRefresh: true,
                trigger: highlightSectionRef.current,
                end: () => `+=${window.innerHeight}`,
                onUpdate: event => {
                    setParallaxProgress(event.progress);
                },
            },
        });

        const gsapParallaxOutScene = gsap.timeline({
            scrollTrigger: {
                invalidateOnRefresh: true,
                trigger: highlightSectionRef.current,
                start: self => `+=${(self.trigger?.clientHeight || 0) * 2}`,
                end: () => `+=${window.innerHeight}`,
                onUpdate: event => {
                    setParallaxProgress(event.progress + 1);
                },
            },
        });

        const pinScene = gsap.timeline({
            scrollTrigger: {
                invalidateOnRefresh: true,
                pin: true,
                trigger: highlightSectionRef.current,
                end: () => `+=${window.innerHeight * 2}`,
                onUpdate: event => {
                    const tl = timeline.current;

                    if (tl) {
                        gsap.killTweensOf(tl);
                        gsap.to(tl, { time: event.progress * tl.totalDuration(), duration: 0 });
                    }
                },
            },
        });

        return () => {
            gsapParallaxInScene.kill();
            gsapParallaxOutScene.kill();
            pinScene.kill();
        };
    }, [timeline]);

    useEffect((): () => void => {
        timeline.current = gsap.timeline({ paused: true });

        return () => {
            timeline.current?.kill();
        };
    }, []);

    useDebounce((): void => {
        if (documentScrollHeight) {
            // ScrollTrigger only updates on a window resize. So we have to manually trigger refresh
            // when the document height changes.
            ScrollTrigger.refresh();
        }
    }, 100, [documentScrollHeight]);

    return (
        <HighlightedPage
            {...contentProps}
            isAnimationDisabled={preferReducedMotion}
            ref={highlightSectionRef}
            imageOffset={preferReducedMotion ? 0 : imageOffset}
            timeline={timeline.current || undefined}
            className={className}
        />
    );
};

export default HighlightedPageScene;
