import {
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';
import { Document, Page, pdfjs } from 'react-pdf';

import { DownloadableFile } from '../../entities/Media/Media';
import { isSSR } from '../../helpers';
import useElementSize from '../../hooks/useElementSize';
import useKeyPress from '../../hooks/useKeyPress';
import { PresentationControls } from './subcomponents';

import './PdfReader.scss';

if (!isSSR) {
    pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';
}

interface PdfReaderProps {
    file: DownloadableFile;
    className?: string;
}

const PdfReader: FC<PdfReaderProps> = ({ file, className = '' }): ReactElement => {
    const ref = useRef<HTMLDivElement>(null);

    const [pageNumber, setPageNumber] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [pageHeight, setPageHeight] = useState<number>(0);
    const [isDocumentLoading, setIsDocumentLoading] = useState<boolean>(true);

    const { width: pageWidth } = useElementSize(ref);

    const nextPage = (): void => setPageNumber(Math.min(totalPages, pageNumber + 1));
    const prevPage = (): void => setPageNumber(Math.max(pageNumber - 1, 1));

    const isArrowRightPressed = useKeyPress('ArrowRight');
    const isArrowLeftPressed = useKeyPress('ArrowLeft');

    useEffect((): void => {
        if (isArrowRightPressed) nextPage();
    }, [isArrowRightPressed]);

    useEffect((): void => {
        if (isArrowLeftPressed) prevPage();
    }, [isArrowLeftPressed]);

    const handleDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
        setIsDocumentLoading(false);
        setTotalPages(numPages);
    };

    const handlePageLoadSuccess = (): void => {
        if (ref.current) {
            setPageHeight(ref.current.clientHeight);
        }
    };

    const pdfReaderClassName = classNames('pdf-reader', {
        'pdf-reader--is-loading': isDocumentLoading,
    }, className);

    return (
        <div className={pdfReaderClassName}>
            <div
                // Min height is set to prevent the element from jumping when loading between pages
                style={{ minHeight: `${pageHeight}px`, maxHeight: pageHeight ? `${pageHeight}px` : 'inherit' }}
                className="pdf-reader__wrapper"
            >
                <div ref={ref} className="pdf-reader__sizer">
                    <div className="pdf-reader__document-wrapper">
                        <Document
                            file={file.src}
                            onLoadSuccess={handleDocumentLoadSuccess}
                            className="pdf-reader__document"
                        >
                            <Page
                                renderAnnotationLayer={false}
                                renderTextLayer={false}
                                pageNumber={pageNumber}
                                onRenderSuccess={handlePageLoadSuccess}
                                width={pageWidth}
                                className="pdf-reader__page"
                            />
                        </Document>
                    </div>

                    <PresentationControls
                        pageNumber={pageNumber}
                        totalPages={totalPages}
                        onNextButtonClick={nextPage}
                        onPrevButtonClick={prevPage}
                        className="pdf-reader__controls"
                    />
                </div>
            </div>
        </div>
    );
};

export default PdfReader;
