import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { ShareBuybackLinkInterface } from '../../../../../entities/@sections/ShareBuyback/ShareBuyback';
import useTrans from '../../../../../hooks/useTrans';

import './ShareBuybackLink.scss';

interface ShareBuybackLinkProps extends ShareBuybackLinkInterface {
    className?: string;
}

const ShareBuybackLink: FC<ShareBuybackLinkProps> = ({
    title,
    link,
    file,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const label = file
        ? trans('containers.shareBuyback.file')
        : trans('containers.shareBuyback.link');

    return (
        <li className={`share-buyback-link ${className}`}>
            {link && !file && (
                <h2 className="share-buyback-link__title">
                    <Link to={link.path} className="share-buyback-link__link">
                        {title}
                    </Link>
                </h2>
            )}

            {file && (
                <h2 className="share-buyback-link__title">
                    <Link to={file.src} className="share-buyback-link__link">
                        {title}
                    </Link>
                </h2>
            )}

            {(link || file) && (
                <p className="share-buyback-link__label">
                    {label}
                </p>
            )}
        </li>
    );
};

export default ShareBuybackLink;
