import {
    CSSProperties,
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';
import { useDebounce } from 'react-use';

import { LinkButton, Section } from '../../../components';
import {
    ArticleCard,
    ArticleCardListSkeletons,
    ChevronNavigation,
    SectionWrapper,
} from '../../../compositions';
import { Article } from '../../../entities/Article/Article';
import { DefaultComponentAppearance } from '../../../entities/Component/Component';
import { Link } from '../../../entities/Link/Link';
import { clamp } from '../../../helpers/number';
import useElementSize from '../../../hooks/useElementSize';
import usePreferReducedMotion from '../../../hooks/usePreferReducedMotion';
import useTrans from '../../../hooks/useTrans';

import './ArticlesCarousel.scss';

interface ArticlesCarouselProps extends DefaultComponentAppearance {
    isLoading: boolean;
    title: string;
    articles: Article[];
    link?: Link;
    className?: string;
}

const ArticlesCarousel: FC<ArticlesCarouselProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    isLoading,
    title,
    articles,
    link,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const preferReducedMotion = usePreferReducedMotion();

    const listRef = useRef<HTMLUListElement>(null);

    const [activeIndex, setActiveIndex] = useState(0);
    const [cardWidth, setCardWidth] = useState(0);
    const [isResizing, setIsResizing] = useState(false);

    const { width: listWidth } = useElementSize(listRef, [articles]);

    const childrenPerView = Math.round(listWidth / cardWidth);
    const maxIndex = articles.length - childrenPerView;

    const handleChevronClick = (value = 0): void => {
        if (!listWidth || !cardWidth) return;

        setActiveIndex(clamp(activeIndex + (value * childrenPerView), 0, maxIndex));
    };

    useEffect((): void => {
        const firstChild = listRef.current?.firstChild as HTMLDivElement | null;

        if (firstChild) {
            setCardWidth(firstChild.offsetWidth);
        }

        setIsResizing(true);
    }, [listWidth]);

    useEffect((): void => {
        if (!listWidth || !cardWidth) return;

        setActiveIndex(clamp(activeIndex, 0, maxIndex));
    }, [maxIndex]);

    useDebounce((): void => {
        setIsResizing(false);
    }, 500, [listWidth]);

    const cssVariables = {
        '--carousel-scroll-progress': `${activeIndex * cardWidth}px`,
    } as CSSProperties;

    const articlesCarouselClassNames = classNames('articles-carousel', {
        'articles-carousel--is-animation-disabled': isResizing || preferReducedMotion,
    }, className);

    return (
        <Section
            isDark={isDark}
            style={cssVariables}
            className={articlesCarouselClassNames}
        >
            <SectionWrapper noSpacingTop={noSpacingTop} noSpacingBottom={noSpacingBottom}>
                <h1 className="articles-carousel__title">
                    {title}
                </h1>

                {isLoading && (
                    <ArticleCardListSkeletons
                        amountOfCards={6}
                        className="articles-carousel__list"
                        itemClassName="articles-carousel__item"
                    />
                )}

                {!isLoading && articles.length === 0 && (
                    <div className="articles-carousel__no-results-wrapper">
                        <p className="articles-carousel__no-results">
                            {trans('containers.articlesCarousel.noResults')}
                        </p>
                    </div>
                )}

                {!isLoading && articles.length > 0 && (
                    <ul ref={listRef} className="articles-carousel__list">
                        {articles.map(article => (
                            <li key={article.id} className="articles-carousel__item">
                                <ArticleCard
                                    {...article}
                                    className="articles-carousel__card"
                                />
                            </li>
                        ))}
                    </ul>
                )}

                <div className="articles-carousel__navigation-wrapper">
                    {articles.length > 0 && (
                        <ChevronNavigation
                            isPrevButtonDisabled={isLoading}
                            isNextButtonDisabled={isLoading}
                            onClick={handleChevronClick}
                            className="articles-carousel__navigation"
                        />
                    )}

                    {link && (
                        <LinkButton
                            to={link.path}
                            text={link.label}
                            className="articles-carousel__link"
                        />
                    )}
                </div>
            </SectionWrapper>
        </Section>
    );
};

export default ArticlesCarousel;
