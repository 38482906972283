import { FC, ReactElement } from 'react';

import { Address } from '../../../../../entities/Address/Address';

import './ContactCompany.scss';

interface ContactCompanyProps {
    title: string;
    addresses: Address[];
    className?: string;
}

const ContactCompany: FC<ContactCompanyProps> = ({
    title,
    addresses,
    className = '',
}): ReactElement => (
    <div className={`contact-company ${className}`}>
        <h3 className="contact-company__title">
            {title}
        </h3>

        {addresses.map(address => (
            <address key={address.id} className="contact-company__address">
                <h4 className="contact-company__address-name">
                    {address.name}
                </h4>

                <div className="contact-company__line">
                    {address.streetAddress}
                </div>

                <div className="contact-company__line">
                    {address.postalCode}
                </div>

                <div className="contact-company__line">
                    {address.city}
                </div>

                <div className="contact-company__line">
                    {address.country}
                </div>

                <div className="contact-company__line">
                    {address.phone}
                </div>
            </address>
        ))}
    </div>
);

export default ContactCompany;
