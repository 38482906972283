import { FC, ReactElement, useMemo } from 'react';

import { Wrapper } from '../../components';
import { Article } from '../../entities/Article/Article';
import { Navigation } from '../../entities/Navigation/Navigation';
import { PageSearchResult } from '../../entities/Search/Search';
import { Solution } from '../../entities/Solution/Solution';
import { findNavigationItemByPathname, findPageCategory } from '../../helpers/navigation';
import useTrans from '../../hooks/useTrans';
import { SearchResultsSkeletons } from './skeletons';
import { ArticleSearchResults, PageSearchResults, SolutionSearchResults } from './subcomponents';
import { PageSearchResultItemProps } from './subcomponents/PageSearchResultItem/PageSearchResultItem';

import './SearchResults.scss';

interface SearchResultsProps {
    isLoading: boolean;
    pageResults: PageSearchResult[];
    articleResults: Article[];
    solutionResults: Solution[];
    recommendedPageResults: PageSearchResult[];
    mainNavigation?: Navigation;
    className?: string;
}

const SearchResults: FC<SearchResultsProps> = ({
    isLoading,
    pageResults,
    articleResults,
    solutionResults,
    recommendedPageResults,
    mainNavigation,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const hasNoResults = pageResults.length === 0 && articleResults.length === 0 && solutionResults.length === 0;

    const pageResultsWithCategory = useMemo((): PageSearchResultItemProps[] => (
        pageResults.map(result => {
            const cleanSlug = result.slug.slice(1);

            const slug = mainNavigation
                ? findNavigationItemByPathname(cleanSlug, mainNavigation.list)?.href
                : undefined;

            const navigationGroup = mainNavigation
                ? findPageCategory(cleanSlug, mainNavigation.list)
                : '';

            return {
                ...result,
                slug: slug || result.slug,
                navigationGroup,
            };
        })
    ), [pageResults, mainNavigation]);

    return (
        <div className={`search-results ${className}`}>
            {isLoading && (
                <Wrapper>
                    <SearchResultsSkeletons />
                </Wrapper>
            )}

            {!isLoading && hasNoResults && (
                <Wrapper>
                    <p className="search-results__no-results-description">
                        {trans('containers.searchResults.noResults')}
                    </p>

                    {recommendedPageResults.length > 0 && (
                        <PageSearchResults
                            isRecommended
                            pageResults={recommendedPageResults}
                            className="search-results__no-results-list"
                        />
                    )}
                </Wrapper>
            )}

            {!isLoading && !hasNoResults && (
                <Wrapper>
                    {pageResults.length > 0 && (
                        <PageSearchResults
                            pageResults={pageResultsWithCategory}
                            className="search-results__results"
                        />
                    )}

                    {articleResults.length > 0 && (
                        <ArticleSearchResults
                            articleResults={articleResults}
                            className="search-results__results"
                        />
                    )}

                    {solutionResults.length > 0 && (
                        <SolutionSearchResults
                            solutionResults={solutionResults}
                            className="search-results__results"
                        />
                    )}
                </Wrapper>
            )}
        </div>
    );
};

export default SearchResults;
