import { FC, ReactElement } from 'react';

import { CustomPageHelmet } from '../../containers';
import { useTypedSelector } from '../../redux/store';

const ConnectedCustomPageHelmet: FC = (): ReactElement | null => {
    const pageData = useTypedSelector(state => state.pageReducer.pageData);
    const breadcrumbs = useTypedSelector(state => state.pageReducer.breadcrumbs);

    if (!pageData) {
        return null;
    }

    return (
        <CustomPageHelmet
            breadcrumbs={breadcrumbs}
            metaData={pageData.seo}
        />
    );
};

export default ConnectedCustomPageHelmet;
