import { FC, ReactElement } from 'react';

import {
    ConnectedArticlesCarousel,
    ConnectedArticlesOverview,
    ConnectedCalendar,
    ConnectedContactInvestorRelations,
    ConnectedLatestArticles,
    ConnectedNewsletterCallToAction,
    ConnectedSolutionDetailBody,
    ConnectedSolutionsCarousel,
    ConnectedTellerSharePrice,
    ConnectedVacancyCardList,
} from '../../connectors';
import { AnalystCoverageTableInterface } from '../../entities/@sections/AnalystCoverageTable/AnalystCoverageTable';
import { AnnualDownloadsInterface } from '../../entities/@sections/AnnualDownloads/AnnualDownloads';
import { ArticlesCarouselInterface } from '../../entities/@sections/ArticlesCarousel/ArticlesCarousel';
import { ArticlesOverviewInterface } from '../../entities/@sections/ArticlesOverview/ArticlesOverview';
import { BackgroundIllustrationInterface } from '../../entities/@sections/BackgroundIllustration/BackgroundIllustration';
import { CallToActionCardListInterface } from '../../entities/@sections/CallToActionCardList/CallToActionCardList';
import { CommitteeInterface } from '../../entities/@sections/Committee/Committee';
import { ContactCompanyFormInterface } from '../../entities/@sections/ContactCompanyForm/ContactCompanyForm';
import { ContactInvestorRelationsInterface } from '../../entities/@sections/ContactInvestorRelations/ContactInvestorRelations';
import { ContactPersonFormInterface } from '../../entities/@sections/ContactPersonForm/ContactPersonForm';
import { ContentColumnsInterface } from '../../entities/@sections/ContentColumns/ContentColumns';
import { DisclaimerInterface } from '../../entities/@sections/Disclaimer/Disclaimer';
import { DownloadListInterface } from '../../entities/@sections/DownloadList/DownloadList';
import { FinancialOverviewTableInterface } from '../../entities/@sections/FinancialOverviewTable/FinancialOverviewTable';
import { FinancialResultsTableListInterface } from '../../entities/@sections/FinancialResultsTableList/FinancialResultsTableList';
import { FixedComponentInterface, FixedComponentType } from '../../entities/@sections/FixedComponent/FixedComponent';
import { FlipCardListInterface } from '../../entities/@sections/FlipCardList/FlipCardList';
import { GoalCardListInterface } from '../../entities/@sections/GoalCardList/GoalCardList';
import { HighlightedArticleInterface } from '../../entities/@sections/HighlightedArticle/HighlightedArticle';
import { HighlightedDownloadCardInterface } from '../../entities/@sections/HighlightedDownloadCard/HighlightedDownloadCard';
import { HighlightedImpactCardInterface } from '../../entities/@sections/HighlightedImpactCard/HighlightedImpactCard';
import { HighlightedKeyFigureInterface } from '../../entities/@sections/HighlightedKeyFigure/HighlightedKeyFigure';
import { IllustratedContentBridgeInterface } from '../../entities/@sections/IllustratedContentBridge/IllustratedContentBridge';
import { ImageSectionInterface } from '../../entities/@sections/ImageSection/ImageSection';
import { KeyFiguresInterface } from '../../entities/@sections/KeyFigures/KeyFigures';
import { LatestArticlesInterface } from '../../entities/@sections/LatestArticles/LatestArticles';
import { MapCallToActionInterface } from '../../entities/@sections/MapCallToAction/MapCallToAction';
import { NewsletterCallToActionInterface } from '../../entities/@sections/NewsletterCallToAction/NewsletterCallToAction';
import { NewsletterFormInterface } from '../../entities/@sections/NewsletterForm/NewsletterForm';
import { PageCallToActionInterface } from '../../entities/@sections/PageCallToAction/PageCallToAction';
import { PageIntroInterface } from '../../entities/@sections/PageIntro/PageIntro';
import { ParallaxLineSectionInterface } from '../../entities/@sections/ParallaxLineSection/ParallaxLineSection';
import { PeopleListInterface } from '../../entities/@sections/PeopleList/PeopleList';
import { PresentationInterface } from '../../entities/@sections/Presentation/Presentation';
import { QuickLinksInterface } from '../../entities/@sections/QuickLinks/QuickLinks';
import { SdgCarouselInterface } from '../../entities/@sections/SdgCarousel/SdgCarousel';
import { ShareBuybackInterface } from '../../entities/@sections/ShareBuyback/ShareBuyback';
import { ShareBuybackHistoryTableInterface } from '../../entities/@sections/ShareBuybackHistoryTable/ShareBuybackHistoryTable';
import { ShareholderDocumentsInterface } from '../../entities/@sections/ShareholderDocuments/ShareholderDocuments';
import { ShareholderMeetingsArchiveInterface } from '../../entities/@sections/ShareholderMeetingsArchive/ShareholderMeetingsArchive';
import { ShareholderTableInterface } from '../../entities/@sections/ShareholderTable/ShareholderTable';
import { SolutionDetailBodyInterface } from '../../entities/@sections/SolutionDetailBody/SolutionDetailBody';
import { SolutionsCarouselInterface } from '../../entities/@sections/SolutionsCarousel/SolutionsCarousel';
import { TellerSharePriceInterface } from '../../entities/@sections/TellerSharePrice/TellerSharePrice';
import { TestimonialInterface } from '../../entities/@sections/Testimonial/Testimonial';
import { TestimonialCarouselInterface } from '../../entities/@sections/TestimonialCarousel/TestimonialCarousel';
import { TextColumnsInterface } from '../../entities/@sections/TextColumns/TextColumns';
import { TextImageInterface } from '../../entities/@sections/TextImage/TextImage';
import { ValueCardsInterface } from '../../entities/@sections/ValueCards/ValueCards';
import { VideoSectionInterface } from '../../entities/@sections/VideoSection/VideoSection';
import { VisualCarouselInterface } from '../../entities/@sections/VisualCarousel/VisualCarousel';
import { WebcastInterface } from '../../entities/@sections/Webcast/Webcast';
import { HeaderInterface } from '../../entities/Header/HeaderInterface';
import { Link } from '../../entities/Link/Link';
import { PageSection, PageSectionInterface } from '../../entities/PageSection/PageSection';
import {
    AnalystCoverage,
    AnnualDownloads,
    BackgroundIllustrationSection,
    Committee,
    ContactCompanyForm,
    ContactPersonForm,
    ContentColumns,
    CtaCardList,
    Disclaimer,
    DownloadListSection,
    FinancialOverview,
    FinancialResults,
    FlipCardList,
    GoalCardList,
    HeaderSection,
    HighlightedArticle,
    HighlightedDownloadCard,
    HighlightedImpactCard,
    HighlightedKeyFigure,
    HighlightedPageScene,
    IllustratedContentBridge,
    ImageSection,
    InteractiveMap,
    KeyFigures,
    MapCta,
    NewsletterFormSection,
    PageIntro,
    ParallaxLineSectionScene,
    PeopleListSection,
    Presentation,
    QuickLinksSection,
    SdgCarousel,
    Share,
    ShareBuyback,
    ShareBuybackHistory,
    ShareholderDocuments,
    ShareholderMeetingsArchive,
    Shareholders,
    SharePerformance,
    Testimonial,
    TestimonialsCarousel,
    TextColumns,
    TextImage,
    ValueCardList,
    VideoSection,
    VisualCarousel,
    Webcast,
} from '..';
import { PageSectionSkeletons } from './skeletons';

import './PageSectionRenderer.scss';

interface PageSectionRendererProps {
    isLoading: boolean;
    header?: HeaderInterface;
    breadcrumbs: Link[];
    sections: PageSectionInterface[];
    className?: string;
}

const PageSectionRenderer: FC<PageSectionRendererProps> = ({
    isLoading,
    header,
    breadcrumbs,
    sections,
    className = '',
}): ReactElement => {
    if (isLoading) {
        return (
            <PageSectionSkeletons />
        );
    }

    return (
        <div className={`page-section-renderer ${className}`}>
            {header && (
                <HeaderSection
                    {...header}
                    breadcrumbs={breadcrumbs}
                    className="page-section-renderer__header"
                    headerEndClassName="header-end"
                />
            )}

            {sections.map((section, index) => {
                if (section.type === PageSection.textImage) {
                    return (
                        <TextImage
                            {...section as TextImageInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section"
                        />
                    );
                }

                if (section.type === PageSection.pageIntro) {
                    return (
                        <PageIntro
                            {...section as PageIntroInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section page-section-renderer__section--is-foreground"
                        />
                    );
                }

                if (section.type === PageSection.keyFigures) {
                    return (
                        <KeyFigures
                            {...section as KeyFiguresInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section page-section-renderer__section--is-foreground"
                        />
                    );
                }

                if (section.type === PageSection.articlesCarousel) {
                    return (
                        <ConnectedArticlesCarousel
                            {...section as ArticlesCarouselInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section"
                        />
                    );
                }

                if (section.type === PageSection.parallaxLineSection) {
                    return (
                        <ParallaxLineSectionScene
                            {...section as ParallaxLineSectionInterface}
                            key={section.reactKey}
                            index={index}
                            className="page-section-renderer__section"
                        />
                    );
                }

                if (section.type === PageSection.textColumns) {
                    return (
                        <TextColumns
                            {...section as TextColumnsInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section"
                        />
                    );
                }

                if (section.type === PageSection.contentColumns) {
                    return (
                        <ContentColumns
                            {...section as ContentColumnsInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section"
                        />
                    );
                }

                if (section.type === PageSection.highlightedImpactCard) {
                    return (
                        <HighlightedImpactCard
                            {...section as HighlightedImpactCardInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section page-section-renderer__section--is-foreground"
                        />
                    );
                }

                if (section.type === PageSection.goalCardList) {
                    return (
                        <GoalCardList
                            {...section as GoalCardListInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section"
                        />
                    );
                }

                if (section.type === PageSection.mapCallToAction) {
                    return (
                        <MapCta
                            {...section as MapCallToActionInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section"
                        />
                    );
                }

                if (section.type === PageSection.videoSection) {
                    return (
                        <VideoSection
                            {...section as VideoSectionInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section"
                        />
                    );
                }

                if (section.type === PageSection.pageCallToAction) {
                    return (
                        <HighlightedPageScene
                            {...section as PageCallToActionInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section"
                        />
                    );
                }

                if (section.type === PageSection.visualCarousel) {
                    return (
                        <VisualCarousel
                            {...section as VisualCarouselInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section"
                        />
                    );
                }

                if (section.type === PageSection.quickLinks) {
                    return (
                        <QuickLinksSection
                            {...section as QuickLinksInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section"
                        />
                    );
                }

                if (section.type === PageSection.backgroundIllustration) {
                    return (
                        <BackgroundIllustrationSection
                            {...section as BackgroundIllustrationInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section page-section-renderer__section--is-foreground"
                        />
                    );
                }

                if (section.type === PageSection.shareholderDocuments) {
                    return (
                        <ShareholderDocuments
                            {...section as ShareholderDocumentsInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section"
                        />
                    );
                }

                if (section.type === PageSection.shareholderMeetingsArchive) {
                    return (
                        <ShareholderMeetingsArchive
                            {...section as ShareholderMeetingsArchiveInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section"
                        />
                    );
                }

                if (section.type === PageSection.peopleList) {
                    return (
                        <PeopleListSection
                            {...section as PeopleListInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section"
                        />
                    );
                }

                if (section.type === PageSection.downloadList) {
                    return (
                        <DownloadListSection
                            {...section as DownloadListInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section"
                        />
                    );
                }

                if (section.type === PageSection.committee) {
                    return (
                        <Committee
                            {...section as CommitteeInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section"
                        />
                    );
                }

                if (section.type === PageSection.imageSection) {
                    return (
                        <ImageSection
                            {...section as ImageSectionInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section"
                        />
                    );
                }

                if (section.type === PageSection.valueCards) {
                    return (
                        <ValueCardList
                            {...section as ValueCardsInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section"
                        />
                    );
                }

                if (section.type === PageSection.testimonialCarousel) {
                    return (
                        <TestimonialsCarousel
                            {...section as TestimonialCarouselInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section"
                        />
                    );
                }

                if (section.type === PageSection.fixedComponent) {
                    const { component } = section as FixedComponentInterface;

                    if (component === FixedComponentType.map) {
                        return (
                            <InteractiveMap
                                {...section}
                                key={section.reactKey}
                                className="page-section-renderer__section"
                            />
                        );
                    }

                    if (component === FixedComponentType.share) {
                        return (
                            <Share
                                {...section}
                                key={section.reactKey}
                                className="page-section-renderer__section"
                            />
                        );
                    }

                    if (component === FixedComponentType.sharePerformance) {
                        return (
                            <SharePerformance
                                {...section}
                                key={section.reactKey}
                                className="page-section-renderer__section"
                            />
                        );
                    }

                    if (component === FixedComponentType.calendar) {
                        return (
                            <ConnectedCalendar
                                {...section}
                                key={section.reactKey}
                                className="page-section-renderer__section"
                            />
                        );
                    }

                    if (component === FixedComponentType.vacancies) {
                        return (
                            <ConnectedVacancyCardList
                                {...section}
                                key={section.reactKey}
                                className="page-section-renderer__section"
                            />
                        );
                    }
                }

                if (section.type === PageSection.testimonial) {
                    return (
                        <Testimonial
                            {...section as TestimonialInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section"
                        />
                    );
                }

                if (section.type === PageSection.sdgCarousel) {
                    return (
                        <SdgCarousel
                            {...section as SdgCarouselInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section"
                        />
                    );
                }

                if (section.type === PageSection.flipCardList) {
                    return (
                        <FlipCardList
                            {...section as FlipCardListInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section"
                        />
                    );
                }

                if (section.type === PageSection.presentation) {
                    return (
                        <Presentation
                            {...section as PresentationInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section"
                        />
                    );
                }

                if (section.type === PageSection.contactInvestorRelations) {
                    return (
                        <ConnectedContactInvestorRelations
                            {...section as ContactInvestorRelationsInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section"
                        />
                    );
                }

                if (section.type === PageSection.financialResultsTableList) {
                    return (
                        <FinancialResults
                            {...section as FinancialResultsTableListInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section"
                        />
                    );
                }

                if (section.type === PageSection.annualDownloads) {
                    return (
                        <AnnualDownloads
                            {...section as AnnualDownloadsInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section"
                        />
                    );
                }

                if (section.type === PageSection.webcast) {
                    return (
                        <Webcast
                            {...section as WebcastInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section"
                        />
                    );
                }

                if (section.type === PageSection.financialOverviewTable) {
                    return (
                        <FinancialOverview
                            {...section as FinancialOverviewTableInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section"
                        />
                    );
                }

                if (section.type === PageSection.highlightedDownloadCard) {
                    return (
                        <HighlightedDownloadCard
                            {...section as HighlightedDownloadCardInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section"
                        />
                    );
                }

                if (section.type === PageSection.highlightedKeyFigure) {
                    return (
                        <HighlightedKeyFigure
                            {...section as HighlightedKeyFigureInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section"
                        />
                    );
                }

                if (section.type === PageSection.tellerSharePrice) {
                    return (
                        <ConnectedTellerSharePrice
                            {...section as TellerSharePriceInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section"
                        />
                    );
                }

                if (section.type === PageSection.shareholderTable) {
                    return (
                        <Shareholders
                            {...section as ShareholderTableInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section"
                        />
                    );
                }

                if (section.type === PageSection.analystCoverageTable) {
                    return (
                        <AnalystCoverage
                            {...section as AnalystCoverageTableInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section"
                        />
                    );
                }

                if (section.type === PageSection.shareBuyback) {
                    return (
                        <ShareBuyback
                            {...section as ShareBuybackInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section"
                        />
                    );
                }

                if (section.type === PageSection.shareBuybackHistoryTable) {
                    return (
                        <ShareBuybackHistory
                            {...section as ShareBuybackHistoryTableInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section"
                        />
                    );
                }

                if (section.type === PageSection.callToActionCardList) {
                    return (
                        <CtaCardList
                            {...section as CallToActionCardListInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section"
                        />
                    );
                }

                if (section.type === PageSection.newsletterForm) {
                    return (
                        <NewsletterFormSection
                            {...section as NewsletterFormInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section"
                        />
                    );
                }

                if (section.type === PageSection.contactPersonForm) {
                    return (
                        <ContactPersonForm
                            {...section as ContactPersonFormInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section"
                        />
                    );
                }

                if (section.type === PageSection.illustratedContentBridge) {
                    return (
                        <IllustratedContentBridge
                            {...section as IllustratedContentBridgeInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section page-section-renderer__section--is-foreground"
                        />
                    );
                }

                if (section.type === PageSection.solutionDetailBody) {
                    return (
                        <ConnectedSolutionDetailBody
                            {...section as SolutionDetailBodyInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section"
                        />
                    );
                }

                if (section.type === PageSection.solutionsCarousel) {
                    return (
                        <ConnectedSolutionsCarousel
                            {...section as SolutionsCarouselInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section"
                        />
                    );
                }

                if (section.type === PageSection.highlightedArticle) {
                    return (
                        <HighlightedArticle
                            {...section as HighlightedArticleInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section"
                        />
                    );
                }

                if (section.type === PageSection.articlesOverview) {
                    return (
                        <ConnectedArticlesOverview
                            {...section as ArticlesOverviewInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section page-section-renderer__section--is-article-overview"
                        />
                    );
                }

                if (section.type === PageSection.newsletterCallToAction) {
                    return (
                        <ConnectedNewsletterCallToAction
                            {...section as NewsletterCallToActionInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section page-section-renderer__section--is-foreground"
                        />
                    );
                }

                if (section.type === PageSection.latestArticles) {
                    return (
                        <ConnectedLatestArticles
                            {...section as LatestArticlesInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section page-section-renderer__section--is-foreground"
                        />
                    );
                }

                if (section.type === PageSection.contactCompanyForm) {
                    return (
                        <ContactCompanyForm
                            {...section as ContactCompanyFormInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section"
                        />
                    );
                }

                if (section.type === PageSection.disclaimer) {
                    return (
                        <Disclaimer
                            {...section as DisclaimerInterface}
                            key={section.reactKey}
                            className="page-section-renderer__section"
                        />
                    );
                }

                return null;
            })}
        </div>
    );
};

export default PageSectionRenderer;
