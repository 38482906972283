import {
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import { Picture } from '../../components';
import { Image } from '../../entities/Media/Media';

import './BackgroundIllustration.scss';

interface BackgroundIllustrationProps {
    image: Image;
    className?: string;
    pictureClassName?: string;
}

const BackgroundIllustration: FC<BackgroundIllustrationProps> = ({
    image,
    className = '',
    pictureClassName = '',
}): ReactElement => {
    const ref = useRef<HTMLDivElement>(null);

    const [insetBlockStart, setInsetBlockStart] = useState<string>('');
    const [marginBlockStart, setMarginBlockStart] = useState<string>('');

    useEffect((): void => {
        if (!ref.current) {
            return;
        }

        const nextSibling = ref.current?.parentElement?.nextSibling as HTMLElement;

        if (!nextSibling) {
            return;
        }

        const computedStyle = getComputedStyle(nextSibling);

        setMarginBlockStart(computedStyle.marginBlockStart);
        setInsetBlockStart(computedStyle.insetBlockStart);
    }, [ref.current]);

    return (
        <div ref={ref} className={`background-illustration ${className}`}>
            <Picture
                {...image}
                style={{
                    marginBlockStart: marginBlockStart || insetBlockStart,
                }}
                className={`background-illustration__picture ${pictureClassName}`}
            />
        </div>
    );
};

export default BackgroundIllustration;
