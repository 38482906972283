import { FC, ReactElement, useEffect } from 'react';

import { SearchResults } from '../../containers';
import { fetchSearchResults, generateRecommendedPages } from '../../redux/search/searchActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

interface ConnectedSearchResultsProps {
    query: string;
}

const ConnectedSearchResults: FC<ConnectedSearchResultsProps> = ({ query }): ReactElement => {
    const dispatch = useTypedDispatch();

    const isLoading = useTypedSelector(state => state.searchReducer.isLoading);
    const pageSearchResults = useTypedSelector(state => state.searchReducer.pageResults);
    const articleSearchResults = useTypedSelector(state => state.searchReducer.articleResults);
    const solutionSearchResults = useTypedSelector(state => state.searchReducer.solutionResults);

    const hasFetchedRecommendedPageResults = useTypedSelector(state => state.searchReducer.hasFetchedRecommendedPageResults);
    const recommendedPageResults = useTypedSelector(state => state.searchReducer.recommendedPageResults);

    const mainNavigation = useTypedSelector(state => state.navigationReducer.mainNavigation);

    useEffect((): void => {
        dispatch(fetchSearchResults(query));
    }, [query]);

    useEffect((): void => {
        if (!hasFetchedRecommendedPageResults && mainNavigation) {
            dispatch(generateRecommendedPages(mainNavigation));
        }
    }, [recommendedPageResults, mainNavigation]);

    return (
        <SearchResults
            isLoading={isLoading}
            pageResults={pageSearchResults}
            articleResults={articleSearchResults}
            solutionResults={solutionSearchResults}
            recommendedPageResults={recommendedPageResults}
            mainNavigation={mainNavigation}
        />
    );
};

export default ConnectedSearchResults;
