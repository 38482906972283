import { FC, PropsWithChildren, ReactElement } from 'react';

import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import { companyTwitterHandle } from '../../../constants';
import { Link } from '../../../entities/Link/Link';
import { Seo, SeoMetaSocialType } from '../../../entities/Seo/Seo';
import useTrans from '../../../hooks/useTrans';
import { generateBreadcrumbStructuredData } from './helpers';

interface CustomPageHelmetProps {
    breadcrumbs: Link[];
    metaData: Seo;
}

const CustomPageHelmet: FC<PropsWithChildren<CustomPageHelmetProps>> = ({
    breadcrumbs,
    metaData,
    children,
}): ReactElement => {
    const { pathname } = useLocation();
    const trans = useTrans();

    const siteUrl = trans('company.url');
    const fallbackImageUrl = `${siteUrl}/images/metadata-preview.jpg`;

    const facebookData = metaData.social[SeoMetaSocialType.facebook];
    const twitterData = metaData.social[SeoMetaSocialType.twitter];

    const structuredBreadcrumbs = breadcrumbs.length > 0
        ? generateBreadcrumbStructuredData(breadcrumbs, siteUrl)
        : undefined;

    return (
        <Helmet>
            <title>{metaData.title}</title>
            <meta name="description" content={metaData.description} />
            <meta name="keywords" content={metaData.keywords} />

            <meta property="og:title" content={facebookData?.title || metaData.title} />
            <meta property="og:description" content={facebookData?.title || metaData.description} />

            <meta property="og:image" content={facebookData?.image?.src || fallbackImageUrl} />
            <meta property="og:image:alt" content={facebookData?.image?.alt || trans('company.name')} />

            {companyTwitterHandle && (
                <>
                    <meta property="twitter:card" content="summary" />
                    <meta property="twitter:site" content={companyTwitterHandle} />

                    <meta property="twitter:title" content={twitterData?.title || metaData.title} />
                    <meta property="twitter:description" content={twitterData?.title || metaData.description} />

                    <meta property="twitter:image" content={twitterData?.image?.src || fallbackImageUrl} />
                    <meta property="twitter:image:alt" content={twitterData?.image?.alt || trans('company.name')} />
                </>
            )}

            <link rel="canonical" href={metaData.canonicalURL || siteUrl + pathname} />

            {metaData.structuredData && (
                <script type="application/ld+json">
                    {metaData.structuredData}
                </script>
            )}

            {structuredBreadcrumbs && (
                <script type="application/ld+json">
                    {structuredBreadcrumbs}
                </script>
            )}

            {children}
        </Helmet>
    );
};

export default CustomPageHelmet;
