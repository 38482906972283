import { FC, ReactElement } from 'react';

import { NavLink } from 'react-router-dom';

import { Link } from '../../entities/Link/Link';
import { generateIdArray } from '../../helpers/array';

import './HeaderQuickLinksGroup.scss';

interface HeaderQuickLinksGroupProps {
    links: Link[];
    className?: string;
}

const HeaderQuickLinksGroup: FC<HeaderQuickLinksGroupProps> = ({ links, className = '' }): ReactElement => {
    const quickLinkKeys = generateIdArray(links.length);

    return (
        <ul className={`header-quick-links-group ${className}`}>
            {links.map((link, index) => (
                <li
                    key={quickLinkKeys[index]}
                    className="header-quick-links-group__list-item"
                >
                    <NavLink
                        to={link.path}
                        className="header-quick-links-group__link"
                    >
                        {link.label}
                    </NavLink>
                </li>
            ))}
        </ul>
    );
};

export default HeaderQuickLinksGroup;
