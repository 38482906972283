import { FC, ReactElement } from 'react';

import { TableCell, TableRow } from '../../../components';
import { FinancialOverviewTableInterface } from '../../../entities/@sections/FinancialOverviewTable/FinancialOverviewTable';
import { generateIdArray } from '../../../helpers/array';
import { NonSortTable } from '../..';

import './FinancialOverviewTable.scss';

interface FinancialOverviewTableProps extends FinancialOverviewTableInterface {
    className?: string;
}

const FinancialOverviewTable: FC<FinancialOverviewTableProps> = ({
    headerTitle,
    years,
    rows,
    className = '',
}): ReactElement => {
    const tableHeaders = [headerTitle, ...years].map(header => header.toString());
    const tableRowKeys = generateIdArray(rows.length);
    const tableCellKeys = generateIdArray(years.length + 1);

    return (
        <NonSortTable
            tableHeaders={tableHeaders}
            bodyClassName="financial-overview-table__body"
            cellClassName="financial-overview-table__cell"
            className={`financial-overview-table ${className}`}
        >
            {rows.map(((row, rowIndex) => (
                <TableRow key={tableRowKeys[rowIndex]}>
                    <TableCell isBold={row.isBold} className="financial-overview-table__cell">
                        {row.title}
                    </TableCell>

                    {row.numbers.map((number, cellIndex) => (
                        <TableCell
                            key={tableCellKeys[cellIndex]}
                            isBold={row.isBold}
                            className="financial-overview-table__cell"
                        >
                            {number}
                        </TableCell>
                    ))}
                </TableRow>
            )))}
        </NonSortTable>
    );
};

export default FinancialOverviewTable;
