import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import classNames from 'classnames';

import { Button, Icon } from '../../../components';
import { ButtonProps } from '../../../components/@buttons/Button/Button';
import { IconName } from '../../../components/Icon/Icon';

import './IconButton.scss';

export interface IconButtonProps extends ButtonProps {
    icon: IconName;
    iconPos?: 'left' | 'right';
    hideLabel?: boolean;
    iconClassName?: string;
}

const IconButton: ForwardRefExoticComponent<IconButtonProps> = forwardRef(({
    icon,
    iconPos = 'left',
    text,
    hideLabel,
    className = '',
    iconClassName = '',
    ...iconButtonProps
}, ref: Ref<HTMLButtonElement>): ReactElement => {
    const iconButtonClassNames = classNames('icon-button', {
        [`icon-button--align-${iconPos}`]: iconPos,
    }, className);

    return (
        <Button
            {...iconButtonProps}
            ref={ref}
            text={text}
            className={iconButtonClassNames}
        >
            <Icon
                name={icon}
                className={`icon-button__icon ${iconClassName}`}
            />

            {!hideLabel && text}
        </Button>
    );
});

export default IconButton;
