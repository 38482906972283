import { FC, ReactElement } from 'react';

import { Card } from '../../../components';
import { DownloadableFile } from '../../../entities/Media/Media';
import { LabeledDownloadButton } from '../..';

import './DownloadCard.scss';

export interface DownloadCardProps {
    title: string;
    file?: DownloadableFile;
    className?: string;
}

const DownloadCard: FC<DownloadCardProps> = ({
    title,
    file,
    className = '',
}): ReactElement => (
    <Card className={`download-card ${className}`}>
        <h2 className="download-card__title">
            {title}
        </h2>

        {file && (
            <LabeledDownloadButton
                file={file}
                className="download-card__labeled-download-button"
            />
        )}
    </Card>
);

export default DownloadCard;
