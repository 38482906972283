/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconVisibilityOn: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={100} viewBoxHeight={86.3} className={className}>
        <circle cx="50" cy="41" r="13.6" />
        <path d="M50,6.9C27.3,6.9,7.9,21.1,0,41c7.9,20,27.3,34.1,50,34.1S92.1,61,100,41C92.2,21.1,72.7,6.9,50,6.9z M50,63.8c-12.6,0-22.7-10.2-22.7-22.7S37.4,18.3,50,18.3S72.7,28.5,72.7,41S62.6,63.8,50,63.8z" />
    </Svg>
);

export default IconVisibilityOn;
/* eslint-enable max-len */
