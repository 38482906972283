import { FC, ReactElement, useEffect } from 'react';

import { LatestArticles } from '../../containers';
import { LatestArticlesInterface } from '../../entities/@sections/LatestArticles/LatestArticles';
import { QuerySortDirection } from '../../helpers/query';
import { generateStoreKey } from '../../helpers/store';
import { fetchArticleCategory } from '../../redux/articleCategories/articleCategoriesActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

interface ConnectedLatestArticlesProps extends LatestArticlesInterface {
    excludeArticleId?: string;
    className?: string;
}

const ConnectedLatestArticles: FC<ConnectedLatestArticlesProps> = ({
    title,
    categories,
    excludeArticleId,
    pageSize,
    className = '',
    ...defaultComponent
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const isLoading = useTypedSelector(state => state.articleCategoriesReducer.isLoading);
    const articleCategories = useTypedSelector(state => state.articleCategoriesReducer.categories);

    const key = generateStoreKey(categories, pageSize);

    useEffect((): void => {
        dispatch(fetchArticleCategory({
            key,
            categories,
            excludeArticleId,
            sort: {
                date: QuerySortDirection.desc,
            },
            pagination: {
                pageSize,
            },
        }));
    }, []);

    return (
        <LatestArticles
            {...defaultComponent}
            isLoading={isLoading}
            title={title}
            articles={articleCategories[key]?.articles || []}
            className={className}
        />
    );
};

export default ConnectedLatestArticles;
