import { FC, ReactElement } from 'react';

import { Section } from '../../../components';
import { SectionWrapper } from '../../../compositions';
import { ShareBuybackInterface } from '../../../entities/@sections/ShareBuyback/ShareBuyback';
import useTrans from '../../../hooks/useTrans';
import { BuybackCard, ShareBuybackLink } from './subcomponents';

import './ShareBuyback.scss';

interface ShareBuybackProps extends ShareBuybackInterface {
    className?: string;
}

const ShareBuyback: FC<ShareBuybackProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    title,
    intro,
    paragraph,
    latest,
    links,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <Section isDark={isDark} className={`share-buyback ${className}`}>
            <SectionWrapper
                noSpacingTop={noSpacingTop}
                noSpacingBottom={noSpacingBottom}
                className="share-buyback__wrapper"
            >
                <div className="share-buyback__description">
                    <h2 className="share-buyback__title">
                        {title}
                    </h2>

                    <p className="share-buyback__intro">
                        {intro}
                    </p>

                    <p className="share-buyback__details">
                        {paragraph}
                    </p>
                </div>

                <aside className="share-buyback__aside">
                    {latest && (
                        <div className="share-buyback__latest-wrapper">
                            <h3 className="share-buyback__sub-title">
                                {trans('containers.shareBuyback.latestShareBuyback')}
                            </h3>

                            <BuybackCard
                                {...latest}
                                className="share-buyback__buyback-card"
                            />
                        </div>
                    )}

                    {links.length > 0 && (
                        <ul className="share-buyback__link-list">
                            {links.map(link => (
                                <ShareBuybackLink
                                    key={link.id}
                                    {...link}
                                />
                            ))}
                        </ul>
                    )}
                </aside>
            </SectionWrapper>
        </Section>
    );
};

export default ShareBuyback;
