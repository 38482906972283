import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Picture } from '../../../../../components';
import { VisualCarouselSlideInterface } from '../../../../../entities/@sections/VisualCarousel/VisualCarousel';

import './VisualCarouselSlide.scss';

export interface VisualCarouselSlideProps extends VisualCarouselSlideInterface {
    className?: string;
}

const VisualCarouselSlide: FC<VisualCarouselSlideProps> = ({
    title,
    intro,
    paragraph,
    label,
    image,
    align,
    className = '',
}): ReactElement => {
    const slideClassNames = classNames('visual-carousel-slide', {
        [`visual-carousel-slide--align-${align}`]: align,
    }, className);

    return (
        <div className={slideClassNames}>
            <div className="visual-carousel-slide__text-wrapper">
                <h2 className="visual-carousel-slide__title">
                    {title}
                </h2>

                {intro && (
                    <h3 className="visual-carousel-slide__intro">
                        {intro}
                    </h3>
                )}

                <p className="visual-carousel-slide__description">
                    {paragraph}
                </p>

                <p className="visual-carousel-slide__label">
                    {label}
                </p>
            </div>

            {image && (
                <Picture
                    src={image.src}
                    alt={image.alt}
                    formats={image.formats}
                    className="visual-carousel-slide__picture"
                    imageClassName="visual-carousel-slide__image"
                />
            )}
        </div>
    );
};

export default VisualCarouselSlide;
