import { FC, memo, ReactElement } from 'react';

import { Skeleton } from '../../../components';
import { generateIdArray } from '../../../helpers/array';

import './ArticleCardListSkeletons.scss';

interface ArticleCardListSkeletonsProps {
    amountOfCards?: number;
    className?: string;
    itemClassName?: string;
}

const ArticleCardListSkeletons: FC<ArticleCardListSkeletonsProps> = ({
    amountOfCards = 3,
    className = '',
    itemClassName = '',
}): ReactElement => {
    const skeletonCards = generateIdArray(amountOfCards);

    return (
        <ul className={`article-card-list-skeletons ${className}`}>
            {skeletonCards.map(card => (
                <li key={card} className={`article-card-list-skeletons__list-item ${itemClassName}`}>
                    <div className="article-card-list-skeletons__card-header" />

                    <div className="article-card-list-skeletons__card-body">
                        <div className="article-card-list-skeletons__card-meta">
                            <Skeleton className="article-card-list-skeletons__card-category" />
                            <Skeleton className="article-card-list-skeletons__card-date" />
                        </div>

                        <div className="article-card-list-skeletons__card-text-wrapper">
                            <div className="article-card-list-skeletons__card-title-wrapper">
                                <Skeleton className="article-card-list-skeletons__card-title" />
                                <Skeleton className="article-card-list-skeletons__card-title" />
                            </div>

                            <div className="article-card-list-skeletons__card-read-more">
                                <Skeleton />
                            </div>
                        </div>
                    </div>
                </li>
            ))}
        </ul>
    );
};

export default memo(ArticleCardListSkeletons);
