import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { useSearchParams } from 'react-router-dom';

import { ArticlesOverview } from '../../containers';
import { ArticlesOverviewInterface } from '../../entities/@sections/ArticlesOverview/ArticlesOverview';
import { range } from '../../helpers/array';
import { QuerySortDirection } from '../../helpers/query';
import { generateStoreKey } from '../../helpers/store';
import { setHasDataFromServer } from '../../redux/app/appReducer';
import { fetchArticleCategory } from '../../redux/articleCategories/articleCategoriesActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

interface ConnectedArticlesOverviewProps extends ArticlesOverviewInterface {
    className?: string;
}

const ConnectedArticlesOverview: FC<ConnectedArticlesOverviewProps> = ({
    categories,
    startYear,
    className = '',
    ...defaultComponent
}): ReactElement => {
    const [searchParams, setSearchParams] = useSearchParams();

    const dispatch = useTypedDispatch();

    const hasDataFromServer = useTypedSelector(state => state.appReducer.hasDataFromServer);

    const isLoading = useTypedSelector(state => state.articleCategoriesReducer.isLoading);
    const articleCategories = useTypedSelector(state => state.articleCategoriesReducer.categories);

    const initialActivePage = searchParams.get('page') ? Number(searchParams.get('page')) : 1;
    const [activePage, setActivePage] = useState<number>(initialActivePage);

    const initialActiveYear = searchParams.get('year') ? Number(searchParams.get('year')) : undefined;
    const [activeYear, setActiveYear] = useState<number | undefined>(initialActiveYear);

    const currentYear = new Date().getFullYear() + 1;
    const years = range(startYear, currentYear).reverse();

    const key = generateStoreKey(categories);

    useEffect((): void => {
        setSearchParams({
            page: activePage.toString(),
            year: activeYear?.toString() || '',
        }, {
            replace: true,
        });

        if (hasDataFromServer) {
            dispatch(setHasDataFromServer(false));
            return;
        }

        dispatch(fetchArticleCategory({
            key,
            categories,
            year: activeYear,
            sort: {
                date: QuerySortDirection.desc,
            },
            pagination: {
                pageSize: 12,
                page: activePage,
            },
        }));
    }, [activePage, activeYear]);

    return (
        <ArticlesOverview
            {...defaultComponent}
            isLoading={isLoading}
            years={years}
            articles={articleCategories[key]?.articles || []}
            activeYear={activeYear}
            pagination={articleCategories[key]?.pagination}
            onYearSelect={setActiveYear}
            onPaginationChange={setActivePage}
            className={className}
        />
    );
};

export default ConnectedArticlesOverview;
