import {
    CSSProperties,
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import classNames from 'classnames';

import { Button, RootPortal } from '../../components';
import { useCookieSettings } from '../../context/CookieContext';
import useTrans from '../../hooks/useTrans';
import { clearCaches, isNewerVersion } from './helpers';

import './CacheBuster.scss';

interface CacheBusterProps {
    currentVersion: string;
    latestVersion: string;
}

const CacheBuster: FC<CacheBusterProps> = ({
    currentVersion,
    latestVersion,
}): ReactElement => {
    const trans = useTrans();
    const { cookieSettingsVisible, cookieSettingsBannerHeight } = useCookieSettings();

    const [shouldUpdate, setShouldUpdate] = useState<boolean>(false);

    useEffect((): void => {
        if (currentVersion && latestVersion) {
            const updateAvailable = isNewerVersion(latestVersion, currentVersion);

            setShouldUpdate(updateAvailable);
        }
    }, [currentVersion, latestVersion]);

    const cacheBusterClassNames = classNames('cache-buster', {
        'cache-buster--cookies-visible': cookieSettingsVisible,
        'cache-buster--is-revealed': shouldUpdate,
    });

    const cssVariables = {
        '--cache-buster-offset': `${cookieSettingsBannerHeight}px`,
    } as CSSProperties;

    return (
        <RootPortal>
            <section style={cssVariables} className={cacheBusterClassNames}>
                {shouldUpdate && (
                    <div className="cache-buster__card">
                        <p className="cache-buster__description">
                            {trans('containers.cacheBuster.updateAvailable')}
                        </p>

                        <Button
                            text={trans('containers.cacheBuster.reload')}
                            onClick={clearCaches}
                            className="cache-buster__button"
                        />
                    </div>
                )}
            </section>
        </RootPortal>
    );
};

export default CacheBuster;
