import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { transformToImage } from '../../Media/MediaTransformers';
import { PageSection } from '../../PageSection/PageSection';
import {
    ValueCardInterface,
    ValueCardResource,
    ValueCardsInterface,
    ValueCardsResource,
} from './ValueCards';

const transformToValueCard = (resource: ValueCardResource): ValueCardInterface => {
    const image = resource.image?.data
        ? transformToImage(resource.image.data)
        : undefined;

    return {
        id: resource.id,
        titleHtml: resource.title,
        description: resource.description || '',
        image,
    };
};

export const transformToValueCards = (resource: ValueCardsResource): ValueCardsInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    const cards = resource.cards.map(transformToValueCard);

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.valueCards,
        title: resource.title,
        cards,
    };
};
