/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconChevronBoldLeft: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={6} viewBoxHeight={12} className={className}>
        <path
            d="M 5.75 11.75 L 0.5 6.125 L 5.75 0.5"
            className="stroke"
        />
    </Svg>
);

export default IconChevronBoldLeft;
/* eslint-enable max-len */
