import { FC, ReactElement } from 'react';

import { ContactForm } from '../../../containers';
import { ContactFormData } from '../../../entities/@forms/ContactForm/ContactForm';
import { sendContactForm } from '../../../redux/contactForm/contactFormActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedContactFormProps {
    className?: string;
}

const ConnectedContactForm: FC<ConnectedContactFormProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const isLoading = useTypedSelector(state => state.contactFormReducer.isLoading);
    const isSuccessful = useTypedSelector(state => state.contactFormReducer.isSuccessful);

    const handleSubmit = (formData: ContactFormData): void => {
        dispatch(sendContactForm(formData));
    };

    return (
        <ContactForm
            isLoading={isLoading}
            isSuccessful={isSuccessful}
            onSubmit={handleSubmit}
            className={className}
        />
    );
};

export default ConnectedContactForm;
