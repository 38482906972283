import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { transformToKeyFigure } from '../../KeyFigure/KeyFigureTransformers';
import { PageSection } from '../../PageSection/PageSection';
import {
    GoalCardInterface,
    GoalCardListInterface,
    GoalCardListResource,
    GoalCardResource,
} from './GoalCardList';

const transformToGoalCard = (resource: GoalCardResource): GoalCardInterface => {
    const keyFigure = resource.keyFigure
        ? transformToKeyFigure(resource.keyFigure)
        : undefined;

    return {
        id: resource.id,
        caption: resource.caption || '',
        keyFigure,
        paragraph: resource.paragraph || '',
    };
};

export const transformToGoalCardList = (resource: GoalCardListResource): GoalCardListInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    const cards = resource.cards
        ? resource.cards.map(transformToGoalCard)
        : [];

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.goalCardList,
        title: resource.title || '',
        cards,
    };
};
