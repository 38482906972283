import { FC, ReactElement } from 'react';

import { Card, KeyFigure } from '../../../components';
import { GoalCardInterface } from '../../../entities/@sections/GoalCardList/GoalCardList';
import useTrans from '../../../hooks/useTrans';

import './GoalCard.scss';

export interface GoalCardProps extends Omit<GoalCardInterface, 'id'> {
    className?: string;
}

const GoalCard: FC<GoalCardProps> = ({
    caption,
    keyFigure,
    paragraph,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <Card className={`goal-card ${className}`}>
            <h2 className="goal-card__caption">
                {caption || trans('components.goalCard.label')}
            </h2>

            {keyFigure && (
                <KeyFigure
                    {...keyFigure}
                    className="goal-card__key-figure"
                />
            )}

            {paragraph && (
                <p className="goal-card__paragraph">
                    {paragraph}
                </p>
            )}
        </Card>
    );
};

export default GoalCard;
