import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import {
    Picture,
    SafeHtml,
    Section,
    ThemeTitle,
} from '../../../components';
import { SectionWrapper } from '../../../compositions';
import { MapCallToActionInterface } from '../../../entities/@sections/MapCallToAction/MapCallToAction';
import useTrans from '../../../hooks/useTrans';
import { MapCtaCircle } from './subcomponents';

import './MapCta.scss';

interface MapCtaProps extends MapCallToActionInterface {
    className?: string;
}

const MapCta: FC<MapCtaProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    titleHtml,
    paragraphHtml,
    link,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <Section isDark={isDark} className={`map-cta ${className}`}>
            <SectionWrapper
                noSpacingTop={noSpacingTop}
                noSpacingBottom={noSpacingBottom}
                className="map-cta__wrapper"
            >
                <div className="map-cta__text-wrapper">
                    <ThemeTitle
                        titleHtml={titleHtml}
                        className="map-cta__title"
                    />

                    <SafeHtml
                        html={paragraphHtml}
                        className="map-cta__intro"
                    />

                    {link && (
                        <Link
                            to={link.path}
                            className="map-cta__cta-link"
                        >
                            {link.label}
                        </Link>
                    )}
                </div>

                <div className="map-cta__picture-wrapper">
                    <Picture
                        src="/images/world-map.svg"
                        alt={trans('compositions.mapCta.imageAlt')}
                        className="map-cta__picture"
                        imageClassName="map-cta__image"
                    />

                    <div className="map-cta__picture-overlay">
                        <MapCtaCircle className="map-cta__picture-overlay-circle" />

                        <MapCtaCircle className="map-cta__picture-overlay-circle" />

                        <MapCtaCircle className="map-cta__picture-overlay-circle" />

                        <MapCtaCircle className="map-cta__picture-overlay-circle" />
                    </div>
                </div>
            </SectionWrapper>
        </Section>
    );
};

export default MapCta;
