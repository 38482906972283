import { NewsletterFormData } from '../../entities/@forms/NewsletterForm/NewsletterForm';
import { sendNewsletterFormEmail } from '../../entities/@forms/NewsletterForm/NewsletterFormService';
import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { TypedDispatch } from '../store';
import { addNegativeToast, addPositiveToast } from '../toast/toastActions';
import { setIsLoading, setIsSuccessful } from './newsletterFormReducer';

export const sendNewsletterForm = (formData: NewsletterFormData) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));

    try {
        const response = await sendNewsletterFormEmail(formData);

        if (!isFetchResultSuccessful(response)) {
            console.error('[sendNewsletterForm]', response.error.description);

            dispatch(addNegativeToast(response.error));

            return;
        }

        dispatch(setIsSuccessful(true));
        dispatch(addPositiveToast(response.data));
    } catch (error) {
        console.error('[sendNewsletterForm]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
