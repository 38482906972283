export enum SdgTypeResource {
    noPoverty = 'No poverty',
    zeroHunger = 'Zero hunger',
    goodHealthAndWellBeing = 'Good health and well being',
    qualityEducation = 'Quality education',
    genderEquality = 'Gender equality',
    cleanWaterAndSanitation = 'Clean Water and Sanitation',
    affordableAndCleanEnergy = 'Affordable and Clean Energy',
    decentWorkAndEconomicGrowth = 'Decent Work and Economic Growth',
    industryInnovationAndInfrastructure = 'Industry, Innovation, and Infrastructure',
    reducedInequalities = 'Reduced Inequalities',
    sustainableCitiesAndCommunities = 'Sustainable Cities and Communities',
    responsibleConsumptionAndProduction = 'Responsible Consumption and Production',
    climateAction = 'Climate Action',
    lifeBelowWater = 'Life Below Water',
    lifeOnLand = 'Life On Land',
    peaceAndJusticeStrongInstitutions = 'Peace and Justice, Strong Institutions',
    partnershipsForTheGoals = 'Partnerships for the Goals',
}

export enum SdgType {
    noPoverty = 'noPoverty',
    zeroHunger = 'zeroHunger',
    goodHealthAndWellBeing = 'goodHealthAndWellBeing',
    qualityEducation = 'qualityEducation',
    genderEquality = 'genderEquality',
    cleanWaterAndSanitation = 'cleanWaterAndSanitation',
    affordableAndCleanEnergy = 'affordableAndCleanEnergy',
    decentWorkAndEconomicGrowth = 'decentWorkAndEconomicGrowth',
    industryInnovationAndInfrastructure = 'industryInnovationAndInfrastructure',
    reducedInequalities = 'reducedInequalities',
    sustainableCitiesAndCommunities = 'sustainableCitiesAndCommunities',
    responsibleConsumptionAndProduction = 'responsibleConsumptionAndProduction',
    climateAction = 'climateAction',
    lifeBelowWater = 'lifeBelowWater',
    lifeOnLand = 'lifeOnLand',
    peaceAndJusticeStrongInstitutions = 'peaceAndJusticeStrongInstitutions',
    partnershipsForTheGoals = 'partnershipsForTheGoals',
}

export const transformToSdgType = (resource?: SdgTypeResource): SdgType => {
    if (resource === SdgTypeResource.zeroHunger) return SdgType.zeroHunger;
    if (resource === SdgTypeResource.goodHealthAndWellBeing) return SdgType.goodHealthAndWellBeing;
    if (resource === SdgTypeResource.qualityEducation) return SdgType.qualityEducation;
    if (resource === SdgTypeResource.genderEquality) return SdgType.genderEquality;
    if (resource === SdgTypeResource.cleanWaterAndSanitation) return SdgType.cleanWaterAndSanitation;
    if (resource === SdgTypeResource.affordableAndCleanEnergy) return SdgType.affordableAndCleanEnergy;
    if (resource === SdgTypeResource.decentWorkAndEconomicGrowth) return SdgType.decentWorkAndEconomicGrowth;
    if (resource === SdgTypeResource.industryInnovationAndInfrastructure) return SdgType.industryInnovationAndInfrastructure;
    if (resource === SdgTypeResource.reducedInequalities) return SdgType.reducedInequalities;
    if (resource === SdgTypeResource.sustainableCitiesAndCommunities) return SdgType.sustainableCitiesAndCommunities;
    if (resource === SdgTypeResource.responsibleConsumptionAndProduction) return SdgType.responsibleConsumptionAndProduction;
    if (resource === SdgTypeResource.climateAction) return SdgType.climateAction;
    if (resource === SdgTypeResource.lifeBelowWater) return SdgType.lifeBelowWater;
    if (resource === SdgTypeResource.lifeOnLand) return SdgType.lifeOnLand;
    if (resource === SdgTypeResource.peaceAndJusticeStrongInstitutions) return SdgType.peaceAndJusticeStrongInstitutions;
    if (resource === SdgTypeResource.partnershipsForTheGoals) return SdgType.partnershipsForTheGoals;

    return SdgType.noPoverty;
};

export interface SdgIcon {
    color: string;
    image: string;
    name: string;
}
