import { FC, ReactElement, useEffect } from 'react';

import { TellerSharePrice } from '../../containers';
import { TellerSharePriceInterface } from '../../entities/@sections/TellerSharePrice/TellerSharePrice';
import { fetchSharePrice } from '../../redux/sharePrice/sharePriceActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

interface ConnectedTellerSharePriceProps extends TellerSharePriceInterface {
    className?: string;
}

const ConnectedTellerSharePrice: FC<ConnectedTellerSharePriceProps> = ({
    title,
    paragraph,
    className = '',
    ...defaultComponent
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const isLoading = useTypedSelector(state => state.sharePriceReducer.isLoading);
    const error = useTypedSelector(state => state.sharePriceReducer.error);
    const sharePrice = useTypedSelector(state => state.sharePriceReducer.sharePrice);

    useEffect((): void => {
        dispatch(fetchSharePrice());
    }, []);

    return (
        <TellerSharePrice
            {...defaultComponent}
            isLoading={isLoading}
            title={title}
            paragraph={paragraph}
            sharePrice={sharePrice}
            error={error}
            className={className}
        />
    );
};

export default ConnectedTellerSharePrice;
