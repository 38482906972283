import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { transformToLink } from '../../Link/LinkTransformers';
import { PageSection } from '../../PageSection/PageSection';
import {
    ColumnWidth,
    ColumnWidthResource,
    ContentColumnInterface,
    ContentColumnResource,
    ContentColumnsInterface,
    ContentColumnsResource,
} from './ContentColumns';

const transformToColumnWidth = (resource?: ColumnWidthResource): ColumnWidth => {
    if (resource === ColumnWidthResource.three) return 3;
    if (resource === ColumnWidthResource.four) return 4;
    if (resource === ColumnWidthResource.six) return 6;

    return 2;
};

const transformToContentColumn = (resource: ContentColumnResource): ContentColumnInterface => {
    const introHtml = resource.intro
        ? `<p>${resource.intro}</p>`
        : '';

    const link = resource.link
        ? transformToLink(resource.link)
        : undefined;

    return {
        id: resource.id,
        titleHtml: resource.title || '',
        introHtml,
        paragraphHtml: resource.paragraph || '',
        link,
    };
};

export const transformToContentColumns = (resource: ContentColumnsResource): ContentColumnsInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    const columnWidth = transformToColumnWidth(resource.columnWidth);

    const columns = resource.columns.map(transformToContentColumn);

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.contentColumns,
        titleHtml: resource.title,
        paragraphHtml: resource.paragraph,
        columnWidth,
        columns,
    };
};
