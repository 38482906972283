/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconPin: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={20} viewBoxHeight={20} className={className}>
        <path d="M10 1.79999C13.866 1.79999 17 4.93399 17 8.79999C17 12.4745 14.8474 16.0622 10.6402 19.5682C10.2693 19.8772 9.73066 19.8772 9.35982 19.5682C5.15258 16.0622 3 12.4745 3 8.79999C3 4.93399 6.13401 1.79999 10 1.79999ZM10 5.79999C8.34315 5.79999 7 7.14313 7 8.79999C7 10.4568 8.34315 11.8 10 11.8C11.6569 11.8 13 10.4568 13 8.79999C13 7.14313 11.6569 5.79999 10 5.79999Z" fill="white" />
    </Svg>
);

export default IconPin;
/* eslint-enable max-len */
