import { transformToImage } from '../Media/MediaTransformers';
import {
    Seo,
    SeoMetaSocial,
    SeoMetaSocialResource,
    SeoMetaSocialType,
    SeoMetaSocialTypeResource,
    SeoResource,
} from './Seo';

const transformToSeoMetaSocialType = (resource: SeoMetaSocialTypeResource): SeoMetaSocialType => {
    if (resource === SeoMetaSocialTypeResource.twitter) return SeoMetaSocialType.twitter;

    return SeoMetaSocialType.facebook;
};

const transformToSeoMetaSocial = (resource: SeoMetaSocialResource): SeoMetaSocial => {
    const image = resource.image?.data
        ? transformToImage(resource.image.data)
        : undefined;

    return {
        title: resource.title,
        description: resource.description,
        image,
    };
};

export const transformToSeo = (resource: SeoResource): Seo => {
    const image = resource.metaImage?.data
        ? transformToImage(resource.metaImage.data)
        : undefined;

    const structuredData = resource.structuredData
        ? JSON.stringify(resource.structuredData)
        : undefined;

    const social: Partial<Record<SeoMetaSocialType, SeoMetaSocial>> = {};

    if (resource.metaSocial?.length) {
        for (let i = 0; i < resource.metaSocial.length; i += 1) {
            const socialData = resource.metaSocial[i];
            const platform = transformToSeoMetaSocialType(socialData.socialNetwork);

            social[platform] = transformToSeoMetaSocial(socialData);
        }
    }

    return {
        title: resource.metaTitle,
        description: resource.metaDescription,
        image,
        keywords: resource.keywords || '',
        canonicalURL: resource.canonicalURL || '',
        structuredData,
        social,
    };
};
