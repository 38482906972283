import {
    FC,
    HTMLAttributes,
    PropsWithChildren,
    ReactElement,
} from 'react';

import { GridLines } from '..';

import './Page.scss';

interface PageProps extends HTMLAttributes<HTMLDivElement> {
    className?: string;
}

const Page: FC<PropsWithChildren<PageProps>> = ({
    className = '',
    children,
    ...pagePops
}): ReactElement => (
    <main {...pagePops} className={`page ${className}`}>
        <div className="page__child-wrapper">
            {children}
        </div>

        <GridLines className="page__grid-lines" />
    </main>
);

export default Page;
