import { generateRestApiQuery } from '../../helpers/query';
import { NavigationType } from './Navigation';

export const generateNavigationQuery = (type: NavigationType): string => {
    const filters = {
        slug: {
            $eq: type,
        },
    };

    return generateRestApiQuery({ filters });
};
