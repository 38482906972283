import { generateUuid } from '../../helpers/string';
import { defaultDateIsoString } from '../Date/Date';
import { transformToDateIsoString, transformToTimeString } from '../Date/DateTransformers';
import { transformToLink } from '../Link/LinkTransformers';
import { Event, EventEntityResource } from './Event';

export const transformToEvent = (resource: EventEntityResource): Event => {
    const date = resource.attributes?.date
        ? transformToDateIsoString(resource.attributes.date)
        : defaultDateIsoString;

    const time = resource.attributes?.time
        ? transformToTimeString(resource.attributes.time)
        : '';

    const link = resource.attributes?.link
        ? transformToLink(resource.attributes.link)
        : undefined;

    return {
        id: resource.id || resource.attributes?.title || generateUuid(),
        title: resource.attributes?.title || '',
        date,
        time,
        location: resource.attributes?.location || '',
        link,
    };
};
