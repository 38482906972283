import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { transformToLink } from '../../Link/LinkTransformers';
import { PageSection } from '../../PageSection/PageSection';
import {
    QuickLinkInterface,
    QuickLinkResource,
    QuickLinksInterface,
    QuickLinksResource,
} from './QuickLinks';

export const transformToQuickLink = (resource: QuickLinkResource): QuickLinkInterface => {
    const link = transformToLink(resource.link);

    return {
        id: resource.id,
        paragraph: resource.paragraph || '',
        link,
    };
};

export const transformToQuickLinks = (resource: QuickLinksResource): QuickLinksInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    const links = resource.links
        ? resource.links.map(transformToQuickLink)
        : [];

    const links10To20 = resource.links10to20
        ? resource.links10to20.map(transformToQuickLink)
        : [];

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.quickLinks,
        showParagraphs: resource.showParagraph,
        titleHtml: resource.title,
        links: [...links, ...links10To20],
    };
};
