import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { PageSection } from '../../PageSection/PageSection';
import {
    ParallaxLineSectionInterface,
    ParallaxLineSectionResource,
    ParallaxLineSectionType,
    ParallaxLineSectionTypeResource,
} from './ParallaxLineSection';

const transformToParallaxLineSectionType = (resource?: ParallaxLineSectionTypeResource): ParallaxLineSectionType => {
    if (resource === ParallaxLineSectionTypeResource.manufacturing) return ParallaxLineSectionType.manufacturing;
    if (resource === ParallaxLineSectionTypeResource.connectivity) return ParallaxLineSectionType.connectivity;
    if (resource === ParallaxLineSectionTypeResource.people) return ParallaxLineSectionType.people;
    if (resource === ParallaxLineSectionTypeResource.sustainability) return ParallaxLineSectionType.sustainability;
    if (resource === ParallaxLineSectionTypeResource.innovation) return ParallaxLineSectionType.innovation;

    return ParallaxLineSectionType.vision;
};

export const transformToParallaxLineSection = (resource: ParallaxLineSectionResource): ParallaxLineSectionInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    const imageType = transformToParallaxLineSectionType(resource.lineImage);

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.parallaxLineSection,
        imageType,
        titleHtml: resource.title,
        caption: resource.caption,
    };
};
