import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { transformToDownloadableFile } from '../../Media/MediaTransformers';
import { PageSection } from '../../PageSection/PageSection';
import {
    ShareholderDocumentsInterface,
    ShareholderDocumentsResource,
    ShareholderDocumentTableRowInterface,
    ShareholderDocumentTableRowResource,
} from './ShareholderDocuments';

export const transformToShareholderDocumentTableRow = (resource: ShareholderDocumentTableRowResource): ShareholderDocumentTableRowInterface => {
    const files = resource.files?.data
        ? resource.files.data.map(transformToDownloadableFile)
        : [];

    return {
        category: resource.category,
        files,
    };
};

export const transformToShareholderDocuments = (resource: ShareholderDocumentsResource): ShareholderDocumentsInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    const tableRows = resource.tableRows
        ? resource.tableRows.map(transformToShareholderDocumentTableRow)
        : [];

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.shareholderDocuments,
        tableRows,
    };
};
