import { FC, ReactElement, useRef } from 'react';

import { Section } from '../../../components';
import {
    ArticleCardList,
    ArticleCardListSkeletons,
    Pagination,
    SectionWrapper,
    YearSelector,
} from '../../../compositions';
import { Article } from '../../../entities/Article/Article';
import { DefaultComponentAppearance } from '../../../entities/Component/Component';
import { PaginationInterface } from '../../../entities/Pagination/Pagination';
import { scrollIntoView } from '../../../helpers/scroll';
import useTrans from '../../../hooks/useTrans';

import './ArticlesOverview.scss';

interface ArticlesOverviewProps extends DefaultComponentAppearance {
    isLoading: boolean;
    years: number[];
    articles: Article[];
    activeYear?: number;
    pagination?: PaginationInterface;
    onYearSelect: (year?: number) => void;
    onPaginationChange: (page: number) => void;
    className?: string;
}

const ArticlesOverview: FC<ArticlesOverviewProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    isLoading,
    years,
    articles,
    activeYear,
    pagination,
    onYearSelect,
    onPaginationChange,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const articlesOverviewRef = useRef<HTMLDivElement>(null);

    const handleSelectYear = (year?: number): void => {
        onYearSelect(year);
        onPaginationChange(1);
    };

    const handlePaginationChange = (page: number): void => {
        scrollIntoView(articlesOverviewRef);

        onPaginationChange(page);
    };

    return (
        <Section
            ref={articlesOverviewRef}
            isDark={isDark}
            className={`articles-overview ${className}`}
        >
            <SectionWrapper
                noSpacingTop={noSpacingTop}
                noSpacingBottom={noSpacingBottom}
                className="articles-overview__wrapper"
            >
                <YearSelector
                    updateParams
                    hideLabel
                    showAllButton
                    activeYear={activeYear}
                    years={years}
                    onSelect={handleSelectYear}
                />

                {isLoading && (
                    <ArticleCardListSkeletons
                        amountOfCards={12}
                        className="articles-overview__list"
                    />
                )}

                {!isLoading && articles.length === 0 && (
                    <div className="articles-overview__no-results-wrapper">
                        <p className="articles-overview__no-results">
                            {trans('containers.articlesOverview.noResults')}
                        </p>
                    </div>
                )}

                {!isLoading && articles.length > 0 && (
                    <ArticleCardList
                        articles={articles}
                        className="articles-overview__list"
                    />
                )}

                {pagination && pagination.pageCount > 1 && (
                    <Pagination
                        {...pagination}
                        onChange={handlePaginationChange}
                        className="articles-overview__pagination"
                    />
                )}
            </SectionWrapper>
        </Section>
    );
};

export default ArticlesOverview;
