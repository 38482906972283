import { FC, ReactElement } from 'react';

import { PressReleaseForm } from '../../../containers';
import { PressReleaseFormData } from '../../../entities/@forms/PressReleaseForm/PressReleaseForm';
import { sendPressReleaseForm } from '../../../redux/pressReleaseForm/pressReleaseFormActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedPressReleaseFormProps {
    className?: string;
}

const ConnectedPressReleaseForm: FC<ConnectedPressReleaseFormProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const isLoading = useTypedSelector(state => state.pressReleaseFormReducer.isLoading);
    const isSuccessful = useTypedSelector(state => state.pressReleaseFormReducer.isSuccessful);

    const handleSubmit = (formData: PressReleaseFormData): void => {
        dispatch(sendPressReleaseForm(formData));
    };

    return (
        <PressReleaseForm
            isLoading={isLoading}
            isSuccessful={isSuccessful}
            onSubmit={handleSubmit}
            className={className}
        />
    );
};

export default ConnectedPressReleaseForm;
