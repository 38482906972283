import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Picture, Section } from '../../../components';
import { SectionWrapper, TestimonialQuote } from '../../../compositions';
import { TestimonialInterface } from '../../../entities/@sections/Testimonial/Testimonial';

import './Testimonial.scss';

interface TestimonialProps extends TestimonialInterface {
    className?: string;
}

const Testimonial: FC<TestimonialProps> = ({
    isDark,
    noSpacingTop,
    noSpacingBottom,
    testimonial,
    className = '',
}): ReactElement => {
    const testimonialClassName = classNames('testimonial', {
        'testimonial--has-portrait': testimonial.image,
    }, className);

    return (
        <Section isDark={isDark} className={testimonialClassName}>
            <SectionWrapper
                noSpacingTop={noSpacingTop}
                noSpacingBottom={noSpacingBottom}
                className="testimonial__wrapper"
            >
                <TestimonialQuote
                    {...testimonial}
                    className="testimonial__quote"
                    blockQuoteTextWrapperClassName="testimonial__quote-text-wrapper"
                />

                {testimonial.image && (
                    <div className="testimonial__portrait-wrapper">
                        <Picture
                            {...testimonial.image}
                            hasScaling
                            className="testimonial__portrait"
                            imageClassName="testimonial__portrait-image"
                        />
                    </div>
                )}
            </SectionWrapper>
        </Section>
    );
};

export default Testimonial;
