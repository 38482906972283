import { FC, memo, ReactElement } from 'react';

import { Skeleton, Wrapper } from '../../../../components';
import { generateIdArray } from '../../../../helpers/array';
import { randomInBetweenValue } from '../../../../helpers/number';

import './ArticleDetailSkeletons.scss';

interface ArticleDetailSkeletonsProps {
    className?: string;
}

const ArticleDetailSkeletons: FC<ArticleDetailSkeletonsProps> = ({
    className = '',
}): ReactElement => {
    const amountOfParagraphs = randomInBetweenValue(4, 6);
    const paragraphSkeletons = generateIdArray(amountOfParagraphs);

    return (
        <div className={`article-detail-skeletons ${className}`}>
            <div className="article-detail-skeletons__header">
                <div className="article-detail-skeletons__header-wrapper">
                    <div className="article-detail-skeletons__header-text-wrapper">
                        <Skeleton className="article-detail-skeletons__category" />

                        <div className="article-detail-skeletons__title-wrapper">
                            <Skeleton className="article-detail-skeletons__title" />
                            <Skeleton className="article-detail-skeletons__title" />
                        </div>

                        <Skeleton className="article-detail-skeletons__location" />
                        <Skeleton className="article-detail-skeletons__date" />
                    </div>

                    <div className="article-detail-skeletons__header-image-wrapper">
                        <div className="article-detail-skeletons__header-image" />
                    </div>
                </div>
            </div>

            <Wrapper>
                <div className="article-detail-skeletons__share-wrapper">
                    <Skeleton className="article-detail-skeletons__share" />
                </div>
            </Wrapper>

            <Wrapper className="article-detail-skeletons__body-wrapper">
                <div className="article-detail-skeletons__body">
                    {paragraphSkeletons.map(paragraph => {
                        const amountOfLines = randomInBetweenValue(3, 5);
                        const lines = generateIdArray(amountOfLines);

                        return (
                            <div key={paragraph} className="article-detail-skeletons__paragraph">
                                {lines.map(line => (
                                    <Skeleton key={line} className="article-detail-skeletons__paragraph-line" />
                                ))}
                            </div>
                        );
                    })}
                </div>

                <div className="article-detail-skeletons__aside">
                    <div className="article-detail-skeletons__aside-card-wrapper">
                        <div className="article-detail-skeletons__aside-card">
                            <Skeleton className="article-detail-skeletons__aside-card-title" />

                            <Skeleton className="article-detail-skeletons__aside-card-link" />
                        </div>
                    </div>

                    <div className="article-detail-skeletons__aside-card-wrapper">
                        <div className="article-detail-skeletons__aside-card">
                            <Skeleton className="article-detail-skeletons__aside-card-title" />

                            <Skeleton className="article-detail-skeletons__aside-card-link" />
                        </div>
                    </div>
                </div>
            </Wrapper>
        </div>
    );
};

export default memo(ArticleDetailSkeletons);
