import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { transformToDownloadableFile } from '../../Media/MediaTransformers';
import { PageSection } from '../../PageSection/PageSection';
import { transformToPerson } from '../../Person/PersonTransformers';
import { CommitteeInterface, CommitteeResource } from './Committee';

export const transformToCommittee = (resource: CommitteeResource): CommitteeInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    const file = resource.file?.data
        ? transformToDownloadableFile(resource.file.data)
        : undefined;

    const people = resource.people?.data
        ? resource.people.data.map(transformToPerson)
        : [];

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.committee,
        hidePortraits: resource.hidePortraits,
        title: resource.title,
        paragraphHtml: resource.paragraph || '',
        file,
        people,
    };
};
