import { generateUuid } from '../../helpers/string';
import {
    AppearanceResource,
    DefaultComponent,
    DefaultComponentAppearance,
    DefaultComponentAppearanceResource,
    DefaultComponentResource,
} from './Component';

export const transformToDefaultComponent = (resource: DefaultComponentResource): DefaultComponent => ({
    reactKey: generateUuid(),
    type: resource.__component,
});

export const transformToDefaultComponentAppearance = (resource: DefaultComponentAppearanceResource): DefaultComponentAppearance => {
    const isDark = resource.appearance.appearance === AppearanceResource.dark;

    return {
        isDark,
        noSpacingTop: resource.appearance.noSpacingTop || false,
        noSpacingBottom: resource.appearance.noSpacingBottom || false,
    };
};
