import { FC, ReactElement, useEffect } from 'react';

import { ArticlesCarousel } from '../../containers';
import { ArticlesCarouselInterface } from '../../entities/@sections/ArticlesCarousel/ArticlesCarousel';
import { QuerySortDirection } from '../../helpers/query';
import { generateStoreKey } from '../../helpers/store';
import { setHasDataFromServer } from '../../redux/app/appReducer';
import { fetchArticleCategory } from '../../redux/articleCategories/articleCategoriesActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

interface ConnectedArticlesCarouselProps extends ArticlesCarouselInterface {
    className?: string;
}

const ConnectedArticlesCarousel: FC<ConnectedArticlesCarouselProps> = ({
    title,
    categories,
    tag,
    className = '',
    ...defaultComponent
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const hasDataFromServer = useTypedSelector(state => state.appReducer.hasDataFromServer);

    const isLoading = useTypedSelector(state => state.articleCategoriesReducer.isLoading);
    const articleCategories = useTypedSelector(state => state.articleCategoriesReducer.categories);

    const key = generateStoreKey(categories);

    useEffect((): void => {
        if (hasDataFromServer) {
            dispatch(setHasDataFromServer(false));
            return;
        }

        dispatch(fetchArticleCategory({
            key,
            categories,
            tag,
            sort: {
                date: QuerySortDirection.desc,
            },
            pagination: {
                pageSize: 6,
                page: 1,
            },
        }));
    }, []);

    return (
        <ArticlesCarousel
            {...defaultComponent}
            isLoading={isLoading}
            title={title}
            articles={articleCategories[key]?.articles || []}
            className={className}
        />
    );
};

export default ConnectedArticlesCarousel;
