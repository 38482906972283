import { Entity } from '../../../types';
import { ArticleCategoryEntityResource } from '../../ArticleCategory/ArticleCategory';
import {
    DefaultComponent,
    DefaultComponentAppearance,
    DefaultComponentAppearanceResource,
    DefaultComponentResource,
} from '../../Component/Component';

export enum LatestArticlesPageSizeResource {
    items3 = 'Items 3',
    items6 = 'Items 6',
    items9 = 'Items 9 ',
    items12 = 'Items 12',
}

export interface LatestArticlesResource extends DefaultComponentResource, DefaultComponentAppearanceResource {
    id: string;
    title: string;
    categories?: Entity<ArticleCategoryEntityResource[]>;
    showNumberOfItems: LatestArticlesPageSizeResource;
}

export interface LatestArticlesInterface extends DefaultComponent, DefaultComponentAppearance {
    title: string;
    categories: string[];
    pageSize: number;
}
