export const writeTextToClipboard = async (text: string): Promise<boolean> => {
    try {
        if (!navigator.clipboard || !navigator.clipboard.writeText) {
            return false;
        }

        await navigator.clipboard.writeText(text);

        return true;
    } catch (error) {
        console.error('Async: Error copying text to clipboard', error);

        return false;
    }
};
