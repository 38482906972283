import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { GridLines, Wrapper } from '../../components';
import { Navigation } from '../../entities/Navigation/Navigation';
import useFormatDate from '../../hooks/useFormatDate';
import useTrans from '../../hooks/useTrans';
import { FooterSkeletons } from './skeletons';

import './Footer.scss';

interface FooterProps {
    isLoading: boolean;
    footerNavigation?: Navigation;
    legalNavigation?: Navigation;
    className?: string;
}

const Footer: FC<FooterProps> = ({
    isLoading,
    footerNavigation,
    legalNavigation,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const formatDate = useFormatDate();

    const currentYear = formatDate(new Date().toISOString(), { year: 'numeric' });

    return (
        <footer data-dark="true" className={`footer ${className}`}>
            <Wrapper className="footer__wrapper">
                {isLoading && (
                    <FooterSkeletons navClassName="footer__nav" />
                )}

                {!isLoading && footerNavigation && (
                    footerNavigation.list.map(navList => (
                        <nav key={navList.label} className="footer__nav">
                            <h2 className="footer__nav-title">
                                {navList.label}
                            </h2>

                            <ul className="footer__nav-list">
                                {navList.subNavigation.map(navItem => (
                                    <li key={navItem.label} className="footer__nav-item">
                                        <Link to={navItem.href} className="footer__nav-link">
                                            {navItem.label}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    ))
                )}

                {!isLoading && legalNavigation && (
                    <nav
                        aria-label={trans('containers.footer.legalNavigation.ariaLabel')}
                        className="footer__nav footer__nav--legal"
                    >
                        <ul className="footer__nav-list">
                            {legalNavigation.list.map(navItem => (
                                <li key={navItem.label} className="footer__nav-item footer__nav-item--legal">
                                    <Link to={navItem.href} className="footer__nav-link">
                                        {navItem.label}
                                    </Link>
                                </li>
                            ))}
                        </ul>

                        <p className="footer__copyright">
                            {`© ${trans('company.name')} ${currentYear}`}
                        </p>
                    </nav>
                )}
            </Wrapper>

            <GridLines className="footer__grid-lines" />
        </footer>
    );
};

export default Footer;
