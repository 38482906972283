import { Language } from '../constants/locale';
import { Link, LinkTarget } from '../entities/Link/Link';
import { NavigationItem } from '../entities/Navigation/Navigation';
import { getTranslation } from './trans';

const findBreadcrumbItemByPathname = (pathname: string, list: NavigationItem[]): NavigationItem | undefined => {
    for (let i = 0; i < list.length; i += 1) {
        const item = list[i];

        if (item.href === pathname) {
            return item;
        }

        const foundItem = findBreadcrumbItemByPathname(pathname, item.subNavigation);

        if (foundItem) {
            return foundItem;
        }
    }

    return undefined;
};

export const getBreadcrumbs = (pathname: string, mainNavigationList?: NavigationItem[]): Link[] => {
    const breadcrumbs: Link[] = [];

    const splitPath = pathname.split('/');
    const pathnameCategories = splitPath.slice(1, splitPath.length - 1);

    const pathnameMainCategory = pathnameCategories.length > 0
        ? `/${pathnameCategories[0]}`
        : undefined;

    const pathnameSubCategory = pathnameCategories.length > 1
        ? `/${pathnameCategories[0]}/${pathnameCategories[1]}`
        : undefined;

    if (!mainNavigationList) {
        return breadcrumbs;
    }

    for (let i = 0; i < mainNavigationList.length; i += 1) {
        const mainCategoryItem = mainNavigationList[i];

        if (mainCategoryItem.href === pathnameMainCategory) {
            breadcrumbs.push({
                label: mainCategoryItem.label,
                path: mainCategoryItem.href,
                target: mainCategoryItem.target,
            });

            for (let j = 0; j < mainCategoryItem.subNavigation.length; j += 1) {
                const subCategoryItem = mainCategoryItem.subNavigation[j];

                if (subCategoryItem.href === pathnameSubCategory) {
                    breadcrumbs.push({
                        label: subCategoryItem.label,
                        path: subCategoryItem.href,
                        target: subCategoryItem.target,
                    });
                }
            }
        }
    }

    const homePageLink: Link = {
        label: getTranslation(Language.en, 'routes.home.label'),
        path: getTranslation(Language.en, 'routes.home.path'),
        target: LinkTarget.self,
    };

    const currentNavigationItem = findBreadcrumbItemByPathname(pathname, mainNavigationList);

    const currentPageLink = currentNavigationItem
        ? {
            label: currentNavigationItem.label,
            path: currentNavigationItem.href,
            target: currentNavigationItem.target,
        }
        : undefined;

    const extendedBreadcrumbs = currentPageLink
        ? [homePageLink, ...breadcrumbs, currentPageLink]
        : [homePageLink, ...breadcrumbs];

    return extendedBreadcrumbs.length > 1
        ? extendedBreadcrumbs
        : [];
};
