import { Entity } from '../../../types';
import {
    DefaultComponent,
    DefaultComponentAppearance,
    DefaultComponentAppearanceResource,
    DefaultComponentResource,
} from '../../Component/Component';
import { Person, PersonEntityResource } from '../../Person/Person';

export enum PeopleListTypeResource {
    hideDescription = 'hide-description',
    hidePortraits = 'hide-portraits',
}

export interface PeopleListResource extends DefaultComponentResource, DefaultComponentAppearanceResource {
    id: string;
    type?: PeopleListTypeResource;
    people?: Entity<PersonEntityResource[]>;
}

export enum PeopleListType {
    default = 'default',
    hideDescription = 'hide-description',
    hidePortraits = 'hide-portraits',
}

export interface PeopleListInterface extends DefaultComponent, DefaultComponentAppearance {
    layoutType?: PeopleListType;
    people: Person[];
}
