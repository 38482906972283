import { transformToArticleCategory } from '../../ArticleCategory/ArticleCategoryTransformers';
import { transformToDefaultComponent, transformToDefaultComponentAppearance } from '../../Component/ComponentTransformers';
import { transformToLink } from '../../Link/LinkTransformers';
import { PageSection } from '../../PageSection/PageSection';
import { transformToTag } from '../../Tag/TagTransformers';
import { ArticlesCarouselInterface, ArticlesCarouselResource } from './ArticlesCarousel';

export const transformToArticlesCarousel = (resource: ArticlesCarouselResource): ArticlesCarouselInterface => {
    const defaultComponent = transformToDefaultComponent(resource);
    const defaultComponentAppearance = transformToDefaultComponentAppearance(resource);

    const categories = resource.article_categories?.data
        ? resource.article_categories.data.map(transformToArticleCategory)
        : [];

    const tag = transformToTag(resource.tag?.data);

    const link = resource.link
        ? transformToLink(resource.link)
        : undefined;

    return {
        ...defaultComponent,
        ...defaultComponentAppearance,
        type: PageSection.articlesCarousel,
        title: resource.title,
        categories,
        tag,
        link,
    };
};
